import { ApiPublic } from "./api-public.class";

export class ApiIntegration {
  private static instance: ApiIntegration;
  public apiPublic;

  constructor() {
    this.apiPublic = ApiPublic.getInstance();
  }

  public static getInstance(): ApiIntegration {
    if (!ApiIntegration.instance) {
      ApiIntegration.instance = new ApiIntegration();
    }
    return ApiIntegration.instance;
  }

  getBackendApi() {
    return this.apiPublic.backendApi;
  }

  setCredentialsOff() {
    this.apiPublic.backendApi.defaults.withCredentials = false;
  }

  setBaseUrl(url: string) {
    this.apiPublic.backendApi.defaults.baseURL = url;
  }

  setHeaderContentTypeJson() {
    this.apiPublic.backendApi.defaults.headers.common["Content-Type"] =
      "application/json";
  }

  setHeaderApiKey(apikey: string) {
    this.apiPublic.backendApi.defaults.headers.common["X-ApiKey"] =
      apikey ?? "";
  }
}
