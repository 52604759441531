<template>
  <v-form>
    <v-row noGutters>
      <v-col cols="10">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Server"
          v-model="emailConfigCopy.server"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Port"
          v-model="emailConfigCopy.port"
          type="number"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="10">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="E-Mail Adresse"
          v-model="emailConfigCopy.from"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-checkbox
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="SSL"
          v-model="emailConfigCopy.secure"
          density="compact"
          class="ma-1"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Username"
          v-model="emailConfigCopy.username"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Passwort"
          v-model="emailConfigCopy.password"
          density="compact"
          class="ma-1"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MailServer } from "../../models/mailserver.model";

export default defineComponent({
  name: "EmailServerConfigForm",
  props: {
    modelValue: {
      type: Object as () => MailServer,
      required: true,
    },
  },
  data() {
    const emailConfigCopy = this.modelValue || {};

    return {
      emailConfigCopy,
      vStyle: this.$store.state.vStyle,
    };
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue: {
      handler(model: any) {
        this.updateModel(model);
      },
      deep: true,
    },
  },
  methods: {
    updateModel(model: any) {
      this.emailConfigCopy = model;
    },
    toDisplayName(camelCaseString: string): string {
      // Teilt den String an den Stellen, wo ein Großbuchstabe steht, mit Ausnahme des ersten Zeichens
      const words: string[] = [];
      let startIndexOfWord = 0;

      for (let i = 1; i < camelCaseString.length; i++) {
        if (camelCaseString[i].toUpperCase() === camelCaseString[i]) {
          words.push(camelCaseString.substring(startIndexOfWord, i));
          startIndexOfWord = i;
        }
      }

      // Fügt das letzte Wort hinzu
      words.push(camelCaseString.substring(startIndexOfWord));

      // Kombiniert die Wörter mit Leerzeichen und macht das erste Zeichen jedes Wortes groß
      const displayName = words
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ");

      return displayName;
    },
  },
});
</script>
