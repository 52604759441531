import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-aeaa1574"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_DispatcherBoard = _resolveComponent("DispatcherBoard")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_LeftHeader = _resolveComponent("LeftHeader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_PdMasterHeader = _resolveComponent("PdMasterHeader")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.layout === _ctx.AppLayout.dispositioner)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MainHeader, {
            onTabSelected: _ctx.handleTabSelection,
            showDispositionTabs: true
          }, null, 8, ["onTabSelected"]),
          (_ctx.currentTab === _ctx.HeaderTab.DispatcherBoard)
            ? (_openBlock(), _createBlock(_component_DispatcherBoard, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.currentTab === _ctx.HeaderTab.Calendar)
            ? (_openBlock(), _createBlock(_component_Calendar, { key: 1 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.layout === _ctx.AppLayout.pdmaster)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LeftHeader)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "11"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PdMasterHeader, {
                  onTabSelected: _ctx.handleTabSelection,
                  showDispositionTabs: true
                }, null, 8, ["onTabSelected"]),
                (_ctx.currentTab === _ctx.HeaderTab.DispatcherBoard)
                  ? (_openBlock(), _createBlock(_component_DispatcherBoard, { key: 0 }))
                  : _createCommentVNode("", true),
                (_ctx.currentTab === _ctx.HeaderTab.Calendar)
                  ? (_openBlock(), _createBlock(_component_Calendar, { key: 1 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}