<template>
  <AdminMenuComponent></AdminMenuComponent>
  <PdMasterHeader v-if="appLayout === AppLayout.pdmaster"></PdMasterHeader>
  <v-row>
    <v-col class="ma-10" cols="12" md="auto">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title>DEBUG Info</v-card-title>
        <v-card-text>
          <v-list>
            <li>Version {{ getVersion() }}</li>
            <li>Environment: {{ getEnvironment() }}</li>
            <li>Backend URL: {{ getBackendURI() }}</li>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import AdminMenuComponent from "../menus/admin-menu.component.vue";
import { AppLayout } from "@/enums/app-layout.enum";
import PdMasterHeader from "../header/PdMasterHeader.vue";

export default defineComponent({
  name: "DebugInfo",
  components: {
    AdminMenuComponent,
    PdMasterHeader,
  },
  data() {
    return {
      appLayout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      AppLayout: AppLayout,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["getEnv"]),
  },
  methods: {
    getBackendURI() {
      return this.$store.getters.getEnv.VUE_APP_BACKEND_URI;
    },
    getEnvironment() {
      return this.$store.getters.getEnv.NODE_ENV;
    },
    getVersion() {
      return this.$store.getters.getEnv.VUE_APP_VERSION;
    },
  },
});
</script>
