<!--src/components/payflow/PayFlowEmployee.vue-->
<template>
  <div class="mt-5 d-flex align-end">
    <v-img
      :src="logoPayFlow"
      aspect-ratio="1"
      cover
      alt="PayFlow Icon"
      max-width="4rem"
      class="ml-4 mr-6"
      ><v-tooltip activator="parent" location="bottom"
        >= Eintrag in Lohnlaufinfo oder Vorschussliste</v-tooltip
      >
    </v-img>
    <v-select
      variant="plain"
      density="compact"
      :items="payrollMonths"
      v-model="currentPayrollMonth"
      label="Abrechnungsmonat"
    ></v-select>
    <v-spacer></v-spacer>
    <v-btn
      icon
      size="small"
      color="primary"
      :style="{ color: 'var(--color-font-light)' }"
      @click="newPayFlow"
    >
      <v-icon>fas fa-plus</v-icon>
      <v-tooltip activator="parent" location="bottom"
        >Neuen PayFlow hinzufügen</v-tooltip
      ></v-btn
    >
  </div>

  <v-list-item v-for="payFlow in payFlows" :key="payFlow._id">
    <v-list-item-title class="d-flex align-end font-weight-medium">
      {{ payFlow.payrollMonth || "monatlich" }}
      <strong class="ml-1 mt-3">{{ payFlow.infoType }}</strong>
      <div v-for="role in checkedByRoles" :key="role">
        <div
          v-if="getBadgeConfig(payFlow, role) && !isPayFlowCompleted(payFlow)"
          class="d-flex"
        >
          <v-badge
            :color="getBadgeConfig(payFlow, role)?.color"
            :style="{ scale: 0.8 }"
            :content="getBadgeConfig(payFlow, role)?.content"
            overlap
            offset-x="0"
            offset-y="-5"
            class="mx-1"
          >
            <v-icon class="text-medium-emphasis">
              {{ getBadgeConfig(payFlow, role)?.icon }}
            </v-icon>
          </v-badge>
          <v-tooltip activator="parent" location="end">
            {{ getBadgeConfig(payFlow, role)?.tooltip }}
          </v-tooltip>
        </div>
      </div>
    </v-list-item-title>
    <div class="d-flex text-medium-emphasis">
      <div v-if="isPayFlowCompleted(payFlow)">
        <v-badge
          color="success"
          :style="{ scale: 0.8 }"
          content="✔"
          overlap
          offset-x="0"
          offset-y="0"
          class="mr-2"
        >
          <v-icon class="text-medium-emphasis">fas fa-money-bill-wave</v-icon>
        </v-badge>
        <v-tooltip activator="parent" location="end"> Abgeschlossen </v-tooltip>
      </div>

      <div class="mr-1" v-if="payFlow.monetaryAmount">
        Betrag <strong>{{ payFlow.monetaryAmount }}</strong
        >€
      </div>
      <div class="mr-1" v-if="payFlow.hours">
        <strong>{{ payFlow.hours }}</strong> Stunden
      </div>
      <div class="mr-1" v-if="payFlow.dateFrom">
        {{ formatDate(payFlow.dateFrom) }}
      </div>
      <div class="mr-1" v-if="payFlow.dateTo">
        bis {{ formatDate(payFlow.dateTo) }}
      </div>
      <v-list-item-action
        v-if="!isPayFlowRecordedInErp(payFlow) || !isPayFlowCompleted(payFlow)"
        class="text-high-emphasis"
      >
        <v-btn
          icon
          size="small"
          variant="text"
          density="compact"
          :disabled="isPayFlowRecordedInErp(payFlow)"
          @click="editPayFlow(payFlow)"
        >
          <v-icon size="xs">fas fa-pencil</v-icon>
        </v-btn>
        <v-btn
          icon
          size="small"
          variant="text"
          density="compact"
          :disabled="isPayFlowRecordedInErp(payFlow)"
          @click="removePayFlow(payFlow._id)"
        >
          <v-icon size="xs">fas fa-trash-can</v-icon>
        </v-btn>
      </v-list-item-action>
    </div>
  </v-list-item>
  <v-divider></v-divider>
  <DialogAddEditPayFlow
    ref="dialogAddEditPayFlowComponent"
    :payFlow="currentPayFlow"
    :isEdit="editCurrentPayFlow"
    @addPayFlow="addPayFlow"
    @updatePayFlow="updatePayFlow"
  ></DialogAddEditPayFlow>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PayFlow } from "@/models/payflow.model";
import { PayFlowCheck, PayrollInfoType } from "@/enums/payflow.enum";
import { PayFlowService } from "@/services/api/payflow.service";
import DialogAddEditPayFlow from "./DialogAddEditPayFlow.vue";
import DialogService from "@/services/dialog.service";
import moment from "moment";
import ToastService from "@/services/toast.service";

export default defineComponent({
  name: "PayFlowEmployee",
  components: {
    DialogAddEditPayFlow,
  },
  props: {
    employeeNumber: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
    mandants: {
      type: Array as () => string[],
      required: true,
    },
  },
  data() {
    return {
      checkedByRoles: ["branchManager", "accountant", "payrollOfficer"],
      currentPayFlow: {} as PayFlow,
      currentPayrollMonth: moment().format("YYYY-MM"),
      editCurrentPayFlow: false,
      logoPayFlow: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/payflow-logo-light.svg`,
      payFlowService: new PayFlowService(),
      payFlows: [] as PayFlow[],
    };
  },
  computed: {
    payrollMonths(): string[] {
      const months = [];
      for (let i = 1; i > 0; i--) {
        months.push(moment().subtract(i, "months").format("YYYY-MM"));
      }
      months.push(moment().format("YYYY-MM"));
      months.push(moment().add(1, "months").format("YYYY-MM"));
      return months;
    },
  },
  mounted() {
    this.loadPayFlows();
  },
  watch: {
    currentPayrollMonth() {
      this.loadPayFlows();
    },
  },
  methods: {
    async addPayFlow(payFlow: PayFlow) {
      try {
        const response = await this.payFlowService.addPayFlow(payFlow);
        if (response.includes("success")) {
          ToastService.showSuccess("PayFlow erfolgreich hinzugefügt");
          if (this.$refs.dialogAddEditPayFlowComponent) {
            (
              this.$refs.dialogAddEditPayFlowComponent as InstanceType<
                typeof DialogAddEditPayFlow
              >
            ).closeModal();
          }
          this.loadPayFlows();
        }
      } catch (error) {
        ToastService.showError("Fehler beim erstellen des PayFlows!");
        console.error("Error adding PayFlows", error);
      }
    },
    editPayFlow(payFlow: PayFlow) {
      this.currentPayFlow = payFlow;
      this.editCurrentPayFlow = true;
      if (this.$refs.dialogAddEditPayFlowComponent) {
        (
          this.$refs.dialogAddEditPayFlowComponent as InstanceType<
            typeof DialogAddEditPayFlow
          >
        ).openModal();
      }
    },
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    getBadgeConfig(payFlow: PayFlow, role: string) {
      const checkedItem = payFlow.checked.find(
        (item) => item.payrollMonth === this.currentPayrollMonth
      );

      if (!checkedItem) return null;

      const checkStatus = checkedItem[role as keyof typeof checkedItem];

      switch (checkStatus) {
        case PayFlowCheck.accepted:
          return {
            color: "success",
            content: "✔",
            icon: this.getRoleIcon(role),
            tooltip: `von ${this.getRoleName(role)} geprüft`,
          };
        case PayFlowCheck.edited:
          return {
            color: "yellow-darken-1",
            content: "✎",
            icon: this.getRoleIcon(role),
            tooltip: `von ${this.getRoleName(role)} bearbeitet`,
          };
        case PayFlowCheck.rejected:
          return {
            color: "abort",
            content: "✖",
            icon: this.getRoleIcon(role),
            tooltip: `von ${this.getRoleName(role)} abgelehnt`,
          };
        default:
          return null;
      }
    },
    getRoleIcon(role: string) {
      switch (role) {
        case "branchManager":
          return "fas fa-building-user";
        case "accountant":
          return "fas fa-scale-balanced";
        case "payrollOfficer":
          return "fas fa-user-tie";
        default:
          return "";
      }
    },
    getRoleName(role: string) {
      switch (role) {
        case "branchManager":
          return "Niederlassungsleitung";
        case "accountant":
          return "Buchhaltung";
        case "payrollOfficer":
          return "Lohnauszahlung";
        default:
          return "";
      }
    },
    isPayFlowRecordedInErp(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) =>
          item.recordedInErp === true &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },

    isPayFlowCompleted(payFlow: PayFlow) {
      const checkedItem = payFlow.checked.find(
        (item) =>
          item.completed === true &&
          item.payrollMonth === this.currentPayrollMonth
      );

      return !!checkedItem;
    },
    async loadPayFlows() {
      try {
        const response = await this.payFlowService.getPayFlows(
          this.employeeNumber,
          this.currentPayrollMonth
        );
        this.payFlows = response;
      } catch (error) {
        ToastService.showError("Fehler beim Laden der PayFlows!");
        console.error("Error loading PayFlows:", error);
      }
    },
    newPayFlow() {
      this.currentPayFlow = {
        employeeNumber: this.employeeNumber,
        mandants: this.mandants,
        infoType: PayrollInfoType.advance,
        firstName: this.firstName,
        lastName: this.lastName,
        monthly: false,
        payrollMonth: this.currentPayrollMonth,
        creationDate: moment().format("YYYY-MM"),
        checked: [
          {
            payrollMonth: this.currentPayrollMonth,
            branchManager: PayFlowCheck.none,
            accountant: PayFlowCheck.none,
            payrollOfficer: PayFlowCheck.none,
            recordedInErp: false,
            completed: false,
          },
        ],
        info: "",
      };
      this.editCurrentPayFlow = false;
      if (this.$refs.dialogAddEditPayFlowComponent) {
        (
          this.$refs.dialogAddEditPayFlowComponent as InstanceType<
            typeof DialogAddEditPayFlow
          >
        ).openModal();
      }
    },
    async removePayFlow(id: string | undefined) {
      if (!id) return;
      const confirmed = await DialogService.confirm(
        "PayFlow wirklich löschen?",
        "Abbrechen",
        "Löschen"
      );
      if (!confirmed) {
        return;
      }
      try {
        const result = await this.payFlowService.removePayFlow(id);
        if (result) {
          this.payFlows = this.payFlows.filter((payFlow) => payFlow._id !== id);
          ToastService.showSuccess("PayFlow erfolgreich gelöscht!");
          if (this.$refs.dialogAddEditPayFlowComponent) {
            (
              this.$refs.dialogAddEditPayFlowComponent as InstanceType<
                typeof DialogAddEditPayFlow
              >
            ).closeModal();
          }
          this.loadPayFlows();
        }
      } catch (error) {
        ToastService.showError("Fehler beim Löschen des PayFlows!");
        console.error("Error removing PayFlow:", error);
      }
    },
    async updatePayFlow(payFlow: PayFlow) {
      if (!payFlow._id) return;
      try {
        const response = await this.payFlowService.updatePayFlow(payFlow);
        if (response._id) {
          ToastService.showSuccess("PayFlow erfolgreich hinzugefügt");
          if (this.$refs.dialogAddEditPayFlowComponent) {
            (
              this.$refs.dialogAddEditPayFlowComponent as InstanceType<
                typeof DialogAddEditPayFlow
              >
            ).closeModal();
          }
          this.loadPayFlows();
        }
      } catch (error) {
        ToastService.showError("Fehler beim updaten des PayFlows!");
        console.error("Error adding PayFlows", error);
      }
    },
  },
});
</script>
