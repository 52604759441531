<!--src/views/subscription-view.vue-->
<template>
  <v-container class="pa-1 ma-1">
    <v-card
      :variant="vStyle.card.variant || undefined"
      :rounded="vStyle.card.rounded || undefined"
      :border="vStyle.card.border || undefined"
      flat
    >
      <v-card-title
        >{{ appname }} Abo Auswahl: Nutze jetzt die Chance, Teil unserer
        Alpha-Version zu werden!</v-card-title
      >
      <v-card-subtitle>
        Die App bietet die volle Power der künstlichen Intelligenz, ohne dein
        Budget zusätzlich zu belasten und entspricht dem DSGVO Standard
      </v-card-subtitle>
      <v-row class="pa-2 mt-5">
        <v-col cols="12" md="3">
          <v-card
            :variant="vStyle.card.variant || undefined"
            :rounded="vStyle.card.rounded || undefined"
            :border="vStyle.card.border || undefined"
          >
            <v-btn-toggle v-model="billingCycle" class="d-flex">
              <v-btn
                :variant="vStyle.btn.variant || undefined"
                :rounded="vStyle.btn.rounded || undefined"
                :border="vStyle.btn.border || undefined"
                value="monthly"
                :class="{ active: monthly }"
                style="width: 50%"
              >
                Monatlich
              </v-btn>
              <v-btn
                :variant="vStyle.btn.variant || undefined"
                :rounded="vStyle.btn.rounded || undefined"
                :border="vStyle.btn.border || undefined"
                value="yearly"
                :class="{ active: yearly }"
                style="width: 50%"
              >
                Jährlich
              </v-btn>
            </v-btn-toggle>
            <v-card-text>
              <PriceSelection
                v-for="(subscriptionPackage, index) in subscriptionPackages"
                :key="index"
                :appname="appname"
                :monthly="monthly"
                :subscription="subscriptionPackage"
                :class="subscriptionPackage.selected ? 'selected' : ''"
                @click="select(subscriptionPackage)"
              />
            </v-card-text>
          </v-card>
          <v-card
            :variant="vStyle.card.variant || undefined"
            :rounded="vStyle.card.rounded || undefined"
            :border="vStyle.card.border || undefined"
            class="my-5"
          >
            <v-card-title>
              Laufzeit: {{ monthly ? "1 Monat" : "12 Monate" }}
            </v-card-title>
          </v-card>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            color="success"
            @click="goToPayment"
            v-if="selected"
          >
            auf Warteliste setzten
          </v-btn>
        </v-col>
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="8">
          <FeatureOverview
            :features="features"
            :subscriptions="subscriptions"
            :descriptions="descriptions"
            :selected="selected"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PriceSelection from "@/components/subscription/price-selection.vue";
import FeatureOverview from "@/components/subscription/feature-overview.vue";
import { SubscriptionPackage } from "@/models/subcriptions.model";
import router from "@/router";
import { Routes } from "@/enums/routes.enum";

export default defineComponent({
  components: {
    PriceSelection,
    FeatureOverview,
  },
  data() {
    return {
      appname: "Dispostioner",
      billingCycle: "monthly",
      selected: "",
      features: [
        "Job-Poster: AI Stellengenerator",
        "Dispoboard: alle Prozesse auf einer Seite",
        "AI Profilakquise: Job Leads",
        "AI Profilerstellung",
        "automatisierte Dokumentation",
        "Vertriebscockpit mit AI Analytics",
        "alle AI Token*¹ inklusive",
        "Plan: Autodoku von C3X Telefonaten",
        "Plan: Multiplattformchat mit Bewerbereingang",
        "Plan: Kontaktpflege mit AI Chatbot",
        "Plan: Aufbereitung historischer Daten",
        "eigene Features entwickeln lassen",
      ],
      descriptions: [
        "<h3>Der Job-Poster</h3><h4>AI Stellengenerator<h4><br>Job Matrix:<br>eine Tabelle, die in der ersten Spalte Orte zeigt und<br>in der ersten Zeile alle Berufe.<br>Die Diponenten 'haken' an und die AI schreibt aus!<br>Job Liste:<br>Extrahiert Stellendaten von Kunden oder Anfragen<br>und schreibt alles mit Eurem corporate Desing aus!",
        "<h3>Das Dispoboard</h3><h4>Übersicht</h4>Alle Prozesse (Anfragen, Kandidaten, Kunden, externe Mitarbeiter)<br>werden übersichtlich auf einem Kanbanboard dargestellt. Ein Farb-<br>und Alarmsystem führt dich durch den gesamten Prozess.<br><h4>Statuswechsel automatisiert</h4>Kandidatenstatus werden automatisch vom System angepasst. Fehlende<br>Informationen werden aktiv bei den Disponenten angefragt.<br><h4>Was sucht dein Kunde?</h4>Dank der Anzeigendaten-Integration werden stets die aktuellen<br>Vakanzen der Kundenunternehmen angezeigt.<br><h4>Geschwindigkeit in der Datenanlage</h4>Neue Kunden, Interessenten und Zielkunden können einfach über<br>Suchbegriffe aus dem Internet, dem ERP-System oder Anzeigendaten<br>angelegt werden.<br><h4>Freie Kandidaten</h4>Freie Kandidaten können mit einem Klick auf der eigenen Webseite<br>veröffentlicht werden.<br><h4>Kommunikation</h4>Alle Unternehmen, Kandidaten oder Mitarbeiter können direkt<br>kontaktiert werden. Die AI generiert eigenständig Nachrichten,<br>übersetzt sie in andere Sprachen und liefert Textvorschläge.<br>",
        "<h3>JOB Leads</h3>Sobald ein Kandidat das Vorstellungsgespräch beendet hat,<br>sucht die AI vollautomatisiert im Hintergrund über Anzeigendaten<br>nach passenden Stellen und zeigt diese direkt dem Kandidaten an.<br>Neue Interessenten können direkt mit einem Klick aus den<br>Stellenanzeigen angelegt werden!",
        "<h3>AI Profilgenerator</h3>Kandidaten werden einfach mit der Maus auf den Kunden gezogen,<br>dann fragt die AI, ob sie ein Profil für die Firma erstellen soll<br>oder ob sie ein spezifisches Profil für eine Stellenanzeige von<br>diesem Kunden erstellen soll. Das Profil wird automatisch und<br>passgenau generiert. Außerdem wird eine E-Mail an die<br>Ansprechperson des Kunden erstellt. Alles wird als Vorschlag<br>angezeigt, und der Disponent kann alles nach Belieben anpassen.",
        "<h3>Automatisierte Dokumentation</h3>Jeder Geschäftsprozess wird automatisch dokumentiert und ist<br>direkt beim jeweiligen Unternehmen, Kandidaten oder Mitarbeiter<br>oder in einer Unternehmensübersicht einsehbar.<br>Wenn möglich, schreiben wir die Daten parallel in dein ERP-System,<br>damit du alles zentral hast.<br>Wenn das System keine Information hat, z.B. den Inhalt eines<br>Gesprächs, fragt es aktiv beim Disponenten nach und fordert die<br>Dokumentation ein, bis es sie erhält.",
        "<h3>Vertriebscockpit</h3>Alle KPIs wie Telefonate, Erreichbarkeit, Termine und Termintreue<br>werden aus den Dokumentationen erfasst und auf einem Dashboard<br>dargestellt und sofort gebenchmarkt.<br>Der Disponent steht immer im Vergleich zur gesamten Niederlassung<br>und zum gesamten Unternehmen und kann sich so selbst coachen.<br>Außerdem erstellt die KI immer eine motivierende Analyse und gibt<br>ihm fünf Tipps für den Tag, basierend auf deiner vorgegebenen<br>Unternehmensstrategie.",
        "Für die Nutzung unserer AI kaufen wir Rechenleistung ein, die wir nach Verbrauch bezahlen.<br><h4>Zur Einordnung:</h4>Bei normaler Nutzung benötigt ein Benutzer etwa ein bis drei Euro im Monat pro Disponent",
        "<h3>Autodoku von C3X Telefonaten</h3>Alle eingehenden und ausgehenden Telefonate der C3X-Telefonanlage<br>werden DSGVO-konform transkribiert, zusammengefasst und dem<br>jeweiligen Kunden, Mitarbeiter oder Kandidaten im System<br>zugeordnet. Wenn möglich, werden die Daten auch automatisiert<br>im ERP-System erfasst.",
        "<h3>Multiplattformchat</h3>Wir integrieren WhatsApp, Facebook, Instagram Messenger und viele<br>andere Plattformen in die App. Dort gibt es auch einen AI-Chatbot,<br>der Bewerber automatisiert durch den Prozess führt, fehlende<br>Informationen nachfragt und sie von deinem Unternehmen überzeugt.",
        "<h3>Kontaktpflege mit AI-Chatbot</h3>Ein AI-Chatbot durchsucht alle deine ehemaligen Bewerber, bei denen es<br>noch nicht geklappt hat, DSGVO-konform bis zu sechs Monate in die<br>Vergangenheit (24 Monate bei vorliegender Einverständniserklärung).<br>Die AI kontaktiert diese erneut, versucht sie mit deinen aktuellen Stellen<br>zu matchen oder erneut von deinem Unternehmen zu überzeugen.",
        "<h3>Datenschatz heben</h3>Alle historischen Kandidaten werden DSGVO-konform bis zu sechs<br>Monate in die Vergangenheit (24 Monate bei vorliegender<br>Einverständniserklärung) nochmals genau analysiert. Die AI<br>überprüft sie auf Zuverlässigkeit und, wenn es sich lohnt,<br>werden daraus Tags und Suchbegriffe gebildet, damit der Disponent<br>schnell über ein Suchfeld passende Mitarbeiter finden kann.",
        "<h3>Gestalte mit</h3>Bringe deine Ideen ins Projekt ein und lasse priorisiert Features entwickeln, die deine Probleme lösen.<br>So bringst du dein eigenes Unternehmen, unsere gemeinsame App und die ganze Branche voran.<br>Lass uns gemeinsam stark sein!",
      ],
      subscriptions: [
        {
          name: "Standard",
          features: [
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
          ],
        },
        {
          name: "Seed Investor",
          features: [
            true,
            true,
            true,
            true,
            true,
            true,
            "bei normaler Nutzung",
            true,
            true,
            false,
            false,
            false,
          ],
        },
        {
          name: "Business Angel",
          features: [
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
          ],
        },
      ],
      subscriptionPackages: [
        {
          name: "Standard",
          tooltip:
            "<h3>Standard Paket</h3>Deckt IT-Kosten und Betreuung der App ab. Es fällt je nach Nutzung ein sehr geringer Betrag zur Kostenbeteiligung*¹ an der KI an. Nutze 100% aller Funktionen im heutigen Zustand.",
          price: {
            monthly: 9,
            yearly: 99,
          },
          selected: false,
        },
        {
          name: "Seed Investor",
          tooltip:
            "<h3>Seed Investor</h3>Unterstützt unsere Weiterentwicklung und neue Features. Mit dieser Subscription können wir externe Entwicklungs-Power einkaufen und alle neuen Features direkt an unsere Seed Investoren und Business Angels ausrollen.",
          price: {
            monthly: 30,
            yearly: 299,
          },
          selected: false,
        },
        {
          name: "Business Angel",
          tooltip:
            "<h3>Business Angel</h3>Wünsche dir eigene Features und genieße priorisierten Support. Werde Teil der Community und erlebe, wie einfach und effizient Zeitarbeit sein kann.",
          price: {
            monthly: 50,
            yearly: 499,
          },
          selected: false,
        },
      ] as Array<SubscriptionPackage>,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    monthly() {
      return this.billingCycle === "monthly";
    },
    yearly() {
      return this.billingCycle === "yearly";
    },
  },
  methods: {
    select(subscriptionPackage: SubscriptionPackage) {
      this.subscriptionPackages.forEach((pkg) => (pkg.selected = false));
      this.selected = subscriptionPackage.name;
      subscriptionPackage.selected = true;
    },
    goToPayment() {
      router.push(Routes.dashboard);
    },
  },
  name: "SubscriptionView",
});
</script>

<style scoped>
.active {
  color: var(--color-font-light);
  background-color: var(--color-primary);
}
.selected {
  border: 2px solid var(--color-primary);
}
</style>
