<!-- App.vue -->
<template>
  <div id="app">
    <HeartbeatService />
    <TopHeader
      v-if="appLayout === AppLayout.dispositioner"
      :isLoggedIn="isLoggedIn"
      :showVersion="showVersion()"
    ></TopHeader>
    <JobPoster></JobPoster>
    <router-view />
    <!-- Spinner Overlay -->
    <div v-if="isSpinnerVisible" class="spinner-overlay">
      <SpinnerGame></SpinnerGame>
      <i class="fas fa-spinner fa-spin fa-3x"></i>
    </div>
  </div>
</template>

<script lang="ts">
import "@fortawesome/fontawesome-free/css/all.css";
import { defineComponent, ref } from "vue";
import { mapGetters } from "vuex";
import { onBeforeMount } from "vue";
import { onMounted, onUnmounted } from "vue";
import { UserHelperService } from "./services/user-helper.service";
import HeartbeatService from "@/helper/HeartbeatService.vue";
import JobPoster from "@/components/job-poster/job-poster.vue";
import TopHeader from "@/components/header/TopHeader.vue";
import SpinnerGame from "./components/spinner/SpinnerGame.vue";
import { AppLayout } from "./enums/app-layout.enum";
import router from "@/router";
import { EventBusEvent, EventBusService } from "./services/event-bus.service";

export default defineComponent({
  components: {
    TopHeader,
    HeartbeatService,
    JobPoster,
    SpinnerGame,
  },
  computed: {
    ...mapGetters(["getEnv"]),
  },
  data() {
    return {
      appLayout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      AppLayout: AppLayout,
      appTitle: this.$store.getters.getEnv.VUE_APP_NAME,
    };
  },
  mounted() {
    document.title = this.appTitle; // Set global title
    this.setFavicon(); // Set the favicon
  },
  setup() {
    const isSpinnerVisible = ref(false);
    const isLoggedIn = ref(false);
    const userHelperService = UserHelperService.getInstance();
    const eventBusService = EventBusService.getInstance();

    const handleShowSpinner = () => {
      isSpinnerVisible.value = true;
    };

    const handleHideSpinner = () => {
      isSpinnerVisible.value = false;
    };

    const updateUser = (newValue: boolean) => {
      isLoggedIn.value = newValue;
    };

    const initEventBus = () => {
      userHelperService.updateIsLoggedIn(); // update logged in status of user
      router.push("/");
    };

    onBeforeMount(() => {
      userHelperService.onChange(updateUser);
      isLoggedIn.value = userHelperService.isLoggedIn;
      eventBusService.setEventCallback(
        EventBusEvent.userLoggedOut,
        initEventBus
      );
      eventBusService.notify(EventBusEvent.userLoggedOut);
    });

    onMounted(() => {
      document.addEventListener("show-spinner", handleShowSpinner);
      document.addEventListener("hide-spinner", handleHideSpinner);
    });

    onUnmounted(() => {
      userHelperService.removeFromListener(updateUser);
      document.removeEventListener("show-spinner", handleShowSpinner);
      document.removeEventListener("hide-spinner", handleHideSpinner);
    });

    return { isLoggedIn, isSpinnerVisible, userHelperService, eventBusService };
  },
  methods: {
    showVersion(): boolean {
      return this.$store.getters.getEnv.NODE_ENV !== "production";
    },
    setFavicon() {
      const link = document.createElement("link");
      link.rel = "icon";
      link.type = "image/x-icon";
      link.href = "/favicon.ico";
      document.head.appendChild(link);

      const link32 = document.createElement("link");
      link32.rel = "icon";
      link32.type = "image/png";
      link32.setAttribute("sizes", "32x32");
      link32.href = require("@/assets/favicon/favicon-32x32.png");
      document.head.appendChild(link32);

      const link16 = document.createElement("link");
      link16.rel = "icon";
      link16.type = "image/png";
      link16.setAttribute("sizes", "16x16");
      link16.href = require("@/assets/favicon/favicon-16x16.png");
      document.head.appendChild(link16);

      const appleTouchIcon = document.createElement("link");
      appleTouchIcon.rel = "apple-touch-icon";
      appleTouchIcon.href = require("@/assets/favicon/apple-touch-icon.png");
      document.head.appendChild(appleTouchIcon);
    },
  },
});
</script>

<style lang="scss">
@import "./scss/styles.scss";
</style>
