//src/header-tabs.enum.ts
export enum HeaderTab {
  JobMatrix = "JobMatrix",
  JobList = "JobList",
  Funnels = "Funnels",
  DispatcherBoard = "DispatcherBoard",
  Calendar = "Calendar",
  Documentation = "Documentation",
  PayFlowOverview = "PayFlowOverview",
  WorkTime = "WorkTime",
}
