import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mb-5" }
const _hoisted_2 = { class: "mb-5" }
const _hoisted_3 = { class: "main-container" }
const _hoisted_4 = {
  class: "editor-container editor-container_classic-editor",
  ref: "editorContainerElement"
}
const _hoisted_5 = { class: "editor-container__editor" }
const _hoisted_6 = { ref: "editorElement" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ckeditor = _resolveComponent("ckeditor")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-user")
          ]),
          _: 1
        }),
        _createTextVNode(" Prompts: Benutzerebene ")
      ]),
      (_ctx.user?.config?.aiData)
        ? (_openBlock(), _createBlock(_component_v_form, {
            key: 0,
            onSubmit: _withModifiers(_ctx.updateUserConfig, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.user.config.aiData.whatsAppStyle,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.config.aiData.whatsAppStyle) = $event)),
                        label: "Stil bzw. Beispiele für WhatsApp Nachrichten",
                        rows: "7",
                        class: "mb-4"
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.user.config.aiData.mailStyle,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.config.aiData.mailStyle) = $event)),
                        label: "Stil bzw. Beispiele für Emails",
                        rows: "7",
                        class: "mb-4"
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        variant: _ctx.vStyle.input.variant || undefined,
                        rounded: _ctx.vStyle.input.rounded || undefined,
                        "base-color": _ctx.vStyle.input.baseColor || undefined,
                        color: _ctx.vStyle.input.color || undefined,
                        modelValue: _ctx.user.config.aiData.profileStyle,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.config.aiData.profileStyle) = $event)),
                        label: "Stil für Kandidatenprofile",
                        rows: "7",
                        class: "mb-4"
                      }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", _hoisted_2, [
                        _createVNode(_component_v_icon, {
                          size: "small",
                          class: "mr-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("fa-solid fa-signature")
                          ]),
                          _: 1
                        }),
                        _createTextVNode(" Signatur E-Mail ")
                      ]),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              (_ctx.isLayoutReady)
                                ? (_openBlock(), _createBlock(_component_ckeditor, {
                                    key: 0,
                                    modelValue: _ctx.user.config.aiData.mailSignature,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.config.aiData.mailSignature) = $event)),
                                    editor: _ctx.editor,
                                    config: _ctx.config
                                  }, null, 8, ["modelValue", "editor", "config"]))
                                : _createCommentVNode("", true)
                            ], 512)
                          ])
                        ], 512)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                variant: _ctx.vStyle.btn.variant || undefined,
                rounded: _ctx.vStyle.btn.rounded || undefined,
                border: _ctx.vStyle.btn.border || undefined,
                class: "mt-10",
                type: "submit",
                color: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Speichern")
                ]),
                _: 1
              }, 8, ["variant", "rounded", "border"])
            ]),
            _: 1
          }, 8, ["onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}