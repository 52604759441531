<!--src/components/config/software-integration/ConfigWordPressPlugin.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-globe</v-icon>Endpunkt zu
      Wordpressplugin für Kandidaten + Stellenanzeigen
    </h2>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-model="localConfig.apiKeys.wpPluginBaseUrl"
          label="Base URL WordPress Plugin"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-model="localConfig.apiKeys.wpPlugin"
          label="API Key"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-form @submit.prevent="handleSubmit">
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";

export default defineComponent({
  name: "ConfigWordPressPlugin",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
