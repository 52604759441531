<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="showMatchDialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
    persistent
    class="fill-height"
  >
    <v-card>
      <v-card-title class="d-flex">
        Umkreissuche
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('closeDialog')"
          icon
          size="s"
          variant="text"
          density="compact"
        >
          <v-icon> fa-solid fa-xmark close-icon </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-select
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            :items="statusOptionsRecruit"
            item-title="text"
            item-value="value"
            label="ein oder mehrere Kandidatenstatus"
            v-model="matchCandidateRequest.selectedStatuses"
            :rules="[rules.required]"
            hint="* Erforderlich"
            persistent-hint
            multiple
            @click.stop
          ></v-select>
          <v-label class="mt-5 mx-2"
            >Suche {{ postcodeRadius }}km im Umkreis von Postleitzahl
            {{ postcode }}</v-label
          >
          <v-slider
            :max="100"
            :min="0"
            :step="1"
            class="my-2"
            v-model="postcodeRadius"
            thumb-label
          ></v-slider>
          <v-btn
            block
            color="secondary"
            :disabled="!valid"
            @click="startBackendSearch()"
            >Suchen</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MutationType } from "../../../enums/vuex-mutationtype.enum";
import { mapGetters, mapMutations } from "vuex";
import { ZipCodeService } from "../../../services/api/zip-code.service";
import { MatchCandidatesRequest } from "../../../models/candidate.model";
import { RulesMessage } from "../../../enums/app-messages.enum";
import { InterComponentMessage } from "../../../enums/inter-component-messagin.enum";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "CandidateDetailSearchForm",
  emits: ["closeDialog"],
  props: {
    postcode: {
      type: String,
      required: true,
    },
    showMatchDialog: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Object as PropType<string[]>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      matchCandidateRequest: {} as MatchCandidatesRequest,
      postcodeRadius: 30,
      rules: {
        required: (value: string) => !!value || RulesMessage.required,
      },
      tagsMatchingThreshold:
        this.$store.state.company.aiData.prompt.company.tagsMatchingThreshold,
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    async startBackendSearch() {
      const zipcodeService = new ZipCodeService();
      const postcodes = (await zipcodeService.getZipcodesWithinRadius(
        this.postcode,
        this.postcodeRadius
      )) as string[];

      this.matchCandidateRequest.postcodes = postcodes;
      this.matchCandidateRequest.tags = this.tags;
      this.matchCandidateRequest.threshold = this.tagsMatchingThreshold;
      this.$emit("closeDialog");
      this.setICM({
        message: InterComponentMessage.matchMeFromCustomerOrDemand,
        payload: this.matchCandidateRequest,
      });
    },
  },
});
</script>
