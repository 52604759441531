<template>
  <div class="calendar-header">Kalender</div>
  <vue-cal
    locale="de"
    :time-from="6 * 60"
    :time-to="20 * 60"
    :time-step="60"
    :events="appointments"
    view="week"
    hide-view-selector
    hide-weekends
  >
  </vue-cal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VueCal from "vue-cal";
import { CalendarObject } from "@/models/linking.model";
import { mapGetters } from "vuex";
import { LinkingStatus } from "@/enums/dependency.enum";
import { SpinnerService } from "@/services/spinner.service";

interface EventOption {
  eventName: string;
  candidateStatus?: LinkingStatus;
}

export default defineComponent({
  name: "AppointmentCalendar",
  components: {
    VueCal,
  },
  props: {
    selectedDatesTimes: {
      type: Array as () => Array<string>,
      default: () => [],
    },
  },
  data() {
    return {
      appointments: [] as CalendarObject[],
    };
  },
  computed: {
    ...mapGetters([
      "company",
      "candidateToCustomerEvents",
      "candidateToMandantEvents",
      "followUpEvents",
      "getLoggedInMandantUuids",
    ]),
    eventMap(): Record<string, LinkingStatus | undefined> {
      const allEvents: EventOption[] = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ];
      const eventStatusMap: Record<string, LinkingStatus | undefined> = {};
      allEvents.forEach((event) => {
        if (event.candidateStatus) {
          eventStatusMap[event.eventName] = event.candidateStatus;
        }
      });
      return eventStatusMap;
    },
    displayStatuses(): Set<LinkingStatus> {
      return new Set([
        LinkingStatus.interviewSuggestionExternal,
        LinkingStatus.trailWorkSuggestionExternal,
        LinkingStatus.interviewExternal,
        LinkingStatus.trailWorkExternal,
        LinkingStatus.interview,
        LinkingStatus.followUp,
        LinkingStatus.followUpPrio,
      ]);
    },
  },
  methods: {
    async fetchAppointments() {
      try {
        SpinnerService.showSpinner();

        const fetchedAppointments =
          this.$store.getters.getLinkingAppointmentsForCalendar;

        this.appointments = fetchedAppointments
          .filter((appointment: CalendarObject) => {
            const status = appointment.class
              ? this.eventMap[appointment.class]
              : undefined;
            return status && this.displayStatuses.has(status);
          })
          .map((appointment: CalendarObject) => {
            const startDate = new Date(appointment.start);
            const endDate = new Date(appointment.end);
            const candidateStatus = this.getStatusForClass(
              appointment.class ?? ""
            );

            return {
              start: startDate,
              end: endDate,
              title: appointment.title,
              class: candidateStatus,
            };
          });
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },

    getStatusForClass(className: string) {
      const allEvents = [
        ...this.candidateToCustomerEvents,
        ...this.candidateToMandantEvents,
        ...this.followUpEvents,
      ];
      const event = allEvents.find((event) => event.eventName === className);
      return event ? event.candidateStatus : undefined;
    },
    addSelectedDatesToCalendar() {
      this.appointments = this.appointments.filter(
        (appointment) => appointment.class !== "Placeholder"
      );

      this.selectedDatesTimes.forEach((dateTime) => {
        const startDate = new Date(dateTime);
        const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

        this.appointments.push({
          start: startDate,
          end: endDate,
          title: "Neuer Termin",
          class: "Placeholder",
        });
      });
    },
  },
  watch: {
    selectedDatesTimes: {
      immediate: true,
      handler() {
        this.addSelectedDatesToCalendar();
      },
    },
    getLoggedInMandantUuids: {
      immediate: false,
      handler() {
        this.fetchAppointments();
      },
    },
  },
  mounted() {
    this.fetchAppointments();
  },
});
</script>
