import { createApp } from "vue";

const ToastService = {
  getSCSSVariables() {
    const rootStyles = getComputedStyle(document.documentElement);

    return {
      default: {
        bgColor: rootStyles.getPropertyValue("--toast-bg-color").trim(),
        textColor: rootStyles.getPropertyValue("--toast-text-color").trim(),
        padding: rootStyles.getPropertyValue("--toast-padding").trim(),
        borderRadius: rootStyles
          .getPropertyValue("--toast-border-radius")
          .trim(),
        boxShadow: rootStyles.getPropertyValue("--toast-box-shadow").trim(),
        zIndex: rootStyles.getPropertyValue("--toast-z-index").trim(),
        fontSize: rootStyles.getPropertyValue("--toast-font-size").trim(),
      },
      success: {
        bgColor: rootStyles.getPropertyValue("--toast-success-bg-color").trim(),
        borderColor: rootStyles
          .getPropertyValue("--toast-success-border-color")
          .trim(),
        fontSize: rootStyles
          .getPropertyValue("--toast-success-font-size")
          .trim(),
        icon: "fas fa-check-circle",
        iconSize: "5rem",
        position: "50%",
      },
      reminder: {
        bgColor: rootStyles
          .getPropertyValue("--toast-reminder-bg-color")
          .trim(),
        borderColor: rootStyles
          .getPropertyValue("--toast-reminder-border-color")
          .trim(),
        fontSize: rootStyles
          .getPropertyValue("--toast-reminder-font-size")
          .trim(),
        icon: "fas fa-circle-info",
        iconSize: "4rem",
        position: "80%",
      },
      error: {
        bgColor: rootStyles.getPropertyValue("--toast-error-bg-color").trim(),
        borderColor: rootStyles
          .getPropertyValue("--toast-error-border-color")
          .trim(),
        fontSize: rootStyles.getPropertyValue("--toast-error-font-size").trim(),
        icon: "fas fa-exclamation-triangle",
        iconSize: "3rem",
        position: "50%",
      },
    };
  },
  createToast(
    message: string,
    styles: Record<string, string>,
    timeout: number,
    iconClass?: string
  ) {
    const app = createApp({
      template: `
        <div :style="toastStyles">
          <i v-if="iconClass" :class="iconClass" :style="iconStyles"></i>
          {{ message }}
        </div>
      `,
      data() {
        return {
          message,
          toastStyles: {
            position: "fixed",
            top: styles.position || "1rem",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: styles.bgColor,
            color: styles.textColor,
            padding: styles.padding,
            borderRadius: styles.borderRadius,
            boxShadow: styles.boxShadow,
            border: `2px solid ${styles.borderColor || "transparent"}`,
            zIndex: styles.zIndex,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: styles.fontSize || "1.5rem",
            maxWidth: "80%",
          },
          iconStyles: {
            marginRight: "1rem",
            fontSize: styles.iconSize || "3rem",
          },
          iconClass,
        };
      },
      created() {
        setTimeout(() => {
          app.unmount();
        }, timeout);
      },
    });

    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    app.mount(tempDiv);
  },

  show(message: string) {
    const styles = this.getSCSSVariables().default;
    this.createToast(message, styles, 5000);
  },

  showSuccess(message: string) {
    const { default: defaultStyles, success } = this.getSCSSVariables();
    const styles = { ...defaultStyles, ...success };
    this.createToast(message, styles, 3000, success.icon);
  },

  showReminder(message: string) {
    const { default: defaultStyles, reminder } = this.getSCSSVariables();
    const styles = { ...defaultStyles, ...reminder };
    this.createToast(message, styles, 7000, reminder.icon);
  },

  showError(message: string) {
    const { default: defaultStyles, error } = this.getSCSSVariables();
    const styles = { ...defaultStyles, ...error };
    this.createToast(message, styles, 3000, error.icon);
  },
};

export default ToastService;
