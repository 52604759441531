<!--src/components/dispositon/elements/ProfileGenerator.vue-->
<template>
  <div>
    <v-dialog
      v-model="existingProfilesModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          Vorhandene Profile
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            block
            color="primary"
            @click="showSelectMatchingDataModal()"
            >Neues Profil generieren</v-btn
          >
          <v-list>
            <v-list-item
              v-for="profile in profileSourceData.profiles"
              :key="profile._id"
              @click="getExistingProfile(profile)"
            >
              <div class="d-flex">
                <div v-if="profile.uuid">
                  <img
                    src="@/assets/icon-ats-recruit.svg"
                    class="zvoove-recruit-icon"
                    alt="zvoove Recruit Icon"
                  />
                </div>
                <div v-if="profile.html">
                  <img
                    src="@/assets/favicon.png"
                    class="zvoove-recruit-icon"
                    alt="Dispositioner:er Icon"
                  />
                </div>
                <v-list-item-title>{{ profile.description }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectMatchingDataModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-s)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          individualisiertes Profil generieren
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            block
            color="primary"
            class="mb-5"
            @click="generateProfile()"
            >{{
              profileSourceData.tags.length > 0
                ? "Profil passend auf Firmentags generieren"
                : "AI Profil generieren"
            }}</v-btn
          >
          <v-list v-if="profileSourceData.demands.length > 0">
            <v-card-subtitle>passend auf Anfrage generieren:</v-card-subtitle>
            <v-list-item
              v-for="demand in profileSourceData.demands"
              :key="demand.jobTitle"
              @click="generateProfile(demand)"
            >
              <div>
                <v-list-item-title>{{ demand.jobTitle }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
          <v-list v-if="profileSourceData?.jobAds?.length > 0">
            <v-card-subtitle
              >passend auf Stellenanzeige generieren:</v-card-subtitle
            >
            <v-list-item
              v-for="jobAd in profileSourceData.jobAds"
              :key="jobAd.jobTitle"
              @click="generateProfile(jobAd)"
            >
              <div>
                <v-list-item-title>{{ jobAd.jobTitle }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="availabilityModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          Verfügbarkeit / Anonymisierung
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-combobox
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="tempAvailability"
            :items="availabilityOptions"
            label="Verfügbarkeit"
            density="compact"
            @keydown.enter="updateAvailability"
          >
          </v-combobox>
          <v-divider class="mb-5 mt-3"></v-divider>
          <v-label>Nachname anonymisieren?</v-label>
          <v-switch
            v-model="isLastnameAnonymized"
            class="ml-1"
            color="primary"
            :label="isLastnameAnonymized ? 'Vorname N.' : 'Vorname Nachname'"
            density="compact"
          ></v-switch>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-btn variant="text" color="success" @click="updateAvailability"
            >Weiter
            <v-tooltip activator="parent" location="bottom"
              >auswählen und 'Weiter' oder manuell ins Textfeld eingeben und mit
              'Enter' bestätigen</v-tooltip
            ></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="profileEditorModal"
      v-model="profileEditorModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : widthDialogProfileGenerator"
    >
      <v-card>
        <v-card-title class="d-flex">
          Profil Editor
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModals()"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-5 mx-3">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              density="compact"
              label="Dateiname Profil"
              v-model="profileFileName"
            >
            </v-text-field>
            <v-btn icon variant="text" @click="saveHtmlInCandidate()"
              ><v-icon>fa-solid fa-floppy-disk</v-icon>
              <v-tooltip activator="parent" location="top"
                >Profil abspeichern</v-tooltip
              ></v-btn
            >
            <v-btn icon variant="text">
              <v-icon>fas fa-arrows-rotate</v-icon>
              <v-menu activator="parent">
                <v-list>
                  <v-list-item @click="generateNewProfil()">
                    <template v-slot:prepend>
                      <v-icon>fas fa-rocket</v-icon>
                    </template>
                    <v-list-item-title>Neu generieren</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="promptModal = true">
                    <template v-slot:prepend>
                      <v-icon>fas fa-comment</v-icon>
                    </template>
                    <v-list-item-title
                      >mit individuellem Prompt</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu></v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="success" @click="saveHtmlAndGenerateEmail()"
              >übernehmen & senden<v-tooltip
                activator="parent"
                location="top left"
                >Profil speichern und E-Mail für den Ansprechpartner des Kunden
                generieren!</v-tooltip
              ></v-btn
            >
          </v-row>
          <v-row>
            <v-col :cols="isWidened ? 8 : 12">
              <ProfileEditor
                ref="profileEditorComponent"
                :generatedProfile="generatedProfileJSON"
                :profileData="profileDataJSON"
                :loadedProfile="loadedProfile"
                :mandantUuid="mandantUuid"
                :availability="availability"
              ></ProfileEditor>
            </v-col>
            <v-col v-if="isWidened" cols="4" class="my-15">
              <v-card
                class="my-15 mx-0 px-0"
                color="primary"
                variant="tonal"
                style="z-index: 1000"
              >
                <v-card-text>
                  <v-list class="mx-0">
                    <v-list-item class="mx-0">
                      <v-card-title>
                        <h2>Werdegang</h2>
                      </v-card-title>
                    </v-list-item>
                    <v-list-item
                      class="mx-0"
                      v-for="(step, index) in profileSourceData.careerSteps"
                      :key="index"
                    >
                      <v-card-title>
                        {{ step.title }}
                      </v-card-title>
                      <v-card-subtitle>
                        <p>Bei: {{ step.at }}</p>
                        <p>
                          Von: {{ formatDate(step.from) }} Bis:
                          {{ formatDate(step.until) }}
                        </p>
                        <v-divider class="my-2"></v-divider>
                      </v-card-subtitle>
                      <p class="ml-4 mr-2 text-body-2 text-medium-emphasis">
                        {{ step.details }}
                      </p>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn
        @click="toggleProfileEditorWidth"
        icon
        size="s"
        variant="text"
        density="compact"
        style="position: absolute; right: 0.8rem; top: 50%"
      >
        <v-icon>
          {{
            isWidened ? "fa-solid fa-chevron-left" : "fa-solid fa-chevron-right"
          }}
        </v-icon>
        <v-tooltip activator="parent" location="left"
          >Lebenslauf aus den Kandidatendaten ein- bzw. ausblenden</v-tooltip
        >
      </v-btn>
    </v-dialog>
    <v-dialog
      v-model="promptModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
    >
      <v-card>
        <v-card-title class="d-flex">
          Prompt: Auf was soll ich achten?
          <v-spacer></v-spacer>
          <v-btn
            @click="promptModal = false"
            icon
            size="s"
            variant="text"
            density="compact"
          >
            <v-icon> fa-solid fa-xmark close-icon </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="customPrompt"
          >
          </v-textarea>
          <v-card-actions>
            <v-btn @click="promptModal = false" color="abort">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="generateNewProfil(customPrompt)" color="success"
              >Neu generieren</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ProfileJobAd, ProfileSourceData } from "@/models/profile.model";
import { useDisplay } from "vuetify";
import { AiService } from "@/services/ai.service";
import { AiAnonymizedName } from "@/enums/ai-options.enum";
import ProfileEditor from "./ProfileEditor.vue";
import { ProfileData, ProfileAiData } from "@/models/profile.model";
import { SpinnerService } from "@/services/spinner.service";
import { MandantService } from "@/services/api/mandant.service";
import { AtsRecruitService } from "@/services/api/api-integration-one.service";
import ToastService from "@/services/toast.service";
import moment from "moment";
import { Profile } from "@/models/candidate.model";
import { Email } from "@/models/email.model";

export default defineComponent({
  name: "ProfileGenerator",
  emits: ["openMailClient"],
  components: {
    ProfileEditor,
  },
  props: {
    profileSourceData: {
      type: Object as PropType<ProfileSourceData>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      availability: "",
      availabilityModal: false,
      availabilityOptions: [
        "Sofort",
        "14 Tage Kündigungsfrist",
        "4 Wochen Kündigungsfrist",
        "4 Wochen zum Monatsende",
        "Zum Quartalsende",
      ],
      atsRecruitService: new AtsRecruitService(),
      currentJobAd: undefined as ProfileJobAd | undefined,
      customPrompt: "",
      existingProfilesModal: false,
      generatedProfileJSON: {} as ProfileAiData,
      isLastnameAnonymized: true,
      isWidened: false,
      loadedProfile: "",
      mandantService: new MandantService(),
      mandantUuid: "",
      messageSubject: "Betreff",
      messageText: "[Gibt Dein Text ein...]",
      profileDataJSON: {} as ProfileData,
      profileEditorModal: false,
      profileFileName: "",
      promptModal: false,
      selectMatchingDataModal: false,
      tempAvailability: "Sofort",
      vStyle: this.$store.state.vStyle,
      widthDialogProfileGenerator: "var(--d-w-xl)",
    };
  },
  watch: {
    generatedProfileJSON: {
      handler(newVal) {
        this.profileFileName = `#${
          this.profileDataJSON.profileNumber
        }_${this.prepareFilename(newVal.headline)}`;
      },
    },
  },
  methods: {
    toggleProfileEditorWidth() {
      this.isWidened = !this.isWidened;
      this.widthDialogProfileGenerator = this.isWidened
        ? "95%"
        : "var(--d-w-xl)";
    },
    prepareFilename(filename: string) {
      return filename.replace(/[/?<>\\:*|"]/g, "_").replace(/\s/g, "_");
    },
    formatDate(date: string) {
      return moment(date).format("DD.MM.YYYY");
    },
    signatureText() {
      const user = this.$store.state.company.loggedInUser;
      return `freundliche Grüße\n${user.salutation} ${user.forename} ${user.lastname}\n${this.$store.state.company.name}`;
    },
    checkForExisitingProfiles() {
      if (
        !this.profileSourceData.profiles ||
        this.profileSourceData.profiles.length === 0
      ) {
        this.showSelectMatchingDataModal();
        return;
      }
      this.showExistingProfilesModal();
    },

    showExistingProfilesModal() {
      this.closeModals();
      this.existingProfilesModal = true;
    },

    showSelectMatchingDataModal() {
      this.closeModals();
      this.selectMatchingDataModal = true;
    },

    showAvailabilityModal() {
      this.availability = "";
      return new Promise((resolve) => {
        this.closeModals();
        this.availabilityModal = true;

        const unwatch = this.$watch("availability", (newValue) => {
          if (newValue) {
            this.availabilityModal = false;
            unwatch();
            resolve(newValue);
          }
        });
      });
    },

    showProfileEditorModal() {
      this.existingProfilesModal = false;
      this.selectMatchingDataModal = false;
      this.profileEditorModal = true;
      this.availabilityModal = false;
    },

    closeModals() {
      this.existingProfilesModal = false;
      this.selectMatchingDataModal = false;
      this.profileEditorModal = false;
      this.availabilityModal = false;
      this.promptModal = false;
    },

    async getExistingProfile(profile: Profile) {
      // existing zvoove recruit pdf profile
      if (profile.uuid) {
        const email: Email = {
          body: "",
          pdf: {
            data: await this.atsRecruitService.getProfileAsPdf(profile.uuid),
            fileName: profile.description,
          },
          subject: "",
          to: "",
        };
        SpinnerService.showSpinner();
        //this.showExistingProfilesModal();
        this.closeModals();
        this.generateEmail(await this.getGeneratedEmailText(), email);
        SpinnerService.removeSpinner();
      } else if (profile.html) {
        this.buildProfileData();
        this.loadedProfile = profile.html;
        this.profileFileName = profile.description;
        this.showProfileEditorModal();
      }
    },

    buildProfileData() {
      this.mandantUuid = this.profileSourceData.mandant;
      this.profileDataJSON = {
        age: this.profileSourceData.age,
        candidateId: this.profileSourceData.candidateId,
        city: this.profileSourceData.city,
        firstname: this.profileSourceData.firstName,
        lastname: this.isLastnameAnonymized
          ? this.profileSourceData.lastName.charAt(0) + "."
          : this.profileSourceData.lastName,
        licences: this.profileSourceData.licences,
        mobility: this.profileSourceData.mobility,
        profileNumber: this.profileSourceData.profileNumber,
        shiftReadiness: this.profileSourceData.shiftReadiness,
      };
    },
    async generateProfile(jobAd?: ProfileJobAd) {
      if (jobAd) {
        this.currentJobAd = jobAd;
      } else {
        this.currentJobAd = undefined;
      }
      await this.showAvailabilityModal().then(() => {
        this.generateNewProfil();
      });
    },
    async generateNewProfil(customPrompt?: string) {
      let jobAd = undefined as ProfileJobAd | undefined;
      if (this.currentJobAd) jobAd = this.currentJobAd;
      this.closeModals();
      const anonymizedNote = this.profileSourceData.notes ?? "";
      let jobAdOrTags = JSON.stringify(this.profileSourceData.tags);
      let basedOnTags = true;
      if (jobAd) {
        jobAdOrTags = jobAd.jobAdText;
        basedOnTags = false;
      }
      SpinnerService.showSpinner();
      this.closeModals();
      try {
        const aiService = new AiService();
        const generatedProfil = await aiService.generateProfile(
          this.profileSourceData.anonymizedResume,
          anonymizedNote,
          jobAdOrTags,
          basedOnTags,
          customPrompt
        );
        this.buildProfileData();
        this.generatedProfileJSON = generatedProfil;
        await this.$nextTick();
        SpinnerService.removeSpinner();
      } catch (error) {
        ToastService.showError("Fehler bei der Profilgenerierung: " + error);
      } finally {
        this.showProfileEditorModal();
      }
    },
    async saveHtmlAndGenerateEmail() {
      await this.saveHtmlInCandidate();
      SpinnerService.showSpinner();
      const pdfResponse = await this.exportPdf();
      if (pdfResponse) {
        const generatedEmailText = await this.getGeneratedEmailText();
        SpinnerService.removeSpinner();
        this.closeModals();
        this.generateEmail(generatedEmailText, {
          pdf: {
            data: pdfResponse,
            fileName: this.profileFileName,
          },
        });
      } else {
        SpinnerService.removeSpinner();
        ToastService.showError("PDF konnte nicht erstellt werden");
      }
    },
    generateEmail(mailText: string, email: Email) {
      const anonymizedName = AiAnonymizedName.unisex;
      const lastName = this.isLastnameAnonymized
        ? this.profileSourceData.lastName.charAt(0) + "."
        : this.profileSourceData.lastName;
      const messageWithClearname = mailText.replace(
        new RegExp(anonymizedName, "g"),
        lastName
      );
      email.body = messageWithClearname;
      email.subject = this.messageSubject;
      const candidateTimelineObject = {
        name: `${this.profileSourceData.firstName} ${this.profileSourceData.lastName}`,
        uuid: this.profileSourceData.candidateUuid || undefined,
        employee_id: "",
      };
      this.$emit("openMailClient", email, candidateTimelineObject);
    },
    async getGeneratedEmailText(): Promise<string> {
      const anonymizedNote = this.profileSourceData.notes ?? "";
      const aiService = new AiService();
      const generatedMail = await aiService.generateEmailforProfile(
        this.profileSourceData.salutation,
        this.profileSourceData.anonymizedResume,
        anonymizedNote
      );
      return generatedMail.text;
    },
    exportPdf() {
      if (this.$refs.profileEditorComponent) {
        return (
          this.$refs.profileEditorComponent as InstanceType<
            typeof ProfileEditor
          >
        ).exportHtml(this.profileSourceData.candidateId);
      }
    },
    async saveHtmlInCandidate() {
      if (this.$refs.profileEditorComponent) {
        await (
          this.$refs.profileEditorComponent as InstanceType<
            typeof ProfileEditor
          >
        ).saveHtmlInCandidate(
          this.profileSourceData.candidateId,
          this.profileFileName
        );
      }
      this.$store.state.lastUpdateTimeline = Date.now();
      return;
    },
    updateAvailability() {
      this.availability = this.tempAvailability;
    },
  },
});
</script>
<style scoped>
.zvoove-recruit-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
</style>
