<template>
  <div class="d-flex align-center">
    <v-switch
      :model-value="status"
      color="primary"
      hide-details
      v-bind="$attrs"
      @change="$emit('handleChange')"
    ></v-switch>
    <label
      @click="$emit('labelClick')"
      :class="{ label: true, labelclickable: !noLink && status }"
      >{{ label }}</label
    >
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ClickableSwitch",
  emits: ["handleChange", "labelClick"],
  props: {
    label: String,
    status: Boolean,
    noLink: Boolean,
  },
});
</script>
<style lang="scss">
.label {
  padding-left: 1rem;
}
.labelclickable {
  color: var(--color-primary-darken-1);
  cursor: pointer;
}
</style>
