<template>
  <v-btn
    v-if="!mdAndDown"
    icon
    density="compact"
    variant="text"
    class="toggle-button"
    @click="toggleExpansion"
  >
    <v-icon>{{
      isExpanded ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
    }}</v-icon>
  </v-btn>
  <v-btn
    v-if="mdAndDown"
    icon
    density="compact"
    variant="text"
    class="toggle-button"
  >
    <v-icon>fa-solid fa-ellipsis-vertical</v-icon>
    <v-menu activator="parent">
      <v-list>
        <v-list-item @click="toggleExpansion">
          <v-icon>{{
            isExpanded ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"
          }}</v-icon>
        </v-list-item>
        <v-list-item v-if="showMaximize()" @click="toggleFullExpansion">
          <v-icon>fa-solid fa-maximize</v-icon>
        </v-list-item>
        <v-list-item @click="openContextMenu($event)">
          <v-icon>fa-solid fa-bars</v-icon>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "DemandItem",
  emits: ["openContextMenu", "toggleExpansion", "toggleFullExpansion"],
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
    isFullyExpanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
    };
  },
  methods: {
    openContextMenu(event: any) {
      this.$emit("openContextMenu", event);
    },
    showMaximize() {
      return this.isExpanded && !this.isFullyExpanded;
    },
    toggleExpansion() {
      this.$emit("toggleExpansion");
    },
    toggleFullExpansion() {
      this.$emit("toggleFullExpansion");
    },
  },
});
</script>
