// services/zvoove.service service for zvoove Recruit Endpoints
import store from "@/store/store";
import { Assignment } from "@/models/assignment.model";
import { ApiConfig, SoftwareIntegration } from "@/models/company-config.model";
import { ApiIntegration } from "./api-integration.class";
import { CompanyConfigService } from "./company-config.service";
import { CustomerService } from "./customer.service";
import {
  Customer,
  CustomerContact,
  CustomerList,
} from "@/models/customer.model";
import { EmployeeService } from "./employee.service";
import { getCustomerStateValue } from "@/enums/customer-states.enum";
import { ZorstApiData } from "@/models/zorst-api-request.model";
import { ZvooveEmployee } from "@/models/external/zvoove-one.model";
import { ZvooveOneTimelineEntry } from "@/models/timeline.model";
import { ZorstService } from "@/services/zorst.service";
import * as Adapter from "@/adapter/api-integration-one.adapter";
import ToastService from "../toast.service";
import { Employee, EmployeeList } from "@/models/employee.model";
import { Candidate } from "@/models/candidate.model";
import { CandidateList } from "@/models/candidate-list.model";
import { PayrollDeduction } from "@/enums/payflow.enum";
import { ErpFetchOption } from "@/enums/init-options.enum";
import { ApiMethod } from "../../enums/api-method.enum";
import { NextLevelMandant } from "../../models/external/zvoove-mandants.model";

export const zorstService = ZorstService.getInstance();

export class ApiIntegrationOne {
  private static instance: ApiIntegrationOne;
  protected api = ApiIntegration.getInstance();
  companyConfigService = CompanyConfigService.getInstance();
  apiKeys = {} as ApiConfig;
  softwareIntegration = {} as SoftwareIntegration;
  protected openBewerberURL = "";
  zvooveOneApiBaseUrl = "";
  zvooveOneBaseUrl = "";
  xCoreClientIdZvooveOne: string | undefined;

  constructor() {
    this.subscribeToStore();
  }

  public static getInstance(): ApiIntegrationOne {
    if (!ApiIntegrationOne.instance) {
      ApiIntegrationOne.instance = new ApiIntegrationOne();
    }
    return ApiIntegrationOne.instance;
  }

  async waitForCompanyConfig(): Promise<boolean> {
    return new Promise((resolve) => {
      const checkVariable = async () => {
        const companyConfig = await this.companyConfigService.getConfig();
        if (companyConfig) {
          this.apiKeys = companyConfig.apiKeys;
          this.softwareIntegration = companyConfig.softwareIntegration;
          this.updateConfig();
          resolve(true);
        } else {
          setTimeout(checkVariable, 50);
        }
      };
      checkVariable();
    });
  }

  updateConfig() {
    this.api.setBaseUrl(this.softwareIntegration.zvooveRecruitLink);
    this.openBewerberURL = this.softwareIntegration.zvooveRecruitLink;
    this.xCoreClientIdZvooveOne = this.apiKeys?.xCoreClientIdZvooveOne;
    this.zvooveOneApiBaseUrl = this.apiKeys?.baseURLZvooveOne;
    this.zvooveOneBaseUrl = this.softwareIntegration?.zvooveOneLink;
  }

  private subscribeToStore() {
    store.subscribe((mutation) => {
      if (mutation.type === "SET_STORE_HAS_TO_INIT") {
        this.updateConfig();
      }
    });
  }

  getBackendApi() {
    return this.api.getBackendApi();
  }

  async updateApiConfigOpenBewerber() {
    this.api.setHeaderApiKey(this.apiKeys?.zvooveOpenBewerber);
  }

  async updateApiConfigOpenStelle() {
    this.api.setHeaderApiKey(this.apiKeys?.zvooveOpenStelle);
  }

  async updateApiConfigNextLevel() {
    this.api.setHeaderApiKey(this.apiKeys?.zvooveNextLevel);
  }
}

// Third party API integration number 1 (Zvoove)
export class AtsRecruitService {
  private api = ApiIntegrationOne.getInstance();

  async getBewerbungenFiltered(searchterm: string, mandants?: string[]) {
    if (!searchterm) return [];
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigOpenBewerber();
    const response = await this.api
      .getBackendApi()
      .get(
        `/api/public/v1/Bewerbung/GetBewerbungenFiltered?searchterm=${searchterm}`,
        {
          withCredentials: false,
        }
      );
    const candidateList = Adapter.getBewerbungenFilteredToCandidateList(
      response.data.Items,
      mandants
    );
    return candidateList as CandidateList[];
  }

  async getById(
    candidateUuid: string,
    applicationId: string,
    applicationUuid: string
  ) {
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigOpenBewerber();
    const response = await this.api
      .getBackendApi()
      .get(`/api/public/v2/Bewerber/GetById?bewerberUuid=${candidateUuid}`);
    const candidateData = Adapter.zvooveCandidateToCandidate(
      response.data,
      applicationId,
      applicationUuid
    );
    return candidateData as Candidate;
  }

  async getProfileAsPdf(profileUuid: string): Promise<Blob> {
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigOpenBewerber();
    const response = await this.api
      .getBackendApi()
      .get(
        `/api/public/v1/Bewerber/GetProfileAsPdf?profileUuid=${profileUuid}`,
        { responseType: "blob" }
      ); // Wichtig für den Download von Binärdaten wie PDF      // Erstellen eines Links zum Herunterladen des PDFs
    // Später muss die Datei nur an das Backend weitergegeben werd und an eine Mail angehängt werden
    return new Blob([response.data]);
  }

  async postStelleCreate(parameter: any) {
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigOpenStelle();
    const response = await this.api
      .getBackendApi()
      .post("/api/public/v1/Stelle/Create", parameter);
    return response.data;
  }

  async publishStelle(parameter: any) {
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigOpenStelle();
    const response = await this.api
      .getBackendApi()
      .post("/api/public/v1/Stelle/PublishStelle", parameter);
    return response.data;
  }

  async getMandants() {
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigNextLevel();
    const response = await this.api
      .getBackendApi()
      .get(`/api/nextlevel/v1/Benutzer/GetMandanten`);

    return response.data as NextLevelMandant[];
  }

  async getAvatar(avatarUuid: string): Promise<Blob> {
    await this.api.waitForCompanyConfig();
    this.api.updateApiConfigNextLevel();
    const response = await this.api
      .getBackendApi()
      .get(`/api/nextlevel/v1/Bewerber/GetAvatarById?avatarId=${avatarUuid}`, {
        responseType: "blob",
      });
    return new Blob([response.data]);
  }
}

export class ZvooveOneCustomer {
  private api = ApiIntegrationOne.getInstance();

  async getAll(
    status: number,
    geschaeftsstelleIds: string[]
  ): Promise<Customer[]> {
    await this.api.waitForCompanyConfig();
    const geschaeftsstelleIdsQuery = geschaeftsstelleIds
      .map((id) => `geschaeftsstelleIds=${id}`)
      .join("&");

    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen?status=${status}&${geschaeftsstelleIdsQuery}`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      if (response && Array.isArray(response.unternehmen)) {
        const customers = response.unternehmen.map((customerData: any) =>
          Adapter.zvooveCustomerToCustomer(customerData)
        );
        return customers;
      } else {
        console.error("Unexpected API response format:", response);
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error retrieving customer data:", error);
      throw error;
    }
  }

  async getList(geschaeftsstelleIds: number[]): Promise<any[]> {
    await this.api.waitForCompanyConfig();
    const geschaeftsstelleIdsQuery = geschaeftsstelleIds
      .map((id) => `geschaeftsstelleIds=${id}`)
      .join("&");

    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen?${geschaeftsstelleIdsQuery}`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      if (response && Array.isArray(response.unternehmen)) {
        const customers = response.unternehmen.map((customerData: any) =>
          Adapter.transformToCustomerList(customerData)
        );
        return customers;
      } else {
        console.error("Unexpected API response format:", response);
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error retrieving customer data:", error);
      throw error;
    }
  }

  async getByCustomerNumber(customerNumber: string): Promise<Customer> {
    await this.api.waitForCompanyConfig();
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      const transformedCustomer = Adapter.zvooveCustomerToCustomer(response);
      return transformedCustomer;
    } catch (error) {
      console.error("Error retrieving customer data:", error);
      throw error;
    }
  }

  async getContactsByCustomerNumber(
    customerNumber: string
  ): Promise<CustomerContact[]> {
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/Ansprechpartner`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      if (response && Array.isArray(response.ansprechpartner)) {
        return response.ansprechpartner.map((contact: any) =>
          Adapter.transformContact(contact)
        );
      } else {
        throw new Error("No valid contact data received.");
      }
    } catch (error) {
      console.error("Error retrieving contact data:", error);
      throw error;
    }
  }

  async getPlacements(
    customerNumber: string,
    isNoTimeFilter?: boolean
  ): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/ueberlassungen`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return Adapter.zvooveCustomerAssingmentsToAssignments(
        response.unternehmenUeberlassungen,
        isNoTimeFilter
      );
    } catch (error) {
      console.error("Error retrieving employee timeline:", error);
      throw error;
    }
  }

  async getContactsAndPlacements(customerNumber: string): Promise<any> {
    await this.api.waitForCompanyConfig();

    const openApiSiteLink = `${this.api.zvooveOneBaseUrl}`;
    const apiRequests = [
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/Ansprechpartner`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/ueberlassungen`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
    ];

    try {
      const response = await zorstService.ZorstMultiApiCall(
        openApiSiteLink,
        apiRequests
      );

      const customerData = {
        contacts: [] as CustomerContact[],
        assignments: [] as Assignment[],
      };
      if (response[0].length > 0 && response[0].data.ansprechpartner) {
        customerData.contacts = response[0].data.ansprechpartner.map(
          (contact: CustomerContact) => Adapter.transformContact(contact)
        );
      }
      if (
        response[1].length > 0 &&
        response[1].data.unternehmenUeberlassungen
      ) {
        customerData.assignments =
          Adapter.zvooveCustomerAssingmentsToAssignments(
            response[1].data.unternehmenUeberlassungen
          );
      }
      return customerData;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }

  async getCustomerAndContactsAndPlacements(
    customerNumber: string
  ): Promise<any> {
    await this.api.waitForCompanyConfig();

    const openApiSiteLink = `${this.api.zvooveOneBaseUrl}`;
    const apiRequests = [
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/Ansprechpartner`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/ueberlassungen`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
    ];

    try {
      const response = await zorstService.ZorstMultiApiCall(
        openApiSiteLink,
        apiRequests
      );

      const customerData = Adapter.zvooveCustomerToCustomer(response[0].data);

      customerData.contacts = response[1].data.ansprechpartner.map(
        (contact: any) => Adapter.transformContact(contact)
      );
      customerData.assignments = Adapter.zvooveCustomerAssingmentsToAssignments(
        response[2].data.unternehmenUeberlassungen
      );
      return customerData;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }

  async getTimeline(customerNumber: string): Promise<any> {
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/aktivitaeten`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error retrieving customer timeline:", error);
      throw error;
    }
  }

  async createTimelineEntry(
    customerNumber: string,
    zvooveOneTimelineEntry: ZvooveOneTimelineEntry
  ): Promise<any> {
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/aktivitaeten`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.post,
      body: zvooveOneTimelineEntry,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error creating activity for the company:", error);
      throw error;
    }
  }

  async createCustomer(newCustomer: Customer): Promise<any> {
    const zvooveCustomer = Adapter.customerToZvooveCustomer(newCustomer);
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.post,
      body: zvooveCustomer,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error posting customer in zvoove", error);
      throw error;
    }
  }

  async putCustomer(customer: Customer): Promise<any> {
    const zvooveCustomer = Adapter.customerToZvooveCustomer(customer);
    const customerNumber = customer.customerNumber;
    const apiUrl = `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}`;
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: apiUrl,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.put,
      body: zvooveCustomer,
    };

    try {
      // Retrieve the customer data from zvoove
      const customerInErp = await this.getByCustomerNumber(customerNumber);
      const customerInErpStatus =
        getCustomerStateValue(customerInErp.generalData.status) ?? 1;
      const newStatus =
        getCustomerStateValue(customer.generalData.status.toString()) ?? 1;

      if (customerInErpStatus !== newStatus) {
        await this.changeCustomerStatus(customer.customerNumber, newStatus);
        ToastService.showReminder(
          "ERP Kundenstatus wurde geändert - bitte Daten prüfen und Upload wiederholen!"
        );
        return null;
      }

      // Update the customer data
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error updating customer data:", error);
      throw error;
    }
  }

  async changeCustomerStatus(
    customerNumber: string,
    status: number
  ): Promise<any> {
    const apiUrl = `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen/${customerNumber}/status`;
    const body = {
      status: status,
    };
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: apiUrl,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.put,
      body: body,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error changing customer status:", error);
      throw error;
    }
  }
}

export class ZvooveOneEmployee {
  private api = ApiIntegrationOne.getInstance();

  async getAll(
    status: number,
    geschaeftsstelleIds: string[]
  ): Promise<Employee[]> {
    await this.api.waitForCompanyConfig();
    const geschaeftsstelleIdsQuery = geschaeftsstelleIds
      .map((id) => `geschaeftsstelleIds=${id}`)
      .join("&");

    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal?${geschaeftsstelleIdsQuery}`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      if (response && Array.isArray(response.personal)) {
        // Filter the employees based on the provided status
        const employees = response.personal
          .filter((employeeData: any) => employeeData.personalstatus === status) // Apply the status filter here
          .map((employeeData: any) =>
            Adapter.zvooveOneEmployeeToEmployee(employeeData)
          );

        return employees;
      } else {
        console.error("Unexpected API response format:", response);
        throw new Error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error retrieving employee data:", error);
      throw error;
    }
  }

  async getByEmployeeNumber(employeeNumber: string): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return Adapter.zvooveOneEmployeeToEmployee(response);
    } catch (error) {
      console.error("Error retrieving customer data:", error);
      throw error;
    }
  }

  async getBankData(employeeNumber: string): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}/abrechnungseinstellungen`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return Adapter.zvooveOnePayrollDetailsToBankAccount(response);
    } catch (error) {
      console.error("Error retrieving customer data:", error);
      throw error;
    }
  }

  async getPlacements(employeeNumber: string): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}/ueberlassungen`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return Adapter.zvooveEmployeeAssingmentsToAssignments(
        response.ueberlassungen
      );
    } catch (error) {
      console.error("Error retrieving employee timeline:", error);
      throw error;
    }
  }

  async getTimeline(employeeNumber: string): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData: ZorstApiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}/aktivitaeten`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.get,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error retrieving employee timeline:", error);
      throw error;
    }
  }

  async createTimelineEntry(
    employeeNumber: string,
    zvooveOneTimelineEntry: ZvooveOneTimelineEntry
  ): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}/aktivitaeten`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.post,
      body: zvooveOneTimelineEntry,
    };
    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error creating activity for employee:", error);
      throw error;
    }
  }
  async getEmployeeAndPlacement(employeeNumber: string): Promise<any> {
    await this.api.waitForCompanyConfig();

    const openApiSiteLink = `${this.api.zvooveOneBaseUrl}`;
    const apiRequests = [
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}/ueberlassungen`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
    ];

    try {
      const response = await zorstService.ZorstMultiApiCall(
        openApiSiteLink,
        apiRequests
      );
      const employeeData = Adapter.zvooveOneEmployeeToEmployee(
        response[0].data
      );
      Object.assign(
        employeeData,
        Adapter.zvooveEmployeeAssingmentsToAssignments(
          response[1].data.ueberlassungen
        )
      );
      return employeeData;
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
  async putEmployee(employee: Employee): Promise<any> {
    const zvooveEmployee = Adapter.employeeToZvooveOneEmployee(employee);
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/Personal/${employee.employeeNumber}`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.put,
      body: zvooveEmployee,
    };

    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error updating employee in zvoove", error);
      throw error;
    }
  }
  async createEmployee(
    candidateData: Candidate,
    candidateMandantUuid: string
  ): Promise<any> {
    const payload = Adapter.zvooveCandidateToNewZvooveOneEmployee(
      candidateData,
      candidateMandantUuid
    );
    await this.api.waitForCompanyConfig();
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.post,
      body: payload,
    };

    try {
      const response = (await zorstService.ZorstOneApiCall(
        apiData
      )) as ZvooveEmployee;
      const employee = Adapter.zvooveOneEmployeeToEmployee(
        response
      ) as Employee;
      return employee;
    } catch (error) {
      console.error("Error creating activity for the company:", error);
      throw error;
    }
  }
  async createPayrollDeduction(
    employeeNumber: string,
    payrollDeduction: PayrollDeduction
  ): Promise<any> {
    await this.api.waitForCompanyConfig();
    const apiData = {
      openApiSiteLink: `${this.api.zvooveOneBaseUrl}`,
      apiEndpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal/${employeeNumber}/AbzuegeBezuege/ueberweisungen`,
      xCoreClientId: this.api.xCoreClientIdZvooveOne,
      apiMethod: ApiMethod.post,
      body: Adapter.payrollDeductionToZvoovePayrollDeduction(payrollDeduction),
    };
    try {
      const response = await zorstService.ZorstOneApiCall(apiData);
      return response;
    } catch (error) {
      console.error("Error creating payroll deduction:", error);
      throw error;
    }
  }
}

export class ZvooveOneMultiFetch {
  private api = ApiIntegrationOne.getInstance();
  [x: string]: any;

  async fetchZvooveOneInitData(
    geschaeftsstelleIds: number[],
    erpOptions: ErpFetchOption[],
    loggedInGeschaeftsstelleIds?: number[]
  ): Promise<void> {
    await this.api.waitForCompanyConfig();
    const geschaeftsstelleIdsQuery = geschaeftsstelleIds
      .map((id) => `geschaeftsstelleIds=${id}`)
      .join("&");
    let loggedInGeschaeftsstelleIdsQuery = "";
    if (loggedInGeschaeftsstelleIds) {
      loggedInGeschaeftsstelleIdsQuery = loggedInGeschaeftsstelleIds
        .map((id) => `geschaeftsstelleIds=${id}`)
        .join("&");
    }
    const openApiSiteLink = `${this.api.zvooveOneBaseUrl}`;
    const apiRequests = [
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/unternehmen?${geschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/personal?${geschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/dashboard/fehlzeiten?${loggedInGeschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/dashboard/mitarbeiter/fluktuation?${loggedInGeschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/dashboard/einsaetze?${loggedInGeschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
    ];

    let message = "";
    let errorMessage = "";
    const customerList: CustomerList[] = [];
    const customers: Customer[] = [];
    const employeeList: EmployeeList[] = [];
    const employees: Employee[] = [];
    const dashboardData = {};

    try {
      const responses = await zorstService.ZorstMultiApiCall(
        openApiSiteLink,
        apiRequests
      );

      // Process each response individually
      responses.forEach((response: any, index: number) => {
        try {
          if (
            index === 0 &&
            response.success &&
            (erpOptions.includes(ErpFetchOption.CustomerList) ||
              erpOptions.includes(ErpFetchOption.Customers))
          ) {
            response.data.unternehmen.forEach((item: any) => {
              if (
                item.status === 2 &&
                erpOptions.includes(ErpFetchOption.Customers)
              ) {
                customers.push(Adapter.zvooveCustomerToCustomer(item));
              }
              if (erpOptions.includes(ErpFetchOption.CustomerList)) {
                customerList.push(Adapter.transformToCustomerList(item));
              }
            });
          } else if (
            index === 1 &&
            response.success &&
            (erpOptions.includes(ErpFetchOption.EmployeeList) ||
              erpOptions.includes(ErpFetchOption.Employees))
          ) {
            response.data.personal.forEach((item: ZvooveEmployee) => {
              if (
                item.personalstatus === 2 &&
                erpOptions.includes(ErpFetchOption.Employees)
              ) {
                employees.push(Adapter.zvooveOneEmployeeToEmployee(item));
              }
              if (erpOptions.includes(ErpFetchOption.EmployeeList)) {
                employeeList.push(Adapter.transformToEmployeeList(item));
              }
            });
          } else if (index >= 2 && response.success) {
            Object.assign(dashboardData, response.data);
          }
        } catch (processError) {
          console.error(
            `Error processing response for request ${index}:`,
            processError
          );
          errorMessage += `Error processing response for request ${index}. `;
        }
      });

      try {
        const employeeService = new EmployeeService();
        const hasFetchedValidEmployeeList =
          erpOptions.includes(ErpFetchOption.EmployeeList) &&
          employeeList.length > 0;
        if (hasFetchedValidEmployeeList)
          await employeeService.saveList(employeeList);
        if (erpOptions.includes(ErpFetchOption.Employees))
          await employeeService.addAllEmployees(employees);
      } catch (error) {
        console.error("Error saving employees:", error);
        errorMessage += "Error saving employees. ";
      }

      try {
        const customerService = new CustomerService();
        const hasFetchedValidCustomerList =
          erpOptions.includes(ErpFetchOption.CustomerList) &&
          customerList.length > 0;
        if (hasFetchedValidCustomerList)
          await customerService.saveList(customerList);
        if (erpOptions.includes(ErpFetchOption.Customers))
          await customerService.addCustomers(customers);
      } catch (error) {
        console.error("Error saving customers:", error);
        errorMessage += "Error saving customers. ";
      }

      try {
        if (erpOptions.includes(ErpFetchOption.CustomerList))
          store.commit("SET_ZVOOVE_CUSTOMER_LIST", customerList);
        if (erpOptions.includes(ErpFetchOption.EmployeeList))
          store.commit("SET_ZVOOVE_EMPLOYEE_LIST", employeeList);
        store.commit("SET_ZVOOVE_DASHBOARD_DATA", dashboardData);
      } catch (error) {
        console.error("Error committing to store:", error);
        errorMessage += "Error committing to store. ";
      }

      message += `${customerList.length} Unternehmensdaten, `;
      message += `${employeeList.length} Personaldaten, `;
      message += `${customers.length} aktive Kunden und `;
      message += `${employees.length} aktive Mitarbeiter erhalten.`;

      ToastService.showReminder(message);
      if (errorMessage) ToastService.showError(errorMessage);
    } catch (error) {
      console.error("Error retrieving data:", error);
      ToastService.showError("Error retrieving data.");
    }
  }

  async fetchZvooveOneDashboard(
    loggedInGeschaeftsstelleIds: number[]
  ): Promise<any> {
    await this.api.waitForCompanyConfig();
    const loggedInGeschaeftsstelleIdsQuery = loggedInGeschaeftsstelleIds
      .map((id) => `geschaeftsstelleIds=${id}`)
      .join("&");
    const openApiSiteLink = `${this.api.zvooveOneBaseUrl}`;
    const apiRequests = [
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/dashboard/fehlzeiten?${loggedInGeschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/dashboard/mitarbeiter/fluktuation?${loggedInGeschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
      {
        endpointLink: `${this.api.zvooveOneApiBaseUrl}/api/v1/dashboard/einsaetze?${loggedInGeschaeftsstelleIdsQuery}`,
        xCoreClientId: this.api.xCoreClientIdZvooveOne,
        method: ApiMethod.get,
      },
    ];

    try {
      const response = await zorstService.ZorstMultiApiCall(
        openApiSiteLink,
        apiRequests
      );
      const dashboardData = {
        ...response[0].data,
        ...response[1].data,
        ...response[2].data,
      };
      store.commit("SET_ZVOOVE_DASHBOARD_DATA", dashboardData);
    } catch (error) {
      console.error("Error retrieving data:", error);
      throw error;
    }
  }
}
