export enum SoftwareIntegrationType {
  indexAnzeigendaten = "indexAnzeigendaten",
  dashboardAiKpiAnalysis = "dashboardAiKpiAnalysis",
  payFlow = "payFlow",
  pdHub = "pdHub",
  whatsApp = "whatsApp",
  workTime = "workTime",
  wordPress = "wordPressPlugin",
  zorst = "zorst",
  zvooveOne = "zvooveOne",
  zvooveRecruit = "zvooveRecruit",
}
