<template>
  <v-container class="dispatcherboard columns ma-0 px-1" fluid>
    <v-row v-if="smAndDown" class="closed-icons-row">
      <v-col v-for="col in columns" :key="`icon-${col.id}`" cols="3">
        <v-icon
          @click.stop="activateColumn(col.id)"
          :class="{
            'control-icon': true,
            'ml-2': true,
            'icon-highlight': isClosed(col.id),
          }"
          color="primary"
          size="x-large"
        >
          {{ col.icon }}
        </v-icon>
      </v-col>
    </v-row>
    <v-row class="my-0 py-0">
      <v-col
        v-for="col in columns"
        :key="col.id"
        :cols="columnWidth(col.id)"
        :class="columnClasses(col.id)"
        @mouseover="hoveredColumn = col.id"
        @mouseleave="hoveredColumn = null"
      >
        <v-container
          v-if="!smAndDown"
          class="column-controll-icon d-flex ma-0 pa-0"
        >
          <v-icon
            v-if="isClosed(col.id)"
            @click.stop="reopenColumn(col.id)"
            :elevation="15"
            class="control-icon ml-2"
            color="primary"
          >
            {{ col.icon }}
          </v-icon>
          <v-spacer v-if="isClosed(col.id)"></v-spacer>
          <v-icon
            v-if="
              activeColumn !== col.id &&
              !isClosed(col.id) &&
              hoveredColumn === col.id
            "
            @click.stop="activateColumn(col.id)"
            class="control-icon"
            color="grey-lighten-0"
            size="x-small"
          >
            fa-solid fa-chevron-right
          </v-icon>
          <v-icon
            v-if="activeColumn === col.id"
            @click.stop="deactivateColumn(col.id)"
            class="control-icon"
            color="grey-lighten-0"
            size="x-small"
          >
            fa-solid fa-chevron-left
          </v-icon>
          <v-spacer></v-spacer>
          <div
            v-if="!user.config.hideTip?.onDispatcherBoard"
            style="min-height: 3.5rem"
          >
            <v-chip
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              v-if="
                !smAndDown &&
                !user.config.hideTip?.onDispatcherBoard &&
                !isActive(col.id) &&
                !isClosed(col.id)
              "
              label
              size="large"
              class="pa-4 mt-4"
              closable
              color="primary"
              border="md opacity-50"
              :prepend-icon="col.icon"
              @click:close="countDeactivateHeadlines()"
            >
              <v-card-subtitle>{{ col.headline }}</v-card-subtitle>
              <v-tooltip activator="parent" location="bottom">{{
                col.tooltip
              }}</v-tooltip>
            </v-chip>
          </div>
          <v-spacer></v-spacer>
          <v-icon
            v-if="!isClosed(col.id) && hoveredColumn === col.id"
            @click.stop="closeColumn(col.id)"
            class="control-icon"
            color="grey-lighten-0"
            size="x-small"
          >
            fa-solid fa-xmark
          </v-icon>
        </v-container>

        <div class="scroll-container" v-if="!isClosed(col.id)">
          <component
            :is="col.component"
            :columnHeight="columnHeight"
            :isActive="isActive(col.id)"
            :interComponentMessage="interComponentMessage"
            :lastUpdateTimeline="lastUpdateTimeline"
            :loggedInMandantUuids="loggedInMandantUuids"
            :mandants="mandants"
            :softwareIntegration="softwareIntegration"
            :user="user"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, markRaw } from "vue";
import { mapGetters } from "vuex";
import { useDisplay } from "vuetify";
import { UserService } from "@/services/api/user.service";
import CandidateColumn from "@/components/disposition/CandidateColumn.vue";
import CustomerColumn from "@/components/disposition/CustomerColumn.vue";
import DemandColumn from "@/components/disposition/DemandColumn.vue";
import DialogService from "@/services/dialog.service";
import EmployeeColumn from "@/components/disposition/EmployeeColumn.vue";
import { DispatcherBoardColumnHeight } from "../../enums/app-layout.enum";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    DemandColumn: markRaw(DemandColumn),
    CustomerColumn: markRaw(CustomerColumn),
    CandidateColumn: markRaw(CandidateColumn),
    EmployeeColumn: markRaw(EmployeeColumn),
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      activeColumn: null as number | null,
      closedColumns: [] as number[],
      counterDeactivateHeadlines: 3,
      columns: [
        {
          id: 1,
          component: DemandColumn,
          icon: "fa-solid fa-person-circle-question",
          headline: "Anfragen",
          tooltip:
            "Anfragen / Aufträge werden in dieser Spalte dargestellt. Die Karten können aus Telefonaten oder über das plus-Symbol angelegt werden.",
        },
        {
          id: 2,
          component: CustomerColumn,
          icon: "fa-solid fa-industry",
          headline: "Unternehmen (ERP)",
          tooltip:
            "Interessenten, Kunden, Ehemalige Kunden und erledigte Interessenten aus der ERP System bzw. aus Stellenanzeigen oder Internetsuche hinzugefügt.",
        },
        {
          id: 3,
          component: CandidateColumn,
          icon: "fa-solid fa-user-tie",
          headline: "Kandidaten (ATS)",
          tooltip: "Darstellung aller Kandidaten aus dem ATS System.",
        },
        {
          id: 4,
          component: EmployeeColumn,
          icon: "fa-solid fa-users",
          headline: "Personal (ERP)",
          tooltip:
            "Bewerber, Mitarbeiter, zukünftige Mitarbeiter, ehemalige Mitarbeiter und erledigte Bewerber aus dem ERP System.",
        },
      ],
      columnHeight: DispatcherBoardColumnHeight.standard as number,
      hoveredColumn: null as number | null,
      previousMandants: [] as string[],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      getEnv: "getEnv",
      interComponentMessage: "interComponentMessage",
      lastUpdateTimeline: "lastUpdateTimeline",
      loggedInMandantUuids: "getLoggedInMandantUuids",
      mandants: "reducedMandants",
      softwareIntegration: "softwareIntegration",
      user: "user",
    }),
  },
  watch: {
    "$store.getters.allSelectedMandants": {
      immediate: true,
      handler(newMandants) {
        const newEntries = newMandants.filter(
          (mandant: string) => !this.previousMandants.includes(mandant)
        );

        if (newEntries.length > 0) {
          this.loadDependenciesForAllSelectedMandants();
        }

        this.previousMandants = [...newMandants];
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Call initially to handle initial screen size
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    activateColumn(columnId: number) {
      if (this.smAndDown) {
        this.closedColumns = this.columns
          .map((col) => col.id)
          .filter((id) => id !== columnId);
        if (!this.closedColumns.includes(columnId)) {
          this.activeColumn = columnId;
        }
      } else {
        if (!this.closedColumns.includes(columnId)) {
          this.activeColumn = columnId;
        }
      }
    },
    columnClasses(columnId: number) {
      return {
        active: this.activeColumn === columnId,
        closed: this.isClosed(columnId),
        inactive: this.activeColumn !== columnId && !this.isClosed(columnId),
        "no-animation": this.smAndDown,
      };
    },
    columnWidth(columnId: number) {
      // Handle small screens
      if (this.smAndDown) {
        if (this.isClosed(columnId)) {
          return 0;
        }
        if (this.activeColumn === columnId) {
          return 12;
        } else {
          return 0;
        }
      }

      // Handle large screens
      if (this.isClosed(columnId)) {
        return 1;
      }

      const openColumnsCount = this.columns.filter(
        (col) => !this.isClosed(col.id)
      ).length;
      const totalWidthAvailable = 12 - this.closedColumns.length;

      if (this.activeColumn === columnId) {
        return Math.max(6, Math.floor(totalWidthAvailable / 2));
      } else {
        if (openColumnsCount === 1) {
          return totalWidthAvailable;
        } else {
          const remainingWidth =
            totalWidthAvailable -
            (this.activeColumn
              ? Math.max(6, Math.floor(totalWidthAvailable / 2))
              : 0);
          return Math.floor(
            remainingWidth / (openColumnsCount - (this.activeColumn ? 1 : 0))
          );
        }
      }
    },
    async countDeactivateHeadlines() {
      if (this.counterDeactivateHeadlines === 0) {
        const confirmed = await DialogService.confirm(
          "In Zukunft die Überschriften der Spalten nicht mehr anzeigen?",
          "Nein Behalten",
          "Spaltenüberschriften in Zukunft verbergen"
        );

        if (confirmed) {
          let userConfig = this.user.config;
          if (!userConfig.hideTip) {
            userConfig.hideTip = {};
          }
          userConfig.hideTip.onDispatcherBoard = true;
          const userService = new UserService();
          userService.updateUserConfig(userConfig, useRouter());
          return;
        } else {
          this.counterDeactivateHeadlines = 20;
          return;
        }
      }
      this.counterDeactivateHeadlines = this.counterDeactivateHeadlines - 1;
    },
    closeColumn(columnId: number) {
      this.closedColumns.push(columnId);
      if (this.smAndDown && this.activeColumn === columnId) {
        this.activeColumn = null;
        const nextActive = this.columns.find(
          (col) => !this.closedColumns.includes(col.id)
        );
        if (nextActive) {
          this.activateColumn(nextActive.id);
        }
      }
      if (this.activeColumn === columnId) {
        this.activeColumn = null;
      }
    },
    deactivateColumn(columnId: number) {
      if (this.activeColumn === columnId) {
        this.activeColumn = null;
      }
    },
    handleResize() {
      this.columnHeight =
        window.innerHeight - DispatcherBoardColumnHeight.headerHeight;
      const scrollContainer = document.querySelectorAll(".scroll-container");
      if (this.smAndDown) {
        scrollContainer.forEach((container) => {
          (container as HTMLElement).classList.remove("hide-scrollbar");
          (container as HTMLElement).style.overflowY = "auto";
          (container as HTMLElement).style.height =
            this.columnHeight.toString();
        });
      } else {
        scrollContainer.forEach((container) => {
          (container as HTMLElement).classList.add("hide-scrollbar");
          (container as HTMLElement).style.overflowY = "auto";
          (container as HTMLElement).style.height =
            this.columnHeight.toString();
        });
      }
    },
    isClosed(columnId: number) {
      return this.closedColumns.includes(columnId);
    },
    isActive(columnId: number) {
      return this.activeColumn === columnId;
    },
    async loadDependenciesForAllSelectedMandants() {
      this.$store.dispatch("loadLinkingsAndAddMissingDescriptions");
    },
    reopenColumn(columnId: number) {
      const index = this.closedColumns.indexOf(columnId);
      if (index !== -1) {
        this.closedColumns.splice(index, 1);
      }

      // scrollbar only on small screens
      const container = document.querySelector(
        `.scroll-container[data-id="${columnId}"]`
      );
      if (container) {
        if (this.smAndDown) {
          (container as HTMLElement).classList.remove("hide-scrollbar");
          (container as HTMLElement).style.overflowY = "auto";
        } else {
          (container as HTMLElement).classList.add("hide-scrollbar");
          (container as HTMLElement).style.overflowY = "auto";
        }
      }
    },
  },
});
</script>

<style>
body {
  margin: 0;
}
.dispatcherboard {
  height: calc(100vh - var(--height-header) - var(--height-top-header));
}
.column-controll-icon {
  min-height: 1rem;
}

.v-col {
  transition: flex-basis 0.5s ease-in-out, height 0.5s ease, opacity 0.5s ease;
}

.closed-column {
  transition: transform 0.5s ease-in-out;
}

.active-column {
  transition: transform 0.5s ease-in-out;
}

.inactive-column {
  transition: transform 0.5s ease-in-out;
}

.no-animation {
  transition: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.columns {
  overflow: hidden;
}

.control-icon {
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  transition: color 0.3s ease, transform 0.3s ease;
  text-shadow: var(--text-shadow);
}
.control-icon:hover {
  color: var(--color-tertiary);
  transform: scale(var(--medium-scale-up));
}
.item-collapsed {
  min-height: 3rem;
  margin-bottom: 0.5rem;
}
.hide-on-inactive-column {
  position: absolute;
  top: 0.2rem;
  right: 2rem;
  text-align: right;
}
.status-communication-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}
.status-select {
  max-width: 10rem;
  padding: 0.2rem;
  border-radius: var(--border-radius-elements);
  color: var(--color-primary);
  background-color: var(--color-accentblue);
}
.status-select:hover {
  background-color: var(--table-hover);
}
.communication-icons-container {
  margin-top: 0.2rem;
  display: flex;
  align-items: top;
  justify-content: space-between;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0.2rem;
  margin-bottom: 0.7rem;
}
.job-tag {
  margin-right: 1rem;
  margin-top: 0.5rem;
  border-radius: 5px;
}
.tag-input-field {
  margin-right: 1rem;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 5px;
  width: 11.5rem;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE & Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll-container {
  overflow-y: auto;
}
</style>
