<template>
  <div class="registration-form">
    <h2>Firmenregistrierung</h2>
    <form @submit.prevent="submitForm">
      <div>
        <label for="companyName">Firmenname:</label>
        <input type="text" id="companyName" v-model="companyName" required />
      </div>
      <div>
        <label for="adminEmail">Admin Emailadresse:</label>
        <input type="email" id="adminEmail" v-model="adminEmail" required />
      </div>
      <div>
        <label for="name">Admin Name:</label>
        <input type="text" id="name" v-model="name" required />
      </div>
      <div>
        <label for="lastname">Admin Nachname:</label>
        <input type="text" id="lastname" v-model="lastname" required />
      </div>
      <div>
        <label for="password">Passwort:</label>
        <input
          type="password"
          id="password"
          minlength="6"
          v-model="password"
          required
        />
      </div>
      <div>
        <label for="confirmPassword">Passwortbestätigung:</label>
        <input
          type="password"
          id="confirmPassword"
          minlength="6"
          v-model="confirmPassword"
          required
        />
      </div>
      <div>
        <label for="street">Straße:</label>
        <input type="text" id="street" v-model="companyAddress.street" />
      </div>
      <div>
        <label for="street-number">Hausnummer:</label>
        <input
          type="text"
          id="street-number"
          v-model="companyAddress.street_number"
        />
      </div>
      <div>
        <label for="postcode">Postleitzahl:</label>
        <input type="text" id="postcode" v-model="companyAddress.postcode" />
      </div>
      <div>
        <label for="city">Stadt</label>
        <input type="text" id="city" v-model="companyAddress.city" />
      </div>
      <label for="country">Land</label>
      <select id="country" v-model="companyAddress.country">
        <option
          v-for="(country, index) in countries"
          :key="index"
          :value="country"
        >
          {{ country }}
        </option>
      </select>
      <div>
        <label for="companyWebsite">Firmen Website:</label>
        <input type="url" id="companyWebsite" v-model="companyWebsite" />
      </div>
      <div>
        <label for="facebook">Facbeook:</label>
        <input type="url" id="facebook" v-model="social_media_links.facebook" />
      </div>
      <div>
        <label for="instagram">Instagram:</label>
        <input
          type="url"
          id="instagram"
          v-model="social_media_links.instagram"
        />
      </div>
      <div>
        <label for="tiktok">TikTok:</label>
        <input type="url" id="tiktok" v-model="social_media_links.tiktok" />
      </div>
      <button type="submit">Registrieren</button>
    </form>
  </div>
</template>

<script lang="ts">
import ToastService from "@/services/toast.service";
import { RegistrationService } from "@/services/api/registration.service";
import { Countries } from "@/enums/countries.enum";
import router from "@/router";
import { defineComponent } from "vue";
import { Routes } from "@/enums/routes.enum";

export default defineComponent({
  name: "RegistrationView",
  data() {
    return {
      companyName: "",
      countries: Object.values(Countries),
      adminEmail: "",
      name: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      vat: "",
      companyWebsite: "",
      companyAddress: {
        street: "",
        street_number: "",
        postcode: "",
        city: "",
        country: "",
      },
      registrationService: new RegistrationService(),
      social_media_links: {
        facebook: "",
        instagram: "",
        tiktok: "",
      },
    };
  },
  methods: {
    async submitForm() {
      if (this.password !== this.confirmPassword) {
        ToastService.show("Die Passwörter stimmen nicht überein.");
      } else {
        try {
          await this.registrationService.registerCompany({
            company_name: this.companyName,
            email: this.adminEmail,
            admin_name: this.name,
            admin_lastname: this.lastname,
            password: this.password,
            password_validation: this.confirmPassword,
            website: this.companyWebsite,
            vat: this.vat,
            address: this.companyAddress,
            social_media_links: this.social_media_links,
          });
          router.push(Routes.registrationSuccess);
        } catch (error: any) {
          switch (error.response.status) {
            case 409:
              ToastService.show(
                "Diese Admin Emailadresse ist bereits registriert. Bitte geben Sie eine andere Email Adresse an."
              );
              return;
            default:
              ToastService.show("Netzwerkfehler");
          }
        }
      }
    },
  },
});
</script>

<style scoped>
.registration-form {
  background-color: var(--color-primary);
  color: white;
  border: 1px solid black;
  border-radius: 0.25rem;
  margin: 1rem auto;
  padding: 0 1rem;
  width: 30vw;
}

select {
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: calc(100% - 1rem);
  padding: 0.5rem;
  margin-bottom: 1rem;
}

button {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
