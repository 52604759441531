import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdminMenuComponent = _resolveComponent("AdminMenuComponent")!
  const _component_PdMasterHeader = _resolveComponent("PdMasterHeader")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AdminMenuComponent),
    (_ctx.appLayout === _ctx.AppLayout.pdmaster)
      ? (_openBlock(), _createBlock(_component_PdMasterHeader, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          class: "ma-10",
          cols: "12",
          md: "auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("DEBUG Info")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list, null, {
                      default: _withCtx(() => [
                        _createElementVNode("li", null, "Version " + _toDisplayString(_ctx.getVersion()), 1),
                        _createElementVNode("li", null, "Environment: " + _toDisplayString(_ctx.getEnvironment()), 1),
                        _createElementVNode("li", null, "Backend URL: " + _toDisplayString(_ctx.getBackendURI()), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded", "border"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}