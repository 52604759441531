import { ZipCodeDistance } from "@/models/zipcode.model";
import { Api } from "./api.class";

export class ZipCodeService {
  private api = Api.getInstance();
  private urlPath = "/zipcode";

  async getDistances(baseZipcode: string, targetZipcodes: string[]) {
    try {
      const response = await this.api.getBackendApi().get(`${this.urlPath}`, {
        params: {
          baseZipcode: baseZipcode,
          targetZipcodes: targetZipcodes.join(","),
        },
      });
      return response.data as ZipCodeDistance[];
    } catch (error) {
      console.error("Fehler beim Abrufen der Distanzen: ", error);
      throw new Error("Fehler beim Abrufen der Distanzen von der API");
    }
  }

  async getZipcodesWithinRadius(postalCode: string, radius: number) {
    try {
      const response = await this.api
        .getBackendApi()
        .get(`${this.urlPath}/within-radius`, {
          params: {
            postalCode: postalCode,
            radius: radius,
          },
        });
      return response.data as string[];
    } catch (error) {
      console.error(
        "Fehler beim Abrufen der Distanzen innerhalb des Radius: ",
        error
      );
      throw new Error(
        "Fehler beim Abrufen der Distanzen innerhalb des Radius von der API"
      );
    }
  }
}
