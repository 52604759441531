export enum FunnelSection {
  MainQuestion = "mainQuestion",
  SubQuestion = "subQuestion",
  ThankYouPage = "thankYouPage",
  NoWayPage = "noWayPage",
}

export enum CurrentFunnel {
  creatingNewFunnel = "creatingNewFunnel",
}
