<template>
  <!-- Modal for changing pwd -->
  <v-dialog :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'">
    <v-card>
      <v-card-title class="d-flex justify-space-between ml-2 mt-2">
        Passwort vergessen
        <v-btn icon variant="text" density="compact" @click="$emit('cancel')"
          ><v-icon>fa-solid fa-xmark</v-icon></v-btn
        ></v-card-title
      >
      <v-card-subtitle class="ml-2"
        >Bitte geben Sie Ihre Email Adresse ein</v-card-subtitle
      >
      <v-card-text>
        <v-text-field
          variant="outlined"
          v-model="emailInput"
          label="Email Adresse"
          type="email"
          required
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="$emit('cancel')">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-4" @click="sendPassword">Senden</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { PasswordChange } from "@/models/password-change.model";
import { defineComponent } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import ToastService from "../../../../services/toast.service";
import { UserService } from "../../../../services/api/user.service";

export default defineComponent({
  name: "PasswordForgottenDialog",
  emits: ["cancel", "sent"],
  props: {
    requestError: String,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      emailInput: "",
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      } as PasswordChange,
      smAndDown,
      userService: new UserService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    sendPassword() {
      this.userService.forgotPassword(this.emailInput).then(
        () => {
          this.$emit("sent");
          ToastService.showSuccess("Neues Passwort gesendet");
        },
        () => {
          this.$emit("sent");
          ToastService.showSuccess("Neues Passwort gesendet");
        }
      );
    },
  },
});
</script>
