import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { noGutters: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "10" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "Server",
                modelValue: _ctx.emailConfigCopy.server,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailConfigCopy.server) = $event)),
                density: "compact",
                class: "ma-1"
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "Port",
                modelValue: _ctx.emailConfigCopy.port,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.emailConfigCopy.port) = $event)),
                type: "number",
                density: "compact",
                class: "ma-1"
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "10" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "E-Mail Adresse",
                modelValue: _ctx.emailConfigCopy.from,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailConfigCopy.from) = $event)),
                density: "compact",
                class: "ma-1"
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "SSL",
                modelValue: _ctx.emailConfigCopy.secure,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.emailConfigCopy.secure) = $event)),
                density: "compact",
                class: "ma-1"
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "Username",
                modelValue: _ctx.emailConfigCopy.username,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.emailConfigCopy.username) = $event)),
                density: "compact",
                class: "ma-1"
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "Passwort",
                modelValue: _ctx.emailConfigCopy.password,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.emailConfigCopy.password) = $event)),
                density: "compact",
                class: "ma-1"
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}