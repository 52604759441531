import { Api } from "./api.class";

export class BackendLoggingService {
  private api = Api.getInstance();
  private urlPath = "/logging";

  async getAllLogs(logCount?: number, logCountFrom?: number) {
    try {
      let url = this.urlPath;

      const params = [];
      if (logCount) {
        params.push(`logCount=${logCount}`);
      }
      if (logCountFrom) {
        params.push(`logCountFrom=${logCountFrom}`);
      }

      if (params.length > 0) {
        url += `?${params.join("&")}`;
      }

      const response = await this.api.getBackendApi().get(url);
      return response.data.response || [];
    } catch (error) {
      console.error("Error fetching logs:", error);
      return { data: null, error };
    }
  }
}
