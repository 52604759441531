<!--src/components/config/software-integration/ConfigReminderSettings.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="reminder-icon">fa-solid fa-bell</v-icon>
      Erinnerungen und Deadlines
    </h2>

    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col
          cols="12"
          md="4"
          v-for="(reminder, index) in localConfig.reminderSettings"
          :key="index"
        >
          <v-card
            :variant="vStyle.card.variant || undefined"
            :rounded="vStyle.card.rounded || undefined"
            :border="vStyle.card.border || undefined"
            class="mb-4 pa-0"
          >
            <v-card-title>{{
              getEventNameByCandidateStatus(reminder.reminderFor)
            }}</v-card-title>
            <v-card-text class="mt-4">
              <v-slider
                v-model="reminder.hoursAfter"
                :label="'Stunden danach'"
                :min="0"
                :max="24"
                step="1"
                thumb-label="always"
              ></v-slider>
              <v-slider
                v-model="reminder.daysAfter"
                :label="'Tage danach'"
                :min="0"
                :max="30"
                step="1"
                thumb-label="always"
              ></v-slider>
              <v-switch
                v-model="reminder.showPrompt"
                :label="'Meldung einfordern?'"
              ></v-switch>
              <v-switch
                v-model="reminder.needToContact"
                :label="'Kandidat blinkt?'"
              ></v-switch>
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="reminder.message"
                :label="'Message'"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig, ReminderSetting } from "@/models/company-config.model";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ConfigReminderSettings",
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["getEventNameByCandidateStatus"]),
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
      this.syncReminderSettings();
    },
  },
  mounted() {
    this.syncReminderSettings();
  },
  methods: {
    //if there are new reminders in store raw data => add them to configt
    syncReminderSettings() {
      const storeReminderSettings = this.$store.state.company.reminderSettings;
      const localReminderSettings = this.localConfig.reminderSettings;
      const missingReminders = storeReminderSettings.filter(
        (storeReminder: ReminderSetting) =>
          !localReminderSettings.some(
            (localReminder) =>
              localReminder.reminderFor === storeReminder.reminderFor
          )
      );
      if (missingReminders.length > 0) {
        this.localConfig.reminderSettings.push(...missingReminders);
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>

<style scoped>
.reminder-icon {
  animation: blinkAnimation 1s infinite, colorBlinkAnimation 1s infinite,
    shakeAnimation 0.3s infinite;
  cursor: pointer;
}

@keyframes blinkAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes colorBlinkAnimation {
  0% {
    color: #cb0000;
  }
  25% {
    color: #8d0000;
  }
  50% {
    color: #cb0000;
  }
  75% {
    color: #ff2929;
  }
  100% {
    color: #cb0000;
  }
}

@keyframes shakeAnimation {
  0% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
