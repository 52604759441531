<template>
  <ValidateDeletionDialog
    :show="showValidateDeletionDialog"
    :email="deleteCompanyId"
    @validate="deleteCompany"
    @update:show="updateValidateDeletionDialogVisibility"
  />
  <CompanyDialog
    :show="showCompanyDialog"
    :editMode="editMode"
    :editCompanyModel="companyModel"
    @update:show="updateDialogVisibility"
    @click:outside="handleBackgroundClick"
  />
  <UserDialog
    :mandants="mandants"
    :show="showUserDialog"
    :editMode="editMode"
    :editUserModel="userModel"
    @update:show="updateDialogVisibility"
    @click:outside="handleBackgroundClick"
  />
  <AdminMenu />
  <PdMasterHeader v-if="layout === AppLayout.pdmaster"></PdMasterHeader>
  <v-container :fluid="true">
    <div class="d-flex flex-row-reverse mt-4 mb-4">
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        density="comfortable"
        size="large"
        @click="addCompany()"
      >
        <v-icon icon="fa-solid fa-plus" />
      </v-btn>
    </div>
    <v-row>
      <v-col
        v-for="company in companies"
        :key="company.name"
        :name="company.name"
        cols="12"
        sm="3"
      >
        <v-card
          :variant="vStyle.card.variant || undefined"
          :border="vStyle.card.border || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :title="`${company.name} - ${company.tradeRegisterEntry} `"
          :subtitle="company.email"
        >
          <v-card-text class="d-flex">
            <v-row>
              <v-col cols="12" sm="4">
                <div><i class="fa-solid fa-envelope"></i>&nbsp;:</div>
                <div>UStId:</div>
              </v-col>
              <v-col cols="12" sm="8">
                <div>{{ company.email }}</div>
                <div>{{ company.vat }}</div>
                <div>{{ company.address?.street }}</div>
                <div>{{ company.address?.street_number }}</div>
                <div>{{ company.address?.postcode }}</div>
                <div>{{ company.address?.country }}</div>
              </v-col>
              <v-col cols="12" sm="12">
                <div>Benutzer:</div>
                <div v-for="user in company.users" :key="user._id">
                  <div>{{ user.username }} ({{ getRole(user.role) }})</div>
                </div>
                <v-btn @click="addUser">Benutzer anlegen</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn color="success" @click="editCompany(company)"
              >Bearbeiten</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="abort" @click="removeCompany(company)">Löschen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import { defineComponent } from "vue";
import CompanyDialog from "./company.dialog.vue";
import { CompanyService } from "@/services/api/company.service";
import { Company } from "@/models/company.model";
import ValidateDeletionDialog from "@/components/modals/validate-deletion.modal.vue";
import { UserRole } from "@/enums/user-role.enum";
import { User } from "@/models/user.model";
import UserDialog from "../user/dialogs/UserDialog.vue";
import PdMasterHeader from "@/components/header/PdMasterHeader.vue";
import { AppLayout } from "@/enums/app-layout.enum";

export default defineComponent({
  name: "ConfigCompany",
  components: {
    AdminMenu,
    CompanyDialog,
    UserDialog,
    ValidateDeletionDialog,
    PdMasterHeader,
  },
  data() {
    return {
      companies: [] as Company[],
      companyModel: {} as Company,
      companyService: CompanyService.getInstance(),
      deleteCompanyId: "",
      editMode: false,
      mandants: [],
      showCompanyDialog: false,
      showUserDialog: false,
      showValidateDeletionDialog: false,
      AppLayout: AppLayout,
      layout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      userModel: {} as User,
      vStyle: this.$store.state.vStyle,
    };
  },
  mounted() {
    this.loadCompanies();
  },
  methods: {
    addCompany() {
      this.editMode = false;
      this.showCompanyDialog = true;
    },
    addUser() {
      this.editMode = false;
      this.showUserDialog = true;
    },
    getRole(num: number): string {
      return UserRole[num];
    },
    loadCompanies() {
      this.companyService
        .getCompanies()
        .then((result: any) => (this.companies = result));
    },
    updateDialogVisibility(newValue: boolean) {
      this.showCompanyDialog = newValue;
      this.showUserDialog = newValue;
      this.loadCompanies();
    },
    updateValidateDeletionDialogVisibility(newValue: boolean) {
      this.showValidateDeletionDialog = newValue;
      this.loadCompanies();
    },
    editCompany(company: Company) {
      this.editMode = true;
      this.companyModel = company;
      this.showCompanyDialog = true;
    },
    async deleteCompany(id: string) {
      await this.companyService.removeCompany(id);
      this.loadCompanies();
    },
    handleBackgroundClick() {
      this.showCompanyDialog = false;
      this.showUserDialog = false;
    },
    removeCompany(company: Company) {
      this.deleteCompanyId = company._id as string;
      this.showValidateDeletionDialog = true;
    },
  },
});
</script>
