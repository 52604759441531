// src/services/funnel.service.ts
import { JobFunnel } from "../../models/job-funnel.model";
import { Api } from "./api.class";

export class FunnelService {
  private api = Api.getInstance();
  private urlPath = "/funnel";

  async saveJobFunnel(funnelObject: JobFunnel): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .post<{ response: JobFunnel }>(`${this.urlPath}`, funnelObject);

    if (response.data && response.data.response) {
      return response.data.response;
    } else {
      throw new Error("Failed to save funnel");
    }
  }

  async updateFunnel(funnelObject: JobFunnel): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .put<{ response: string }>(`${this.urlPath}`, funnelObject);

    if (response.data && response.data.response) {
      return response.data.response;
    } else {
      throw new Error("Failed to update funnel");
    }
  }

  async deleteFunnel(id: string): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .delete<{ response: string }>(`${this.urlPath}/${id}`);

    if (response.data && response.data.response) {
      return response.data.response;
    } else {
      throw new Error("Failed to delete funnel");
    }
  }

  async getAllFunnels(): Promise<JobFunnel[]> {
    const response = await this.api
      .getBackendApi()
      .get<{ response: JobFunnel[] }>(`${this.urlPath}`);

    if (response.data && Array.isArray(response.data.response)) {
      return response.data.response;
    } else {
      throw new Error("Failed to fetch funnels");
    }
  }
}
