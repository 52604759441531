import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_externalSoftwareLinksMenu = _resolveComponent("externalSoftwareLinksMenu")!
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_menu, null, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_btn, _mergeProps(props, {
          icon: "",
          class: "phone-icon",
          variant: "text",
          density: "compact"
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, { size: "xsmall" }, {
              default: _withCtx(() => [
                _createTextVNode("fa-solid fa-phone")
              ]),
              _: 1
            }),
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Telefonat starten")
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1040)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_card, {
          variant: _ctx.vStyle.card.variant || undefined,
          rounded: _ctx.vStyle.card.rounded || undefined,
          border: _ctx.vStyle.card.border || undefined
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_subtitle, { class: "my-1" }, {
              default: _withCtx(() => [
                _createTextVNode("Telefon")
              ]),
              _: 1
            }),
            _createVNode(_component_v_list, { dense: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item, { onClick: _ctx.handleOutgoingCall }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "text-medium-emphasis" }, {
                      default: _withCtx(() => [
                        _createTextVNode("fas fa-phone")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis mr-3 mb-3",
                      size: "xsmall"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("fas fa-right-long")
                      ]),
                      _: 1
                    }),
                    _createTextVNode("Ausgehend")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_v_list_item, { onClick: _ctx.handleIncomingCall }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, { class: "text-medium-emphasis mr-1" }, {
                      default: _withCtx(() => [
                        _createTextVNode("fas fa-phone-volume")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_icon, {
                      class: "text-medium-emphasis mr-2 mb-3",
                      size: "xsmall"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("fas fa-left-long")
                      ]),
                      _: 1
                    }),
                    _createTextVNode("Eingehend")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["variant", "rounded", "border"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showSelectNummerDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSelectNummerDialog) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Wähle eine Nummer")
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneNumbers, (phoneNumber, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: index,
                        onClick: ($event: any) => (_ctx.dialNumber(phoneNumber)),
                        title: phoneNumber.label,
                        subtitle: phoneNumber.value
                      }, null, 8, ["onClick", "title", "subtitle"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSelectNummerDialog = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Schließen")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showSelectContactDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showSelectContactDialog) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Wähle einen Ansprechpartner")
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.phoneNumbers, (phoneNumber, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: index,
                        onClick: ($event: any) => (_ctx.selectContact(phoneNumber)),
                        subtitle: phoneNumber.label
                      }, null, 8, ["onClick", "subtitle"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "primary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showSelectContactDialog = false))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Schließen")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showResultDialog,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showResultDialog) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isIncoming ? "Eingehendes Telefonat" : "Teilnehmer erreicht?") + " ", 1),
                _createVNode(_component_v_spacer),
                (_ctx.candidate)
                  ? (_openBlock(), _createBlock(_component_externalSoftwareLinksMenu, {
                      key: 0,
                      zvooveRecruitMitarbeiterUuid: _ctx.candidate.candidateUuid,
                      hideUpdateMenuPoint: true
                    }, null, 8, ["zvooveRecruitMitarbeiterUuid"]))
                  : (_ctx.employee)
                    ? (_openBlock(), _createBlock(_component_externalSoftwareLinksMenu, {
                        key: 1,
                        zvooveOneEmployeeNumber: _ctx.employee.employeeNumber,
                        hideUpdateMenuPoint: true
                      }, null, 8, ["zvooveOneEmployeeNumber"]))
                    : (_ctx.customer)
                      ? (_openBlock(), _createBlock(_component_externalSoftwareLinksMenu, {
                          key: 2,
                          zvooveOneCustomerNumber: _ctx.customer.customerNumber,
                          hideUpdateMenuPoint: true
                        }, null, 8, ["zvooveOneCustomerNumber"]))
                      : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            (_ctx.candidate)
              ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.candidateName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.customer)
              ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.selectedPhoneNumber?.label), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.candidate)
              ? (_openBlock(), _createBlock(_component_v_card_subtitle, { key: 2 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.employee?.firstName) + " " + _toDisplayString(_ctx.employee?.lastName), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: "outlined",
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  block: "",
                  color: "primary",
                  class: "mb-1",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.callEstablished()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isIncoming ? "Notiz schreiben" : "Erreicht?"), 1)
                  ]),
                  _: 1
                }, 8, ["rounded", "border"]),
                (_ctx.customer || _ctx.candidate)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      variant: "outlined",
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      block: "",
                      color: "primary",
                      class: "mb-1",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setAppointment()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Termin / Ergebnis")
                      ]),
                      _: 1
                    }, 8, ["rounded"]))
                  : _createCommentVNode("", true),
                (!_ctx.isIncoming)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      variant: "outlined",
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      block: "",
                      color: "primary",
                      class: "mb-1",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.closeModalNotReached('nicht erreicht')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Nicht erreicht")
                      ]),
                      _: 1
                    }, 8, ["rounded"]))
                  : _createCommentVNode("", true),
                (_ctx.customer || _ctx.candidate)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 2,
                      block: "",
                      color: "primary",
                      variant: "text",
                      class: "mt-5",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.addFollowUp()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Wiedervorlage")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showNoteDialog,
      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showNoteDialog) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Notiz zum Telefonat")
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_textarea, {
                  variant: _ctx.vStyle.input.variant || undefined,
                  rounded: _ctx.vStyle.input.rounded || undefined,
                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                  color: _ctx.vStyle.input.color || undefined,
                  modelValue: _ctx.callNote,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.callNote) = $event)),
                  label: "Notiz"
                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                (_ctx.customer)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      variant: "outlined",
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      block: "",
                      class: "mb-2",
                      density: "compact",
                      color: "primary",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addDemand()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Anfrage anlegen")
                      ]),
                      _: 1
                    }, 8, ["rounded"]))
                  : _createCommentVNode("", true),
                (_ctx.customer || _ctx.candidate)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      variant: "outlined",
                      rounded: _ctx.vStyle.btn.rounded || undefined,
                      block: "",
                      density: "compact",
                      color: "primary",
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.addFollowUp()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Wiedervorlage")
                      ]),
                      _: 1
                    }, 8, ["rounded"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  variant: _ctx.vStyle.btn.variant || undefined,
                  rounded: _ctx.vStyle.btn.rounded || undefined,
                  border: _ctx.vStyle.btn.border || undefined,
                  color: "primary",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.backToResultModal()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("zurück")
                  ]),
                  _: 1
                }, 8, ["variant", "rounded", "border"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.submitNote(true)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Speichern + zurück")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.submitNote()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("OK")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ], 64))
}