<!--src/componenets/config/UserProfile.vue-->
<template>
  <v-btn variant="text" icon density="compact" class="mr-2">
    <v-icon size="small" @click="mainModal = true" class="mx-3 clickable">
      fa-solid fa-user
    </v-icon>
    <v-tooltip activator="parent" location="bottom"
      >User Profil ändern</v-tooltip
    >
  </v-btn>
  <v-dialog
    v-model="mainModal"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'"
  >
    <v-card>
      <v-card-title class="title d-flex">
        Profil bearbeiten
        <v-spacer></v-spacer>
        <v-icon @click="mainModal = false" class="close-icon">
          <i class="fa-solid fa-xmark"></i>
        </v-icon>
      </v-card-title>
      <div class="name-title d-flex justify-space-between font-weight-bold">
        <div>{{ user?.forename }} {{ user?.lastname }}</div>
        <div>Firma {{ $store.state.company.name }}</div>
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="user.tel"
            label="Telefonnummer"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="user.mobilePhone"
            label="Handynummer"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="user.email"
            label="E-Mail"
            :rules="[rules.required, rules.email]"
            @update:model-value="resetError"
          ></v-text-field>
          <div class="d-flex justify-space-between">
            <div>
              <v-btn
                :variant="vStyle.btn.variant || undefined"
                :rounded="vStyle.btn.rounded || undefined"
                :border="vStyle.btn.border || undefined"
                color="primary"
                @click="showPasswordModal"
                @update:model-value="resetError"
                >Passwort ändern</v-btn
              >
              <ErrorChip :label="requestError"></ErrorChip>
            </div>
            <div>
              <v-btn
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.btn.rounded || undefined"
                :border="vStyle.btn.border || undefined"
                color="abort"
                @click="showPasswordForgottenModal"
                >Passwort vergessen</v-btn
              >
            </div>
          </div>
          <v-divider class="my-5"></v-divider>
          <v-divider v-if="softwareIntegration.pdHub" class="my-5"></v-divider>
          <v-checkbox
            v-if="user.config.hideTip && user.config.hideTip?.onDispatcherBoard"
            v-model="user.config.hideTip.onDispatcherBoard"
            label="Spaltenüberschriften auf Dispoboard verbergen"
          ></v-checkbox>
        </v-form>
        <v-label class="mr-1">Theme umstellen:</v-label>
        <v-btn density="compact" @click="setTheme()">
          {{ themeLight ? "Light" : "Dark" }}
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red-darken-3" @click="mainModal = false">Abbrechen</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-4" @click="save">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <PasswordDialog
    v-if="passwordModal"
    model-value=""
    :requestError="passwordRequestError"
    @cancel="passwordModal = false"
    @changePassword="changePassword"
    @click:outside="handlePasswordBackgroundClick"
    @dirty="resetPasswordError"
  ></PasswordDialog>
  <PasswordForgottenDialog
    v-if="passwordForgottenModal"
    model-value=""
    :requestError="passwordRequestError"
    @cancel="passwordForgottenModal = false"
    @sent="passwordForgottenModal = false"
    @click:outside="handlePasswordBackgroundClick"
  ></PasswordForgottenDialog>
</template>

<script lang="ts">
import { useTheme } from "vuetify";
import { ApiResponse } from "@/models/api-response.model";
import { defineComponent } from "vue";
import { PasswordChange } from "@/models/password-change.model";
import { Salutation } from "@/enums/salutation.enum";
import { useDisplay } from "vuetify";
import { UserService } from "@/services/api/user.service";
import { User } from "@/models/user.model";
import ErrorChip from "@/helper/ErrorChip.vue";
import PasswordDialog from "./dialogs/PasswordDialog.vue";
import { mapGetters } from "vuex";
import { RulesMessage } from "../../../enums/app-messages.enum";
import PasswordForgottenDialog from "./dialogs/PasswordForgottenDialog.vue";

export default defineComponent({
  name: "UserProfile",
  components: {
    ErrorChip,
    PasswordDialog,
    PasswordForgottenDialog,
  },
  setup() {
    const theme = useTheme();

    return { theme };
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      rules: {
        required: (value: string) => !!value || RulesMessage.required,
        email: (value: string) => {
          const pattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
          return pattern.test(value) || RulesMessage.invalidMail;
        },
      },
      smAndDown,
      themeLight: true,
      user: this.$store.state.company.loggedInUser as User,
      valid: false,
      passwordValid: false,
      mainModal: false,
      passwordForgottenModal: false,
      passwordModal: false,
      passwordRequestError: "",
      requestError: "",
      userService: new UserService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user",
      softwareIntegration: "softwareIntegration",
    }),
    salutations() {
      return Object.entries(Salutation)
        .filter(([, value]) => !isNaN(Number(value)))
        .map(([key, value]) => ({
          text: key,
          value: value as number,
        }));
    },
  },
  methods: {
    handlePasswordBackgroundClick() {
      this.passwordModal = false;
      this.passwordForgottenModal = false;
    },
    handleBackgroundClick() {
      this.mainModal = false;
    },
    showPasswordForgottenModal() {
      this.passwordForgottenModal = true;
    },
    showPasswordModal() {
      this.passwordModal = true;
    },
    changePassword(passwordChange: PasswordChange) {
      if (passwordChange) {
        this.userService
          .changePassword(passwordChange)
          .then((response: ApiResponse) => {
            if (response.error === "Password object invalid") {
              this.passwordRequestError = "Falsches aktuelles Passwort";
            } else {
              this.passwordModal = false;
            }
          });
      }
    },
    resetError() {
      this.requestError = "";
    },
    resetPasswordError() {
      this.passwordRequestError = "";
    },
    save() {
      if (this.valid) {
        this.userService
          .updateUserProfile({
            email: this.user.email,
            mobilePhone: this.user.mobilePhone,
            tel: this.user.tel,
          })
          .then((response: ApiResponse) => {
            if (!response.error) {
              this.mainModal = false;
            } else {
              this.requestError = response.error;
            }
          });
      } else {
        this.requestError = "Formular unvollständig";
      }
    },
    setTheme() {
      this.themeLight = !this.themeLight;
      this.theme.global.name.value = this.themeLight ? "light" : "dark";
    },
  },
});
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.clickable:hover {
  transition: all 0.3s ease;
  scale: 1.2;
}
.name-title {
  margin: 0 1.5rem;
}

.title {
  margin: 8px;
}
</style>
