export enum AppLayout {
  dispositioner = "dispositioner",
  pdmaster = "pdmaster",
}

export enum HeaderSpinner {
  pdLoading = "pdhub-loading.gif",
  pdRecuripon = "recurion-pdconnect.gif",
  recurion = "recurion0.gif",
}

export enum DispatcherBoardColumnHeight {
  headerHeight = 280,
  standard = 500,
  iLoaderSubtract = 20,
  iLoaderDistance = 200,
}
