import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-timeline")
          ]),
          _: 1
        }),
        _createTextVNode("Kontaktarten ")
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTimelineEntryTypes, (entry, key) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "12",
                  md: "4",
                  key: key
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, { variant: "flat" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "mt-1" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, { cols: "9" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: (_ctx.localConfig.timelineEntryTypes as any)[key].description,
                                  "onUpdate:modelValue": ($event: any) => (((_ctx.localConfig.timelineEntryTypes as any)[key].description) = $event),
                                  label: _ctx.getReadableName(key),
                                  density: "compact"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue", "label"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, { cols: "3" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: (_ctx.localConfig.timelineEntryTypes as any)[key].artZvoove,
                                  "onUpdate:modelValue": ($event: any) => (((_ctx.localConfig.timelineEntryTypes as any)[key].artZvoove) = $event),
                                  label: "Art",
                                  density: "compact"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            variant: _ctx.vStyle.btn.variant || undefined,
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            class: "mt-10",
            type: "submit",
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Speichern")
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}