import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = {
  class: "text-subtitle-1",
  style: {"font-size":"0.6rem","color":"gray"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_SearchEmployee = _resolveComponent("SearchEmployee")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_SearchCustomer = _resolveComponent("SearchCustomer")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_file_input, {
      variant: _ctx.vStyle.input.variant || undefined,
      rounded: _ctx.vStyle.input.rounded || undefined,
      "base-color": _ctx.vStyle.input.baseColor || undefined,
      color: _ctx.vStyle.input.color || undefined,
      ref: "fileInput",
      modelValue: _ctx.file,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file) = $event)),
      label: "PNG / JPEG / PDF",
      "prepend-icon": "fa-solid fa-cloud-arrow-up",
      "show-size": "",
      rules: [_ctx.fileRules],
      accept: ".png,.jpg,.pdf",
      onInput: _ctx.onFileChange,
      density: "compact"
    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules", "onInput"]),
    _createVNode(_component_v_card, {
      variant: "outlined",
      border: "primary-darken-1 md dashed",
      class: "mb-4 pa-5 d-flex flex-column align-center",
      onDrop: _withModifiers(_ctx.onDrop, ["prevent"]),
      onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "d-flex align-center" }, {
          default: _withCtx(() => [
            _createTextVNode("Datei hierhin verschieben")
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_title, { class: "d-flex align-center" }, {
          default: _withCtx(() => [
            _createTextVNode("oder STRG + V")
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            (_ctx.file)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("p", null, "Dateiname: " + _toDisplayString(_ctx.file.name), 1),
                  _createElementVNode("p", null, "Dateigröße: " + _toDisplayString((_ctx.file.size / 1024).toFixed(2)) + " KB", 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onDrop"]),
    _createVNode(_component_v_select, {
      "append-icon": "fas fa-plus",
      "prepend-inner-icon": _ctx.showEditIcon ? 'fas fa-pencil' : undefined,
      variant: _ctx.vStyle.input.variant || undefined,
      rounded: _ctx.vStyle.input.rounded || undefined,
      "base-color": _ctx.vStyle.input.baseColor || undefined,
      color: _ctx.vStyle.input.color || undefined,
      modelValue: _ctx.selectedPrompt,
      "onUpdate:modelValue": [
        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedPrompt) = $event)),
        _cache[5] || (_cache[5] = ($event: any) => (_ctx.checkIfEditable()))
      ],
      items: _ctx.prompts,
      "item-title": "headline",
      density: "compact",
      "return-object": "",
      "onClick:append": _cache[3] || (_cache[3] = ($event: any) => (_ctx.openAddOrEditPromptDialog())),
      "onClick:prependInner": _cache[4] || (_cache[4] = ($event: any) => (_ctx.editSelectedPrompt()))
    }, null, 8, ["prepend-inner-icon", "variant", "rounded", "base-color", "color", "modelValue", "items"]),
    _createVNode(_component_v_textarea, {
      variant: _ctx.vStyle.input.variant || undefined,
      rounded: _ctx.vStyle.input.rounded || undefined,
      "base-color": _ctx.vStyle.input.baseColor || undefined,
      color: _ctx.vStyle.input.color || undefined,
      modelValue: _ctx.selectedPrompt.prompt,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedPrompt.prompt) = $event)),
      "onClick:appendInner": _cache[7] || (_cache[7] = ($event: any) => (_ctx.deleteWorkTimePrompt(_ctx.selectedPrompt))),
      "append-inner-icon": _ctx.showEditIcon ? 'fas fa-circle-xmark' : undefined,
      label: "Kundenspezifische Besonderheiten",
      rows: "4",
      placeholder: "Beschreibe genau wie die Arbeitsstunden extahiert werden sollen..."
    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "append-inner-icon"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_btn, {
        variant: _ctx.vStyle.btn.variant || undefined,
        rounded: _ctx.vStyle.btn.rounded || undefined,
        border: _ctx.vStyle.btn.border || undefined,
        disabled: !_ctx.file,
        onClick: _ctx.uploadFile,
        color: "secondary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Arbeitszeiten extrahieren ")
        ]),
        _: 1
      }, 8, ["variant", "rounded", "border", "disabled", "onClick"]),
      _createVNode(_component_v_btn, {
        icon: "",
        density: "compact",
        variant: "text"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode("fa-solid fa-ellipsis-vertical")
            ]),
            _: 1
          }),
          _createVNode(_component_v_menu, { activator: "parent" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                variant: _ctx.vStyle.card.variant || undefined,
                rounded: _ctx.vStyle.card.rounded || undefined,
                border: _ctx.vStyle.card.border || undefined
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_radio_group, {
                        modelValue: _ctx.selectedAiSpecs.core,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.selectedAiSpecs.core) = $event)),
                        label: "AI Core"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aiCores, (core) => {
                            return (_openBlock(), _createBlock(_component_v_radio, {
                              key: core.core,
                              label: core.label,
                              value: core.core
                            }, {
                              label: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", null, _toDisplayString(core.label), 1),
                                  _createElementVNode("div", _hoisted_3, _toDisplayString(core.subtitle), 1)
                                ])
                              ]),
                              _: 2
                            }, 1032, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_v_divider, { class: "mb-4" }),
                      _createVNode(_component_v_radio_group, {
                        modelValue: _ctx.selectedAiSpecs.model,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedAiSpecs.model) = $event)),
                        label: "AI Model"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aiModels, (model) => {
                            return (_openBlock(), _createBlock(_component_v_radio, {
                              key: model.core,
                              label: model.label,
                              value: model.core
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["variant", "rounded", "border"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showAddOrEditPromptDialog,
      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.showAddOrEditPromptDialog) = $event)),
      persistent: "",
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.editPrompt ? "Details Bearbeiten" : "Neuen Prompt hinzufügen") + " ", 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showAddOrEditPromptDialog = false)),
                  icon: "",
                  size: "xsmall",
                  variant: "text",
                  density: "compact"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode(" fa-solid fa-xmark close-icon ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { noGutters: "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.currentPrompt.headline,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.currentPrompt.headline) = $event)),
                          density: "compact",
                          label: "Überschrift"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_textarea, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.currentPrompt.prompt,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.currentPrompt.prompt) = $event)),
                          density: "compact",
                          label: "Prompt"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          class: "mr-4",
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.currentPrompt.customerNumber,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.currentPrompt.customerNumber) = $event)),
                          density: "compact",
                          label: "Kundenummer",
                          "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                          "onClick:prependInner": _cache[14] || (_cache[14] = ($event: any) => (_ctx.searchCustomerMenuOpen()))
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                        _createVNode(_component_SearchEmployee, {
                          ref: "searchEmployeeComponent",
                          onEmployeeSelected: _ctx.addSelectedEmployee
                        }, null, 8, ["onEmployeeSelected"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "8" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.currentPrompt.customer,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.currentPrompt.customer) = $event)),
                          density: "compact",
                          label: "Firmenname"
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_combobox, {
                          variant: _ctx.vStyle.input.variant || undefined,
                          rounded: _ctx.vStyle.input.rounded || undefined,
                          "base-color": _ctx.vStyle.input.baseColor || undefined,
                          color: _ctx.vStyle.input.color || undefined,
                          modelValue: _ctx.currentPrompt.employeeNumbers,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.currentPrompt.employeeNumbers) = $event)),
                          density: "compact",
                          clearable: "",
                          multiple: "",
                          label: "Mitarbeiter",
                          "prepend-inner-icon": "fa-solid fa-magnifying-glass",
                          "onClick:prependInner": _cache[17] || (_cache[17] = ($event: any) => (_ctx.searchEmployeeMenuOpen()))
                        }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                        _createVNode(_component_SearchCustomer, {
                          ref: "searchCustomerComponent",
                          onCustomerSelected: _ctx.addSelectedCustomer
                        }, null, 8, ["onCustomerSelected"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employeeNames, (name, index) => {
                          return (_openBlock(), _createBlock(_component_v_chip, {
                            key: index,
                            class: "ma-2",
                            color: "primary-darken-1",
                            outlined: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(name), 1)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_btn, {
                  color: "abort",
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.closeAddOrEditPromptDialog()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Abbrechen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  color: "success",
                  onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.addOrEditPrompt()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Speichern")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ], 64))
}