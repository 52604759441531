<template>
  <v-row class="mt-2">
    <v-col cols="12" md="auto">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title class="mb-4">Import</v-card-title>
        <v-card-text>
          <FileUpload @addWorkTime="addWorktime"></FileUpload>
          <v-card-subtitle class="my-4"
            >Stundenzuordnung im ERP</v-card-subtitle
          >
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            prepend-inner-icon="fa-solid fa-magnifying-glass"
            @click:prepend-inner="searchEmployeeMenuOpen()"
            v-model="transponderNumber"
            label="Mitarbeiter Transpondernummer"
            clearable
            density="compact"
          ></v-text-field>
          <SearchEmployee
            ref="searchEmployeeComponent"
            @employeeSelected="setTransponderNummer"
          ></SearchEmployee>
          <v-card-subtitle class="mb-4">Nachzuschlag</v-card-subtitle>
          <div class="d-flex">
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Zuschlagszeit von"
              v-model="nightShiftBonus.startTime"
              type="time"
              class="mr-1"
              clearable
              density="compact"
            >
            </v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              label="Zuschlagszeit bis"
              v-model="nightShiftBonus.endTime"
              type="time"
              class="ml-1"
              clearable
              density="compact"
            >
            </v-text-field>
          </div>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            :disabled="!worktimesExportable"
            block
            @click="exportWorktimes"
            color="primary"
          >
            Buchungsdaten exportieren
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title
          >Abrechnungsmonat {{ payrollMonth.format("MM-YYYY") }}</v-card-title
        >
        <v-card-text>
          <v-list>
            <v-list-item v-for="(workday, index) in worktime" :key="index">
              <v-row class="mt-1">
                <v-col cols="2">{{ formateDate(workday.date) }}</v-col>
                <v-col cols="auto" class="d-flex">
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    class="mr-2"
                    v-model="workday.startTime"
                    label="Startzeit"
                    density="compact"
                    type="time"
                    clearable
                  ></v-text-field>
                  <v-text-field
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    class="mr-2"
                    v-model="workday.endTime"
                    label="Endzeit"
                    density="compact"
                    type="time"
                    clearable
                  ></v-text-field>
                  <v-btn class="ml-2"
                    ><v-icon class="mr-1">fas fa-mug-hot</v-icon
                    >{{ calculateTimes(workday).totalPauseMinutes }}
                    <v-menu activator="parent" :close-on-content-click="false">
                      <v-card
                        :variant="vStyle.card.variant || undefined"
                        :rounded="vStyle.card.rounded || undefined"
                        :border="vStyle.card.border || undefined"
                      >
                        <v-card-title>Pausenzeiten</v-card-title>
                        <v-card-text class="d-flex">
                          <div
                            v-for="(breakTime, breakIndex) in workday.breaks"
                            :key="breakIndex"
                          >
                            <v-text-field
                              :variant="vStyle.input.variant || undefined"
                              :rounded="vStyle.input.rounded || undefined"
                              :base-color="vStyle.input.baseColor || undefined"
                              :color="vStyle.input.color || undefined"
                              v-model="breakTime.breakStart"
                              :label="breakIndex + 1 + '. Pause'"
                              class="mr-2"
                              density="compact"
                              type="time"
                              clearable
                            ></v-text-field>
                            <v-text-field
                              :variant="vStyle.input.variant || undefined"
                              :rounded="vStyle.input.rounded || undefined"
                              :base-color="vStyle.input.baseColor || undefined"
                              :color="vStyle.input.color || undefined"
                              v-model="breakTime.breakEnd"
                              label="Ende"
                              class="mr-2"
                              density="compact"
                              type="time"
                              clearable
                            ></v-text-field>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </v-btn>
                </v-col>
                <v-col cols="2">
                  <div
                    v-if="calculateTimes(workday).totalWorkMinutes !== 'NaN'"
                    class="d-flex flex-column"
                  >
                    <p>
                      <v-icon size="xsmall" class="mr-1"
                        >fa-regular fa-clock</v-icon
                      >{{ calculateTimes(workday).totalWorkMinutes }}
                      <v-icon size="xsmall">{{
                        compareTimes(
                          calculateTimes(workday).totalWorkMinutes,
                          workday.totalWorkTime
                        )
                      }}</v-icon>
                      {{ workday.totalWorkTime }}
                    </p>
                    <p
                      v-if="
                        parseFloat(calculateTimes(workday).nightBonusHours) > 0
                      "
                    >
                      <v-icon size="xsmall" class="mr-1"
                        >fa-solid fa-moon></v-icon
                      >
                      {{ calculateTimes(workday).nightBonusHours }}
                      <v-icon size="xsmall">{{
                        compareTimes(
                          calculateTimes(workday).nightBonusHours,
                          workday.nightShiftBonus
                        )
                      }}</v-icon>
                      {{ workday.nightShiftBonus }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="2">
                  <p v-if="workday.sundayShiftBonus">
                    <v-icon size="xsmall" class="mr-1">fa-solid fa-sun</v-icon>
                    {{ workday.sundayShiftBonus }}
                  </p>
                  <p v-if="workday.holidayShiftBonus">
                    <v-icon size="xsmall" class="mr-1"
                      >fa-solid fa-gifts</v-icon
                    >
                    {{ workday.holidayShiftBonus }}
                  </p>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FileUpload from "./FileUpload.vue";
import SearchEmployee from "./SearchEmployee.vue";
import { WorkDay } from "@/models/worktime.model";
import moment from "moment";
import ToastService from "../../services/toast.service";

export default defineComponent({
  name: "WorkTime",
  components: {
    FileUpload,
    SearchEmployee,
  },
  data() {
    return {
      nightShiftBonus: {
        startTime: "23:00",
        endTime: "06:00",
      },
      payrollMonth: moment(),
      transponderNumber: "",
      vStyle: this.$store.state.vStyle,
      worktime: [] as WorkDay[],
    };
  },
  computed: {
    worktimesExportable(): boolean {
      return this.worktime.some(
        (day) => day.date && day.startTime && day.endTime
      );
    },
  },
  mounted() {
    this.generateMonth(this.payrollMonth);
  },
  methods: {
    async addWorktime(newWorkdays: WorkDay[]) {
      newWorkdays = this.formatWorkDays(newWorkdays);
      const lastNewMonth = moment(
        newWorkdays[newWorkdays.length - 1].date,
        "DD.MM.YYYY"
      );

      if (
        lastNewMonth.format("MM-YYYY") !== this.payrollMonth.format("MM-YYYY")
      ) {
        this.generateMonth(lastNewMonth);
      }
      this.addOrUpdateWorkdays(newWorkdays);
    },
    addOrUpdateWorkdays(newWorkdays: WorkDay[]) {
      newWorkdays.forEach((newDay) => {
        const existingDayIndex = this.worktime.findIndex(
          (day) => day.date === newDay.date
        );

        if (existingDayIndex !== -1) {
          this.worktime.splice(existingDayIndex, 1, newDay);
        } else {
          this.worktime.push(newDay);
        }
      });
    },

    calculateTimes(workday: WorkDay) {
      const timeFormat = "HH:mm";
      let totalPauseMinutes = 0;

      let startTime = moment(workday.startTime, timeFormat);
      let endTime = moment(workday.endTime, timeFormat);

      if (startTime.isAfter(endTime)) {
        endTime.add(1, "day");
      }

      workday.breaks.forEach((breakTime) => {
        if (breakTime.breakStart && breakTime.breakEnd) {
          const breakStart = moment(breakTime.breakStart, timeFormat);
          const breakEnd = moment(breakTime.breakEnd, timeFormat);
          if (breakStart.isAfter(breakEnd)) {
            breakEnd.add(1, "day");
          }
          totalPauseMinutes += breakEnd.diff(breakStart, "minutes");
        }
      });

      const totalWorkMinutes =
        endTime.diff(startTime, "minutes") - totalPauseMinutes;

      const nightBonus = this.calculateSurcharge(workday, this.nightShiftBonus);

      const totalPauseHours = (totalPauseMinutes / 60).toFixed(2);
      const totalWorkHours = (totalWorkMinutes / 60).toFixed(2);

      return {
        totalPauseMinutes: totalPauseHours,
        totalWorkMinutes: totalWorkHours,
        nightBonusHours: nightBonus,
      };
    },
    calculateSurcharge(
      workday: WorkDay,
      bonus: { startTime: string; endTime: string }
    ) {
      const timeFormat = "HH:mm";

      // Split start and end times
      const [bonusStartHour, bonusStartMinute] = bonus.startTime
        .split(":")
        .map(Number);
      const [bonusEndHour, bonusEndMinute] = bonus.endTime
        .split(":")
        .map(Number);

      let startTime = moment(
        `${workday.date} ${workday.startTime}`,
        "DD.MM.YYYY HH:mm"
      );
      let endTime = moment(
        `${workday.date} ${workday.endTime}`,
        "DD.MM.YYYY HH:mm"
      );

      // Check if the shift crosses midnight
      if (startTime.isAfter(endTime)) {
        endTime.add(1, "day");
      }

      let surchargeMinutes = 0;
      const dayStart = startTime.clone().startOf("day");

      // Create moment objects for bonus start and end times
      const bonusStart = dayStart
        .clone()
        .add(bonusStartHour, "hours")
        .add(bonusStartMinute, "minutes");
      const bonusEnd = dayStart
        .clone()
        .add(bonusEndHour, "hours")
        .add(bonusEndMinute, "minutes");

      // Handle crossing midnight
      if (bonusEndHour < bonusStartHour) {
        bonusEnd.add(1, "day");
      }

      // Calculate the surcharge time only within the bonus time range
      const bonusShiftStart = moment.max(bonusStart, startTime);
      const bonusShiftEnd = moment.min(bonusEnd, endTime);

      if (bonusShiftStart.isBefore(bonusShiftEnd)) {
        surchargeMinutes = bonusShiftEnd.diff(bonusShiftStart, "minutes");

        let bonusPauseMinutes = 0;
        workday.breaks.forEach((breakTime) => {
          let breakStart = moment(breakTime.breakStart, timeFormat);
          let breakEnd = moment(breakTime.breakEnd, timeFormat);

          // Adjust for cross-midnight breaks
          if (breakStart.isBefore(startTime)) {
            breakStart.add(1, "day");
          }
          if (breakEnd.isBefore(startTime)) {
            breakEnd.add(1, "day");
          }

          if (breakEnd.isBefore(breakStart)) {
            breakEnd.add(1, "day");
          }

          // Calculate pause time within surcharge period
          if (
            breakStart.isBetween(bonusShiftStart, bonusShiftEnd, null, "[)")
          ) {
            const pauseDuration = breakEnd.diff(breakStart, "minutes");
            bonusPauseMinutes += pauseDuration;
          }
        });

        surchargeMinutes -= bonusPauseMinutes;
      }

      return (surchargeMinutes / 60).toFixed(2);
    },
    compareTimes(time1: any, time2: any) {
      const formattedTime1 = parseFloat(time1.toString().replace(",", "."));
      const formattedTime2 = parseFloat(time2.toString().replace(",", "."));

      const difference = Math.abs(formattedTime1 - formattedTime2);
      if (difference <= 0.02) {
        return "fas fa-check";
      } else if (difference <= 0.3) {
        return "fas fa-equals";
      } else {
        return "fas fa-not-equal";
      }
    },
    exportWorktimes() {
      const csvContent = this.worktime
        .filter((day) => day.date && day.startTime && day.endTime)
        .map((day) => {
          const totalWorkMinutes = this.calculateTimes(day).totalWorkMinutes;
          const breaks = day.breaks.map((breakTime) => {
            const breakDuration = moment
              .duration(
                moment(breakTime.breakEnd, "HH:mm").diff(
                  moment(breakTime.breakStart, "HH:mm")
                )
              )
              .asMinutes();
            return `${breakTime.breakStart};${breakDuration || 0}`;
          });

          const csvRow = [
            this.transponderNumber,
            day.date,
            "AN",
            day.startTime,
            day.endTime,
            day.startTime,
            day.endTime,
            totalWorkMinutes.replace(".", ","),
            ...breaks,
            "", // 4th Pause Beginn empty
            "", // 4th Pause Dauer empty
            "ZEITERFASSUNG",
            "Stor",
            "age",
            "1550",
          ].join(";");

          return csvRow;
        })
        .join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "worktimes.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    formateDate(date: string) {
      return moment(date, "DD.MM.YYYY").locale("de").format("DD.MM.YYYY (dd)");
    },
    formatWorkDays(workdays: WorkDay[]): WorkDay[] {
      const validDateFormats = ["DD.MM.YYYY", "DD.MM.YY", "YYYY-MM-DD"];
      const timeFormats = ["HH:mm", "H:mm", "HH:mm:ss", "H:m"];

      return workdays.filter((day) => {
        const formattedDate = moment(day.date, validDateFormats, true);
        if (!formattedDate.isValid()) {
          console.warn(
            `Ungültiges Datum: ${day.date}. Dieser Eintrag wird entfernt.`
          );
          return false;
        } else {
          day.date = formattedDate.format("DD.MM.YYYY");
        }

        const formattedStartTime = moment(day.startTime, timeFormats, true);
        day.startTime = formattedStartTime.isValid()
          ? formattedStartTime.format("HH:mm")
          : "";

        const formattedEndTime = moment(day.endTime, timeFormats, true);
        day.endTime = formattedEndTime.isValid()
          ? formattedEndTime.format("HH:mm")
          : "";

        day.breaks.forEach((breakTime) => {
          const formattedBreakStart = moment(
            breakTime.breakStart,
            timeFormats,
            true
          );
          breakTime.breakStart = formattedBreakStart.isValid()
            ? formattedBreakStart.format("HH:mm")
            : "";

          const formattedBreakEnd = moment(
            breakTime.breakEnd,
            timeFormats,
            true
          );
          breakTime.breakEnd = formattedBreakEnd.isValid()
            ? formattedBreakEnd.format("HH:mm")
            : "";
        });

        while (day.breaks.length < 3) {
          day.breaks.push({ breakStart: "", breakEnd: "" });
        }

        if (day.breaks.length > 3) {
          this.mergeExtraBreaks(day);
        }

        return true;
      });
    },
    generateMonth(month: moment.Moment) {
      const daysInMonth = [];
      const startOfMonth = month.clone().startOf("month");
      const endOfMonth = month.clone().endOf("month");
      this.worktime = [];
      for (
        let day = startOfMonth;
        day.isBefore(endOfMonth) || day.isSame(endOfMonth);
        day.add(1, "day")
      ) {
        daysInMonth.push({
          date: day.format("DD.MM.YYYY"),
          startTime: "",
          endTime: "",
          breaks: [
            { breakStart: "", breakEnd: "" },
            { breakStart: "", breakEnd: "" },
            { breakStart: "", breakEnd: "" },
          ],
          totalWorkTime: "",
        });
      }

      this.worktime = daysInMonth;
      this.payrollMonth = month;
    },
    mergeExtraBreaks(newDay: WorkDay) {
      const extraBreaks = newDay.breaks.slice(3);
      const totalExtraBreakTime = extraBreaks.reduce((total, b) => {
        const start = moment(b.breakStart, "HH:mm");
        const end = moment(b.breakEnd, "HH:mm");
        return total + end.diff(start, "minutes");
      }, 0);

      const lastBreak = newDay.breaks[2];
      const lastBreakEnd = moment(lastBreak.breakEnd, "HH:mm").add(
        totalExtraBreakTime,
        "minutes"
      );
      lastBreak.breakEnd = lastBreakEnd.format("HH:mm");
      newDay.breaks = newDay.breaks.slice(0, 3);
    },
    setTransponderNummer(employeeNumber: string, employeeName: string) {
      this.transponderNumber = `T${employeeNumber}`;
      ToastService.show(employeeName + " ausgewählt");
    },
    searchEmployeeMenuOpen() {
      if (this.$refs.searchEmployeeComponent) {
        (
          this.$refs.searchEmployeeComponent as InstanceType<
            typeof SearchEmployee
          >
        ).toggleMenu();
      }
    },
  },
});
</script>
