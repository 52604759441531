import { CompanyConfig, WorkTimePrompt } from "@/models/company-config.model";
import { Api } from "./api.class";
import store from "@/store/store";
import { MailServer } from "../../models/mailserver.model";

export class CompanyConfigService {
  private static instance: CompanyConfigService;
  private api = Api.getInstance();
  private companyConfig = {} as CompanyConfig;

  public static getInstance(): CompanyConfigService {
    if (!CompanyConfigService.instance) {
      CompanyConfigService.instance = new CompanyConfigService();
    }
    return CompanyConfigService.instance;
  }

  async getConfig(): Promise<CompanyConfig> {
    if (Object.keys(this.companyConfig).length === 0) {
      await this.getCompanyConfig();
    }
    return this.companyConfig;
  }

  private async getCompanyConfig(): Promise<CompanyConfig> {
    const response = await this.api
      .getBackendApi()
      .get<CompanyConfig>("/company-config");
    if (response.data) {
      this.companyConfig = this.mergeConfigWithDefaults(response.data);
    } else {
      this.companyConfig = this.getDefaultConfigFromStore();
    }
    return this.companyConfig;
  }

  private getDefaultConfigFromStore(): CompanyConfig {
    const defaultConfig = store.state.company;
    // Deep clone to avoid mutation
    return JSON.parse(JSON.stringify(defaultConfig));
  }

  private mergeConfigWithDefaults(config: CompanyConfig): CompanyConfig {
    const defaultConfig = this.getDefaultConfigFromStore();

    // Ensure the nested objects exist
    const backendAiData = config.aiData || {};
    const backendAiDataTemplate = backendAiData.template || {};
    const backendAiDataPrompt = backendAiData.prompt || {};

    const defaultAiData = defaultConfig.aiData || {};
    const defaultAiDataTemplate = defaultAiData.template || {};
    const defaultAiDataPrompt = defaultAiData.prompt || {};

    // Use store template if statusOptionsRecruit is not present or is an empty array
    const mergedStatusOptionsRecruit =
      config.statusOptionsRecruit && config.statusOptionsRecruit.length > 0
        ? config.statusOptionsRecruit
        : defaultConfig.statusOptionsRecruit;

    // Merge reminderSettings
    const mergedReminderSettings = [...config.reminderSettings];
    defaultConfig.reminderSettings.forEach((defaultSetting) => {
      if (
        !mergedReminderSettings.some(
          (setting) => setting.reminderFor === defaultSetting.reminderFor
        )
      ) {
        mergedReminderSettings.push(defaultSetting);
      }
    });

    // Merge aiData templates
    const mergedAiDataTemplate = {
      ...defaultAiDataTemplate,
      ...backendAiDataTemplate,
      mail:
        backendAiDataTemplate.mail && backendAiDataTemplate.mail.length
          ? backendAiDataTemplate.mail
          : defaultAiDataTemplate.mail,
    };

    // Merge aiData prompts
    const mergedAiDataPrompts = {
      ...defaultAiDataPrompt,
      ...backendAiDataPrompt,
    };

    // Merge apiKeys
    const mergedApiKeys = {
      ...defaultConfig.apiKeys,
      ...config.apiKeys,
    };

    // Merge profileTemplate
    const mergedProfileTemplate = {
      ...defaultConfig.profileTemplate,
      ...config.profileTemplate,
    };

    // Overwrite salaryAdjustments from the backend, if present
    const mergedSalaryAdjustments =
      config.salaryAdjustments && config.salaryAdjustments.length > 0
        ? config.salaryAdjustments
        : defaultConfig.salaryAdjustments;

    return {
      ...defaultConfig,
      ...config,
      statusOptionsRecruit: mergedStatusOptionsRecruit,
      reminderSettings: mergedReminderSettings,
      aiData: {
        ...defaultAiData,
        ...backendAiData,
        template: mergedAiDataTemplate,
        prompt: mergedAiDataPrompts,
      },
      candidateStatusColor: {
        ...defaultConfig.candidateStatusColor,
        ...config.candidateStatusColor,
      },
      timelineEntryTypes: {
        ...defaultConfig.timelineEntryTypes,
        ...config.timelineEntryTypes,
      },
      mailServer: {
        ...defaultConfig.mailServer,
        ...config.mailServer,
      },
      softwareIntegration: {
        ...defaultConfig.softwareIntegration,
        ...config.softwareIntegration,
      },
      apiKeys: mergedApiKeys,
      profileTemplate: mergedProfileTemplate,
      salaryAdjustments: mergedSalaryAdjustments,
    };
  }

  async putCompanyConfig(companyConfig: CompanyConfig): Promise<CompanyConfig> {
    const response = await this.api
      .getBackendApi()
      .put("/company-config", companyConfig);
    return response.data;
  }

  async putCompanyMailServerConfig(
    mailServer: MailServer
  ): Promise<CompanyConfig> {
    const response = await this.api
      .getBackendApi()
      .put("/company-config/mailserver", mailServer);
    return response.data;
  }

  async putLastAutoUpdateFromErp(
    lastAutoUpdateFromErp: string
  ): Promise<CompanyConfig> {
    const response = await this.api
      .getBackendApi()
      .put("/company-config/last-auto-update", { lastAutoUpdateFromErp });
    return response.data;
  }

  async addWorkTimePrompt(
    workTimePrompt: WorkTimePrompt
  ): Promise<WorkTimePrompt[]> {
    const response = await this.api
      .getBackendApi()
      .post<WorkTimePrompt[]>(
        "/company-config/worktime-prompt",
        workTimePrompt
      );
    return response.data;
  }

  async updateWorkTimePrompts(
    workTimePrompts: WorkTimePrompt[]
  ): Promise<WorkTimePrompt[]> {
    const response = await this.api
      .getBackendApi()
      .put<WorkTimePrompt[]>(
        "/company-config/worktime-prompts",
        workTimePrompts
      );
    return response.data;
  }
}
