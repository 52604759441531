import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-afe4baa2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "reminder-icon"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-bell")
          ]),
          _: 1
        }),
        _createTextVNode(" Erinnerungen und Deadlines ")
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localConfig.reminderSettings, (reminder, index) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "12",
                  md: "4",
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      variant: _ctx.vStyle.card.variant || undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      class: "mb-4 pa-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getEventNameByCandidateStatus(reminder.reminderFor)), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_card_text, { class: "mt-4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_slider, {
                              modelValue: reminder.hoursAfter,
                              "onUpdate:modelValue": ($event: any) => ((reminder.hoursAfter) = $event),
                              label: 'Stunden danach',
                              min: 0,
                              max: 24,
                              step: "1",
                              "thumb-label": "always"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_slider, {
                              modelValue: reminder.daysAfter,
                              "onUpdate:modelValue": ($event: any) => ((reminder.daysAfter) = $event),
                              label: 'Tage danach',
                              min: 0,
                              max: 30,
                              step: "1",
                              "thumb-label": "always"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_switch, {
                              modelValue: reminder.showPrompt,
                              "onUpdate:modelValue": ($event: any) => ((reminder.showPrompt) = $event),
                              label: 'Meldung einfordern?'
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_switch, {
                              modelValue: reminder.needToContact,
                              "onUpdate:modelValue": ($event: any) => ((reminder.needToContact) = $event),
                              label: 'Kandidat blinkt?'
                            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: reminder.message,
                              "onUpdate:modelValue": ($event: any) => ((reminder.message) = $event),
                              label: 'Message'
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["variant", "rounded", "border"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            variant: _ctx.vStyle.btn.variant || undefined,
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            class: "mt-10",
            type: "submit",
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Speichern")
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}