<template>
  <v-sheet
    class="d-flex align-center"
    :class="mdAndDown ? 'flex-row' : 'flex-column'"
    :width="mdAndDown ? '100vw' : '5rem'"
    :height="mdAndDown ? '3rem' : '100%'"
    color="primary-darken-1"
  >
    <v-icon
      :size="mdAndDown ? 'large' : 'x-large'"
      :class="mdAndDown ? 'ml-10 mr-15' : 'mt-10 mb-15'"
      >fas fa-home</v-icon
    >
    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'ml-10 mr-5' : 'mt-10 mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="
        $route.path === Routes.dashboard ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.dashboard)"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >fas fa-tachometer-alt</v-icon
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="
        $route.path === Routes.recruiting ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.recruiting)"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >fas fa-people-arrows</v-icon
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="
        $route.path === Routes.dispostion ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.dispostion)"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >fas fa-headset</v-icon
      >
    </v-sheet>

    <v-sheet
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="
        $route.path === Routes.administration ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.administration)"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >far fa-folder-open</v-icon
      >
    </v-sheet>

    <v-sheet
      v-if="$store.state.company.softwareIntegration.workTime"
      class="d-flex justify-center align-center"
      :class="mdAndDown ? 'mr-5' : 'mb-5'"
      :width="mdAndDown ? '5rem' : '100%'"
      :height="mdAndDown ? '100%' : '3rem'"
      :color="
        $route.path === Routes.worktime ? 'secondary' : 'primary-darken-1'
      "
      @click="navigateTo(Routes.worktime)"
    >
      <v-icon class="menu-icon" :size="mdAndDown ? 'large' : 'x-large'"
        >far fa-clock</v-icon
      >
    </v-sheet>
  </v-sheet>
</template>

<script lang="ts">
import { Routes } from "@/enums/routes.enum";
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "LeftHeader",
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      menuHeight: "99%",
      Routes: Routes,
    };
  },
  methods: {
    navigateTo(route: string) {
      this.$router.push(route);
    },
  },
});
</script>
<style scoped>
.menu-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.menu-icon:hover {
  scale: 1.1;
}
</style>
