import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = { class: "d-flex justify-space-between" }
const _hoisted_5 = { class: "d-flex align-center" }
const _hoisted_6 = { class: "d-flex align-center" }
const _hoisted_7 = { class: "d-flex justify-space-between align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_color_picker = _resolveComponent("v-color-picker")!
  const _component_IconPicker = _resolveComponent("IconPicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { class: "mt-5" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: "flat",
              color: _ctx.funnelData.design.background,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined,
              class: "px-15 pb-15"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "d-flex justify-space-between mb-5" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createTextVNode(_toDisplayString(_ctx.funnelData.companyConfig.title) + " Vorschau ", 1),
                      (_ctx.funnelData._id)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            icon: "",
                            variant: "text",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openFunnel(_ctx.funnelData._id)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("fas fa-eye")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_v_btn, {
                      color: "success",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveFunnel()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.funnelData._id ? "Funnel speichern" : "neuen Funnel anlegen"), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    label: "Funnel Titel",
                    modelValue: _ctx.funnelData.companyConfig.title,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.funnelData.companyConfig.title) = $event)),
                    density: "compact",
                    class: "mt-2"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                  _createVNode(_component_v_btn, {
                    icon: "",
                    variant: "text",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openCustomPromptDialog()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { size: "large" }, {
                        default: _withCtx(() => [
                          _createTextVNode("fa-solid fa-rocket")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_dialog, {
                    modelValue: _ctx.showCustomPromptDialog,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showCustomPromptDialog) = $event)),
                    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Funnel Fragen generieren")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_subtitle, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Thema: " + _toDisplayString(_ctx.funnelData.companyConfig.title), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_text, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_textarea, {
                                variant: _ctx.vStyle.input.variant || undefined,
                                rounded: _ctx.vStyle.input.rounded || undefined,
                                "base-color": _ctx.vStyle.input.baseColor || undefined,
                                placeholder: "Hier können weitere Anweisungen zur Generierung der Funnelfragen gestellt werden ...",
                                modelValue: _ctx.customPrompt,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customPrompt) = $event))
                              }, null, 8, ["variant", "rounded", "base-color", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_card_actions, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                color: "abort",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeCustomPromptDialog()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Abbrechen")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_spacer),
                              _createVNode(_component_v_btn, {
                                color: "success",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.generateFunnelQuestions()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Generieren")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "max-width"])
                ]),
                _createVNode(_component_v_card, {
                  rounded: _ctx.funnelData.design.rounding,
                  class: "mb-5"
                }, {
                  default: _withCtx(() => [
                    (_ctx.funnelData.design.funnelHeader)
                      ? (_openBlock(), _createBlock(_component_v_img, {
                          key: 0,
                          width: "100%",
                          "aspect-ratio": "1/1",
                          cover: "",
                          src: _ctx.funnelData.design.funnelHeader
                        }, null, 8, ["src"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_sheet, {
                      height: "1rem",
                      class: "d-flex align-center justify-start",
                      color: _ctx.funnelData.design.primary
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_sheet, {
                          height: "0.7rem",
                          width: `${_ctx.progressBar}%`,
                          color: _ctx.funnelData.design.secondary
                        }, null, 8, ["width", "color"])
                      ]),
                      _: 1
                    }, 8, ["color"])
                  ]),
                  _: 1
                }, 8, ["rounded"]),
                (
            _ctx.currentFunnelPage.mainQuestions &&
            _ctx.selectedMainQuestionIndex === null
          )
                  ? (_openBlock(), _createBlock(_component_v_card, {
                      key: 0,
                      class: "pa-4",
                      color: _ctx.funnelData.design.bgQuestionForm,
                      rounded: _ctx.funnelData.design.rounding
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  width: 500,
                                  variant: "plain",
                                  label: "Überschrift Hauptfragen",
                                  modelValue: _ctx.funnelData.funnelHeader,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.funnelData.funnelHeader) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.funnelData.questions, (question, qIndex) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                cols: "12",
                                sm: "4",
                                key: qIndex
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    color: _ctx.funnelData.design.primary,
                                    rounded: _ctx.funnelData.design.rounding,
                                    class: "ma-3 pa-3",
                                    onClick: ($event: any) => (_ctx.showSubQuestions(qIndex))
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_3, [
                                        _createVNode(_component_v_icon, {
                                          size: "x-large",
                                          onClick: _withModifiers(($event: any) => (
                      _ctx.showIconPicker(_ctx.FunnelSection.MainQuestion, qIndex)
                    ), ["stop"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(question.icon), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]),
                                        _createVNode(_component_v_btn, {
                                          icon: "",
                                          variant: "text",
                                          onClick: _withModifiers(($event: any) => (_ctx.removeMainQuestion(qIndex)), ["stop"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode("fa-solid fa-trash-can")
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _createVNode(_component_v_text_field, {
                                        variant: "plain",
                                        density: "compact",
                                        modelValue: question.text,
                                        "onUpdate:modelValue": ($event: any) => ((question.text) = $event),
                                        onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        color: _ctx.funnelData.design.secondary,
                                        modelValue: question.exitsFunnel,
                                        "onUpdate:modelValue": ($event: any) => ((question.exitsFunnel) = $event),
                                        onClick: _cache[10] || (_cache[10] = _withModifiers(() => {}, ["stop"])),
                                        label: "Knock-Out"
                                      }, null, 8, ["color", "modelValue", "onUpdate:modelValue"])
                                    ]),
                                    _: 2
                                  }, 1032, ["color", "rounded", "onClick"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  color: _ctx.funnelData.design.primary,
                                  rounded: _ctx.funnelData.design.rounding,
                                  class: "ma-3 pa-3",
                                  border: "dashed",
                                  variant: "outlined",
                                  onClick: _ctx.addMainQuestion
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode("fa-solid fa-plus")
                                          ]),
                                          _: 1
                                        }),
                                        _createTextVNode(" Hauptfrage ")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["color", "rounded", "onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["color", "rounded"]))
                  : _createCommentVNode("", true),
                (_ctx.selectedMainQuestionIndex !== null)
                  ? (_openBlock(), _createBlock(_component_v_card, {
                      key: 1,
                      color: _ctx.funnelData.design.bgQuestionForm,
                      rounded: _ctx.funnelData.design.rounding,
                      class: "pa-4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          variant: "text",
                          icon: "",
                          onClick: _ctx.showMainQuestions
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, null, {
                              default: _withCtx(() => [
                                _createTextVNode("fa-solid fa-arrow-left")
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  width: 500,
                                  variant: "plain",
                                  label: "Überschrift Subfrage",
                                  modelValue: _ctx.funnelData.questions[_ctx.selectedMainQuestionIndex].header,
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.funnelData.questions[_ctx.selectedMainQuestionIndex].header) = $event)),
                                  onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"]))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.funnelData.questions[
                _ctx.selectedMainQuestionIndex
              ].subQuestions, (subQuestion, sIndex) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                cols: "12",
                                sm: "6",
                                key: sIndex
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    class: "pa-2 mb-2",
                                    color: _ctx.funnelData.design.primary,
                                    rounded: _ctx.funnelData.design.rounding
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_4, [
                                        _createVNode(_component_v_icon, {
                                          size: "x-large",
                                          onClick: _withModifiers(($event: any) => (
                      _ctx.showIconPicker(
                        _ctx.FunnelSection.SubQuestion,
                        _ctx.selectedMainQuestionIndex,
                        sIndex
                      )
                    ), ["stop"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(subQuestion.icon), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]),
                                        _createVNode(_component_v_btn, {
                                          icon: "",
                                          variant: "text",
                                          onClick: ($event: any) => (
                      _ctx.removeSubQuestion(_ctx.selectedMainQuestionIndex, sIndex)
                    )
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_v_icon, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode("fa-solid fa-trash-can")
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]),
                                      _createVNode(_component_v_text_field, {
                                        modelValue: subQuestion.text,
                                        "onUpdate:modelValue": ($event: any) => ((subQuestion.text) = $event),
                                        variant: "plain"
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                      _createVNode(_component_v_switch, {
                                        color: _ctx.funnelData.design.secondary,
                                        modelValue: subQuestion.exitsFunnel,
                                        "onUpdate:modelValue": ($event: any) => ((subQuestion.exitsFunnel) = $event),
                                        onClick: _cache[13] || (_cache[13] = _withModifiers(() => {}, ["stop"])),
                                        label: "Knock-Out"
                                      }, null, 8, ["color", "modelValue", "onUpdate:modelValue"]),
                                      (!subQuestion.exitsFunnel)
                                        ? (_openBlock(), _createBlock(_component_v_sheet, {
                                            key: 0,
                                            class: "pa-2"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_text_field, {
                                                variant: "plain",
                                                modelValue: subQuestion.skillsHeader,
                                                "onUpdate:modelValue": ($event: any) => ((subQuestion.skillsHeader) = $event)
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                              _createVNode(_component_v_text_field, {
                                                modelValue: _ctx.newSkill,
                                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newSkill) = $event)),
                                                rounded: "pill",
                                                variant: "outlined",
                                                label: "Neuen Skill hinzufügen",
                                                density: "compact",
                                                class: "mt-2",
                                                onKeyup: _withKeys(($event: any) => (_ctx.addSkill(_ctx.selectedMainQuestionIndex, sIndex)), ["enter"])
                                              }, null, 8, ["modelValue", "onKeyup"]),
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subQuestion.skills, (skill, skillIndex) => {
                                                return (_openBlock(), _createBlock(_component_v_chip, {
                                                  key: skillIndex,
                                                  class: "mr-2 my-1",
                                                  closable: "",
                                                  "onClick:close": ($event: any) => (
                      _ctx.removeSkill(_ctx.selectedMainQuestionIndex, sIndex, skillIndex)
                    )
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(skill), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick:close"]))
                                              }), 128))
                                            ]),
                                            _: 2
                                          }, 1024))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1032, ["color", "rounded"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              sm: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card, {
                                  color: _ctx.funnelData.design.primary,
                                  rounded: _ctx.funnelData.design.rounding,
                                  class: "ma-3 pa-3",
                                  border: "dashed",
                                  variant: "outlined",
                                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.addSubQuestion(_ctx.selectedMainQuestionIndex)))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card_title, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode("fa-solid fa-plus")
                                          ]),
                                          _: 1
                                        }),
                                        _createTextVNode(" Subfrage ")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["color", "rounded"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["color", "rounded"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["color", "rounded", "border"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Letzte Seite")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      label: "Titel 'Vielen Dank' Seite",
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.funnelData.thankYouPage.title,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.funnelData.thankYouPage.title) = $event)),
                      density: "compact",
                      class: "mt-3"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      label: "Untertitel",
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.funnelData.thankYouPage.subtitle,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.funnelData.thankYouPage.subtitle) = $event)),
                      density: "compact",
                      class: "mb-3"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.funnelData.thankYouPage.steps, (step, stepIndex) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            key: `thankYou-step-${stepIndex}`,
                            cols: "12",
                            md: "6"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, [
                                _createVNode(_component_v_icon, {
                                  size: "x-large",
                                  color: _ctx.funnelData.design.primary,
                                  class: "mr-4",
                                  onClick: ($event: any) => (_ctx.showIconPicker(_ctx.FunnelSection.ThankYouPage, stepIndex))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(step.icon), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color", "onClick"]),
                                _createVNode(_component_v_text_field, {
                                  modelValue: step.text,
                                  "onUpdate:modelValue": ($event: any) => ((step.text) = $event),
                                  label: "Step Text",
                                  variant: "plain",
                                  density: "compact"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_divider, { class: "mb-6" }),
                    _createVNode(_component_v_text_field, {
                      label: "Titel 'Leider Nein'-Seite",
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      density: "compact",
                      modelValue: _ctx.funnelData.noWayPage.title,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.funnelData.noWayPage.title) = $event))
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      label: "Untertitel",
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      density: "compact",
                      modelValue: _ctx.funnelData.noWayPage.subtitle,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.funnelData.noWayPage.subtitle) = $event)),
                      class: "mb-3"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.funnelData.noWayPage.steps, (step, stepIndex) => {
                          return (_openBlock(), _createBlock(_component_v_col, {
                            key: `noWay-step-${stepIndex}`,
                            cols: "12",
                            md: "6"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, [
                                _createVNode(_component_v_icon, {
                                  size: "x-large",
                                  color: _ctx.funnelData.design.primary,
                                  class: "mr-2",
                                  onClick: ($event: any) => (_ctx.showIconPicker(_ctx.FunnelSection.NoWayPage, stepIndex))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(step.icon), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color", "onClick"]),
                                _createVNode(_component_v_text_field, {
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: step.text,
                                  "onUpdate:modelValue": ($event: any) => ((step.text) = $event),
                                  label: "Step Text",
                                  variant: "plain",
                                  density: "compact"
                                }, null, 8, ["rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded", "border"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Funnel Design")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, {
                              label: "Abrundungen",
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.funnelData.design.rounding,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.funnelData.design.rounding) = $event)),
                              items: _ctx.vRounding,
                              "item-title": "value",
                              "item-value": "label"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_file_input, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              label: "upload Header",
                              onChange: _ctx.uploadFunnelHeader,
                              accept: "image/*"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "onChange"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "4"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_file_input, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              label: "Upload Effekt / Logo",
                              onChange: _ctx.uploadEffectImage,
                              accept: "image/*"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "onChange"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card, { variant: "flat" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_card_subtitle, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Ansicht Effektbild / Logo")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_text, null, {
                                  default: _withCtx(() => [
                                    (_ctx.funnelData.design.effectImage)
                                      ? (_openBlock(), _createBlock(_component_v_img, {
                                          key: 0,
                                          width: "80%",
                                          "aspect-ratio": "1/1",
                                          cover: "",
                                          src: _ctx.funnelData.design.effectImage
                                        }, null, 8, ["src"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Primärfarbe")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_color_picker, {
                              modelValue: _ctx.funnelData.design.primary,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.funnelData.design.primary) = $event)),
                              mode: "hexa"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Sekundärfarbe")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_color_picker, {
                              modelValue: _ctx.funnelData.design.secondary,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.funnelData.design.secondary) = $event)),
                              mode: "hexa"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Hintergrundfarbe")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_color_picker, {
                              modelValue: _ctx.funnelData.design.background,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.funnelData.design.background) = $event)),
                              mode: "hexa"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Hintergrund Fragen")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_color_picker, {
                              modelValue: _ctx.funnelData.design.bgQuestionForm,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.funnelData.design.bgQuestionForm) = $event)),
                              mode: "hexa"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "3"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Farbe bei Fehler")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_color_picker, {
                              modelValue: _ctx.funnelData.design.errorColor,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.funnelData.design.errorColor) = $event)),
                              mode: "hexa"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded", "border"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Unternehmenseinstellungen")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Unternehmensname",
                      modelValue: _ctx.funnelData.companyConfig.name,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.funnelData.companyConfig.name) = $event)),
                      density: "compact",
                      class: "mt-2"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Webseite",
                      modelValue: _ctx.funnelData.companyConfig.domain,
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.funnelData.companyConfig.domain) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Link zur Stellenbörse",
                      modelValue: _ctx.funnelData.companyConfig.companyJobBoard,
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.funnelData.companyConfig.companyJobBoard) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Copyright Text",
                      modelValue: _ctx.funnelData.companyConfig.copyright,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.funnelData.companyConfig.copyright) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "DSGVO Link",
                      modelValue: _ctx.funnelData.companyConfig.GDPRlink,
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.funnelData.companyConfig.GDPRlink) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded", "border"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode("ATS Recruit Konfiguration")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card_text, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "API URL",
                      modelValue: _ctx.funnelData.atsRecruit.apiUrl,
                      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.funnelData.atsRecruit.apiUrl) = $event)),
                      class: "mt-2",
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "API Key",
                      modelValue: _ctx.funnelData.atsRecruit.apiKey,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.funnelData.atsRecruit.apiKey) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Bewerbungsquelle",
                      modelValue: _ctx.funnelData.atsRecruit.sourceName,
                      "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.funnelData.atsRecruit.sourceName) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Bewerbungsquelle Uuid",
                      modelValue: _ctx.funnelData.atsRecruit.sourceUuid,
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.funnelData.atsRecruit.sourceUuid) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Default Mandant Name",
                      modelValue: _ctx.funnelData.atsRecruit.defaultMandant.name,
                      "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.funnelData.atsRecruit.defaultMandant.name) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      label: "Default Mandant Uuid",
                      modelValue: _ctx.funnelData.atsRecruit.defaultMandant.uuid,
                      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.funnelData.atsRecruit.defaultMandant.uuid) = $event)),
                      density: "compact"
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded", "border"])
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.funnelData.atsRecruit.mandants, (mandant, mandantIndex) => {
          return (_openBlock(), _createBlock(_component_v_col, {
            cols: "12",
            md: "4",
            key: `mandant-${mandantIndex}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                variant: _ctx.vStyle.card.variant || undefined,
                rounded: _ctx.vStyle.card.rounded || undefined,
                border: _ctx.vStyle.card.border || undefined,
                class: "pa-4 mb-3"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h3", null, _toDisplayString(mandant.name), 1),
                    _createVNode(_component_v_btn, {
                      icon: "",
                      variant: "text",
                      onClick: ($event: any) => (_ctx.removeMandant(mandantIndex))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("fa-solid fa-trash-can")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    label: "Mandant Name",
                    modelValue: mandant.name,
                    "onUpdate:modelValue": ($event: any) => ((mandant.name) = $event),
                    density: "compact",
                    class: "mt-2"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    rounded: _ctx.vStyle.input.rounded || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    label: "Mandant Uuid",
                    modelValue: mandant.uuid,
                    "onUpdate:modelValue": ($event: any) => ((mandant.uuid) = $event),
                    density: "compact"
                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_component_v_divider),
                  _createVNode(_component_v_text_field, {
                    variant: _ctx.vStyle.input.variant || undefined,
                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                    color: _ctx.vStyle.input.color || undefined,
                    rounded: "pill",
                    density: "compact",
                    modelValue: _ctx.newPlz[mandantIndex],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.newPlz[mandantIndex]) = $event),
                    label: "Neue PLZ hinzufügen",
                    onKeyup: _withKeys(($event: any) => (_ctx.addPlz(mandantIndex)), ["enter"]),
                    class: "mt-2"
                  }, null, 8, ["variant", "base-color", "color", "modelValue", "onUpdate:modelValue", "onKeyup"]),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mandant.plzs, (plz, plzIndex) => {
                      return (_openBlock(), _createBlock(_component_v_chip, {
                        key: `plz-${plzIndex}`,
                        class: "mr-2 mb-2",
                        closable: "",
                        "onClick:close": ($event: any) => (_ctx.removePlz(mandantIndex, plz))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(plz), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick:close"]))
                    }), 128))
                  ])
                ]),
                _: 2
              }, 1032, ["variant", "rounded", "border"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: "dashed",
              onClick: _ctx.addMandant,
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode("fa-solid fa-plus")
                      ]),
                      _: 1
                    }),
                    _createTextVNode(" Mandant ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["variant", "rounded", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_IconPicker, {
      ref: "iconPicker",
      onIconSelected: _ctx.setIcon
    }, null, 8, ["onIconSelected"])
  ], 64))
}