//src/services/api/linking.service.ts
import {
  LinkingDescription,
  LinkingEvent,
  Linking,
  LinkingCore,
} from "@/models/linking.model";
import { CustomerService } from "./customer.service";
import { CandidateService } from "./candidate.service";
import store from "@/store/store";
import { Api } from "./api.class";
import { ApiResponse } from "@/models/api-response.model";

export class LinkingService {
  private api = Api.getInstance();
  private urlPath = "/linking";
  private candidateService = new CandidateService();

  async addLinking(linking: Linking) {
    const response = await this.api.getBackendApi().post(this.urlPath, linking);
    store.commit("ADD_LINKINGS", response.data.response.linkings);
    return response.data.response.linkings;
  }

  async updateLinking(linking: Linking) {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/${linking._id}`, linking);
    store.commit("ADD_LINKINGS", response.data.linkings);
    return response.data;
  }

  async updateLinkingEvents(linkingId: string, linkingEvents: LinkingEvent[]) {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/event/${linkingId}`, linkingEvents);
    store.commit("ADD_LINKINGS", response.data.linkings);
    return response.data;
  }

  async deleteLinking(id: string) {
    const response = await this.api
      .getBackendApi()
      .delete(`${this.urlPath}/${id}`);
    store.commit("DELETE_LINKING", id);
    return response.data;
  }

  async deleteLinkingsByTypeAndId(type: string, id: string) {
    try {
      const response = await this.api
        .getBackendApi()
        .delete(`${this.urlPath}/by-type-and-id`, {
          params: {
            type: type,
            id: id,
          },
        });
      return response.data;
    } catch (error) {
      console.error(
        `Error deleting linkings for type ${type} and id ${id}:`,
        error
      );
      throw error;
    }
  }

  async getAllLinkings(mandants?: string[]): Promise<Linking[]> {
    let url = `${this.urlPath}`;

    if (mandants && mandants.length > 0) {
      const mandantParams = mandants
        .map((uuid) => `mandants[]=${encodeURIComponent(uuid)}`)
        .join("&");
      url = `${this.urlPath}?${mandantParams}`;
    }

    const response = await this.api.getBackendApi().get<ApiResponse>(url);

    return response.data.response;
  }

  async getAllLinkingsByCustomerId(customerId: string): Promise<Linking[]> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/customer/${customerId}`);
    return response.data;
  }

  async getAllLinkingsByCandidateId(candidateId: string): Promise<Linking[]> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/candidate/${candidateId}`);
    return response.data;
  }

  async getLinkingById(_id: string): Promise<Linking> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/${_id}`);
    return response.data;
  }

  async getLinkingNewerThanAppointmentDate(
    appointmentDate: string
  ): Promise<Linking> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/appointment/${appointmentDate}`);
    return response.data;
  }

  async checkDependencyCandidateCustomer(
    candidateId: string,
    customerId: string
  ): Promise<LinkingEvent> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/check`, {
        params: {
          firstType: "candidate",
          firstId: candidateId,
          secondType: "customer",
          secondId: customerId,
        },
      });
    return response.data.response;
  }

  async checkDependencyCustomerMandant(
    customerId: string,
    mandantUuid: string
  ): Promise<LinkingEvent> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/check`, {
        params: {
          firstType: "customer",
          firstId: customerId,
          secondType: "mandant",
          secondId: mandantUuid,
        },
      });
    return response.data;
  }

  async checkDependencyCandidateMandant(
    candidateId: string,
    mandantUuid: string
  ): Promise<LinkingEvent> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/check`, {
        params: {
          firstType: "candidate",
          firstId: candidateId,
          secondType: "mandant",
          secondId: mandantUuid,
        },
      });
    return response.data;
  }

  async checkDependencyCustomerDemand(
    customerId: string,
    demandId: string
  ): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/check`, {
        params: {
          firstType: "customer",
          firstId: customerId,
          secondType: "demand",
          secondId: demandId,
        },
      });
    return response.data;
  }

  async getDependencyBySearchId(search_id: string): Promise<Linking[]> {
    const response = await this.api
      .getBackendApi()
      .get(`${this.urlPath}/search/${search_id}`);
    if (response && response.data && response.data.response)
      return response.data.response;
    return [];
  }

  async candidateToCustomer(
    candidateId: string,
    customerId: string,
    mandants: string[],
    eventType: string,
    eventDate: string[],
    description?: LinkingDescription
  ) {
    const dependencyToCheck: Linking = {
      mandants: mandants,
      linkingCore: {
        candidate: candidateId,
        customer: customerId,
      },
      events: [
        {
          eventType: eventType,
          eventDate: eventDate,
        },
      ],
      ...(description && { description: description }),
    };

    const existingLinkingId = await this.checkDependencyIfExists(
      dependencyToCheck.linkingCore
    );

    if (!existingLinkingId) {
      return this.addLinking(dependencyToCheck);
    } else {
      return this.updateLinkingEvents(
        existingLinkingId,
        dependencyToCheck.events
      );
    }
  }

  async candidateToMandant(
    candidateId: string,
    mandantUuid: string,
    mandants: string[],
    eventType: string,
    eventDate: string[],
    description?: LinkingDescription
  ) {
    const dependencyToCheck: Linking = {
      mandants: mandants,
      linkingCore: {
        candidate: candidateId,
        mandant: mandantUuid,
      },
      events: [
        {
          eventType: eventType,
          eventDate: eventDate,
        },
      ],
      ...(description && { description: description }),
    };
    const existingLinkingId = await this.checkDependencyIfExists(
      dependencyToCheck.linkingCore
    );

    if (!existingLinkingId) {
      return this.addLinking(dependencyToCheck);
    } else {
      return this.updateLinkingEvents(
        existingLinkingId,
        dependencyToCheck.events
      );
    }
  }

  async customerToMandant(
    customerId: string,
    mandantUuid: string,
    mandants: string[],
    eventType: string,
    eventDate: string[]
  ) {
    const dependencyToCheck: Linking = {
      mandants: mandants,
      linkingCore: {
        customer: customerId,
        mandant: mandantUuid,
      },
      events: [
        {
          eventType: eventType,
          eventDate: eventDate,
        },
      ],
    };
    const existingLinkingId = await this.checkDependencyIfExists(
      dependencyToCheck.linkingCore
    );

    if (!existingLinkingId) {
      return this.addLinking(dependencyToCheck);
    } else {
      return this.updateLinkingEvents(
        existingLinkingId,
        dependencyToCheck.events
      );
    }
  }

  async demandToCustomer(linkingData: Linking) {
    const existingLinkingId = await this.checkDependencyIfExists(
      linkingData.linkingCore
    );

    if (!existingLinkingId) {
      return this.addLinking(linkingData);
    } else {
      return this.deleteLinking(existingLinkingId);
    }
  }

  async checkDependencyIfExists(
    linkingsToCheck: LinkingCore
  ): Promise<string | null> {
    const result: any = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/exists`, linkingsToCheck);
    if (!result.data.error) {
      return Promise.resolve(result.data.response);
    } else {
      return Promise.resolve(null);
    }
  }

  async getAllDependencies(itemId: string): Promise<Linking[]> {
    const matchingLinkings = (await this.getDependencyBySearchId(
      itemId
    )) as Linking[];

    for (const linking of matchingLinkings) {
      if (!linking.description) {
        linking.description = {} as LinkingDescription;
      }

      if (linking.linkingCore.candidate) {
        try {
          const candidateDetails = await this.candidateService.getCandidateById(
            linking.linkingCore.candidate
          );
          if (candidateDetails) {
            Object.assign(linking.description, {
              firstName: candidateDetails.firstName,
              lastName: candidateDetails.lastName,
              postCode: candidateDetails.addressPostalCode,
              city: candidateDetails.addressCity,
              uuid: candidateDetails.uuid,
            });
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Kandidatendetails: ", error);
        }
      }

      if (linking.linkingCore.customer) {
        try {
          const customerService = new CustomerService();
          const customerDetails = await customerService.getById(
            linking.linkingCore.customer
          );
          if (customerDetails && customerDetails.generalData) {
            Object.assign(linking.description, {
              customerName: customerDetails.generalData.name,
              customerPostCode:
                customerDetails.addressAndCommunication.postalCode,
              customerCity: customerDetails.addressAndCommunication.city,
            });
          } else {
            console.error(
              "customerDetails oder customerDetails.generalData ist undefined"
            );
          }
        } catch (error) {
          console.error("Fehler beim Abrufen der Kundendetails: ", error);
        }
      }
    }

    return matchingLinkings;
  }
}
