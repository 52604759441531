<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-link</v-icon>Konfiguration
      für PD-Hub
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="9"></v-col>
        <v-col cols="12" md="3">
          <v-switch
            color="primary"
            v-model="localConfig.softwareIntegration.erpAutoDocu"
            label="automatische Dokumentation"
          ></v-switch>
        </v-col>
        <v-col v-if="isUberAdmin" cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="pdHubConfig.clientId"
            label="Client Id"
          ></v-text-field>
        </v-col>
        <v-col v-if="isUberAdmin" cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="pdHubConfig.clientSecret"
            label="Client Secret"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-form>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="pdHubConfig.username"
              label="Username"
            ></v-text-field>
            <v-text-field
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="pdHubConfig.password"
              label="Passwort"
              type="password"
            ></v-text-field>
            <v-btn
              v-if="isAdmin"
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              prepend-icon="fa-solid fa-arrows-rotate"
              @click="askBackendForNewToken()"
              >Manueller Token Refresh</v-btn
            >
          </v-form>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="pdHubConfig.customerId"
            label="CustomerId"
          ></v-text-field>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localConfig.apiKeys.pdHubL1Mandant"
            label="default L1 Mandant"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CompanyConfig } from "@/models/company-config.model";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserRoleHelper } from "@/helper/user-role.helper";
import { PdHubServiceApi } from "../../../services/api/pd-hub-be.service";
import { PdHubConfig } from "../../../models/pdhub.model";
import { SpinnerService } from "../../../services/spinner.service";
import ToastService from "../../../services/toast.service";
import moment from "moment";

export default defineComponent({
  name: "ConfigPdHub",
  emits: ["update:modelValue", "save"],
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      pdHubConfig: {} as PdHubConfig,
      pdHubServiceApi: new PdHubServiceApi(),
      user: {} as User | any,
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    isUberAdmin(): boolean {
      return UserRoleHelper.isUberAdmin(this.user);
    },
    isAdmin(): boolean {
      return UserRoleHelper.isAdmin(this.user);
    },
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  created() {
    this.getUser();
    this.getPdHubConfig();
  },
  methods: {
    async askBackendForNewToken() {
      if (!this.isAdmin) return;
      try {
        SpinnerService.showSpinner();
        const response = await this.pdHubServiceApi.getNewToken();
        const formattedDate = moment(response).format("DD.MM.YYYY HH:mm:ss");

        ToastService.showSuccess(`Neuer Token gültig bis: ${formattedDate}`);
      } catch (error) {
        console.error(error);
        ToastService.showError("Fehler beim Generieren eines neuen Tokens.");
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async getPdHubConfig() {
      try {
        this.pdHubConfig = await this.pdHubServiceApi.get();
      } catch (error) {
        console.error(error);
      }
    },
    async getUser() {
      this.user = this.$store.getters.user;
    },
    handleSubmit() {
      this.pdHubServiceApi.update(this.pdHubConfig);
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
}
</style>
