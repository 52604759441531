import { ApiBase } from "./api-base.class";

export class Api extends ApiBase {
  private static instance: Api;

  public static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api(true);
    }
    return Api.instance;
  }
}
