<template>
  <v-dialog
    v-model="showDialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
    class="xs12 md6"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          editMode ? "Firmenkunden bearbeiten" : "Neuen Firmenkunden anlegen"
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="saveCompany">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.tradeRegisterEntry"
                :disabled="editMode"
                label="Handelsregister"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.name"
                :rule="rule20"
                label="Firmenname"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.address.street"
                :rules="rule20"
                label="Strasse"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.address.number"
                :rules="rule15"
                label="Hausnummer"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.address.postalCode"
                label="PLZ"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.address.city"
                :rules="rule20"
                label="Ort"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="newCompany.address.country"
                label="Land"
                required
              ></v-text-field>
            </v-col>
            <v-row v-if="!editMode" class="ml-0 mr-0">
              <v-col cols="12">Administrator</v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="newUser.email"
                  label="Email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="newUser.username"
                  label="Benutzername"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="newUser.password"
                  label="Passwort"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-row>
          <v-card-actions>
            <div v-if="saveError">{{ saveError }}</div>
            <v-btn @click="closeDialog" color="abort">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit" color="success">Speichern</v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Company } from "../../../models/company.model";
import { useDisplay } from "vuetify";
import { CompanyService } from "@/services/api/company.service";

export default defineComponent({
  name: "CompanyDialog",
  props: {
    show: Boolean,
    editMode: Boolean,
    editCompanyModel: Object as () => Company | null,
    allCompanys: Object as PropType<Company[]>,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      emailRules: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) =>
          /.+@.+\..+/.test(v) || "Geben Sie eine gültige E-Mail-Adresse ein",
      ],
      rule20: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) => (v && v.length <= 20) || "Maximal 20 Zeichen",
      ],
      rule15: [
        (v: any) => !!v || "Dieses Feld ist erforderlich",
        (v: any) => (v && v.length <= 15) || "Maximal 15 Zeichen",
      ],
      companyService: CompanyService.getInstance(),
      form: null as any,
      newCompany: {
        name: "",
        tradeRegisterEntry: "",
        address: {
          street: "",
          number: "",
          postalCode: "",
          city: "",
          country: "",
        },
        users: [],
      } as Company,
      newUser: {
        email: "",
        password: "",
        username: "",
      },
      saveError: "",
      showDialog: false,
      smAndDown,
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    show(newVal) {
      this.showDialog = newVal;
      if (this.form) this.form.reset();
    },
    editCompanyModel(newVal) {
      if (newVal && this.editMode) {
        this.newCompany = { ...newVal };
      }
      if (newVal && !this.editMode) {
        this.newCompany = {
          name: "",
          tradeRegisterEntry: "",
          address: {
            street: "",
            number: "",
            postalCode: "",
            city: "",
            country: "",
          },
          users: [],
        } as Company;
      }
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit("update:show", false); // Emit event to update the prop
    },
    async saveCompany() {
      this.saveError = "";
      if (this.$refs.form && (this.$refs.form as any).validate()) {
        try {
          if (!this.editMode)
            await this.companyService.addCompany(this.newCompany, this.newUser);
          else await this.companyService.editCompany(this.newCompany);

          this.$emit("update:show", false);
          (this.$refs.form as any).reset();
        } catch (error: any) {
          switch (error.response.status) {
            case 401:
              this.saveError =
                "Authentifizierungsfehler. Bitte loggen Sie sich neu ein.";
              break;
            case 409:
              this.saveError =
                "Firma existiert bereits. Bitte geben Sie eine andere Handeslregisternummer ein.";
              break;
            default:
              this.saveError = "Server Fehler";
          }
        }
      }
    },
  },
});
</script>

<style scoped>
.minus-icon {
  margin-top: -1rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
