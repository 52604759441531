// src/helpers/user-role.helper.ts
import { UserRole } from "@/enums/user-role.enum";

export class UserRoleHelper {
  static isUberAdmin(user: { role: UserRole }): boolean {
    return user.role <= UserRole.UberAdmin;
  }

  static isCompanyAdmin(user: { role: UserRole }): boolean {
    return user.role <= UserRole.CompanyAdmin;
  }

  static isAdmin(user: { role: UserRole }): boolean {
    return user.role <= UserRole.Admin;
  }

  static isPayrollOfficer(user: { role: UserRole }): boolean {
    return user.role <= UserRole.PayrollOfficer;
  }

  static isAccountant(user: { role: UserRole }): boolean {
    return user.role <= UserRole.Accountant;
  }

  static isBranchManager(user: { role: UserRole }): boolean {
    return user.role <= UserRole.BranchManager;
  }

  static isDispatcher(user: { role: UserRole }): boolean {
    return user.role <= UserRole.Dispatcher;
  }
}
