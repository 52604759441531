<!--src/components/disposition/elements/JobAdLeadsCandidate.vue-->
<template>
  <transition name="slide-right" v-if="isVisible">
    <v-container
      class="job-ad-list"
      :style="{ top: 0, right: 0 + 'px', width: listWidth + 'px' }"
    >
      <i
        class="fa-solid fa-circle-xmark close-icon"
        @click="toggleVisibility"
      ></i>
      <div class="resize-handle" @mousedown="startResize">||</div>
      <v-row justify="space-between" class="pa-2">
        <v-col class="d-flex">
          <h1><i class="fa-solid fa-users-viewfinder"></i> JOB Leads</h1>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-col>
        <v-col class="d-flex" cols="auto">
          <v-btn
            size="small"
            icon
            variant="text"
            @click="$emit('generateAiSearchterm')"
          >
            <v-tooltip activator="parent" location="bottom"
              >AI Suche starten</v-tooltip
            >
            <v-icon>fa-solid fa-rocket job-ad-icon</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Job Leads mit AI suchen</v-tooltip
            >
          </v-btn>
          <v-btn size="small" icon variant="text" @click="initComponent()">
            <v-tooltip activator="parent" location="bottom"
              >Neu laden und angelegte Kunden neu prüfen!</v-tooltip
            >
            <v-icon>fa-solid fa-arrows-rotate job-ad-icon</v-icon>
          </v-btn>
          <v-btn
            size="small"
            icon
            variant="text"
            @click="$emit('manualAnzeigendatenSearch')"
          >
            <v-tooltip activator="parent" location="bottom"
              >manuelle Suche</v-tooltip
            >
            <v-icon>fa-solid fa-magnifying-glass job-ad-icon</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="auto">
          <v-spacer></v-spacer>
          <v-btn
            icon
            variant="text"
            @click="filterCustomers(customerNumbersForFilter)"
          >
            <v-tooltip activator="parent" location="bottom"
              >nur diese Firmen anzeigen</v-tooltip
            >
            <v-icon>fa-solid fa-eye filter-customers-icon</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="space-between" class="mt-2">
        <v-col class="d-flex">
          <v-icon
            class="mx-3"
            icon="fa-solid fa-person-arrow-down-to-line"
          ></v-icon>
          <v-range-slider
            class="mx-2"
            :max="12"
            :min="0"
            :step="1"
            v-model="careerLevel"
            @update:model-value="updateCareerLevelIndexNumbers()"
          ></v-range-slider>
          <v-icon class="mx-1" icon="fa-solid fa-user-tie"></v-icon>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item v-for="jobAd in uniqueAndLatestJobAds" :key="jobAd.adId">
          <div class="job-ad">
            <v-list-item-title>
              <div class="d-flex">
                <a class="job-ad-title" :href="jobAd.link" target="_blank">{{
                  jobAd.jobTitle
                }}</a>
                <v-spacer></v-spacer>
              </div>
              <div class="d-flex">
                {{ jobAd.company?.name }} (
                <div v-if="customerMatches.has(jobAd.adId)">
                  {{ customerMatches.get(jobAd.adId).number }}
                  <span v-if="customerMatches.get(jobAd.adId).active">
                    <i
                      class="fa-solid fa-eye add-customer-icon"
                      @click="
                        filterCustomers([
                          customerMatches.get(jobAd.adId).number,
                        ])
                      "
                    >
                      <v-tooltip activator="parent" location="bottom"
                        >in der Spalte "Unternehmen" anzeigen</v-tooltip
                      >
                    </i></span
                  >
                  <span v-else>
                    <i
                      class="fa-solid fa-cloud-arrow-down add-customer-icon"
                      @click="
                        addCustomerFromZvoove(
                          customerMatches.get(jobAd.adId).number
                        )
                      "
                    >
                      <v-tooltip activator="parent" location="bottom"
                        >Unternehmen aus ERP übernehmen!</v-tooltip
                      >
                    </i>
                  </span>
                </div>
                <div v-else>
                  <div
                    class="add-customer-icon"
                    @click="
                      addCustomerFromIndex(
                        jobAd.company?.name || '',
                        jobAd.company?.postalCode || ''
                      )
                    "
                  >
                    <i class="fa-solid fa-plus"></i
                    ><i class="fa-solid fa-building"></i>
                    <v-tooltip activator="parent" location="bottom"
                      >Unternehmen im System anlegen</v-tooltip
                    >
                  </div>
                </div>
                )
              </div>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ jobAd.date }}: {{ jobAd.location }}
              {{ jobAd.source }}
              <span v-html="jobAd.listPrice"> </span>
              <a
                v-if="company.softwareIntegration.indexAnzeigendaten"
                :href="jobAd.internLinkPdf"
                target="_blank"
              >
                <i class="fa-solid fa-file-pdf job-ad-icon"></i>
                <v-tooltip activator="parent" location="bottom"
                  >PDF Datei aus Anzeigendaten öffnen</v-tooltip
                >
              </a>
              <a
                v-if="company.softwareIntegration.indexAnzeigendaten"
                :href="jobAd.internLinkTxt"
                target="_blank"
              >
                <i class="fa-solid fa-file-lines job-ad-icon"></i>
                <v-tooltip activator="parent" location="bottom"
                  >Textdatei aus Anzeigendaten öffnen</v-tooltip
                >
              </a>
              <v-icon small>mdi-plus-box</v-icon>
              <v-icon small>mdi-advertisements</v-icon>
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
    </v-container>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import debounce from "lodash/debounce";
import { mapGetters, mapMutations } from "vuex";
import { Customer, CustomerList } from "@/models/customer.model";
import { CustomerService } from "@/services/api/customer.service";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import {
  careerLevel,
  IaCareerLevel as IaCareerLevel,
} from "@/enums/anzeigendaten.enum";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { JobAdLead } from "@/models/job-ad-lead.model";

export default defineComponent({
  name: "JobAdListCustomer",
  emits: [
    "generateAiSearchterm",
    "manualAnzeigendatenSearch",
    "updateCareerLevels",
  ],

  props: {
    indexCareerLevel: {
      type: Array as PropType<number[]>,
      default: () => [0, 12],
    },
    jobAds: {
      type: Array as PropType<JobAdLead[]>,
      required: true,
    },
    mandant: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeCustomers: [] as Customer[],
      careerLevel: [0, 12],
      closeTimeout: null as number | null,
      customerMatches: new Map(),
      customerNumbersForFilter: [] as string[],
      initialWidth: 0,
      initialX: 0,
      isResizing: false,
      isVisible: true,
      listWidth: 350,
    };
  },
  computed: {
    ...mapGetters(["company", "customerList"]),
    careerLevelIndexNumbers() {
      const labels: IaCareerLevel[] = [];
      for (let i = this.careerLevel[0]; i <= this.careerLevel[1]; i++) {
        const careerLevelValue = careerLevel[i as keyof typeof careerLevel];
        if (careerLevelValue !== undefined) {
          labels.push(careerLevelValue);
        }
      }
      return labels;
    },
    uniqueAndLatestJobAds() {
      const uniqueAds = [];
      const positions = new Set();
      for (const ad of this.jobAds) {
        if (!positions.has(ad.jobTitle)) {
          uniqueAds.push(ad);
          positions.add(ad.jobTitle);
        }
      }

      return uniqueAds;
    },
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
    }),
    async initComponent() {
      await this.loadActiveCustomers();
      this.findCustomerMatches();
      this.setSliderFromCareerLevel(this.indexCareerLevel);
    },
    setSliderFromCareerLevel(indexLevels: number[]) {
      if (!indexLevels.length) {
        this.careerLevel = [0, 12];
        return;
      }
      const careerLevelToIndex: Record<number, IaCareerLevel> = {};
      Object.entries(careerLevel).forEach(([index, enumValue]) => {
        careerLevelToIndex[enumValue] = parseInt(index);
      });

      const sliderValues = indexLevels.map((level) => {
        if (careerLevelToIndex[level] !== undefined) {
          return careerLevelToIndex[level];
        }
        throw new Error(
          `Karrierelevel ${level} ist im Mapping nicht definiert.`
        );
      });

      this.careerLevel = [Math.min(...sliderValues), Math.max(...sliderValues)];
    },
    filterCustomers(customerNumbers: string[]) {
      this.setICM({
        message: InterComponentMessage.filterCustomers,
        payload: { customerNumbers: customerNumbers },
      });
    },
    addCustomerFromZvoove(customerNumber: string) {
      this.setICM({
        message: InterComponentMessage.addCustomerFromZvoove,
        payload: { customerNumber: customerNumber },
      });
    },
    addCustomerFromIndex(name: string, postalCode: string) {
      this.setICM({
        message: InterComponentMessage.addCustomerFromIndex,
        payload: {
          name: name,
          postalCode: postalCode,
        },
      });
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    startResize(event: MouseEvent) {
      this.isResizing = true;
      this.initialX = event.clientX;
      this.initialWidth = this.listWidth;
      document.addEventListener("mousemove", this.performResize);
      document.addEventListener("mouseup", this.stopResize);
    },
    performResize(event: MouseEvent) {
      if (this.isResizing) {
        const deltaX = this.initialX - event.clientX;
        this.listWidth = this.initialWidth + deltaX;
      }
    },
    stopResize() {
      this.isResizing = false;
      document.removeEventListener("mousemove", this.performResize);
      document.removeEventListener("mouseup", this.stopResize);
    },
    async loadActiveCustomers() {
      try {
        const apiCustomerService = new CustomerService();
        this.activeCustomers = await apiCustomerService.getAll();
      } catch (error) {
        console.error("Error fetching active customers:", error);
      }
    },
    findCustomerMatches() {
      this.customerMatches.clear();
      this.uniqueAndLatestJobAds.forEach((ad: JobAdLead) => {
        const customerData = this.matchCustomer(
          ad.company?.name || "",
          ad.company?.postalCode || ""
        );
        if (customerData) {
          const isActive = this.activeCustomers.some(
            (customer) =>
              customer.customerNumber === customerData.customerNumber
          );
          this.customerMatches.set(ad.adId, {
            number: customerData.customerNumber,
            active: isActive,
          });
          this.customerNumbersForFilter.push(customerData.customerNumber);
        }
      });
    },
    matchCustomer(companyName: string, postalCode: string) {
      const cleanString = (str: string) => str.trim().toLowerCase();
      const cleanedCompanyName = cleanString(companyName);
      const cleanedPostalCode = cleanString(postalCode);

      let words = cleanedCompanyName
        .split(" ")
        .filter((word) => word.length > 0);
      let matches = this.customerList.filter((customer: CustomerList) =>
        cleanString(customer.name).includes(words[0])
      );

      let i = 1;
      while (matches.length > 1 && i < words.length) {
        matches = matches.filter((customer: CustomerList) =>
          cleanString(customer.name).includes(words[i])
        );
        i++;
      }

      matches = matches.filter((customer: CustomerList) => {
        const addressParts = customer.address.split(" ");
        const addressPostalCode = addressParts.find((part) =>
          /^\d{5}$/.test(part)
        );
        return addressPostalCode === cleanedPostalCode;
      });

      if (matches.length > 1) {
        matches = matches.filter(
          (customer: CustomerList) =>
            cleanString(customer.name) === cleanedCompanyName
        );
      }

      if (matches.length > 0) {
        return {
          customerNumber: matches[0].customerNumber,
          id: matches[0]._id,
        };
      }

      return null;
    },
    updateCareerLevelIndexNumbers: debounce(function (this: any) {
      const careerLevelIndexNumbers = this.careerLevelIndexNumbers;
      this.$emit("updateCareerLevels", careerLevelIndexNumbers);
    }, 3000),
  },
});
</script>

<style scoped>
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease;
}
.slide-right-enter, .slide-right-leave-to /* "leave-to" in <2.1.8 */ {
  transform: translateX(100%);
}
.job-ad-list {
  position: absolute;
  overflow: auto;
  background-color: var(--modal-background);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  padding-left: 3rem;
  padding-bottom: 1rem;
  border-radius: 4px;
  z-index: 500;
  height: 100%;
  resize: horizontal;
  overflow-x: hidden;
  overflow-y: auto;
}
.close-icon {
  position: absolute;
  font-size: 1.5rem;
  top: 0.3rem;
  left: 0.3rem;
  cursor: pointer;
  color: var(--color-font-primary);
  transition: all 0.3s ease;
}

.close-icon:hover {
  color: var(--color-tertiary);
}
.resize-handle {
  color: var(--color-font-light);
  background-color: var(--color-primary);
  border-top-right-radius: var(--border-radius-elements);
  border-bottom-right-radius: var(--border-radius-elements);
  padding: 0.2rem;
  font-size: 2.5rem;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 7rem;
  cursor: ew-resize;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: right;
  align-items: center;
}
.job-ad:hover {
  background-color: var(--table-hover);
}
.job-ad-title {
  color: var(--color-primary);
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
}

.job-ad-title:hover {
  color: var(--color-tertiary);
}
.add-customer-image {
  height: 1rem;
  width: 1rem;
}
.add-customer-icon {
  font-size: 1rem;
}
.filter-customers-icon {
  font-size: 2rem;
}
.add-customer-image,
.add-customer-icon,
.filter-customers-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}
.add-customer-icon:hover,
.filter-customers-icon:hover {
  color: var(--color-tertiary);
}
.add-customer-image:hover {
  scale: var(--small-scale-up);
}
.button-icon {
  height: 1rem;
  width: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.job-ad-icon {
  margin: 0.1rem;
  color: var(--color-font-primary);
  cursor: pointer;
}
.job-ad-icon:hover {
  color: var(--color-tertiary);
}
.context-menu-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
