// dialog.service.ts

class DialogService {
  private getCssVariableValue(varName: string): string {
    const style = getComputedStyle(document.documentElement);
    return style.getPropertyValue(varName).trim();
  }

  private getDialogStyles() {
    return {
      colorPrimary: this.getCssVariableValue("--color-primary"),
      colorSecondary: this.getCssVariableValue("--color-tertiary"),
      colorFontPrimary: this.getCssVariableValue("--color-font-primary"),
      colorFontLight: this.getCssVariableValue("--color-font-light"),
      colorHover: this.getCssVariableValue("--color-secondary"),
      colorModalBackground: this.getCssVariableValue("--modal-background"),
      borderRadiusButton: this.getCssVariableValue("--border-radius-elements"),
      borderRadiusDialog: this.getCssVariableValue("--border-radius-dialog"),
      boxShadow: this.getCssVariableValue("--box-shadow-dialog"),
    };
  }

  private createDialogElement(htmlContent: string, styles: any): HTMLElement {
    const dialog = document.createElement("div");
    dialog.innerHTML = htmlContent;
    dialog.style.position = "fixed";
    dialog.style.left = "50%";
    dialog.style.top = "50%";
    dialog.style.transform = "translate(-50%, -50%)";
    dialog.style.backgroundColor = styles.colorModalBackground;
    dialog.style.borderRadius = styles.borderRadiusDialog;
    dialog.style.boxShadow = styles.boxShadow;
    dialog.style.padding = "1rem";
    dialog.style.border = `2px solid ${styles.colorPrimary}`;
    dialog.style.zIndex = "9999";
    dialog.style.width = "auto";
    dialog.style.display = "flex";
    dialog.style.flexDirection = "column";
    dialog.style.alignItems = "center";
    document.body.appendChild(dialog);

    const buttonContainer = document.createElement("div");
    buttonContainer.style.display = "flex";
    buttonContainer.style.justifyContent = "space-around";
    buttonContainer.style.width = "100%";
    buttonContainer.style.marginTop = "1rem";

    const buttons = dialog.querySelectorAll("button");
    buttons.forEach((button) => {
      button.style.borderRadius = styles.borderRadiusButton;
      button.style.backgroundColor = styles.colorPrimary;
      button.style.color = styles.colorFontLight;
      button.style.border = "none";
      button.style.padding = "0.5rem 1rem";
      button.style.margin = "1rem";
      button.style.cursor = "pointer";
      button.style.flexGrow = "1";

      button.onmouseover = () => {
        button.style.backgroundColor = styles.colorHover;
        button.style.color = styles.colorFontPrimary;
      };
      button.onmouseleave = () => {
        button.style.backgroundColor = styles.colorPrimary;
        button.style.color = styles.colorFontLight;
      };
      buttonContainer.appendChild(button);
    });

    dialog.appendChild(buttonContainer);
    return dialog;
  }

  private closeDialog(dialog: HTMLElement) {
    document.body.removeChild(dialog);
  }

  alert(message: string, buttonText = "OK"): void {
    const styles = this.getDialogStyles();
    const htmlContent = `<div>${message}</div>
                         <button id="dialog-alert-validation" style="border-radius: ${styles.borderRadiusButton}; 
                                        background-color: ${styles.colorPrimary}; 
                                        color: ${styles.colorFontLight};">
                           ${buttonText}
                         </button>`;
    const dialog = this.createDialogElement(htmlContent, styles);
    const button = dialog.querySelector("button");
    if (button) {
      button.addEventListener("click", () => this.closeDialog(dialog));
      button.onmouseover = () => {
        button.style.backgroundColor = styles.colorHover;
        button.style.color = styles.colorFontPrimary;
      };
      button.onmouseleave = () => {
        button.style.backgroundColor = styles.colorPrimary;
        button.style.color = styles.colorFontLight;
      };
    } else {
      console.error("Button element not found in the dialog.");
    }
  }

  confirm(
    question: string,
    btnAbortText = "Abbrechen",
    btnConfirmText = "Bestätigen"
  ): Promise<boolean> {
    const styles = this.getDialogStyles();
    const htmlContent = `<div>${question}</div>
                         <button>${btnAbortText}</button>
                         <button>${btnConfirmText}</button>`;
    const dialog = this.createDialogElement(htmlContent, styles);
    return new Promise((resolve) => {
      const buttons = dialog.querySelectorAll("button");
      if (buttons.length >= 2) {
        buttons.forEach((button, index) => {
          button.onmouseover = () => {
            button.style.backgroundColor = styles.colorHover;
            button.style.color = styles.colorFontPrimary;
          };
          button.onmouseleave = () => {
            button.style.backgroundColor = styles.colorPrimary;
            button.style.color = styles.colorFontLight;
          };
          button.addEventListener("click", () => {
            this.closeDialog(dialog);
            resolve(index === 1);
          });
        });
      } else {
        console.error("Not enough buttons found in the dialog.");
      }
    });
  }

  prompt(
    promptQuestion: string,
    inputLabelText = "Bitte Eingabe machen",
    btnAbortText = "Abbrechen",
    btnConfirmText = "Bestätigen"
  ): Promise<string> {
    const styles = this.getDialogStyles();
    const htmlContent = `<div>${promptQuestion}</div>
                         <input type="text" placeholder="${inputLabelText}" style="border-radius: ${styles.borderRadiusButton};"/>
                         <button>${btnAbortText}</button>
                         <button>${btnConfirmText}</button>`;
    const dialog = this.createDialogElement(htmlContent, styles);
    const input = dialog.querySelector("input");
    const buttons = dialog.querySelectorAll("button");
    return new Promise((resolve) => {
      if (input && buttons.length >= 2) {
        buttons.forEach((button) => {
          button.onmouseover = () => {
            button.style.backgroundColor = styles.colorHover;
            button.style.color = styles.colorFontPrimary;
          };
          button.onmouseleave = () => {
            button.style.backgroundColor = styles.colorPrimary;
            button.style.color = styles.colorFontLight;
          };
          button.addEventListener("click", () => {
            this.closeDialog(dialog);
            resolve(input ? input.value : "");
          });
        });
      } else {
        console.error("Input or buttons not found in the dialog.");
      }
    });
  }
}
const dialog = new DialogService();
export default dialog;
