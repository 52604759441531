<!--src/components/config/software-integration/ConfigEmail.vue-->
<template>
  <v-container v-if="getUserRole() < 6">
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-envelope</v-icon>Email
      Server
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <EmailServerConfigForm
        v-model="localConfig.mailServer"
      ></EmailServerConfigForm>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import EmailServerConfigForm from "@/components/forms/email-server-config.component.vue";
import { CompanyConfig } from "@/models/company-config.model";
import { UserHelperService } from "@/services/user-helper.service";

export default defineComponent({
  name: "ConfigEmail",
  components: {
    EmailServerConfigForm,
  },
  props: {
    modelValue: {
      type: Object as PropType<CompanyConfig>,
      required: true,
    },
  },
  data() {
    return {
      localConfig: { ...this.modelValue },
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue(newValue: CompanyConfig) {
      this.localConfig = { ...newValue };
    },
  },
  methods: {
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },

    handleSubmit() {
      this.$emit("update:modelValue", this.localConfig);
      this.$emit("save");
    },
  },
});
</script>
