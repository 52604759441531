import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_ZorstStatus = _resolveComponent("ZorstStatus")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_ctx.isLoggedIn)
    ? (_openBlock(), _createBlock(_component_v_sheet, {
        key: 0,
        class: "d-flex justify-space-between pa-1",
        color: "primary-darken-1"
      }, {
        default: _withCtx(() => [
          (_ctx.getUserRole() === 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.gimmeStore())),
                class: "gimme-store-button"
              }, " GIMME STORE DATA "))
            : _createCommentVNode("", true),
          _createVNode(_component_v_spacer),
          (_ctx.$store.state.company.softwareIntegration.zorst)
            ? (_openBlock(), _createBlock(_component_ZorstStatus, { key: 1 }))
            : _createCommentVNode("", true),
          _createTextVNode("   "),
          _createVNode(_component_v_btn, {
            icon: "",
            density: "compact",
            color: "primary-darken-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                color: "white",
                class: "menu-icon"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" fa-solid fa-bars ")
                ]),
                _: 1
              }),
              _createVNode(_component_v_menu, {
                activator: "parent",
                "offset-y": ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list, { "bg-color": "primary-darken-1" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item) => {
                        return (_openBlock(), _createBlock(_component_v_list_item, {
                          color: "error",
                          key: item.text,
                          class: "d-flex",
                          onClick: ($event: any) => (_ctx.toggleMenu(item.route))
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_v_icon, { size: "xs" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.icon), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.text), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}