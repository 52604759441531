import { PdHubCall, PdHubConfig } from "../../models/pdhub.model";
import { Api } from "./api.class";

export class PdHubServiceApi {
  private api = Api.getInstance();
  private urlPath = "/pdhub";

  async get() {
    return (await this.api.getBackendApi().get(`${this.urlPath}/config`)).data
      .response as PdHubConfig;
  }

  async update(pdHubConfig: PdHubConfig) {
    if (pdHubConfig) {
      const response = await this.api
        .getBackendApi()
        .put(`${this.urlPath}/config`, pdHubConfig);
      return response.data.response;
    }
    return undefined;
  }

  async call(pdHubCall: PdHubCall) {
    if (pdHubCall) {
      const response = await this.api
        .getBackendApi()
        .post(`${this.urlPath}`, pdHubCall);
      return response.data.Data;
    }
    return undefined;
  }

  // admin manual token refresh
  async getNewToken() {
    try {
      const response = await this.api
        .getBackendApi()
        .get(`${this.urlPath}/getNewToken`);

      if (response.data.response && response.data.response.tokenValidTill) {
        return response.data.response.tokenValidTill;
      } else {
        return { error: "Failed to retrieve token" };
      }
    } catch (error) {
      console.error("Error fetching new token:", error);

      if (error instanceof Error && "response" in error) {
        const axiosError = error as any;
        return {
          error:
            axiosError.response?.data?.error || "Failed to fetch new token",
        };
      }
      return { error: "Failed to fetch new token due to unknown error" };
    }
  }
}
