// src/models/job-ad.model.ts
export interface JobLocation {
  _id?: string;
  postalCode: number | null;
  city: string;
  mandants?: string[];
}

export interface JobCategory {
  _id?: string;
  generalCategoriesBfA: string[] | [];
  generalProfile: string;
  generalSalaryFrom: number | null;
  generalSalaryPeriod: string;
  generalSalaryTo: number | null;
  generalTasks: string;
  generalTitle: string;
}

export enum PostingStatus {
  initial = "",
  posted = "posted",
  requested = "requested",
}

export enum jobAdType {
  jobmatrix = 0,
  joblist = 1,
}

export interface JobAd {
  _id?: string;
  type: jobAdType;
  locationId: string; //MongoId von JobLocation
  categoryId?: string; //MongoId von JobCategory
  mandants: string[];
  status: PostingStatus;
  uuid?: string;

  categoriesBfA: string[] | [];
  profile: string;
  salaryFrom: number | null;
  salaryPeriod: string;
  salaryTo: number | null;
  tasks: string;
  title: string;

  campaigns?: string[];
  campaignsBudget?: number;
  socialMedia: boolean;
  supportedBfAJobAd: boolean;
}
