// services/zorst.service.ts

import { ZorstApiData } from "@/models/zorst-api-request.model";
import ToastService from "./toast.service";
import store from "../store/store";
import { StatusResponse, ZorstResponse } from "../enums/dialog-action.enum";
const zorstDeactivatedMessage = "ZORST ist ausgeschaltet";
const zorstInaktiveMessage =
  "ZORST Erweiterung ist in diesem Browser nicht aktiv";

interface StatusChangeMessage {
  type: string;
  action: string;
  candidateLink: string;
  targetStatus: string;
}

interface ZvooveRecruitAddDocu {
  type: string;
  zvooveRecruitLink: string;
  docuType: string;
  subject: string;
  text: string;
}

export class ZorstService {
  private static instance: ZorstService;

  // Singleton-Instanz
  public static getInstance(): ZorstService {
    if (!ZorstService.instance) {
      ZorstService.instance = new ZorstService();
    }
    return ZorstService.instance;
  }
  private checkUseZorst(): boolean {
    if (!store.state.company.softwareIntegration.zorst) {
      ToastService.show(zorstDeactivatedMessage);
      return false;
    } else if (!store.state.zorstAlive) {
      ToastService.show(zorstInaktiveMessage);
      return false;
    }
    return true;
  }

  public ZorstChangeRecruitCandidateStatus(
    candidateLink: string,
    newStatus: string
  ): Promise<string> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message: StatusChangeMessage = {
        type: "ZORST_CHANGE_STATUS",
        action: "openTabAndChangeStatus",
        candidateLink: candidateLink,
        targetStatus: newStatus,
      };

      let finalResponse: string | null = null;

      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "ZORST_ANSWER") {
          finalResponse = event.data.response.includes("not found")
            ? event.data.response
            : event.data.response.includes(ZorstResponse.successful)
            ? StatusResponse.atsSuccess
            : null;

          if (!finalResponse) return;

          ToastService.show(
            finalResponse.includes("not found")
              ? `Fehler: ${finalResponse}`
              : finalResponse
          );
          finalResponse.includes("not found")
            ? reject(finalResponse)
            : resolve(finalResponse);
          window.removeEventListener("message", handleResponse);
        }
      };

      window.addEventListener("message", handleResponse);

      window.postMessage(message, "*");
      setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        if (!finalResponse) {
          const errorMsg =
            "Zeitüberschreitung beim Warten auf die Antwort der Erweiterung";
          ToastService.show(`Fehler: ${errorMsg}`);
          reject(errorMsg);
        }
      }, 15000);
    });
  }
  public ZorstAddZvooveRecruitDocu(
    message: ZvooveRecruitAddDocu
  ): Promise<string> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "ZORST_ANSWER") {
          if (event.data.response.includes(ZorstResponse.successful)) {
            ToastService.show("Dokumentation erfolgreich hinzugefügt.");
            resolve("Dokumentation erfolgreich hinzugefügt.");
            clearTimeout(timeoutId);
          } else {
            ToastService.showError(`Fehler: ${event.data.response}`);
            reject(new Error(`Fehler: ${event.data.response}`));
            clearTimeout(timeoutId);
          }
          window.removeEventListener("message", handleResponse);
        }
      };

      // Set up the event listener to handle responses from the Chrome extension
      window.addEventListener("message", handleResponse);

      // Send the message to the Chrome extension to start the process
      window.postMessage(message, "*");

      // Set a timeout to handle cases where no response is received
      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Erweiterung";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 15000);
    });
  }

  public ZorstPublishJobAdInZvooveRecruit(
    jobAdData: {
      openJobAdLink: string;
      categoriesBfA: string[];
      salary: string;
      placement: string;
      collectiveAgreement: string;
      timemodel?: any;
    },
    supportedJobAd: boolean
  ): Promise<any> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const categoriesBfA = jobAdData.categoriesBfA.map((category) =>
        category.toString()
      );
      const message = {
        type: "PUBLISH_ATS_RECRUIT_JOB_AD",
        openJobAdLink: jobAdData.openJobAdLink,
        categoriesBfA: categoriesBfA,
        salary: jobAdData.salary,
        placement: jobAdData.placement,
        collectiveAgreement: jobAdData.collectiveAgreement,
        supportedJobAd: supportedJobAd,
      } as {
        type: string;
        openJobAdLink: string;
        categoriesBfA: string[];
        salary: string;
        placement: string;
        collectiveAgreement: string;
        supportedJobAd: boolean | undefined;
        timemodel?: string;
      };

      if (jobAdData.timemodel) {
        message.timemodel = jobAdData.timemodel;
      }
      // Listen for responses from the extension
      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "ZORST_ANSWER") {
          if (event.data.response.includes(ZorstResponse.successful)) {
            ToastService.show(`Erfolgreich: ${event.data.response}`);
            resolve(event.data.response);
          } else {
            ToastService.showError(`Fehler: ${event.data.response}`);
            reject(
              new Error(`recruit publishing Error: ${event.data.response}`)
            );
          }
          clearTimeout(timeoutId);
          window.removeEventListener("message", handleResponse);
        }
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      // Set a timeout for the response
      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Erweiterung";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 60000); // Timeout after 60 seconds
    });
  }

  public ZorstOpenSiteAndScrapeText(targetLink: string): Promise<string> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message = {
        type: "ZORST_SCRAPE_SITE",
        targetLink: targetLink,
      };

      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "ZORST_RESPONSE" && event.data.response) {
          ToastService.show(`Gescrappter Text empfangen.`);
          resolve(event.data.response);
          window.removeEventListener("message", handleResponse);
          clearTimeout(timeoutId);
        } else if (event.data.type === "ZORST_ANSWER") {
          if (event.data.response.includes(ZorstResponse.successful)) {
            ToastService.show(`Erfolgreich: ${event.data.response}`);
            clearTimeout(timeoutId);
            resolve(event.data.response);
          } else {
            ToastService.showError(
              `Fehlermeldung empfangen: ${event.data.response}`
            );
            clearTimeout(timeoutId);
            reject(new Error(`Scraping-Fehler: ${event.data.response}`));
          }
          window.removeEventListener("message", handleResponse);
        }
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        reject(new Error("Timeout: Keine Antwort vom Scraping erhalten."));
      }, 15000);
    });
  }

  public ZorstOneApiCall(apiData: {
    openApiSiteLink: string;
    apiEndpointLink: string;
    apiMethod: string;
    body?: any;
    apiKey?: string;
    xCoreClientId?: string;
  }): Promise<any> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message = {
        type: "ONE_API_CALL",
        openApiSiteLink: apiData.openApiSiteLink,
        apiEndpointLink: apiData.apiEndpointLink,
        apiMethod: apiData.apiMethod,
        body: apiData.body,
      } as ZorstApiData;
      if (apiData.apiKey) {
        message.apiKey = apiData.apiKey;
      }
      if (apiData.xCoreClientId) {
        message.xCoreClientId = apiData.xCoreClientId;
      }

      // Listen for responses from the extension
      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "ZORST_RESPONSE") {
          ToastService.show(`API-Antwort erhalten.`);
          resolve(event.data.response);
          clearTimeout(timeoutId);
          window.removeEventListener("message", handleResponse);
        } else if (event.data.type === "ZORST_ANSWER") {
          if (event.data.response.includes(ZorstResponse.successful)) {
            ToastService.show(`Erfolgreich: ${event.data.response}`);
            resolve(event.data.response);
          } else {
            ToastService.showError(`Fehler: ${event.data.response}`);
            reject(new Error(`API Error: ${event.data.response}`));
          }
          clearTimeout(timeoutId);
          window.removeEventListener("message", handleResponse);
        }
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      // Set a timeout for the response
      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Erweiterung";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 60000); // Timeout after 60 seconds
    });
  }
  public ZorstMultiApiCall(
    openApiSiteLink: string,
    apiRequests: Array<{
      endpointLink: string;
      method: string;
      body?: any;
      xCoreClientId?: string;
    }>
  ): Promise<any> {
    if (!this.checkUseZorst()) {
      return Promise.reject(zorstInaktiveMessage);
    }
    return new Promise((resolve, reject) => {
      const message = {
        type: "ZORST_MULTI_API_CALL",
        apiRequests: apiRequests,
        openApiSiteLink: openApiSiteLink,
      };
      // Listener for answers
      const handleResponse = (event: MessageEvent) => {
        if (event.data.type === "ZORST_RESPONSE") {
          ToastService.show(`Mehrere API-Antworten erhalten.`);
          resolve(event.data.response); // All Data as Array from all fetchings
          clearTimeout(timeoutId);
          window.removeEventListener("message", handleResponse);
        } else if (event.data.type === "ZORST_ANSWER") {
          if (event.data.response.includes(ZorstResponse.successful)) {
            ToastService.show(`Erfolgreich: ${event.data.response}`);
            resolve(event.data.response);
          } else {
            ToastService.showError(`Fehler: ${event.data.response}`);
            reject(new Error(`API Error: ${event.data.response}`));
          }
          clearTimeout(timeoutId);
          window.removeEventListener("message", handleResponse);
        }
      };

      window.addEventListener("message", handleResponse);
      window.postMessage(message, "*");

      // Setzen eines Timeouts für die Antwort
      const timeoutId = setTimeout(() => {
        window.removeEventListener("message", handleResponse);
        const errorMsg =
          "Zeitüberschreitung beim Warten auf die Antwort der Erweiterung";
        ToastService.showError(`Fehler: ${errorMsg}`);
        reject(new Error(errorMsg));
      }, 60000); // Timeout nach 30 Sekunden
    });
  }
}
