<template>
  <v-dialog
    v-model="dialog"
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxl)'"
  >
    <v-card>
      <v-card-title class="d-flex">
        Icon auswählen
        <v-spacer></v-spacer>
        <v-btn
          @click="closeDialog()"
          icon
          size="s"
          variant="text"
          density="compact"
        >
          <v-icon> fa-solid fa-xmark close-icon </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="(icon, index) in icons"
              :key="index"
              cols="12"
              sm="1"
              md="1"
            >
              <v-btn
                icon
                density="compact"
                variant="tonal"
                @click="selectIcon(icon)"
              >
                <v-icon :class="icon">{{ icon }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="abort" @click="closeDialog">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "IconPicker",
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      dialog: false,
      icons: [
        // Office and Administration
        "fa-solid fa-briefcase",
        "fa-solid fa-building",
        "fa-solid fa-envelope",
        "fa-solid fa-file-alt",
        "fa-solid fa-calendar-check",
        "fa-solid fa-id-card",
        "fa-solid fa-user-tie",
        "fa-solid fa-chart-line",
        "fa-solid fa-laptop",
        "fa-solid fa-phone-alt",
        "fa-solid fa-paperclip",
        "fa-solid fa-folder",
        "fa-solid fa-copy",
        "fa-solid fa-chart-pie",
        "fa-solid fa-clipboard",
        "fa-solid fa-address-book",
        "fa-solid fa-coffee",
        "fa-solid fa-glasses",
        "fa-solid fa-calculator",
        "fa-solid fa-archive",

        // Medical and Health
        "fa-solid fa-stethoscope",
        "fa-solid fa-user-md",
        "fa-solid fa-hospital",
        "fa-solid fa-syringe",
        "fa-solid fa-pills",
        "fa-solid fa-heartbeat",
        "fa-solid fa-band-aid",
        "fa-solid fa-wheelchair",
        "fa-solid fa-notes-medical",
        "fa-solid fa-tooth",
        "fa-solid fa-first-aid",
        "fa-solid fa-dna",
        "fa-solid fa-clinic-medical",
        "fa-solid fa-procedures",
        "fa-solid fa-bone",
        "fa-solid fa-vial",
        "fa-solid fa-thermometer",
        "fa-solid fa-virus",
        "fa-solid fa-lungs",
        "fa-solid fa-heart",

        // Education and Teaching
        "fa-solid fa-chalkboard-teacher",
        "fa-solid fa-graduation-cap",
        "fa-solid fa-book-open",
        "fa-solid fa-pencil-alt",
        "fa-solid fa-school",
        "fa-solid fa-lightbulb",
        "fa-solid fa-microscope",
        "fa-solid fa-globe",
        "fa-solid fa-atom",
        "fa-solid fa-bell",
        "fa-solid fa-compass",
        "fa-solid fa-book",
        "fa-solid fa-ruler",
        "fa-solid fa-palette",
        "fa-solid fa-chalkboard",
        "fa-solid fa-binoculars",
        "fa-solid fa-magnet",
        "fa-solid fa-puzzle-piece",
        "fa-solid fa-laptop-code",
        "fa-solid fa-award",

        // Technology and IT
        "fa-solid fa-code",
        "fa-solid fa-database",
        "fa-solid fa-desktop",
        "fa-solid fa-cogs",
        "fa-solid fa-network-wired",
        "fa-solid fa-mobile-alt",
        "fa-solid fa-server",
        "fa-solid fa-keyboard",
        "fa-solid fa-cloud",
        "fa-solid fa-robot",
        "fa-solid fa-bug",
        "fa-solid fa-sitemap",
        "fa-solid fa-terminal",
        "fa-solid fa-project-diagram",
        "fa-solid fa-download",
        "fa-solid fa-upload",
        "fa-solid fa-laptop-house",
        "fa-solid fa-file-code",
        "fa-solid fa-headset",

        // Food and Hospitality
        "fa-solid fa-utensils",
        "fa-solid fa-wine-glass",
        "fa-solid fa-mug-hot",
        "fa-solid fa-pizza-slice",
        "fa-solid fa-ice-cream",
        "fa-solid fa-blender",
        "fa-solid fa-hamburger",
        "fa-solid fa-bacon",
        "fa-solid fa-carrot",
        "fa-solid fa-lemon",
        "fa-solid fa-apple-alt",
        "fa-solid fa-bread-slice",
        "fa-solid fa-cocktail",
        "fa-solid fa-cookie",
        "fa-solid fa-candy-cane",
        "fa-solid fa-drumstick-bite",
        "fa-solid fa-egg",
        "fa-solid fa-hotdog",
        "fa-solid fa-fish",
        "fa-solid fa-bowl-rice",

        // Construction and Craftsmanship
        "fa-solid fa-hammer",
        "fa-solid fa-hard-hat",
        "fa-solid fa-wrench",
        "fa-solid fa-toolbox",
        "fa-solid fa-ruler-combined",
        "fa-solid fa-paint-roller",
        "fa-solid fa-screwdriver",
        "fa-solid fa-plug",
        "fa-solid fa-door-open",
        "fa-solid fa-trowel",
        "fa-solid fa-spray-can",
        "fa-solid fa-brush",
        "fa-solid fa-pencil-ruler",
        "fa-solid fa-solar-panel",
        "fa-solid fa-sink",
        "fa-solid fa-fire-extinguisher",
        "fa-solid fa-dumpster",

        // Transportation and Logistics
        "fa-solid fa-truck",
        "fa-solid fa-shipping-fast",
        "fa-solid fa-plane",
        "fa-solid fa-bus",
        "fa-solid fa-train",
        "fa-solid fa-bicycle",
        "fa-solid fa-warehouse",
        "fa-solid fa-road",
        "fa-solid fa-map-marked-alt",
        "fa-solid fa-anchor",
        "fa-solid fa-shuttle-van",
        "fa-solid fa-car",
        "fa-solid fa-trailer",
        "fa-solid fa-motorcycle",
        "fa-solid fa-rocket",
        "fa-solid fa-ship",
        "fa-solid fa-gas-pump",
        "fa-solid fa-taxi",
        "fa-solid fa-helicopter",
        "fa-solid fa-dolly",

        // Security and Law Enforcement
        "fa-solid fa-shield-alt",
        "fa-solid fa-lock",
        "fa-solid fa-gavel",
        "fa-solid fa-balance-scale",
        "fa-solid fa-handcuffs",
        "fa-solid fa-dog",
        "fa-solid fa-key",
        "fa-solid fa-user-shield",
        "fa-solid fa-traffic-light",
        "fa-solid fa-helmet-safety",
        "fa-solid fa-skull-crossbones",
        "fa-solid fa-shield-virus",
        "fa-solid fa-unlock",
        "fa-solid fa-camera",
        "fa-solid fa-exclamation-triangle",
        "fa-solid fa-person-military-rifle",
        "fa-solid fa-user-secret",
        "fa-solid fa-eye-slash",
        "fa-solid fa-fire",

        // Retail and Sales
        "fa-solid fa-shopping-cart",
        "fa-solid fa-store",
        "fa-solid fa-tags",
        "fa-solid fa-barcode",
        "fa-solid fa-shopping-bag",
        "fa-solid fa-gift",
        "fa-solid fa-cash-register",
        "fa-solid fa-coins",
        "fa-solid fa-wallet",
        "fa-solid fa-money-bill-wave",
        "fa-solid fa-receipt",
        "fa-solid fa-cart-plus",
        "fa-solid fa-store-alt",
        "fa-solid fa-box",
        "fa-solid fa-sack-dollar",
        "fa-solid fa-credit-card",
        "fa-solid fa-handshake",
        "fa-solid fa-hand-holding-usd",
        "fa-solid fa-boxes",

        // Science and Research
        "fa-solid fa-flask",
        "fa-solid fa-microscope",
        "fa-solid fa-atom",
        "fa-solid fa-dna",
        "fa-solid fa-seedling",
        "fa-solid fa-water",
        "fa-solid fa-satellite",
        "fa-solid fa-user-astronaut",
        "fa-solid fa-radiation",
        "fa-solid fa-chart-area",
        "fa-solid fa-thermometer-half",
        "fa-solid fa-satellite-dish",
        "fa-solid fa-biohazard",
        "fa-solid fa-vials",
        "fa-solid fa-capsules",
        "fa-solid fa-calendar-alt",
        "fa-solid fa-thermometer-empty",
        "fa-solid fa-vial-virus",

        // Arts and Entertainment
        "fa-solid fa-film",
        "fa-solid fa-music",
        "fa-solid fa-paint-brush",
        "fa-solid fa-theater-masks",
        "fa-solid fa-camera",
        "fa-solid fa-palette",
        "fa-solid fa-photo-video",
        "fa-solid fa-microphone",
        "fa-solid fa-video",
        "fa-solid fa-headphones",
        "fa-solid fa-guitar",
        "fa-solid fa-book-open",
        "fa-solid fa-icons",
        "fa-solid fa-award",
        "fa-solid fa-trophy",
        "fa-solid fa-wine-glass-alt",
        "fa-solid fa-heart",
        "fa-solid fa-crown",
        "fa-solid fa-camera-retro",

        // Other Professions and Miscellaneous
        "fa-solid fa-spa",
        "fa-solid fa-tree",
        "fa-solid fa-recycle",
        "fa-solid fa-earth",
        "fa-solid fa-snowflake",
        "fa-solid fa-anchor",
        "fa-solid fa-globe",
        "fa-solid fa-handshake-alt",
        "fa-solid fa-walking",
        "fa-solid fa-moon",
        "fa-solid fa-sun",
        "fa-solid fa-mountain",
        "fa-solid fa-wave-square",
        "fa-solid fa-compass",
        "fa-solid fa-umbrella",
        "fa-solid fa-rainbow",
        "fa-solid fa-biohazard",
        "fa-solid fa-wind",
        "fa-solid fa-thermometer-full",
        "fa-solid fa-bus-alt",
      ],
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    selectIcon(icon: string) {
      this.$emit("iconSelected", icon);
      this.closeDialog();
    },
  },
});
</script>
