import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createVNode(_component_v_icon, {
          size: "small",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode("fa-solid fa-envelope")
          ]),
          _: 1
        }),
        _createTextVNode(" Nachrichten Vorlagen ")
      ]),
      _createVNode(_component_v_form, {
        onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localAiData.template.mail, (template, index) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: "12",
                  md: "6",
                  key: template._id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, {
                      variant: _ctx.vStyle.card.variant || undefined,
                      rounded: _ctx.vStyle.card.rounded || undefined,
                      border: _ctx.vStyle.card.border || undefined,
                      class: "mb-4 pa-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card_title, { class: "d-flex justify-content-between align-items-center mb-5" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getEventNameByCandidateStatus(template.candidateStatus)) + " ", 1),
                            _createVNode(_component_v_spacer),
                            _createVNode(_component_v_btn, {
                              variant: "text",
                              onClick: ($event: any) => (_ctx.removeTemplate(index))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("fa-solid fa-trash-can")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_card_text, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: template.subject,
                              "onUpdate:modelValue": ($event: any) => ((template.subject) = $event),
                              label: "Betreff"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_textarea, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: template.text1,
                              "onUpdate:modelValue": ($event: any) => ((template.text1) = $event),
                              label: "Text 1"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"]),
                            _createVNode(_component_v_textarea, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: template.text2,
                              "onUpdate:modelValue": ($event: any) => ((template.text2) = $event),
                              label: "Text 2"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["variant", "rounded", "border"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    variant: _ctx.vStyle.card.variant || undefined,
                    rounded: _ctx.vStyle.card.rounded || undefined,
                    border: _ctx.vStyle.card.border || undefined,
                    class: "border-dashed mb-2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, { class: "d-flex justify-content-between align-items-center mb-5" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Neue Nachrichtenvorlage ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newMailTemplate.candidateStatus,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMailTemplate.candidateStatus) = $event)),
                            items: _ctx.candiateStatusOptions,
                            label: "Status",
                            density: "compact"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "items"]),
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newMailTemplate.subject,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newMailTemplate.subject) = $event)),
                            label: "Betreff",
                            density: "compact",
                            class: "mb-2"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                          _createVNode(_component_v_textarea, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newMailTemplate.text1,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newMailTemplate.text1) = $event)),
                            label: "Text 1",
                            "row-height": "20",
                            rows: "3",
                            density: "compact",
                            class: "mb-2"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                          _createVNode(_component_v_textarea, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newMailTemplate.text2,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newMailTemplate.text2) = $event)),
                            label: "Text 2",
                            "row-height": "20",
                            rows: "4",
                            density: "compact",
                            class: "mb-2"
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"]),
                          _createVNode(_component_v_btn, {
                            color: "primary",
                            variant: "text",
                            onClick: _ctx.addNewMailTemplate
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Hinzufügen ")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["variant", "rounded", "border"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            variant: _ctx.vStyle.btn.variant || undefined,
            rounded: _ctx.vStyle.btn.rounded || undefined,
            border: _ctx.vStyle.btn.border || undefined,
            class: "mt-10",
            type: "submit",
            color: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Speichern ")
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}