import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cce18e56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { class: "d-flex align-center" }
const _hoisted_7 = { class: "text-medium-emphasis fade-in-animation" }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { class: "d-flex align-center" }
const _hoisted_10 = { class: "text-medium-emphasis fade-in-animation" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "d-flex justify-center align-center" }
const _hoisted_13 = { class: "d-flex justify-center align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_file_input = _resolveComponent("v-file-input")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_slide_x_transition = _resolveComponent("v-slide-x-transition")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, {
    style: _normalizeStyle({ backgroundColor: _ctx.funnelData?.design?.background || '#ffffff' })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, null, {
        default: _withCtx(() => [
          (_ctx.funnelData?.design)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                rounded: _ctx.funnelData?.design.rounding,
                class: "mb-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_img, {
                    src: _ctx.funnelData?.design?.funnelHeader,
                    width: "100%"
                  }, null, 8, ["src"]),
                  (_ctx.funnelData?.design)
                    ? (_openBlock(), _createBlock(_component_v_sheet, {
                        key: 0,
                        height: "1rem",
                        class: "d-flex align-center justify-start",
                        color: _ctx.funnelData?.design?.primary
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_sheet, {
                            height: "0.7rem",
                            width: `${_ctx.progressBar}%`,
                            color: _ctx.funnelData?.design?.secondary
                          }, null, 8, ["width", "color"])
                        ]),
                        _: 1
                      }, 8, ["color"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["rounded"]))
            : _createCommentVNode("", true),
          (_ctx.funnelData?.design)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 1,
                rounded: _ctx.funnelData?.design.rounding,
                color: _ctx.funnelData?.design.bgQuestionForm,
                class: "pa-5"
              }, {
                default: _withCtx(() => [
                  (_ctx.isCurrentStage(_ctx.FunnelStage.mainQuestions))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_v_card_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.funnelData.funnelHeader), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.funnelData.questions, (question, index) => {
                              return (_openBlock(), _createBlock(_component_v_col, {
                                key: index,
                                cols: "6",
                                md: "4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    class: "pa-4 my-2 text-center",
                                    color: _ctx.funnelData.design.primary || 'primary',
                                    onClick: ($event: any) => (_ctx.handleQuestionSelection(question))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, { size: "4rem" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(question.icon), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_v_card_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(question.text), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["color", "onClick"])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]))
                    : (_ctx.isCurrentStage(_ctx.FunnelStage.subQuestions))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_v_btn, {
                            variant: "text",
                            icon: "",
                            onClick: _ctx.goToMainQuestions
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, {
                                color: _ctx.funnelData.design.primary,
                                size: "3rem"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("far fa-hand-point-left")
                                ]),
                                _: 1
                              }, 8, ["color"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createTextVNode(" zurück "),
                          _createVNode(_component_v_divider, { class: "mb-2" }),
                          _createVNode(_component_v_card_title, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.selectedQuestion.header), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedQuestion.subQuestions, (subQuestion, index) => {
                                return (_openBlock(), _createBlock(_component_v_col, {
                                  key: index,
                                  cols: "6",
                                  md: "4"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_card, {
                                      class: "pa-4 my-2 text-center",
                                      color: _ctx.funnelData.design.primary || 'primary',
                                      onClick: ($event: any) => (_ctx.handleSubQuestionSelection(subQuestion))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, { size: "4rem" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(subQuestion.icon), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_card_text, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(subQuestion.text), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["color", "onClick"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]))
                      : (_ctx.isCurrentStage(_ctx.FunnelStage.skills))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_v_btn, {
                              variant: "text",
                              icon: "",
                              onClick: _ctx.goToSubQuestions
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, {
                                  color: _ctx.funnelData.design.primary,
                                  size: "3rem"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("far fa-hand-point-left")
                                  ]),
                                  _: 1
                                }, 8, ["color"])
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createTextVNode(" zurück "),
                            _createVNode(_component_v_divider, { class: "mb-2" }),
                            _createVNode(_component_v_card_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.selectedSubQuestion.skillsHeader), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, { cols: "12" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedSubQuestion.skills, (skill, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "my-2 d-flex align-center"
                                  }, [
                                    _createVNode(_component_v_switch, {
                                      density: "compact",
                                      color: _ctx.funnelData.design.primary,
                                      modelValue: _ctx.selectedSkills[index],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.selectedSkills[index]) = $event),
                                      label: skill,
                                      onChange: ($event: any) => (_ctx.toggleSkill(index, skill)),
                                      inset: "",
                                      class: "large-checkbox"
                                    }, null, 8, ["color", "modelValue", "onUpdate:modelValue", "label", "onChange"]),
                                    _createVNode(_component_v_spacer),
                                    (_ctx.isSkillSliderVisible(index))
                                      ? (_openBlock(), _createBlock(_component_v_slider, {
                                          key: 0,
                                          color: _ctx.funnelData.design.primary,
                                          modelValue: _ctx.atsSkills[index].Auspraegung,
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.atsSkills[index].Auspraegung) = $event),
                                          min: 1,
                                          max: 3,
                                          step: "1",
                                          class: "ml-4",
                                          "show-ticks": "",
                                          "tick-size": "10",
                                          width: "2rem",
                                          "thumb-label": "always"
                                        }, {
                                          "thumb-label": _withCtx(({ modelValue }) => [
                                            _createTextVNode(_toDisplayString(_ctx.getSkillLevelLabel(modelValue)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["color", "modelValue", "onUpdate:modelValue"]))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_btn, {
                              color: _ctx.funnelData?.design.primary || 'primary',
                              rounded: _ctx.funnelData.design.rounding || 'md',
                              onClick: _ctx.completeSkills
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Weiter")
                              ]),
                              _: 1
                            }, 8, ["color", "rounded", "onClick"])
                          ]))
                        : (_ctx.isCurrentStage(_ctx.FunnelStage.contactForm))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createVNode(_component_v_form, {
                                onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"]),
                                ref: "form"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    items: _ctx.salutation,
                                    "item-title": "label",
                                    "item-value": "value",
                                    variant: "solo",
                                    modelValue: _ctx.atsApplicationObject.Mitarbeiter.SalutationCatalogId,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.atsApplicationObject.Mitarbeiter.SalutationCatalogId) = $event)),
                                    rules: [_ctx.salutationRule],
                                    label: "Anrede",
                                    required: ""
                                  }, null, 8, ["rounded", "items", "modelValue", "rules"]),
                                  _createVNode(_component_v_text_field, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: _ctx.atsApplicationObject.Mitarbeiter.Vorname,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.atsApplicationObject.Mitarbeiter.Vorname) = $event)),
                                    label: "Vorname",
                                    rules: [_ctx.requiredRule],
                                    required: ""
                                  }, null, 8, ["rounded", "modelValue", "rules"]),
                                  _createVNode(_component_v_text_field, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: _ctx.atsApplicationObject.Mitarbeiter.Nachname,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.atsApplicationObject.Mitarbeiter.Nachname) = $event)),
                                    label: "Nachname",
                                    rules: [_ctx.requiredRule],
                                    required: ""
                                  }, null, 8, ["rounded", "modelValue", "rules"]),
                                  _createVNode(_component_v_text_field, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: 
                _ctx.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[0].Wert
              ,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((
                _ctx.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[0].Wert
              ) = $event)),
                                    label: "E-Mail",
                                    type: "email",
                                    rules: [_ctx.requiredRule, _ctx.emailRule],
                                    required: ""
                                  }, null, 8, ["rounded", "modelValue", "rules"]),
                                  _createVNode(_component_v_text_field, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: 
                _ctx.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[1].Wert
              ,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((
                _ctx.atsApplicationObject.Mitarbeiter.Kommunikationsmittel[1].Wert
              ) = $event)),
                                    label: "Telefon",
                                    type: "tel",
                                    rules: [_ctx.requiredRule, _ctx.phoneRule],
                                    required: ""
                                  }, null, 8, ["rounded", "modelValue", "rules"]),
                                  _createVNode(_component_v_text_field, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: _ctx.atsApplicationObject.Mitarbeiter.Adressen[0].Plz,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.atsApplicationObject.Mitarbeiter.Adressen[0].Plz) = $event)),
                                    label: "Postleitzahl",
                                    rules: [_ctx.requiredRule, _ctx.postalCodeRule],
                                    required: ""
                                  }, null, 8, ["rounded", "modelValue", "rules"]),
                                  _createVNode(_component_v_text_field, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: _ctx.atsApplicationObject.Mitarbeiter.Adressen[0].Ort,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.atsApplicationObject.Mitarbeiter.Adressen[0].Ort) = $event)),
                                    label: "Ort",
                                    rules: [_ctx.requiredRule],
                                    required: ""
                                  }, null, 8, ["rounded", "modelValue", "rules"]),
                                  _createVNode(_component_v_file_input, {
                                    rounded: _ctx.funnelData?.design.rounding,
                                    variant: "solo",
                                    modelValue: _ctx.attachments,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.attachments) = $event)),
                                    chips: "",
                                    multiple: "",
                                    clearable: "",
                                    label: "Optional: Dokumente wie Lebenslauf etc. anfügen"
                                  }, null, 8, ["rounded", "modelValue"]),
                                  _createVNode(_component_v_checkbox, {
                                    modelValue: _ctx.isGDPRaccepted,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isGDPRaccepted) = $event)),
                                    rules: [_ctx.gdprRule]
                                  }, {
                                    label: _withCtx(() => [
                                      _createElementVNode("div", null, [
                                        _createTextVNode(" Ich akzeptiere die "),
                                        _createElementVNode("span", {
                                          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open(_ctx.ExternalLink.siteGDPR))),
                                          style: {"cursor":"pointer","font-weight":"bold","text-decoration":"underline"}
                                        }, " Datenschutzrichtlinien "),
                                        _createTextVNode(" und die Verarbeitung meiner Daten. ")
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue", "rules"]),
                                  _createVNode(_component_v_btn, {
                                    color: _ctx.funnelData?.design.primary || 'primary',
                                    rounded: _ctx.funnelData.design.rounding || 'md',
                                    disabled: !_ctx.formValid,
                                    type: "submit"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Absenden")
                                    ]),
                                    _: 1
                                  }, 8, ["color", "rounded", "disabled"])
                                ]),
                                _: 1
                              }, 8, ["onSubmit"])
                            ]))
                          : (_ctx.isCurrentStage(_ctx.FunnelStage.thankYou))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_v_card_title, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.funnelData.thankYouPage.title), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_card_subtitle, { class: "mb-8" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.funnelData.thankYouPage.subtitle), 1)
                                  ]),
                                  _: 1
                                }),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleSteps, (step, index) => {
                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: index,
                                    class: "mb-5"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_6, [
                                        _createVNode(_component_v_icon, {
                                          size: "x-large",
                                          color: _ctx.funnelData.design.primary,
                                          class: "mr-3 my-1 pop-animation"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(step.icon), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["color"]),
                                        _createElementVNode("div", _hoisted_7, _toDisplayString(step.text), 1)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]))
                            : (_ctx.isCurrentStage(_ctx.FunnelStage.noWay))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createVNode(_component_v_card_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.funnelData.noWayPage.title), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_card_subtitle, { class: "mb-8" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.funnelData.noWayPage.subtitle), 1)
                                    ]),
                                    _: 1
                                  }),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleSteps, (step, index) => {
                                    return (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: index,
                                      class: "mb-5"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_slide_x_transition, { mode: "out-in" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_9, [
                                              _createVNode(_component_v_icon, {
                                                size: "x-large",
                                                color: _ctx.funnelData.design.primary,
                                                class: "mr-3 my-1 pop-animation"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(step.icon), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["color"]),
                                              _createElementVNode("div", _hoisted_10, _toDisplayString(step.text), 1)
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["rounded", "color"]))
            : _createCommentVNode("", true),
          (_ctx.funnelData?.design)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_v_btn, {
                  class: "my-15",
                  color: _ctx.funnelData?.design.primary || 'primary',
                  rounded: _ctx.funnelData.design.rounding || 'md',
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.open(_ctx.ExternalLink.jobBoard))),
                  block: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Alle Stellenanzeigen")
                  ]),
                  _: 1
                }, 8, ["color", "rounded"]),
                _createVNode(_component_v_card_subtitle, {
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.open(_ctx.ExternalLink.companySite))),
                  style: {"text-align":"right","cursor":"pointer"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.funnelData.companyConfig.copyright), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.isSpinner,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isSpinner) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                (_ctx.isSpinner)
                  ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                      key: 0,
                      indeterminate: "",
                      color: _ctx.funnelData?.design.secondary || 'secondary',
                      size: "300",
                      width: "30"
                    }, null, 8, ["color"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.isPopActive,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.isPopActive) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_v_img, {
                  src: _ctx.funnelData?.design?.effectImage,
                  width: "100%",
                  "max-width": "20rem",
                  class: "pop-animation"
                }, null, 8, ["src"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["style"]))
}