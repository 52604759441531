import { ZvooveEmployeeList } from "@/models/external/zvoove-candidate-list.model";
import { Api } from "./api.class";
import { Employee, EmployeeList } from "@/models/employee.model";

export class EmployeeService {
  private api = Api.getInstance();
  private urlPath = "/employee";
  private urlPathList = "/employee-list";

  // Methods for ZvooveEmployeeList
  async getList(): Promise<ZvooveEmployeeList[]> {
    const response = await this.api
      .getBackendApi()
      .get<{ response: ZvooveEmployeeList[] }>(`${this.urlPathList}`);

    if (response.data && Array.isArray(response.data.response)) {
      return response.data.response;
    } else {
      throw new Error("Invalid response format");
    }
  }

  async saveList(employees: EmployeeList[]): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .post<EmployeeList[]>(`${this.urlPathList}`, employees);
    return response.data;
  }

  async addEmployeeReducedToList(employee: EmployeeList) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPathList}/add`, employee);
    return response.data;
  }

  // Methods for ZvooveEmployee / Employee
  async addAllEmployees(employees: Employee[]): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}`, employees);
    return response.data.response;
  }

  async getAllFiltered(
    mandants: string[],
    status: number[]
  ): Promise<Employee[]> {
    const response = await this.api
      .getBackendApi()
      .post<{ response: Employee[] }>(`${this.urlPath}/filtered`, {
        mandants: mandants,
        status,
      });
    return response.data.response;
  }

  async addEmployee(employee: Employee) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/add`, employee);
    return response.data;
  }

  async editEmployee(employee: Employee) {
    const response = await this.api
      .getBackendApi()
      .put(`${this.urlPath}/${employee._id}`, employee);
    return response.data.response;
  }

  async deleteEmployee(id: string) {
    const response = await this.api
      .getBackendApi()
      .delete(`${this.urlPath}/${id}`);
    return response.data;
  }

  async getEmployee(id: string): Promise<Employee> {
    const response = await this.api
      .getBackendApi()
      .get<{ response: Employee }>(`${this.urlPath}/${id}`);
    return response.data.response;
  }
}
