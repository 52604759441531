import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    modelValue: _ctx.valid,
    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.valid) = $event)),
    ref: "form"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { noGutters: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "Suchbegriff",
                modelValue: _ctx.localSearchTerm,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localSearchTerm) = $event)),
                class: "mt-1",
                rules: [_ctx.rules.required],
                hint: "* Erforderlich",
                "persistent-hint": "",
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                label: "Postleitzahl",
                modelValue: _ctx.postcodeCenter,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.postcodeCenter) = $event)),
                class: "mt-1 mr-3",
                rules: [_ctx.rules.required, _ctx.rules.postalCode],
                hint: "* Erforderlich",
                "persistent-hint": "",
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_label, { class: "d-flex justify-center" }, {
                default: _withCtx(() => [
                  _createTextVNode("Umkreis in km")
                ]),
                _: 1
              }),
              _createVNode(_component_v_slider, {
                modelValue: _ctx.postcodeRadius,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.postcodeRadius) = $event)),
                max: 100,
                min: 0,
                step: 0.5,
                "thumb-label": "",
                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                items: _ctx.mobilityOptions,
                "item-title": "label",
                "item-value": "label",
                label: "Mobilität",
                modelValue: _ctx.candidateDetailSearchObject.mobility,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.candidateDetailSearchObject.mobility) = $event)),
                multiple: "",
                class: "mr-3",
                onClick: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                items: _ctx.shiftPreferencesOptions,
                "item-title": "label",
                "item-value": "label",
                label: "Schichtbereitschaft",
                modelValue: _ctx.candidateDetailSearchObject.shiftPreference,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.candidateDetailSearchObject.shiftPreference) = $event)),
                multiple: "",
                onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                variant: _ctx.vStyle.input.variant || undefined,
                rounded: _ctx.vStyle.input.rounded || undefined,
                "base-color": _ctx.vStyle.input.baseColor || undefined,
                color: _ctx.vStyle.input.color || undefined,
                items: _ctx.statusOptionsRecruit,
                "item-title": "text",
                "item-value": "value",
                label: "Status",
                modelValue: _ctx.candidateDetailSearchObject.selectedStatuses,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.candidateDetailSearchObject.selectedStatuses) = $event)),
                rules: [_ctx.rules.required],
                hint: "* Erforderlich",
                "persistent-hint": "",
                multiple: "",
                onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["variant", "rounded", "base-color", "color", "items", "modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"])),
                modelValue: _ctx.candidateDetailSearchObject.deepSearch,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.candidateDetailSearchObject.deepSearch) = $event)),
                label: "Suche in Lebenslauf"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                disabled: !_ctx.valid,
                block: "",
                color: "primary",
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.startBackendSearch()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Detailsuche starten")
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}