import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2da17722"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headline" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showDialog) = $event)),
    "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)',
    class: "xs12 md6"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.editMode ? "Firmenkunden bearbeiten" : "Neuen Firmenkunden anlegen"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_form, {
                ref: "form",
                onSubmit: _withModifiers(_ctx.saveCompany, ["prevent"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.tradeRegisterEntry,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCompany.tradeRegisterEntry) = $event)),
                            disabled: _ctx.editMode,
                            label: "Handelsregister",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "disabled"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.name,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCompany.name) = $event)),
                            rule: _ctx.rule20,
                            label: "Firmenname",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rule"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "8"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.address.street,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newCompany.address.street) = $event)),
                            rules: _ctx.rule20,
                            label: "Strasse",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.address.number,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCompany.address.number) = $event)),
                            rules: _ctx.rule15,
                            label: "Hausnummer",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.address.postalCode,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newCompany.address.postalCode) = $event)),
                            label: "PLZ",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "5"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.address.city,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newCompany.address.city) = $event)),
                            rules: _ctx.rule20,
                            label: "Ort",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, {
                        cols: "12",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.newCompany.address.country,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newCompany.address.country) = $event)),
                            label: "Land",
                            required: ""
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                        ]),
                        _: 1
                      }),
                      (!_ctx.editMode)
                        ? (_openBlock(), _createBlock(_component_v_row, {
                            key: 0,
                            class: "ml-0 mr-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "12" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Administrator")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    variant: _ctx.vStyle.input.variant || undefined,
                                    rounded: _ctx.vStyle.input.rounded || undefined,
                                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                                    color: _ctx.vStyle.input.color || undefined,
                                    modelValue: _ctx.newUser.email,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newUser.email) = $event)),
                                    label: "Email",
                                    required: ""
                                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    variant: _ctx.vStyle.input.variant || undefined,
                                    rounded: _ctx.vStyle.input.rounded || undefined,
                                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                                    color: _ctx.vStyle.input.color || undefined,
                                    modelValue: _ctx.newUser.username,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newUser.username) = $event)),
                                    label: "Benutzername",
                                    required: ""
                                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "4"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_text_field, {
                                    variant: _ctx.vStyle.input.variant || undefined,
                                    rounded: _ctx.vStyle.input.rounded || undefined,
                                    "base-color": _ctx.vStyle.input.baseColor || undefined,
                                    color: _ctx.vStyle.input.color || undefined,
                                    modelValue: _ctx.newUser.password,
                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newUser.password) = $event)),
                                    label: "Passwort",
                                    required: ""
                                  }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      (_ctx.saveError)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.saveError), 1))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_btn, {
                        onClick: _ctx.closeDialog,
                        color: "abort"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Abbrechen")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        type: "submit",
                        color: "success"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Speichern")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "max-width"]))
}