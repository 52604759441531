import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_EmailServerConfigForm = _resolveComponent("EmailServerConfigForm")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.getUserRole() < 6)
    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("h2", _hoisted_1, [
            _createVNode(_component_v_icon, {
              size: "small",
              class: "mr-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode("fa-solid fa-envelope")
              ]),
              _: 1
            }),
            _createTextVNode("Email Server ")
          ]),
          _createVNode(_component_v_form, {
            onSubmit: _withModifiers(_ctx.handleSubmit, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EmailServerConfigForm, {
                modelValue: _ctx.localConfig.mailServer,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localConfig.mailServer) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_v_btn, {
                variant: _ctx.vStyle.btn.variant || undefined,
                rounded: _ctx.vStyle.btn.rounded || undefined,
                border: _ctx.vStyle.btn.border || undefined,
                class: "mt-10",
                type: "submit",
                color: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Speichern")
                ]),
                _: 1
              }, 8, ["variant", "rounded", "border"])
            ]),
            _: 1
          }, 8, ["onSubmit"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}