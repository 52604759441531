<!--src/components/disposition/CustomerItem.vue-->
<template>
  <v-container class="ma-0 pa-0">
    <v-hover>
      <template v-slot:default="{ isHovering, props }">
        <v-container
          @drop="handleDrop"
          @dragstart="handleDragStart"
          @dragover="handleDragover"
          class="ma-0 pa-0 item"
          draggable="true"
          v-if="isFullyCollapsed"
        >
          <v-sheet class="ma-0 pt-1 px-1" :color="item.colorList">
            <v-card-title class="d-flex justify-space-between pa-0">
              <span class="pa-0 text-body-2">
                <v-icon class="mx-1 match-icon" v-if="candidatesTagsMatching"
                  >fa-solid fa-heart</v-icon
                >{{ customer?.generalData?.name }}
                <span
                  class="font-weight-light text-caption text-medium-emphasis"
                  >({{ customer?.customerNumber }})
                </span>
              </span>
              <span
                class="mx-1 font-weight-light text-caption text-medium-emphasis"
              >
                <span
                  v-if="customer?.jobAds && customer.jobAds.length > 0"
                  @mouseenter="handleMouseEnter"
                  @mouseleave="scheduleJobAdListClose"
                >
                  <i class="fa-solid fa-rectangle-ad"></i>
                </span>
                {{ customer?.addressAndCommunication?.city }}
              </span>
            </v-card-title>
            <v-divider class="mt-1"></v-divider>
          </v-sheet>
        </v-container>
        <v-card
          v-if="!isFullyCollapsed"
          :variant="vStyle.boardItem.variant || undefined"
          :rounded="vStyle.boardItem.rounded || undefined"
          :border="item.border"
          :elevation="vStyle.boardItem.elevation || undefined"
          class="item pa-2 mb-2"
          v-bind="props"
          :color="isHovering ? item.hoverColor : item.color"
          :class="{
            expanded: isExpanded,
            'fully-expanded': isFullyExpanded,
          }"
          :max-height="getItemHeight()"
          @dblclick="handleDoubleClickOnItem"
          @drop="handleDrop"
          @dragstart="handleDragStart"
          @dragover="handleDragover"
          draggable="true"
        >
          <div class="item-collapsed">
            <v-card-title class="pa-0 text-body-2">
              <img
                v-if="
                  customer?.additionalInfo && customer?.additionalInfo.logoLink
                "
                alt="Logo"
                :src="customer?.additionalInfo.logoLink"
                class="logo-img mr-1"
              />
              <v-icon class="mx-1 match-icon" v-if="candidatesTagsMatching"
                >fa-solid fa-heart</v-icon
              >{{ customer?.generalData?.name }}
            </v-card-title>
            <v-card-subtitle class="pl-0">
              {{ customer?.addressAndCommunication?.postalCode }}
              {{ customer?.addressAndCommunication?.city }} ({{
                customer?.customerNumber
              }})
            </v-card-subtitle>
          </div>
          <TopRightButton
            :isExpanded="isExpanded"
            :isFullyExpanded="isFullyExpanded"
            @toggleExpansion="toggleExpansion"
            @toggleFullExpansion="toggleFullExpansion"
            @openContextMenu="$emit('openContextMenu', $event)"
          />
          <div
            v-if="showJobAdsIcon()"
            class="job-ad-hover"
            @mouseenter="handleMouseEnter"
            @mouseleave="scheduleJobAdListClose"
          >
            <i class="fa-solid fa-rectangle-ad"></i>
          </div>
          <div class="hide-on-inactive-column" v-if="isActive">
            <div class="customer-mandant">
              {{ mandantName() }}

              <v-menu activator="parent">
                <v-list>
                  <v-list-item
                    @click="saveMandantSelection(item.uuid)"
                    v-for="(item, index) in mandants"
                    :key="index"
                    :value="index"
                  >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="customer-status">
              {{ customer?.generalData?.status }}
              <v-menu activator="parent">
                <v-list>
                  <v-list-item
                    v-for="(item, index) in customerStates"
                    :key="index"
                    :value="index"
                    @click="saveStatusSelection(index.toString())"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="status-communication-container">
            <div v-if="isExpanded" class="communication-icons-container">
              <div
                v-if="
                  (softwareIntegration as SoftwareIntegration).indexAnzeigendaten &&
                  (!customer?.additionalInfo?.indexLink ||
                    customer?.additionalInfo?.indexLink === '')
                "
              >
                <img
                  src="@/assets/icon-index-disconnect.png"
                  alt="Index Anzeigendaten disconnected"
                  class="index-erp-ats-icon"
                  @click="openUpdateCustomerModal(ModalMode.indexAnzeigendaten)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >mit Anzeigendaten verknüpfen</v-tooltip
                >
              </div>
              <div
                v-if="
                  (softwareIntegration as SoftwareIntegration).zvooveOne &&
                  (!isNumeric(customer?.customerNumber) ||
                    customer?.generalData?.costCenter == null)
                "
              >
                <img
                  src="@/assets/icon-zvoove-one-disconnect.png"
                  alt="ERP One disconnected"
                  class="index-erp-ats-icon"
                  @click="openUpdateCustomerModal(ModalMode.zvooveOne)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >mit bestehendem Kunden in ERP One verknüpfen</v-tooltip
                >
              </div>
              <div v-if="isNotZvooveCustomerNumber()">
                <img
                  src="@/assets/icon-zvoove-one-upload.svg"
                  alt="ERP One upload"
                  class="index-erp-ats-icon"
                  @click="$emit('createInErp', customer)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >in ERP One anlegen</v-tooltip
                >
              </div>
              <div
                v-if="
                  (softwareIntegration as SoftwareIntegration).pdHub &&
                  (!isNumeric(customer?.customerNumber) ||
                    customer?.generalData?.costCenter == null)
                "
              >
                <img
                  src="@/assets/icon-pd-hub-disconnect.svg"
                  alt="PD-Hub disconnected"
                  class="index-erp-ats-icon"
                  @click="openUpdateCustomerModal(ModalMode.pdHub)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >mit bestehendem Kunden in PD-Hub verknüpfen</v-tooltip
                >
              </div>
              <div v-if="isNotPdHubCustomerNumber()">
                <img
                  src="@/assets/icon-pd-hub-upload.svg"
                  alt="pd hub upload"
                  class="index-erp-ats-icon"
                  @click="$emit('createInErp', customer)"
                />
                <v-tooltip activator="parent" location="bottom"
                  >in PD-Hub anlegen</v-tooltip
                >
              </div>
              <div>
                <i
                  class="fa-solid fa-person-circle-plus get-contacts-icon"
                  @click="
                    openUpdateCustomerModal(ModalMode.scrapeContactsFromSite)
                  "
                >
                  <v-tooltip activator="parent" location="bottom"
                    >Ansprechpartner von Webseite ergänzen</v-tooltip
                  >
                </i>
              </div>
            </div>
            <div class="communication-icons-container">
              <ErpLinkMenu
                v-if="isExpanded && customer?.generalData?.costCenter != null"
                :zvooveOneCustomerNumber="customer?.customerNumber"
                @getErpData="updateCustomerFromErp()"
                @putErpData="updateErpFromCustomer()"
              ></ErpLinkMenu>
              <PhoneClient
                v-if="isExpanded"
                ref="phoneClientComponent"
                :customer="customer"
                :phoneNumbers="filteredPhoneNumbers"
                @addFollowUpEvent="addFollowUpOnDependencies"
                @collapseParentItem="collapseItem"
                @isDialingNumber="phoneClientIsDialing"
                @setAppointmentEvent="setAppointmentOnDependencies"
              ></PhoneClient>
              <MailClient
                v-if="isExpanded || isMailClientActive"
                ref="mailClientComponent"
                :AiMessageType="AiMessageType.mailCustomer"
                :customer="customer"
                :emailAddresses="filteredEmail"
                :emailObject="email"
                :signature="signatureText"
                @collapseParentItem="collapseItem"
              ></MailClient>
            </div>
          </div>
          <v-divider></v-divider>
          <Checklist
            :checklist="customer?.checklist || []"
            @updateChecklist="updateChecklist"
          ></Checklist>
          <v-divider></v-divider>
          <div v-if="isExpanded" class="tags-container">
            <v-chip
              v-for="(tag, index) in customer?.tags"
              :key="index"
              class="dispatcher-board-icon-tag"
              color="primary"
              @click:close="removeTag(index)"
            >
              {{ tag }}
              <v-icon
                small
                @click.stop="removeTag(index)"
                class="fa fa-times"
              ></v-icon>
            </v-chip>
            <v-text-field
              variant="outlined"
              class="mt-2"
              label="Neuer Tag"
              style="max-width: 10rem"
              rounded="lg"
              density="compact"
              v-model="newTag"
              @keyup.enter="addTag"
            >
              <v-tooltip activator="parent" location="bottom"
                >mit "Enter" neuen Tag hinzufügen</v-tooltip
              >
            </v-text-field>
            <v-btn
              icon
              class="mt-3"
              variant="text"
              density="compact"
              @click="generateAndSetCustomerTags()"
            >
              <v-icon size="small">fas fa-arrows-rotate</v-icon>
              <v-tooltip activator="parent" location="bottom"
                >Tags anhand historischer Einsätze und ausgeschriebener Stellen
                neu generieren</v-tooltip
              >
            </v-btn>
          </div>
          <v-divider></v-divider>
          <Dependencies
            v-if="
              customer && (hasLinking(customer?._id) || isDependenciesActive)
            "
            :mandant="[customer?.mandants[0] || '']"
            :customer_id="customer?._id"
            :linkingDescription="linkingDescription"
            ref="dependenciesComponent"
            @generateProfile="generateProfileFromDependencies()"
            @openPhoneClient="openPhoneClient()"
          />
          <v-divider></v-divider>
          <Assignments
            v-if="isExpanded"
            :isCustomer="true"
            :assignments="customer?.assignments"
            @updateAssignments="
              getAssignmentsFromErp(customer?.customerNumber ?? '')
            "
          ></Assignments>
          <Timeline
            contact="Test Ansprechpartner"
            :customer="customer"
            v-if="isExpanded"
          ></Timeline>
          <DialogUpdateCustomer
            v-if="showModal || resultsModal"
            :showModal="showModal"
            :showSearchResultsFromErp="showSearchResultsFromErp"
            @update:showModal="showModal = $event"
            :results="results"
            :resultsModal="resultsModal"
            @update:resultsModal="resultsModal = $event"
            :modalTitle="modalTitle"
            :modalType="modalType"
            :addCustomerModalInput1="addCustomerModalInput1"
            :addCustomerModalInput2="addCustomerModalInput2"
            :addCustomerModalLabel1="addCustomerModalLabel1"
            :addCustomerModalLabel2="addCustomerModalLabel2"
            :modalActionButtonText="modalActionButtonText"
            @submit="handleModalSubmit"
            @selectedResult="handleSelectedResult"
          />
          <ProfileGenerator
            v-if="isProfileGeneratorActive"
            ref="profileGeneratorComponent"
            :profileSourceData="profileSourceData"
            @openMailClient="openMailClientFromProfileGenerator"
          />
        </v-card>
        <Teleport to="body">
          <JobAdListCustomer
            v-if="!isExpanded && customer?.jobAds"
            ref="jobAdListRef"
            @fetchIndexAnzeigendaten="autoFetchJobAds(true)"
            :jobAds="customer?.jobAds || []"
            :mandant="customer?.mandants[0] || ''"
            :positionX="jobAdListPosition.x"
            :positionY="jobAdListPosition.y"
          />
        </Teleport>
      </template>
    </v-hover>
  </v-container>
</template>

<script lang="ts">
import { AiMessageType } from "../../enums/ai-options.enum";
import { AiService } from "@/services/ai.service";
import { AllErpAdapter } from "@/adapter/all-erp.adapter";
import { AnzeigedatenService } from "@/services/api/anzeigendaten.service";
import { Assignment } from "@/models/assignment.model";
import { CheckList } from "@/models/checklist.model";
import { Customer, CustomerContact } from "@/models/customer.model";
import { CustomerService } from "@/services/api/customer.service";
import { defineComponent, nextTick, PropType } from "vue";
import { DialogResponse } from "@/enums/dialog-action.enum";
import { Email } from "@/models/email.model";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import {
  CandidateToCustomerData,
  Linking,
  LinkingDescription,
} from "@/models/linking.model";
import { LinkingService } from "@/services/api/linking.service";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { MessageContent } from "@/enums/empty-message.enum";
import { ModalMode } from "@/enums/dialog-action.enum";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { ProfileJobAd, ProfileSourceData } from "@/models/profile.model";
import { Salutation } from "@/enums/salutation.model";
import { SoftwareIntegration } from "@/models/company-config.model";
import { SpinnerService } from "@/services/spinner.service";
import { TooltipService } from "@/services/tooltip.service";
import { User } from "@/models/user.model";
import { ZorstService } from "@/services/zorst.service";
import Assignments from "./elements/Assignments.vue";
import Checklist from "./elements/Checklist.vue";
import Dependencies from "@/components/disposition/elements/Dependencies.vue";
import DialogService from "@/services/dialog.service";
import DialogUpdateCustomer from "@/components/disposition/elements/DialogAddCustomer.vue";
import ErpLinkMenu from "./elements/ExternalSoftwareLinksMenu.vue";
import Fuse from "fuse.js";
import JobAdListCustomer from "@/components/disposition/elements/JobAdListCustomer.vue";
import MailClient from "./elements/MailClient.vue";
import moment from "moment";
import PhoneClient from "./elements/PhoneClient.vue";
import ProfileGenerator from "./elements/ProfileGenerator.vue";
import Timeline from "./elements/Timeline.vue";
import ToastService from "@/services/toast.service";
import TopRightButton from "./elements/TopRightButton.vue";
import { Demand } from "../../models/demand.model";
import { LinkingStatus, Role } from "../../enums/dependency.enum";
import { DemandStateLabels } from "../../enums/demand-states.enum";

export default defineComponent({
  name: "CustomerItem",
  emits: ["createInErp", "insertUpdatedCustomer", "openContextMenu"],
  components: {
    Assignments,
    Checklist,
    Dependencies,
    DialogUpdateCustomer,
    ErpLinkMenu,
    JobAdListCustomer,
    MailClient,
    PhoneClient,
    ProfileGenerator,
    Timeline,
    TopRightButton,
  },
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    isFullyCollapsed: {
      type: Boolean,
      required: false,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    return {
      addCustomerModalInput1: "",
      addCustomerModalInput2: "",
      addCustomerModalLabel1: "",
      addCustomerModalLabel2: "",
      AiMessageType,
      apiCustomerService: new CustomerService(),
      anzeigedatenService: AnzeigedatenService.getInstance(),
      candidatesTagsMatching: false,
      email: {
        subject: MessageContent.emptySubject as string,
        body: MessageContent.empty as string,
      } as Email,
      filteredEmail: [] as any,
      filteredPhoneNumbers: [] as any,
      isCheckingDependency: false,
      isDependenciesActive: false,
      isExpanded: false,
      isFullyExpanded: false,
      isHovered: false,
      isMailClientActive: false,
      isProfileGeneratorActive: false,
      isTriedToFetchAssignments: false,
      isTriedToFetchContacts: false,
      item: {
        border: this.$store.state.vStyle.boardItem.border || undefined,
        color: "card",
        elevation: 2,
        hoverColor: "cardHover",
        colorList: undefined as undefined | string,
      },
      jobAdListPosition: { x: 0, y: 0 },
      linkingDescription: {} as LinkingDescription,
      linkingService: new LinkingService(),
      modalActionButtonText: "aus ERP importieren",
      modalTitle: "",
      modalType: "",
      mountDependencies: false,
      newTag: "",
      profileSourceData: {} as ProfileSourceData,
      results: [] as Customer[],
      resultsModal: false,
      showModal: false,
      showSearchResultsFromErp: true,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      company: "company",
      customerStates: "customerStates",
      hasLinking: "hasLinkingForCustomer",
      zvooveCustomers: "zvooveCustomers",
    }),
    DialogResponse() {
      return DialogResponse;
    },
    ModalMode() {
      return ModalMode;
    },
    signatureText() {
      const user = this.company.loggedInUser;
      return `freundliche Grüße<br>${Salutation[user.salutation]} ${
        user.forename
      } ${user.lastname}<br>${this.company.name}`;
    },
  },
  mounted() {
    this.extractPhoneNumbers();
    this.extractEmailsAndSalutations();
    this.$nextTick().then(() => {
      this.autoFetchJobAds();
    });
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.matchMeFromCandidate:
            this.handleCheckMatch(newVal.payload);
            break;
          case InterComponentMessage.generateProfile:
            if (this.customer?._id === newVal.payload.customerId) {
              this.handleLinkCandidateToCustomer(
                newVal.payload.draggingData,
                newVal.payload.demand
              );
            }
            break;
          default:
            this.item.color = "card";
            this.item.hoverColor = "card-hover";
            break;
        }
      },
      deep: true,
    },
    lastUpdateTimeline() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).loadLinkingsFromStore();
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).checkForReminders();
      }
    },
  },
  methods: {
    ...mapMutations({
      clearICM: MutationType.clearICM,
      setICM: MutationType.setICM,
    }),
    addFollowUpOnDependencies() {
      this.isDependenciesActive = true;
      this.$nextTick(() => {
        if (this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).linkFollowUp(null, this.customer?._id || null);
        }
      });
    },
    async addTag() {
      const trimmedTag = this.newTag.trim();
      if (!this.customer && trimmedTag !== "") return;
      const customer = Object.assign(this.customer as any);
      if (customer && Array.isArray(customer.tags)) {
        customer.tags.push(trimmedTag);
        await this.updateCustomer(customer);
        this.newTag = "";
      }
    },
    async autoFetchJobAds(forceFetchIndexAnzeigendaten?: boolean) {
      if (!this.customer) return;
      const customer = this.customer;
      const indexJobAdRefreshInterval =
        this.$store.state.company.softwareIntegration.indexJobAdRefreshInterval;
      let shouldUpdateLastFetch = false;
      let shouldFetchAds = false;
      if (
        (this.softwareIntegration as SoftwareIntegration).indexAnzeigendaten &&
        customer.additionalInfo?.indexCompanyId
      ) {
        if (customer.lastEdit?.jobAds) {
          const lastEditDate = moment(
            customer.lastEdit.jobAds,
            "YYYY-MM-DDTHH:mm:ssZ"
          );
          const currentDate = moment();
          const hoursSinceLastEdit = currentDate.diff(lastEditDate, "hours");
          if (hoursSinceLastEdit > 25) {
            shouldFetchAds = true;
            shouldUpdateLastFetch = true;
          }
        } else {
          shouldFetchAds = true;
        }
        if (customer?.jobAds?.[0]?.retrievalDate) {
          const retrievalDate = moment(
            customer.jobAds[0].retrievalDate,
            "DD.MM.YYYY"
          );
          const isRetrievalDateExpired = retrievalDate.isBefore(
            moment().startOf("day").subtract(indexJobAdRefreshInterval, "days")
          );
          if (isRetrievalDateExpired) shouldFetchAds = true;
        }
        if (shouldFetchAds || forceFetchIndexAnzeigendaten) {
          try {
            const mandant = this.customer.mandants[0];
            const newJobAds =
              await this.anzeigedatenService.getJobAdsFromCustomer(
                customer.additionalInfo.indexCompanyId,
                mandant
              );
            ToastService.show(
              `Index Anzeigendaten von ${customer.generalData.name} abgerufen!`
            );

            customer.jobAds = newJobAds;
            customer.lastEdit = customer.lastEdit || {};
            customer.lastEdit.jobAds = moment().toISOString();

            if ((newJobAds && newJobAds.length > 0) || shouldUpdateLastFetch)
              await this.updateCustomer(customer);
          } catch (error) {
            console.error("Fehler beim Abrufen neuer Stellenanzeigen:", error);
          }
        } else if (!this.customer.lastEdit?.jobAds) {
          customer.lastEdit = customer.lastEdit || {};
          customer.lastEdit.jobAds = moment().toISOString();
          await this.updateCustomer(customer);
        }
      }
    },
    checkIfShouldGenerateTags() {
      if (
        this.customer &&
        this.customer.jobAds.length > 0 &&
        this.customer.tags?.length === 0
      ) {
        this.generateAndSetCustomerTags();
      }
    },
    checkTagsMatching(payloadTags: string[], customerTags: string[]) {
      const options = {
        includeScore: true,
        threshold:
          this.$store.state.company.aiData.prompt.company
            .tagsMatchingThreshold ?? 0.7,
        isCaseSensitive: false,
        keys: ["tag"],
      };
      const fuse = new Fuse(
        customerTags.map((tag) => ({ tag })),
        options
      );
      return payloadTags.some((payloadTag) => {
        const results = fuse.search(payloadTag);
        return results.some((result: any) => {
          const matchTag = result.item.tag;
          return matchTag.includes(payloadTag) || payloadTag.includes(matchTag);
        });
      });
    },
    collapseItem() {
      this.isMailClientActive = false;
      this.isFullyExpanded = false;
      this.isExpanded = false;
    },
    async extractEmailsAndSalutations() {
      const emails = [];

      // Add central mailadresse and salutation for all
      if (this.customer?.addressAndCommunication?.email) {
        emails.push({
          mailaddress: this.customer.addressAndCommunication.email,
          salutation: "Sehr geehrte Damen und Herren,",
        });
      }

      // Add contacts mailadresses
      this.customer?.contacts.forEach((contact) => {
        if (
          (contact.status === "aktiv" || contact.status.includes("index")) &&
          contact.email
        ) {
          const salutation = `Guten Tag ${contact.salutation} ${contact.lastName},`;
          emails.push({
            mailaddress: contact.email,
            salutation,
          });
        }
      });

      this.filteredEmail = emails;
    },
    extractPhoneNumbers() {
      const phoneNumbers = [];

      // Add central phone numbers
      if (this.customer?.addressAndCommunication?.phone1) {
        phoneNumbers.push({
          value: this.customer.addressAndCommunication.phone1,
          label: "Zentrale",
        });
      }
      if (this.customer?.addressAndCommunication?.phone2) {
        phoneNumbers.push({
          value: this.customer.addressAndCommunication.phone2,
          label: "Telefon 2",
        });
      }
      // Add phone numbers from contacts
      this.customer?.contacts.forEach((contact) => {
        if (
          (contact.status === "aktiv" || contact.status.includes("index")) &&
          contact.phone
        ) {
          const label = `${contact.salutation} ${contact.firstName} ${
            contact.lastName
          } ${contact.role || ""}`.trim();
          phoneNumbers.push({
            value: contact.phone,
            label,
          });
        }
      });

      this.filteredPhoneNumbers = phoneNumbers;
    },
    async generateAndSetCustomerTags() {
      if (this.customer) {
        const customerDataAndJobAdList = this.generateCustomerSummary();
        const customer = this.customer;

        try {
          let jobAdTags: string[] = [];
          let historicalAssignmentsTags: string[] = [];

          if (customer.jobAds && customer.jobAds.length > 0) {
            const aiService = new AiService();
            jobAdTags = await aiService.generateCustomerTags(
              customerDataAndJobAdList
            );
          }

          if (customer.assignments && customer.assignments.length > 0) {
            const historicalAssignments =
              await AllErpAdapter.getHistoricalAssignments(
                customer.customerNumber,
                customer.mandants[0]
              );
            if (historicalAssignments && historicalAssignments.length > 0) {
              historicalAssignmentsTags = historicalAssignments.map(
                (assignment: Assignment) =>
                  assignment.jobTitle.replace(/\/in/g, "")
              );
            }
          }
          const tagsSet = new Set([...jobAdTags, ...historicalAssignmentsTags]);
          const tags = Array.from(tagsSet);
          customer.tags = tags;
          if (tags.length > 0) await this.updateCustomer(customer);
        } catch (error) {
          console.error("Error generating customer tags:", error);
        }
      }
    },
    generateCustomerSummary() {
      const industries = this.customer?.furtherInformation?.industries
        ? this.customer.furtherInformation.industries.join(", ")
        : "N/A";
      const employeeCount =
        this.customer?.jobAds?.[0]?.company?.employees ?? "N/A";
      const jobPositions = this.customer?.jobAds
        ? this.customer.jobAds
            .map((ad) => ad.jobTitle)
            .filter((value, index, self) => self.indexOf(value) === index)
            .join(", ")
        : "N/A";
      return `
    Branche des Kunden: ${industries}
    Mitarbeiteranzahl: ${employeeCount}
    Aktuell ausgeschriebene Stellenanzeigen: ${jobPositions}
  `;
    },
    async generateProfileFromDependencies() {
      this.isProfileGeneratorActive = true;
      this.$nextTick().then(() => {
        if (this.$refs.profileGeneratorComponent) {
          (
            this.$refs.profileGeneratorComponent as InstanceType<
              typeof ProfileGenerator
            >
          ).checkForExisitingProfiles();
        }
      });
    },
    async getAssignmentsFromErp(customerNumber: string) {
      if (!this.customer) return;
      const customer = this.customer;
      const mandant = this.customer.mandants[0];
      const assignments = await AllErpAdapter.getAssignments(
        customerNumber,
        mandant
      );
      if (assignments) {
        customer.assignments = assignments;
        this.updateCustomer(customer);
      }
    },
    async getContactsAndAssignmentsFromErp(customerNumber: string) {
      if (!this.customer) return;
      const customer = this.customer;

      const response = await AllErpAdapter.getContactsAndAssignments(
        customerNumber
      );

      if (response && response.contacts) {
        const extractedContacts = response.contacts;

        extractedContacts.forEach((newContact: CustomerContact) => {
          const contactExists = this.customer?.contacts.some(
            (existingContact) =>
              existingContact.firstName === newContact.firstName &&
              existingContact.lastName === newContact.lastName
          );

          if (!contactExists) {
            customer.contacts.push(newContact);
          }
        });
        customer.assignments = response.assignments;
        await this.updateCustomer(customer);
      }
    },
    async getContactsFromErp(customerNumber: string) {
      if (!this.customer) return;
      const customer = this.customer;

      const extractedContacts = await AllErpAdapter.getContacts(customerNumber);

      if (extractedContacts && extractedContacts.length > 0) {
        extractedContacts.forEach((newContact: CustomerContact) => {
          const contactExists = this.customer?.contacts.some(
            (existingContact) =>
              existingContact.firstName === newContact.firstName &&
              existingContact.lastName === newContact.lastName
          );

          if (!contactExists) {
            customer?.contacts.push(newContact);
          }
        });

        await this.updateCustomer(customer);
      }
    },
    getItemHeight(): string {
      const { fullyExpanded, expanded, collapsed } = this.vStyle.itemHeight;

      if (this.isFullyExpanded) return fullyExpanded;
      if (this.isExpanded) return expanded;

      return collapsed;
    },
    handleCheckMatch(payload: any) {
      const customerPostalCode =
        this.customer?.addressAndCommunication?.postalCode;

      if (
        customerPostalCode &&
        payload.postcodes.includes(customerPostalCode)
      ) {
        const perfectmatch = (this.candidatesTagsMatching =
          this.checkTagsMatching(
            payload.tags || [],
            this.customer?.tags || []
          ));
        if (perfectmatch) {
          this.item.border = "secondary lg";
          this.item.color = "cardPerfectMatch";
          this.item.colorList = "cardPerfectMatch";
          this.item.hoverColor = "perfectMatchHover";
        } else {
          this.item.border = "primary lg";
          this.item.color = "cardMatch";
          this.item.colorList = "cardMatch";
          this.item.hoverColor = "matchHover";
        }
      } else {
        this.item.elevation = 2;
        this.item.border = this.vStyle.boardItem.border;
        this.item.color = "card";
        this.item.colorList = undefined;
        this.item.hoverColor = "cardHover";
        this.candidatesTagsMatching = false;
      }
    },
    async handleDragover(event: DragEvent) {
      if (this.isCheckingDependency) return;
      event.preventDefault();

      const customerId = this.customer?._id;
      if (!customerId) return;
      const candidateId =
        this.$store.state.isDraggingItem.candidate.candidateData?._id ??
        undefined;
      const demandId = this.$store.state.isDraggingItem.demand._id ?? undefined;
      if (candidateId) {
        this.isCheckingDependency = true;
        try {
          const dependency: any =
            await this.linkingService.checkDependencyCandidateCustomer(
              candidateId,
              customerId
            );
          if (dependency.eventType) {
            const formattedDates = dependency.eventDate
              .map((date: string) =>
                moment(date).format("DD.MM.YYYY [um] HH:mm")
              )
              .join(", ");

            const message = `${dependency.eventType} am ${formattedDates}`;
            TooltipService.showItemDragoverMessage(event, message);
          }
        } catch (error) {
          console.error("Fehler bei der Überprüfung der Abhängigkeit:", error);
        }
      } else if (demandId) {
        const hasLinking = this.$store.getters.hasLinkingForDemand(demandId);
        if (hasLinking) {
          this.isCheckingDependency = true;
          try {
            const dependency: any =
              await this.linkingService.checkDependencyCustomerDemand(
                customerId,
                demandId
              );
            let message = "";
            if (dependency.response._id) {
              message = `<i class="fa-solid fa-trash-can"></i>`;
            } else {
              message = `<i class="fa-solid fa-ban"></i>`;
            }
            TooltipService.showItemDragoverMessage(event, message);
          } catch (error) {
            console.error(
              "Fehler bei der Überprüfung der Abhängigkeit:",
              error
            );
          }
        }
      }
      setTimeout(() => {
        this.isCheckingDependency = false;
      }, 3000);
    },
    handleDoubleClickOnItem() {
      if (!this.isExpanded) {
        this.toggleExpansion();
      } else if (this.isExpanded && !this.isFullyExpanded) {
        this.toggleFullExpansion();
      } else if (this.isFullyExpanded) {
        this.toggleExpansion();
      }
    },
    async handleDragStart(event: DragEvent) {
      if (event.dataTransfer) {
        event.dataTransfer.setData(
          "application/originComponent",
          Role.customer as string
        );
        const customer = this.customer;
        this.$store.commit("SET_DRAGGING_ITEM", {
          type: Role.customer,
          data: customer,
        });
      }
    },
    async handleDrop(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer) {
        const originComponent = event.dataTransfer.getData(
          "application/originComponent"
        );
        this.isDependenciesActive = true;
        if (originComponent === Role.candidate) {
          const draggingData = this.$store.state.isDraggingItem
            .candidate as CandidateToCustomerData;

          this.handleLinkCandidateToCustomer(draggingData);
        } else if (originComponent === Role.demand) {
          const demandToLink = this.$store.state.isDraggingItem.demand;
          this.handleLinkDemandToCustomer(demandToLink);
        }
        this.$nextTick().then(() => {
          this.$store.commit("CLEAR_DRAGGING_ITEM");
        });
      }
    },
    handleLinkCandidateToCustomer(
      draggingData: CandidateToCustomerData,
      demand?: Demand
    ) {
      if (!this.customer) return;
      const positions = new Set<string>();

      const uniqueAndFilteredJobAds = (this.customer.jobAds || []).reduce(
        (uniqueAds: ProfileJobAd[], jobAd) => {
          if (!positions.has(jobAd.jobTitle)) {
            uniqueAds.push({
              jobTitle: jobAd.jobTitle,
              jobCategory: Array.isArray(jobAd.jobCategory)
                ? jobAd.jobCategory
                : [],
              jobAdText: jobAd.jobAdText || "",
            });
            positions.add(jobAd.jobTitle);
          }
          return uniqueAds;
        },
        []
      );
      let demandJobAds = [] as ProfileJobAd[];
      if (demand) {
        demandJobAds = demand.demands.reduce(
          (uniqueAds: ProfileJobAd[], demandItem) => {
            if (!positions.has(demandItem.position)) {
              uniqueAds.push({
                jobTitle: demandItem.position,
                jobCategory: [DemandStateLabels.Active],
                jobAdText: `${demandItem.description} ${
                  demandItem.additionalInformation
                } ${demandItem.requirements.join(", ")}`,
              });
              positions.add(demandItem.position);
            }
            return uniqueAds;
          },
          []
        );
      }
      this.linkingDescription = {
        firstName: draggingData.candidateData.firstName,
        lastName: draggingData.candidateData.lastName,
        postCode: draggingData.candidateData.addressPostalCode,
        city: draggingData.candidateData.addressCity,
        customerName: this.customer.generalData.name,
        customerPostCode: this.customer.addressAndCommunication.postalCode,
        customerCity: this.customer.addressAndCommunication.city,
        uuid: draggingData.candidateData.uuid,
      } as LinkingDescription;

      this.profileSourceData = {
        age: draggingData.age,
        anonymizedResume: draggingData.anonymizedResume,
        appliedAs: draggingData.appliedAs,
        candidateId: draggingData.candidateData._id ?? "",
        candidateUuid: draggingData.candidateData.uuid,
        careerSteps: draggingData.candidateData.careerSteps,
        city: draggingData.candidateData.addressCity,
        demands: demandJobAds,
        filteredEmail: this.filteredEmail,
        firstName: draggingData.candidateData.firstName,
        jobAds: uniqueAndFilteredJobAds,
        lastName: draggingData.candidateData.lastName,
        licences: draggingData.licences,
        mandant: this.customer.mandants[0],
        mobility: `${draggingData.candidateData.mobility}<br>${draggingData.candidateData.mobilityRadius}km`,
        notes: draggingData.notes,
        profileNumber: draggingData.profileNumber,
        profiles: draggingData.candidateData.profiles,
        salutation: draggingData.candidateData.shiftPreference,
        shiftReadiness: draggingData.candidateData.shiftPreference,
        tags: this.customer?.tags || [],
      } as ProfileSourceData;

      const candidateId = draggingData.candidateData._id;
      if (this.$refs.dependenciesComponent && candidateId) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).linkCandidateToCustomer(candidateId);
        this.isExpanded = true;
      }
      nextTick().then(() => {
        this.$store.commit("CLEAR_DRAGGING_ITEM");
      });
    },
    async handleLinkDemandToCustomer(demand: Demand) {
      const customerId = this.customer?._id ?? undefined;
      const mandants = this.customer?.mandants ?? undefined;
      const hasLinking = this.$store.getters.hasLinkingForDemand(demand._id);
      if (!demand._id || !customerId) return;

      if (hasLinking) {
        const existingLinking =
          await this.linkingService.checkDependencyCustomerDemand(
            customerId,
            demand._id
          );
        const existingLinkingId = existingLinking.response?._id ?? undefined;

        if (hasLinking && existingLinkingId) {
          const confirmed = await DialogService.confirm(
            `Die Anfrage "${demand.demands[0].position}" ist bereits mit dem Kunden verlinkt! Löschen?`,
            "Nein",
            "Löschen"
          );
          if (!confirmed) {
            return;
          }
        } else if (hasLinking && !existingLinkingId) {
          ToastService.showError(
            "Es existiert bereits eine Verlinkung mit einem anderen Kunden!"
          );
          return;
        }
      }

      const linkingData = {
        mandants: mandants,
        linkingCore: {
          customer: customerId,
          demand: demand._id,
        },
        events: [
          {
            eventType: LinkingStatus.demand,
            eventDate: [moment().toISOString()],
          },
        ],
        description: {
          position: demand.demands[0].position,
          postCode: demand.demands[0].location.postcode,
          city: demand.demands[0].location.city,
          customerName: this.customer?.generalData.name,
          customerPostCode: this.customer?.addressAndCommunication.postalCode,
          customerCity: this.customer?.addressAndCommunication.city,
        },
      } as Linking;
      if (this.$refs.dependenciesComponent && customerId && mandants) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).linkDemandtoCustomer(linkingData);
        this.isExpanded = true;
      }
    },
    async handleModalSubmit({
      input1,
      input2,
    }: {
      input1: string;
      input2: string;
    }) {
      this.addCustomerModalInput1 = input1;
      this.addCustomerModalInput2 = input2;
      await this.submitModal();
    },
    handleMouseEnter(event: any) {
      const rect = event.target.getBoundingClientRect();
      this.jobAdListPosition = {
        x: rect.left + window.pageXOffset,
        y: rect.top + window.pageYOffset,
      };

      if (this.$refs.jobAdListRef) {
        (
          this.$refs.jobAdListRef as InstanceType<typeof JobAdListCustomer>
        ).show();
        (
          this.$refs.jobAdListRef as InstanceType<typeof JobAdListCustomer>
        ).cancelClose();
      }
    },
    handleSelectedResult(selectedResult: Customer) {
      SpinnerService.showSpinner();
      this.selectResult(selectedResult).then(() =>
        SpinnerService.removeSpinner()
      );
    },
    hasAssignments() {
      const hasAssignments =
        Array.isArray(this.customer?.assignments) &&
        this.customer.assignments.length > 0;
      return hasAssignments;
    },
    hasContactWithPhoneAndEmail() {
      return this.customer?.contacts.some(
        (contact) => contact.phone && contact.email
      );
    },
    async initAssignmentsAndContactsIfEmpty() {
      if (
        this.isNumeric(this.customer?.customerNumber) &&
        this.customer?.generalData?.costCenter != null
      ) {
        if (
          !this.hasContactWithPhoneAndEmail() &&
          !this.isTriedToFetchContacts &&
          !this.hasAssignments() &&
          !this.isTriedToFetchAssignments
        ) {
          this.isTriedToFetchAssignments = true;
          this.isTriedToFetchContacts = true;
          await this.getContactsAndAssignmentsFromErp(
            this.customer?.customerNumber || ""
          );
        } else if (
          !this.hasContactWithPhoneAndEmail() &&
          !this.isTriedToFetchContacts
        ) {
          this.isTriedToFetchContacts = true;
          await this.getContactsFromErp(this.customer?.customerNumber || "");
        } else if (!this.hasAssignments() && !this.isTriedToFetchAssignments) {
          this.isTriedToFetchAssignments = true;
          await this.getAssignmentsFromErp(this.customer?.customerNumber || "");
        }
      }
    },
    isNumeric(value: string | null | undefined): boolean {
      return /^\d+$/.test(value ?? "");
    },
    isNotPdHubCustomerNumber() {
      if (!this.softwareIntegration?.pdHub) return false;
      return (
        this.softwareIntegration.pdHub &&
        (!this.isNumeric(this.customer?.customerNumber) ||
          this.customer?.generalData?.costCenter == null)
      );
    },
    isNotZvooveCustomerNumber() {
      if (!this.softwareIntegration?.zvooveOne) return false;
      return (
        this.softwareIntegration.zvooveOne &&
        (!this.isNumeric(this.customer?.customerNumber) ||
          this.customer?.generalData?.costCenter == null)
      );
    },
    isLastEditOlderThanOneDay() {
      if (!this.customer?.lastEdit?.data) {
        return true;
      }
      const lastEditDate = moment(
        this.customer.lastEdit.data,
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      const oneDayAgo = moment().subtract(1, "days");
      return lastEditDate.isBefore(oneDayAgo);
    },
    mandantName() {
      let mandantName = "Unbekannter Mandant";
      const mandantUuid = this.customer?.mandants[0];

      if (mandantUuid) {
        mandantName = this.$store.getters.getMandantNameByUuid(mandantUuid);
      }

      return mandantName;
    },
    openMailClientFromProfileGenerator(email: any, candidateTimelineData: any) {
      this.isProfileGeneratorActive = false;
      this.isMailClientActive = true;
      this.$nextTick().then(() => {
        this.email = email;
        this.email.subject = `Neues Profil #${this.profileSourceData.profileNumber}`;
        if (this.$refs.mailClientComponent) {
          (
            this.$refs.mailClientComponent as InstanceType<typeof MailClient>
          ).openModal(candidateTimelineData);
        }
      });
    },
    openPhoneClient() {
      this.isExpanded = true;
      this.$nextTick().then(() => {
        if (this.$refs.phoneClientComponent) {
          (
            this.$refs.phoneClientComponent as InstanceType<typeof PhoneClient>
          ).handleOutgoingCall();
        }
      });
    },
    openUpdateCustomerModal(context: string) {
      //TODO: Add timeline entry "SYSTEM"
      switch (context) {
        case ModalMode.scrapeContactsFromSite:
          this.modalTitle = "Ansprechpartner von Internetseite holen";
          this.modalType = ModalMode.scrapeContactsFromSite;
          this.addCustomerModalLabel1 = "Link zur Ansprechpartnern";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "Extrahieren";
          this.showSearchResultsFromErp = false;
          break;
        case ModalMode.zvooveOne:
          this.modalTitle = "Unternehmen in ERP One suchen";
          this.modalType = ModalMode.zvooveOne;
          this.addCustomerModalLabel1 = "Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "in ERP One suchen";
          this.showSearchResultsFromErp = true;
          break;
        case ModalMode.pdHub:
          this.modalTitle = "Unternehmen in PD-Hub suchen";
          this.modalType = ModalMode.pdHub;
          this.addCustomerModalLabel1 = "Kundennummer";
          this.addCustomerModalInput1 = "";
          this.addCustomerModalLabel2 = "";
          this.modalActionButtonText = "im PD-Hub suchen";
          this.showSearchResultsFromErp = true;
          break;
        case ModalMode.indexAnzeigendaten:
          this.modalTitle = "Unternehmen in Index Anzeigendaten suchen";
          this.modalType = ModalMode.indexAnzeigendaten;
          this.addCustomerModalLabel1 = "Postleitzahl";
          this.addCustomerModalInput1 = this.customer?.addressAndCommunication
            .postalCode as string;
          this.addCustomerModalLabel2 = "Firmenname / Suchbegriff";
          this.addCustomerModalInput2 = this.customer?.generalData
            .name as string;
          this.modalActionButtonText = "in Index suchen";
          this.showSearchResultsFromErp = false;
          break;
      }
      this.showModal = true;
    },
    phoneClientIsDialing() {
      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).waitForPhoneClientDialing();
      }
    },
    removeTag(index: number) {
      if (!this.customer) return;
      const customer = this.customer;
      if (customer && Array.isArray(customer.tags)) {
        customer.tags.splice(index, 1);
      }
      this.updateCustomer(customer);
    },
    async saveMandantSelection(selectedMandantUuid: string): Promise<void> {
      if (!this.customer) return;
      const customer = this.customer;
      customer.mandants[0] = selectedMandantUuid;
      await this.updateCustomer(customer);
    },
    async saveStatusSelection(selectedStatusIndex: string): Promise<void> {
      if (!this.customer) return;
      const customer = this.customer;
      const index = parseInt(selectedStatusIndex, 10);
      const selectedStatus = this.customerStates[index];

      if (!selectedStatus) {
        console.error("Ungültiger Statusindex:", selectedStatusIndex);
        return;
      }

      customer.generalData.status = selectedStatus;
      await this.updateCustomer(customer);
      if (this.isNumeric(customer.customerNumber))
        await AllErpAdapter.changeCustomerStatusInErp(customer);
    },
    scheduleJobAdListClose() {
      if (this.$refs.jobAdListRef) {
        (
          this.$refs.jobAdListRef as InstanceType<typeof JobAdListCustomer>
        ).scheduleClose();
      }
    },
    async selectResult(selectedCustomer: Customer) {
      try {
        if (!this.customer) {
          throw new Error("Kundenobjekt ist nicht initialisiert.");
        }
        const customer = this.customer;

        if (
          selectedCustomer.customerNumber &&
          (this.modalType === ModalMode.zvooveOne ||
            this.modalType === ModalMode.pdHub)
        ) {
          customer.customerNumber = selectedCustomer.customerNumber;
        }

        const updatedCustomer = await this.updateCustomerData(
          customer,
          selectedCustomer
        );
        this.updateCustomer(updatedCustomer);
        this.resultsModal = false;
      } catch (error) {
        console.error("Fehler beim Speichern des ausgewählten Kunden:", error);
      }
    },
    setAppointmentOnDependencies() {
      this.isDependenciesActive = true;
      this.$nextTick(() => {
        if (this.$refs.dependenciesComponent) {
          (
            this.$refs.dependenciesComponent as InstanceType<
              typeof Dependencies
            >
          ).openAppointmentModal();
        }
      });
    },
    showJobAdsIcon() {
      return (
        this.customer?.jobAds &&
        this.customer.jobAds.length > 0 &&
        !this.isExpanded
      );
    },
    async submitModal() {
      this.showModal = false;
      let customer = {} as Customer;
      if (this.customer) customer = this.customer;
      try {
        let responseData = [] as Customer[] | null;
        if (this.modalType === ModalMode.scrapeContactsFromSite) {
          try {
            const linkToScrapeContacts = this.addCustomerModalInput1;
            const companyContacts =
              await ZorstService.getInstance().ZorstOpenSiteAndScrapeText(
                linkToScrapeContacts
              );
            const aiService = new AiService();
            const extractedContacts = await aiService.extractCustomerContacts(
              companyContacts as string
            );

            // Prepare a map or set to check for existing contacts more efficiently
            const existingContactsSet = new Set(
              customer.contacts.map(
                (contact) => `${contact.firstName} ${contact.lastName}`
              )
            );

            // Filter and add only new contacts
            const newContacts = extractedContacts.filter((newContact) => {
              const newContactKey = `${newContact.firstName} ${newContact.lastName}`;
              return !existingContactsSet.has(newContactKey);
            });

            if (newContacts.length > 0) {
              customer.contacts.push(...newContacts);
              await this.updateCustomer(customer);
              ToastService.showSuccess("Ansprechpartner ergänzt");
            }
          } catch (error) {
            // ToastService.showError("Fehler beim Abrufen der Daten: " + error);
          }
        } else if (this.modalType === ModalMode.indexAnzeigendaten) {
          responseData = await this.anzeigedatenService.getCustomerFromIndex(
            this.addCustomerModalInput1,
            this.addCustomerModalInput2
          );
        } else {
          responseData = await AllErpAdapter.getCustomerByNumber(
            this.addCustomerModalInput1,
            this.modalType
          );
        }
        if (responseData && responseData.length > 0) {
          this.results = responseData;
          this.resultsModal = true;
        }
      } catch (error) {
        ToastService.showError("Fehler beim Abrufen der Daten: " + error);
      }
    },
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.isFullyExpanded = false;
      }
      if (this.isExpanded) {
        this.updateCustomerItem();
      }
    },
    toggleFullExpansion() {
      if (this.isExpanded) {
        this.isFullyExpanded = !this.isFullyExpanded;
      }
    },
    updateChecklist(checklist: CheckList[]) {
      if (!this.customer) return;
      const customer = this.customer;
      customer.checklist = checklist;
      this.updateCustomer(customer);
    },
    async updateCustomerFromErp() {
      if (this.customer) {
        try {
          const customerData =
            await AllErpAdapter.getCustomerAndContactsAndAssignments(
              this.customer.customerNumber,
              this.customer.mandants[0]
            );

          if (customerData) {
            const mergedCustomer = this.apiCustomerService.mergeCustomerData(
              this.customer,
              customerData[0]
            );

            await this.updateCustomer(mergedCustomer);
          }
        } catch (error) {
          console.error("Error updating customer from ERP:", error);
        }
      }
    },
    updateCustomerItem() {
      this.autoFetchJobAds();
      this.extractPhoneNumbers();
      this.extractEmailsAndSalutations();
      this.checkIfShouldGenerateTags();

      if (
        this.customer &&
        this.customer.tags &&
        this.customer.tags.length === 0 &&
        this.isLastEditOlderThanOneDay()
      ) {
        this.generateAndSetCustomerTags().then(() => {
          this.initAssignmentsAndContactsIfEmpty();
        });
      } else {
        this.initAssignmentsAndContactsIfEmpty();
      }

      if (this.$refs.dependenciesComponent) {
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).loadLinkingsFromStore();
        (
          this.$refs.dependenciesComponent as InstanceType<typeof Dependencies>
        ).checkForReminders();
      }
    },
    async updateCustomer(customer: Customer) {
      try {
        const apiService = new CustomerService();
        await apiService.editCustomer(customer).then(() => {
          if (!customer.lastEdit) customer.lastEdit = {};
          customer.lastEdit.data = moment().toISOString();
          this.$emit("insertUpdatedCustomer", customer);
        });
      } catch (error) {
        console.error("Error updating customer: ", error);
      }
    },
    updateCustomerData(
      existingCustomer: Customer,
      newCustomerData: Customer
    ): Customer {
      //TODO: Add timeline entry "SYSTEM"
      const sections: (keyof Customer)[] = [
        "generalData",
        "addressAndCommunication",
        "furtherInformation",
        "additionalInfo",
        "reporting",
      ];

      sections.forEach((section) => {
        if (section in newCustomerData && newCustomerData[section] !== null) {
          const existingSection = existingCustomer[section] as Record<
            string,
            any
          >;
          const newSection = newCustomerData[section] as Record<string, any>;

          Object.keys(newSection).forEach((key) => {
            if (
              !(key in existingSection) ||
              existingSection[key] === null ||
              existingSection[key] === ""
            ) {
              existingSection[key] = newSection[key];
            }
          });
        }
      });

      newCustomerData.contacts.forEach((newContact) => {
        const contactExists = existingCustomer.contacts.some(
          (existingContact) => existingContact.email === newContact.email
        );
        if (!contactExists) {
          existingCustomer.contacts.push(newContact);
        }
      });

      if (newCustomerData.reporting) {
        existingCustomer.reporting = newCustomerData.reporting;
      }
      return existingCustomer;
    },
    async updateErpFromCustomer(): Promise<any> {
      const customer = this.customer;

      if (!customer) {
        console.error("Customer is undefined");
        return;
      }
      try {
        await AllErpAdapter.updateErpCustomer(this.customer);
      } catch (error) {
        console.error("Error updating ERP from customer:", error);
      }
    },
  },
});
</script>

<style scoped>
.logo-img {
  max-height: 0.8rem;
  height: auto;
  width: auto;
  margin-right: auto;
}

.job-ad-hover {
  background: none;
  border: none;
  color: grey;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0.75rem;
  text-shadow: var(--text-shadow);
  bottom: 0;
  z-index: 10;
}
.customer-status {
  cursor: pointer;
}
.customer-mandant {
  cursor: pointer;
  font-size: 0.8rem;
}
.index-erp-ats-icon {
  margin-right: 1rem;
  cursor: pointer;
  transition: all 0.5s ease;
  width: 1.7rem;
  height: 1.7rem;
}
.index-erp-ats-icon:hover {
  scale: var(--medium-scale-up);
}
.get-contacts-icon {
  color: grey;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.get-contacts-icon:hover {
  color: var(--color-primary);
  font-size: 1.6rem;
}
</style>
@/services/api/anzeigendaten.service
