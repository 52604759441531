// src/services/tooltip.service.ts

export const TooltipService = {
  getSCSSVariables() {
    const rootStyles = getComputedStyle(document.documentElement);

    return {
      bgDarkColor: rootStyles
        .getPropertyValue("--tooltip-bg-dark-color")
        .trim(),
      bgLightColor: rootStyles
        .getPropertyValue("--tooltip-bg-light-color")
        .trim(),
      textLightColor: rootStyles
        .getPropertyValue("--tooltip-text-light-color")
        .trim(),
      textDarkColor: rootStyles
        .getPropertyValue("--tooltip-text-dark-color")
        .trim(),
      padding: rootStyles.getPropertyValue("--tooltip-padding").trim(),
      borderRadius: rootStyles
        .getPropertyValue("--tooltip-border-radius")
        .trim(),
      zIndex: rootStyles.getPropertyValue("--tooltip-z-index").trim(),
      border: rootStyles.getPropertyValue("--tooltip-border").trim(),
    };
  },

  showTooltip(event: MouseEvent, message: string) {
    const { bgDarkColor, textLightColor, padding, borderRadius, zIndex } =
      this.getSCSSVariables();
    const tooltip = document.createElement("div");
    tooltip.textContent = message;
    tooltip.style.position = "fixed";
    tooltip.style.left = `${event.clientX + 10}px`;
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.backgroundColor = bgDarkColor;
    tooltip.style.color = textLightColor;
    tooltip.style.padding = padding;
    tooltip.style.borderRadius = borderRadius;
    tooltip.style.zIndex = zIndex;
    tooltip.style.pointerEvents = "none";
    tooltip.setAttribute("id", "custom-tooltip");

    document.body.appendChild(tooltip);

    setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 3000);
  },
  showItemDragoverMessage(event: MouseEvent, message: string) {
    const { bgDarkColor, textLightColor, padding, borderRadius, zIndex } =
      this.getSCSSVariables();
    const tooltip = document.createElement("div");

    tooltip.innerHTML = message;
    tooltip.style.position = "fixed";
    tooltip.style.left = `${event.clientX + 10}px`;
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.backgroundColor = bgDarkColor;
    tooltip.style.color = textLightColor;
    tooltip.style.padding = padding;
    tooltip.style.borderRadius = borderRadius;
    tooltip.style.zIndex = zIndex;
    tooltip.style.pointerEvents = "none";

    tooltip.setAttribute("id", "custom-tooltip");

    document.body.appendChild(tooltip);

    setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 3000);
  },
  showExpiredEvents(event: MouseEvent, htmlContent: string) {
    const tooltip = document.createElement("div");
    const {
      bgLightColor,
      textDarkColor,
      padding,
      border,
      borderRadius,
      zIndex,
    } = this.getSCSSVariables();
    tooltip.innerHTML = htmlContent;
    tooltip.style.position = "fixed";
    tooltip.style.visibility = "hidden";
    tooltip.style.backgroundColor = bgLightColor;
    tooltip.style.color = textDarkColor;
    tooltip.style.padding = padding;
    tooltip.style.borderRadius = borderRadius;
    tooltip.style.border = border;
    tooltip.style.zIndex = zIndex;
    tooltip.style.pointerEvents = "none";
    tooltip.setAttribute("id", "custom-tooltip");

    document.body.appendChild(tooltip);

    const tooltipWidth = tooltip.offsetWidth;
    let leftPosition = event.clientX - tooltipWidth - 10;

    if (leftPosition < 0) {
      leftPosition = 10;
    }

    tooltip.style.left = `${leftPosition}px`;
    tooltip.style.top = `${event.clientY + 10}px`;
    tooltip.style.visibility = "visible";

    /* setTimeout(() => {
      document.body.removeChild(tooltip);
    }, 3000); */
  },
};
