import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1e8199e3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headline" }
const _hoisted_2 = {
  key: 1,
  class: "d-flex"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "headline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_EmailServerConfigForm = _resolveComponent("EmailServerConfigForm")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showDialog) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xl)',
      class: "xs12 md6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.editMode ? "Niederlassung bearbeiten" : "Neue Niederlassung anlegen"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, {
                  ref: "form",
                  onSubmit: _withModifiers(_ctx.saveMandant, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { noGutters: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6",
                          class: "d-flex"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.newMandant.uuid,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMandant.uuid) = $event)),
                              label: "Uuid",
                              disabled: _ctx.editMode,
                              required: "",
                              class: "ma-1"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "disabled"]),
                            (_ctx.showMandantImportFromAtsRecruitButton())
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 0,
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getAtsMandants())),
                                  icon: "",
                                  variant: "text"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("fa-solid fa-magnifying-glass")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_tooltip, {
                                      activator: "parent",
                                      location: "bottom"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Mandanten aus ATS Recruit abrufen")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_menu, { activator: "parent" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list, null, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.atsRecruitMandants, (atsMandant, index) => {
                                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                                key: index,
                                                value: index
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_list_item_title, {
                                                    onClick: ($event: any) => (_ctx.selectAtsMandant(atsMandant))
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(atsMandant.Bezeichnung), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["onClick"])
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.newMandant.name,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newMandant.name) = $event)),
                              rules: _ctx.nameRules,
                              label: "Name",
                              required: "",
                              class: "ma-1"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: _ctx.softwareIntegration.pdHub ? '2' : '3'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.newMandant.branchNumber,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newMandant.branchNumber) = $event)),
                              label: "NL Nr.",
                              required: "",
                              class: "ma-1"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["md"]),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: _ctx.softwareIntegration.pdHub ? '2' : '3'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.newMandant.branchInitials,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newMandant.branchInitials) = $event)),
                              label: "Initialen",
                              required: "",
                              class: "ma-1"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                          ]),
                          _: 1
                        }, 8, ["md"]),
                        (_ctx.softwareIntegration.pdHub)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "12",
                              md: "2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.newMandant.mandantL1,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newMandant.mandantL1) = $event)),
                                  label: "Mandant L1",
                                  class: "ma-1"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.newMandant.whatsApp,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newMandant.whatsApp) = $event)),
                              rules: _ctx.phoneRules,
                              label: "WhatsApp",
                              required: "",
                              class: "ma-1"
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (_ctx.$store.state.company.softwareIntegration.zvooveRecruit)
                      ? (_openBlock(), _createBlock(_component_v_card_subtitle, {
                          key: 0,
                          class: "my-3"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Konfiguration ATS Recruit:")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.$store.state.company.softwareIntegration.zvooveRecruit)
                      ? (_openBlock(), _createBlock(_component_v_row, {
                          key: 1,
                          noGutters: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.newMandant.zvoovename,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newMandant.zvoovename) = $event)),
                                  rules: _ctx.nameRules,
                                  label: "Name Mandant im ATS",
                                  required: "",
                                  class: "ma-1"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.newMandant.EmailEingangskontoId,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newMandant.EmailEingangskontoId) = $event)),
                                  label: "EmailEingangskontoId",
                                  required: "",
                                  class: "ma-1"
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "5"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_textarea, {
                              variant: _ctx.vStyle.input.variant || undefined,
                              rounded: _ctx.vStyle.input.rounded || undefined,
                              "base-color": _ctx.vStyle.input.baseColor || undefined,
                              color: _ctx.vStyle.input.color || undefined,
                              modelValue: _ctx.newMandant.contact,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newMandant.contact) = $event)),
                              label: "Kontakt (Html)",
                              rows: "8",
                              required: ""
                            }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "7"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, { class: "mb-5" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Konfiguration Email Server")
                              ]),
                              _: 1
                            }),
                            (!_ctx.newMandant.mailServer)
                              ? (_openBlock(), _createBlock(_component_v_btn, {
                                  key: 0,
                                  class: "ml-2",
                                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.addMailserverConfig()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_icon, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("fas fa-plus")
                                      ]),
                                      _: 1
                                    }),
                                    _createTextVNode("Mailserver Hinzufügen")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.newMandant.mailServer)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createVNode(_component_EmailServerConfigForm, {
                                    modelValue: _ctx.newMandant.mailServer
                                  }, null, 8, ["modelValue"]),
                                  _createVNode(_component_v_btn, {
                                    icon: "",
                                    variant: "text",
                                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.removeMailserverConfig()))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_icon, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("fa-solid fa-trash-can")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          md: "12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_card_subtitle, null, {
                              default: _withCtx(() => [
                                _createTextVNode("zugeordnete Postleitzahlen für Bewerbungszuordnung von Landingpage")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_chip_group, { column: "" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newMandant.postalcodes, (postcode, index) => {
                                  return (_openBlock(), _createBlock(_component_v_chip, {
                                    color: "primary",
                                    key: index,
                                    close: "",
                                    "onClick:close": ($event: any) => (_ctx.removePostcode(index)),
                                    onClick: ($event: any) => (_ctx.openEditPostcodeModal(index))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(postcode) + " ", 1),
                                      _createVNode(_component_v_icon, {
                                        small: "",
                                        onClick: _withModifiers(($event: any) => (_ctx.removePostcode(index)), ["stop"]),
                                        class: "ml-2"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" fa-solid fa-times ")
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick:close", "onClick"]))
                                }), 128)),
                                _createVNode(_component_v_icon, {
                                  onClick: _ctx.openAddPostcodeModal,
                                  color: "primary",
                                  class: "mt-2"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" fa-solid fa-plus ")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        (_ctx.saveError)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.saveError), 1))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.closeDialog,
                          color: "abort"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Abbrechen")
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          type: "submit",
                          color: "success"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Speichern")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onSubmit"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.showPostcodeModal,
      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.showPostcodeModal) = $event)),
      "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_card_title, null, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.editPostcodeIndex !== null
            ? "Postleitzahl bearbeiten"
            : "Neue Postleitzahl hinzufügen"), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_form, { ref: "postcodeForm" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      variant: _ctx.vStyle.input.variant || undefined,
                      rounded: _ctx.vStyle.input.rounded || undefined,
                      "base-color": _ctx.vStyle.input.baseColor || undefined,
                      color: _ctx.vStyle.input.color || undefined,
                      modelValue: _ctx.postcodeInput,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.postcodeInput) = $event)),
                      rules: _ctx.postcodeRules,
                      label: "Postleitzahl",
                      required: ""
                    }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            }),
            _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [
                (_ctx.editPostcodeIndex !== null)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      onClick: _ctx.closePostcodeModal,
                      color: "abort"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Abbrechen")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  onClick: _ctx.savePostcode,
                  color: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Speichern")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            (_ctx.editPostcodeIndex === null)
              ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_divider),
                    _createVNode(_component_v_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("oder Umkreissuche")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "8"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.postcodeInputCenter,
                                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.postcodeInputCenter) = $event)),
                                  rules: _ctx.postcodeRules,
                                  label: "zentrale Postleitzahl",
                                  required: ""
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_text_field, {
                                  variant: _ctx.vStyle.input.variant || undefined,
                                  rounded: _ctx.vStyle.input.rounded || undefined,
                                  "base-color": _ctx.vStyle.input.baseColor || undefined,
                                  color: _ctx.vStyle.input.color || undefined,
                                  modelValue: _ctx.zipcodeRadius,
                                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.zipcodeRadius) = $event)),
                                  label: "Radius in km",
                                  rules: _ctx.radiusRules,
                                  required: ""
                                }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue", "rules"]),
                                _createVNode(_component_v_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.zipcodeRadius) + " kilometer ", 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_slider, {
                          modelValue: _ctx.zipcodeRadius,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.zipcodeRadius) = $event)),
                          max: _ctx.zipcodeMax,
                          min: _ctx.zipcodeMin,
                          step: 1,
                          "thumb-label": "",
                          class: "align-center",
                          "hide-details": ""
                        }, null, 8, ["modelValue", "max", "min"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.closePostcodeModal,
                          color: "abort"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Abbrechen")
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.searchPostcodes,
                          color: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Suchen")
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "max-width"])
  ], 64))
}