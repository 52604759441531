import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: _normalizeClass(["d-flex align-center", _ctx.mdAndDown ? 'flex-row' : 'flex-column']),
    width: _ctx.mdAndDown ? '100vw' : '5rem',
    height: _ctx.mdAndDown ? '3rem' : '100%',
    color: "primary-darken-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        size: _ctx.mdAndDown ? 'large' : 'x-large',
        class: _normalizeClass(_ctx.mdAndDown ? 'ml-10 mr-15' : 'mt-10 mb-15')
      }, {
        default: _withCtx(() => [
          _createTextVNode("fas fa-home")
        ]),
        _: 1
      }, 8, ["size", "class"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'ml-10 mr-5' : 'mt-10 mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : '3rem',
        color: 
        _ctx.$route.path === _ctx.Routes.dashboard ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.dashboard)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : 'x-large'
          }, {
            default: _withCtx(() => [
              _createTextVNode("fas fa-tachometer-alt")
            ]),
            _: 1
          }, 8, ["size"])
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : '3rem',
        color: 
        _ctx.$route.path === _ctx.Routes.recruiting ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.recruiting)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : 'x-large'
          }, {
            default: _withCtx(() => [
              _createTextVNode("fas fa-people-arrows")
            ]),
            _: 1
          }, 8, ["size"])
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : '3rem',
        color: 
        _ctx.$route.path === _ctx.Routes.dispostion ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.dispostion)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : 'x-large'
          }, {
            default: _withCtx(() => [
              _createTextVNode("fas fa-headset")
            ]),
            _: 1
          }, 8, ["size"])
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      _createVNode(_component_v_sheet, {
        class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
        width: _ctx.mdAndDown ? '5rem' : '100%',
        height: _ctx.mdAndDown ? '100%' : '3rem',
        color: 
        _ctx.$route.path === _ctx.Routes.administration ? 'secondary' : 'primary-darken-1'
      ,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.administration)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, {
            class: "menu-icon",
            size: _ctx.mdAndDown ? 'large' : 'x-large'
          }, {
            default: _withCtx(() => [
              _createTextVNode("far fa-folder-open")
            ]),
            _: 1
          }, 8, ["size"])
        ]),
        _: 1
      }, 8, ["class", "width", "height", "color"]),
      (_ctx.$store.state.company.softwareIntegration.workTime)
        ? (_openBlock(), _createBlock(_component_v_sheet, {
            key: 0,
            class: _normalizeClass(["d-flex justify-center align-center", _ctx.mdAndDown ? 'mr-5' : 'mb-5']),
            width: _ctx.mdAndDown ? '5rem' : '100%',
            height: _ctx.mdAndDown ? '100%' : '3rem',
            color: 
        _ctx.$route.path === _ctx.Routes.worktime ? 'secondary' : 'primary-darken-1'
      ,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.navigateTo(_ctx.Routes.worktime)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                class: "menu-icon",
                size: _ctx.mdAndDown ? 'large' : 'x-large'
              }, {
                default: _withCtx(() => [
                  _createTextVNode("far fa-clock")
                ]),
                _: 1
              }, 8, ["size"])
            ]),
            _: 1
          }, 8, ["class", "width", "height", "color"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "width", "height"]))
}