<template>
  <div>
    <div>
      <transition name="fade">
        <img
          v-if="linkAnimation142x120"
          :src="linkAnimation142x120"
          class="size1"
          @click="hideImage"
        />
      </transition>
      <transition name="fade">
        <img
          v-if="linkAnimation296x120"
          :src="linkAnimation296x120"
          class="size2"
          @click="hideImage"
        />
      </transition>
      <transition name="fade">
        <img
          v-if="linkAnimation296x60"
          :src="linkAnimation296x60"
          class="size3"
          @click="hideImage"
        />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SpinnerService } from "@/services/spinner.service";

export default defineComponent({
  name: "SpinnerElementHeader",
  data() {
    return {
      linkAnimation142x120: "",
      linkAnimation296x120: "",
      linkAnimation296x60: "",
    };
  },
  watch: {
    "$store.state.headerSpinner": {
      handler() {
        this.updateImage();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    hideImage() {
      SpinnerService.clearHeaderSpinnerElement();
    },
    updateImage() {
      const newVal = this.$store.state.headerSpinner;
      if (newVal && newVal.gif) {
        if (newVal.size === 0) {
          this.linkAnimation142x120 = require(`@/assets/${newVal.gif}`);
          this.linkAnimation296x120 = "";
          this.linkAnimation296x60 = "";
        } else {
          this.linkAnimation142x120 = "";
          this.linkAnimation296x120 = "";
          this.linkAnimation296x60 = require(`@/assets/${newVal.gif}`);
        }
      } else {
        this.linkAnimation142x120 = "";
        this.linkAnimation296x120 = "";
        this.linkAnimation296x60 = "";
      }
    },
  },
});
</script>

<style scoped>
.size1,
.size2,
.size3 {
  transition: opacity 0.5s ease;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.size1 {
  width: 142px;
  height: 120px;
}
.size2 {
  width: 296px;
  height: 120px;
}
.size3 {
  width: 296px;
  height: 60px;
}
</style>
