import { Email } from "@/models/email.model";
import { Api } from "./api.class";
import { ApiResponse } from "@/models/api-response.model";
import { blobToBase64 } from "@/helper/pdf.helper";

export class EmailService {
  private api = Api.getInstance();
  private urlPath = "/email";

  async sendMail(message: Email, file: Array<File>): Promise<ApiResponse> {
    const formData = new FormData();
    if (message.pdf && message.pdf.data) {
      message.pdf.data = await blobToBase64(message.pdf.data as Blob);
    }
    formData.append("request", JSON.stringify(message));
    if (file) {
      formData.append("file", file[0]);
    }
    const response = await this.api
      .getBackendApi()
      .post<ApiResponse>(`${this.urlPath}`, formData);
    return response.data;
  }

  async sendEmailValidation(id: number) {
    const response = await this.api
      .getBackendApi()
      .post(`${this.urlPath}/validation/${id}`);
    return response.data;
  }
}
