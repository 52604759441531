import mitt from "mitt";

// Define event types and their payloads
type Events = {
  [key: string]: any; // Generic events with arbitrary payloads
};

export enum EventBusEvent {
  userLoggedOut,
}

export class EventBusService {
  private static instance: EventBusService; // Singleton instance
  private emitter = mitt<Events>(); // Mitt event bus
  // Map to store callbacks for each event
  private callbacks: Map<EventBusEvent, (...args: any[]) => void> = new Map();

  // Get the singleton instance
  public static getInstance(): EventBusService {
    if (!EventBusService.instance) {
      EventBusService.instance = new EventBusService();
    }
    return EventBusService.instance;
  }

  // Publish an event with optional payload
  public publish(event: EventBusEvent, payload?: any): void {
    this.emitter.emit(EventBusEvent[event], payload);
  }

  // Subscribe to an event with a callback
  public notify(event: EventBusEvent): void {
    this.emitter.on(EventBusEvent[event], this.getEventCallback(event));
  }

  // Unsubscribe from an event
  public unsubscribe(event: EventBusEvent): void {
    this.emitter.off(EventBusEvent[event], this.getEventCallback(event));
  }

  // Method to set a callback for a specific event
  public setEventCallback(
    event: EventBusEvent,
    callback: (...args: any[]) => void
  ): void {
    this.callbacks.set(event, callback);
  }

  // Method to get the callback for a specific event
  public getEventCallback(event: EventBusEvent): (...args: any[]) => void {
    const callback = this.callbacks.get(event);
    if (!callback) {
      throw new Error(`No callback defined for event: ${EventBusEvent[event]}`);
    }
    return callback;
  }

  // Optional: Method to check if a callback is registered for an event
  public hasEventCallback(event: EventBusEvent): boolean {
    return this.callbacks.has(event);
  }

  // Optional: Remove a specific callback
  public removeEventCallback(event: EventBusEvent): void {
    if (!this.callbacks.delete(event)) {
      console.warn(`No callback found for event: ${EventBusEvent[event]}`);
    }
  }
}
