<!--src/components/JobPoster.vue-->
<template>
  <v-container
    v-if="!isOffline"
    class="job-poster-container ma-0 pa-0"
    :class="{ 'is-hidden': !isClosed }"
  >
    <v-sheet
      @click="toggleDrawer"
      color="primary-darken-1"
      class="pt-3 pl-3 job-poster-open-button"
    >
      <!-- status-Icons -->
      <i
        class="status-icon fa-solid fa-circle-pause"
        v-if="jobPosterState.status === JobposterState.paused"
        data-tid="job-poster-status-icon"
      ></i>
      <i
        class="status-icon fa-solid fa-hand"
        v-else-if="jobPosterState.status === JobposterState.break"
        data-tid="job-poster-status-icon"
      ></i>
      <i
        class="status-icon fa-solid fa-hourglass-half"
        v-else-if="jobPosterState.status === JobposterState.posting"
        data-tid="job-poster-status-icon"
      ></i>
      <i
        class="status-icon fa-solid fa-square-check"
        v-else-if="jobPosterState.status === JobposterState.completed"
        data-tid="job-poster-status-icon"
      ></i>
      <!-- Open/Close-Icons -->
      <span v-if="isClosed"
        ><i class="open-close-arrow fa-solid fa-arrow-left"></i
      ></span>
      <span v-else
        ><i class="open-close-arrow fa-solid fa-arrow-right"></i
      ></span>
    </v-sheet>
    <v-card
      color="primary-darken-1"
      class="ma-0 pr-4 rounded-s-lg"
      :elevation="1"
    >
      <v-card-title class="mb-2"> Jobposter </v-card-title>
      <v-card-text>
        <v-checkbox
          v-model="publishOnHomepage"
          label="auf allen eigenen Stellenbörsen veröffentlichen"
          class="text-caption"
          density="compact"
        ></v-checkbox>
        <v-checkbox
          v-model="publishOnBfA"
          label="auf BA veröffentlichen"
          class="text-caption"
          density="compact"
        ></v-checkbox>
        <v-divider></v-divider>
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            :style="{ width: progressBarWidth + '%' }"
            data-tid="job-poster-progress-bar"
          ></div>
        </div>
        <v-divider class="mb-2"></v-divider>
        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          v-if="
            jobPosterState.status === JobposterState.posting ||
            jobPosterState.status === JobposterState.paused
          "
          @click="pausePosting"
          color="success"
          style="color: var(--color-primary)"
          class="ma-2"
        >
          {{
            jobPosterState.status === JobposterState.posting
              ? "Pause"
              : "Weiter"
          }}
        </v-btn>
        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          v-if="jobPosterState.status === JobposterState.posting"
          @click="cancelPosting"
          color="abort"
          class="ma-2"
        >
          Abbrechen
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          v-if="
            jobPosterState.status === JobposterState.break ||
            jobPosterState.status === JobposterState.completed
          "
          @click="turnOffJobPoster"
          color="white"
          style="color: var(--color-primary)"
          data-tid="close-jobposter"
          class="ma-2"
        >
          ausschalten
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { JobposterState } from "@/enums/jobposterstate.enum";
import store from "@/store/store";
import { defineComponent } from "vue";
import { mapState, mapMutations } from "vuex";
import DialogService from "@/services/dialog.service";

export default defineComponent({
  name: "I_JobPoster",
  computed: {
    ...mapState({
      softwareIntegration: (state: any) => state.company.softwareIntegration,
      jobPosterState: (state: any) => state.jobPosterState,
    }),
    publishOnHomepage: {
      get(): boolean {
        return this.$store.getters.isJobAdPublishOnHomepage;
      },
      set(value: boolean) {
        this.$store.commit("SET_JOB_AD_PUBLISH_HOMEPAGE", value);
      },
    },
    publishOnBfA: {
      get(): boolean {
        return this.$store.getters.isJobAdPublishOnBfA;
      },
      set(value: boolean) {
        this.$store.commit("SET_JOB_AD_PUBLISH_ON_BFA", value);
      },
    },
    progressBarWidth() {
      const { totalJobAds, postedJobAds } = this.jobPosterState;
      return totalJobAds > 0 ? (postedJobAds / totalJobAds) * 100 : 0;
    },
    isOffline() {
      return this.jobPosterState.status === JobposterState.offline;
    },
  },
  data() {
    return {
      isClosed: false,
      JobposterState: JobposterState,
      vStyle: this.$store.state.vStyle,
    };
  },

  methods: {
    ...mapMutations([
      "SET_STATUS",
      "SET_JOB_AD_PUBLISH_HOMEPAGE",
      "TOGGLE_POSTING_IS_PAUSED",
      "TOGGLE_POSTING_IS_CANCELLED",
    ]),
    togglePublishOnHomepage() {
      store.commit("SET_JOB_AD_PUBLISH_HOMEPAGE", !this.publishOnHomepage);
    },
    toggleDrawer() {
      this.isClosed = !this.isClosed;
    },
    pausePosting() {
      store.commit("TOGGLE_POSTING_IS_PAUSED");
      if (this.jobPosterState.status === JobposterState.posting) {
        DialogService.alert(
          "Ich mach nur noch die aktuelle Anzeige schnell fertig und pausiere dann ..."
        );
      }
    },
    cancelPosting() {
      store.commit("TOGGLE_POSTING_IS_CANCELLED", true);
      if (this.jobPosterState.status === JobposterState.posting) {
        DialogService.alert(
          "Ich mach nur noch die aktuelle Anzeige schnell fertig und breche dann ab..."
        );
      }
    },
    turnOffJobPoster() {
      store.commit("SET_STATUS", JobposterState.offline);
      store.commit("RESET_JOBPOSTER");
    },
  },
});
</script>

<style scoped>
.job-poster-container {
  right: 0;
  z-index: 15;
  transform: translateX(0%);
  position: fixed;
  top: 50%;
  transform: translateY(-30%);
  right: -20rem;
  width: 20rem;
  max-height: 30rem;
  transition: right 0.5s ease-in-out;
}

.job-poster-container.is-hidden {
  right: -1rem;
}

.open-close-arrow {
  margin-right: 5px;
}

.status-icon {
  animation: iconAnimation 2s infinite;
  margin-right: 5px;
}

@keyframes iconAnimation {
  0%,
  100% {
    transform: scale(1);
    color: white;
  }
  50% {
    transform: scale(1.2);
    color: var(--color-tertiary);
  }
}
.job-poster-open-button {
  position: absolute;
  border-top-left-radius: var(--border-radius-elements);
  border-bottom-left-radius: var(--border-radius-elements);
  scale: 1;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  width: 4rem;
  height: 3rem;
  cursor: pointer;
}
.progress-bar-container {
  background-color: var(--color-progress-bar-bg);
  border: 2px solid var(--border-medium);
  width: 100%;
  height: 2rem;
  border-radius: var(--border-radius-dialog);
  margin: 1rem 0;
}
.progress-bar {
  background-color: var(--color-progress-bar);
  height: 100%;
  border-radius: var(--border-radius-elements);
  transition: width 0.5s ease-in-out;
}
</style>
