<template>
  <v-row class="mt-5">
    <v-col cols="12" md="6">
      <v-card
        variant="flat"
        :color="funnelData.design.background"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
        class="px-15 pb-15"
      >
        <v-card-title class="d-flex justify-space-between mb-5"
          ><div class="d-flex">
            {{ funnelData.companyConfig.title }} Vorschau
            <v-btn
              v-if="funnelData._id"
              icon
              variant="text"
              @click="openFunnel(funnelData._id)"
              ><v-icon>fas fa-eye</v-icon></v-btn
            >
          </div>
          <v-btn color="success" @click="saveFunnel()">{{
            funnelData._id ? "Funnel speichern" : "neuen Funnel anlegen"
          }}</v-btn></v-card-title
        >
        <div class="d-flex">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Funnel Titel"
            v-model="funnelData.companyConfig.title"
            density="compact"
            class="mt-2"
          />
          <v-btn icon variant="text" @click="openCustomPromptDialog()"
            ><v-icon size="large">fa-solid fa-rocket</v-icon></v-btn
          >
          <v-dialog
            v-model="showCustomPromptDialog"
            :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
          >
            <v-card>
              <v-card-title>Funnel Fragen generieren</v-card-title>
              <v-card-subtitle
                >Thema: {{ funnelData.companyConfig.title }}</v-card-subtitle
              >
              <v-card-text>
                <v-textarea
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  placeholder="Hier können weitere Anweisungen zur Generierung der Funnelfragen gestellt werden ..."
                  v-model="customPrompt"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="abort" @click="closeCustomPromptDialog()"
                  >Abbrechen</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="success" @click="generateFunnelQuestions()"
                  >Generieren</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-card :rounded="funnelData.design.rounding" class="mb-5">
          <v-img
            v-if="funnelData.design.funnelHeader"
            width="100%"
            aspect-ratio="1/1"
            cover
            :src="funnelData.design.funnelHeader"
          />
          <v-sheet
            height="1rem"
            class="d-flex align-center justify-start"
            :color="funnelData.design.primary"
          >
            <v-sheet
              height="0.7rem"
              :width="`${progressBar}%`"
              :color="funnelData.design.secondary"
            ></v-sheet>
          </v-sheet>
        </v-card>
        <v-card
          v-if="
            currentFunnelPage.mainQuestions &&
            selectedMainQuestionIndex === null
          "
          class="pa-4"
          :color="funnelData.design.bgQuestionForm"
          :rounded="funnelData.design.rounding"
        >
          <v-row>
            <v-card-title>
              <v-text-field
                :width="500"
                variant="plain"
                label="Überschrift Hauptfragen"
                v-model="funnelData.funnelHeader"
              />
            </v-card-title>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              v-for="(question, qIndex) in funnelData.questions"
              :key="qIndex"
            >
              <v-card
                :color="funnelData.design.primary"
                :rounded="funnelData.design.rounding"
                class="ma-3 pa-3"
                @click="showSubQuestions(qIndex)"
              >
                <div class="d-flex justify-space-between">
                  <v-icon
                    size="x-large"
                    @click.stop="
                      showIconPicker(FunnelSection.MainQuestion, qIndex)
                    "
                  >
                    {{ question.icon }}
                  </v-icon>
                  <v-btn
                    icon
                    variant="text"
                    @click.stop="removeMainQuestion(qIndex)"
                  >
                    <v-icon>fa-solid fa-trash-can</v-icon>
                  </v-btn>
                </div>
                <v-text-field
                  variant="plain"
                  density="compact"
                  v-model="question.text"
                  @click.stop
                />
                <v-switch
                  :color="funnelData.design.secondary"
                  v-model="question.exitsFunnel"
                  @click.stop
                  label="Knock-Out"
                ></v-switch>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card
                :color="funnelData.design.primary"
                :rounded="funnelData.design.rounding"
                class="ma-3 pa-3"
                border="dashed"
                variant="outlined"
                @click="addMainQuestion"
              >
                <v-card-title>
                  <v-icon>fa-solid fa-plus</v-icon>
                  Hauptfrage
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="selectedMainQuestionIndex !== null"
          :color="funnelData.design.bgQuestionForm"
          :rounded="funnelData.design.rounding"
          class="pa-4"
        >
          <v-btn variant="text" icon @click="showMainQuestions">
            <v-icon>fa-solid fa-arrow-left</v-icon>
          </v-btn>
          <v-row
            ><v-card-title>
              <v-text-field
                :width="500"
                variant="plain"
                label="Überschrift Subfrage"
                v-model="funnelData.questions[selectedMainQuestionIndex].header"
                @click.stop
              /> </v-card-title
          ></v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              v-for="(subQuestion, sIndex) in funnelData.questions[
                selectedMainQuestionIndex
              ].subQuestions"
              :key="sIndex"
            >
              <v-card
                class="pa-2 mb-2"
                :color="funnelData.design.primary"
                :rounded="funnelData.design.rounding"
              >
                <div class="d-flex justify-space-between">
                  <v-icon
                    size="x-large"
                    @click.stop="
                      showIconPicker(
                        FunnelSection.SubQuestion,
                        selectedMainQuestionIndex,
                        sIndex
                      )
                    "
                  >
                    {{ subQuestion.icon }}
                  </v-icon>

                  <v-btn
                    icon
                    variant="text"
                    @click="
                      removeSubQuestion(selectedMainQuestionIndex, sIndex)
                    "
                  >
                    <v-icon>fa-solid fa-trash-can</v-icon>
                  </v-btn>
                </div>
                <v-text-field v-model="subQuestion.text" variant="plain" />
                <v-switch
                  :color="funnelData.design.secondary"
                  v-model="subQuestion.exitsFunnel"
                  @click.stop
                  label="Knock-Out"
                ></v-switch>
                <v-sheet v-if="!subQuestion.exitsFunnel" class="pa-2">
                  <v-text-field
                    variant="plain"
                    v-model="subQuestion.skillsHeader"
                  />
                  <v-text-field
                    v-model="newSkill"
                    rounded="pill"
                    variant="outlined"
                    label="Neuen Skill hinzufügen"
                    density="compact"
                    class="mt-2"
                    @keyup.enter="addSkill(selectedMainQuestionIndex, sIndex)"
                  />
                  <v-chip
                    v-for="(skill, skillIndex) in subQuestion.skills"
                    :key="skillIndex"
                    class="mr-2 my-1"
                    closable
                    @click:close="
                      removeSkill(selectedMainQuestionIndex, sIndex, skillIndex)
                    "
                  >
                    {{ skill }}
                  </v-chip>
                </v-sheet>
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-card
                :color="funnelData.design.primary"
                :rounded="funnelData.design.rounding"
                class="ma-3 pa-3"
                border="dashed"
                variant="outlined"
                @click="addSubQuestion(selectedMainQuestionIndex)"
              >
                <v-card-title>
                  <v-icon>fa-solid fa-plus</v-icon>
                  Subfrage
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title>Letzte Seite</v-card-title>
        <v-card-text>
          <v-text-field
            label="Titel 'Vielen Dank' Seite"
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="funnelData.thankYouPage.title"
            density="compact"
            class="mt-3"
          />
          <v-text-field
            label="Untertitel"
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="funnelData.thankYouPage.subtitle"
            density="compact"
            class="mb-3"
          />
          <v-row>
            <v-col
              v-for="(step, stepIndex) in funnelData.thankYouPage.steps"
              :key="`thankYou-step-${stepIndex}`"
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <v-icon
                  size="x-large"
                  :color="funnelData.design.primary"
                  class="mr-4"
                  @click="showIconPicker(FunnelSection.ThankYouPage, stepIndex)"
                >
                  {{ step.icon }}
                </v-icon>
                <v-text-field
                  v-model="step.text"
                  label="Step Text"
                  variant="plain"
                  density="compact"
                />
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-6"></v-divider>
          <v-text-field
            label="Titel 'Leider Nein'-Seite"
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            density="compact"
            v-model="funnelData.noWayPage.title"
          />
          <v-text-field
            label="Untertitel"
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            density="compact"
            v-model="funnelData.noWayPage.subtitle"
            class="mb-3"
          />
          <v-row>
            <v-col
              v-for="(step, stepIndex) in funnelData.noWayPage.steps"
              :key="`noWay-step-${stepIndex}`"
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <v-icon
                  size="x-large"
                  :color="funnelData.design.primary"
                  class="mr-2"
                  @click="showIconPicker(FunnelSection.NoWayPage, stepIndex)"
                >
                  {{ step.icon }}
                </v-icon>
                <v-text-field
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="step.text"
                  label="Step Text"
                  variant="plain"
                  density="compact"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="12">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title>Funnel Design</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                label="Abrundungen"
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                v-model="funnelData.design.rounding"
                :items="vRounding"
                item-title="value"
                item-value="label"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-file-input
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="upload Header"
                @change="uploadFunnelHeader"
                accept="image/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
              <v-file-input
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="Upload Effekt / Logo"
                @change="uploadEffectImage"
                accept="image/*"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="3">
              <v-card variant="flat">
                <v-card-subtitle>Ansicht Effektbild / Logo</v-card-subtitle>
                <v-card-text>
                  <v-img
                    v-if="funnelData.design.effectImage"
                    width="80%"
                    aspect-ratio="1/1"
                    cover
                    :src="funnelData.design.effectImage"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3">
              <v-card-subtitle>Primärfarbe</v-card-subtitle>
              <v-color-picker
                v-model="funnelData.design.primary"
                mode="hexa"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-card-subtitle>Sekundärfarbe</v-card-subtitle>
              <v-color-picker
                v-model="funnelData.design.secondary"
                mode="hexa"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-card-subtitle>Hintergrundfarbe</v-card-subtitle>
              <v-color-picker
                v-model="funnelData.design.background"
                mode="hexa"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-card-subtitle>Hintergrund Fragen</v-card-subtitle>
              <v-color-picker
                v-model="funnelData.design.bgQuestionForm"
                mode="hexa"
              ></v-color-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-card-title>Farbe bei Fehler</v-card-title>
              <v-color-picker
                v-model="funnelData.design.errorColor"
                mode="hexa"
              ></v-color-picker>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="4">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title>Unternehmenseinstellungen</v-card-title>
        <v-card-text>
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Unternehmensname"
            v-model="funnelData.companyConfig.name"
            density="compact"
            class="mt-2"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Webseite"
            v-model="funnelData.companyConfig.domain"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Link zur Stellenbörse"
            v-model="funnelData.companyConfig.companyJobBoard"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Copyright Text"
            v-model="funnelData.companyConfig.copyright"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="DSGVO Link"
            v-model="funnelData.companyConfig.GDPRlink"
            density="compact"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title>ATS Recruit Konfiguration</v-card-title>
        <v-card-text>
          <!-- API URL und Key -->
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="API URL"
            v-model="funnelData.atsRecruit.apiUrl"
            class="mt-2"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="API Key"
            v-model="funnelData.atsRecruit.apiKey"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Bewerbungsquelle"
            v-model="funnelData.atsRecruit.sourceName"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Bewerbungsquelle Uuid"
            v-model="funnelData.atsRecruit.sourceUuid"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Default Mandant Name"
            v-model="funnelData.atsRecruit.defaultMandant.name"
            density="compact"
          />
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Default Mandant Uuid"
            v-model="funnelData.atsRecruit.defaultMandant.uuid"
            density="compact"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="4"
      v-for="(mandant, mandantIndex) in funnelData.atsRecruit.mandants"
      :key="`mandant-${mandantIndex}`"
    >
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
        class="pa-4 mb-3"
      >
        <div class="d-flex justify-space-between align-center">
          <h3>{{ mandant.name }}</h3>
          <v-btn icon variant="text" @click="removeMandant(mandantIndex)">
            <v-icon>fa-solid fa-trash-can</v-icon>
          </v-btn>
        </div>
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Mandant Name"
          v-model="mandant.name"
          density="compact"
          class="mt-2"
        />
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          label="Mandant Uuid"
          v-model="mandant.uuid"
          density="compact"
        />
        <v-divider></v-divider>
        <v-text-field
          :variant="vStyle.input.variant || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          rounded="pill"
          density="compact"
          v-model="newPlz[mandantIndex]"
          label="Neue PLZ hinzufügen"
          @keyup.enter="addPlz(mandantIndex)"
          class="mt-2"
        />
        <div>
          <v-chip
            v-for="(plz, plzIndex) in mandant.plzs"
            :key="`plz-${plzIndex}`"
            class="mr-2 mb-2"
            closable
            @click:close="removePlz(mandantIndex, plz)"
          >
            {{ plz }}
          </v-chip>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="2">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        border="dashed"
        @click="addMandant"
        class="mt-4"
      >
        <v-card-title>
          <v-icon>fa-solid fa-plus</v-icon>
          Mandant
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
  <IconPicker ref="iconPicker" @iconSelected="setIcon" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FunnelService } from "@/services/api/funnel.service";
import IconPicker from "./IconPicker.vue";
import { JobFunnel } from "../../../models/job-funnel.model";
import { VRounding } from "../../../enums/roundings.enum";
import { FunnelSection } from "../../../enums/funnel.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import ToastService from "../../../services/toast.service";
import { useDisplay } from "vuetify";
import moment from "moment";
import { AiService } from "../../../services/ai.service";
import { SpinnerService } from "../../../services/spinner.service";

export default defineComponent({
  name: "JobFunnelEdit",
  emits: ["loadJobFunnels"],
  components: { IconPicker },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      funnelData: this.initFunnel(),
      currentFunnelPage: {
        mainQuestions: true,
        subQuestions: false,
        skills: false,
        thankYou: false,
        nowWay: false,
      },
      customPrompt: "",
      context: {
        section: "" as FunnelSection,
        mainIndex: null as number | null,
        subIndex: null as number | null,
      },
      progressBar: 10,
      FunnelSection,
      iconDialogVisible: false,
      selectedIconIndex: null,
      selectedMainQuestionIndex: null as number | null,
      showCustomPromptDialog: false,
      newSkill: "",
      newPlz: {} as any,
      funnelService: new FunnelService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    vRounding() {
      return getEnumOptions(VRounding);
    },
  },
  methods: {
    addSkill(mainIndex: number, subIndex: number) {
      if (this.newSkill) {
        this.funnelData.questions[mainIndex].subQuestions[subIndex].skills.push(
          this.newSkill
        );
        this.newSkill = "";
      }
    },
    addMandant() {
      this.funnelData.atsRecruit.mandants.push({
        name: "Neuer Mandant",
        uuid: "",
        plzs: [],
      });
    },
    addMainQuestion() {
      this.funnelData.questions.push({
        icon: "fas fa-question-circle",
        header: "Neue Subfrage Überschrift",
        text: "Neue Hauptfrage",
        exitsFunnel: false,
        subQuestions: [],
      });
    },
    addPlz(mandantIndex: number) {
      const plz = this.newPlz[mandantIndex]?.trim();
      if (!plz) return;
      const exists = this.funnelData.atsRecruit.mandants.some(
        (mandant: { plzs: string | any[] }, index: any) =>
          mandant.plzs.includes(plz) && index !== mandantIndex
      );

      if (exists) {
        ToastService.show(
          `Die Postleitzahl ist bereits bei ${this.funnelData.atsRecruit.mandants[mandantIndex].name} verwendet`
        );
      } else {
        this.funnelData.atsRecruit.mandants[mandantIndex].plzs.push(plz);
        this.newPlz[mandantIndex] = "";
      }
    },
    addSubQuestion(mainIndex: number) {
      this.funnelData.questions[mainIndex].subQuestions.push({
        icon: "fas fa-info-circle",
        text: "Neue Subfrage",
        exitsFunnel: false,
        skillsHeader: "Skills für Subfrage",
        skills: [],
      });
    },
    closeCustomPromptDialog() {
      this.showCustomPromptDialog = false;
    },
    editJobFunnel(jobFunnel: JobFunnel) {
      this.funnelData = jobFunnel;
      ToastService.show(`Funnel mit id${jobFunnel._id} erfolgreich geladen`);
    },
    getCurrentYear() {
      return moment().format("YYYY");
    },
    async generateFunnelQuestions() {
      const aiService = new AiService();
      try {
        SpinnerService.showSpinner();
        const response = await aiService.generateFunnelQuestions(
          this.funnelData.companyConfig.title,
          this.customPrompt
        );
        if (response.funnelHeader) {
          this.funnelData.funnelHeader = response.funnelHeader;
          this.funnelData.questions = response.questions;
        }
      } catch (error) {
        console.error(error);
        ToastService.showError("Fehler beim generieren der Funnelfragen");
      } finally {
        SpinnerService.removeSpinner();
        this.closeCustomPromptDialog();
      }
    },
    initFunnel(): JobFunnel {
      return {
        companyConfig: {
          name: this.$store.state.company.name,
          domain: this.$store.state.company.domain,
          title: "Job Funnel",
          companyJobBoard: `${this.$store.state.company.domain}/jobs`,
          copyright: `©${this.getCurrentYear()} ${
            this.$store.state.company.name
          }`,
          GDPRlink: `${this.$store.state.company.domain}/datenschutz`,
        },
        design: {
          primary: "#1a3553",
          secondary: "#85bd86",
          background: "#ffffff",
          bgQuestionForm: "#e4e9f0",
          errorColor: "#ff0000",
          rounding: VRounding.medium,
          funnelHeader: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/banner.gif`,
          effectImage: `${this.$store.getters.getEnv.VUE_APP_ASSETS_URL}/green-check.svg`,
        },
        thankYouPage: {
          title: "Vielen Dank!",
          subtitle: "Was passiert als nächstes?",
          steps: [
            {
              icon: "fa-solid fa-phone",
              text: "Wir melden uns zügig bei Dir,",
            },
            {
              icon: "fa-solid fa-handshake",
              text: "und sprechen über deine Ziele und Wünsche,",
            },
            {
              icon: "fa-solid fa-search",
              text: "danach durchsuchen wir den ganzen Arbeitsmarkt,",
            },
            {
              icon: "fa-solid fa-briefcase",
              text: "damit wir den besten Job für Dich finden!",
            },
          ],
        },
        noWayPage: {
          title: "Leider...",
          subtitle: "... klappt es für diese Stelle nicht",
          steps: [
            {
              icon: "fa-solid fa-thumbs-up",
              text: "Wir bedanken uns für Deine Bewerbung",
            },
            { icon: "fa-solid fa-smile", text: "Sei nicht enttäuscht!" },
            { icon: "fa-solid fa-star", text: "Du hast tolle Fähigkeiten" },
            {
              icon: "fa-solid fa-clipboard-list",
              text: "Am besten bewerbe Dich einfach für einen anderen Job:",
            },
          ],
        },
        atsRecruit: {
          apiUrl: this.$store.getters.softwareIntegration.zvooveRecruitLink,
          apiKey: this.$store.state.company.apiKeys.zvooveOpenStelle,
          sourceUuid: "source-uuid-example",
          sourceName: "Landingpage",
          mandants: this.$store.getters.funnelMandants,
          defaultMandant: {
            name: this.$store.state.company.mandants[0].zvoovename,
            uuid: this.$store.state.company.mandants[0].uuid,
          },
        },
        funnelHeader: "Überschrift für den Funnel",
        questions: [
          {
            icon: "fas fa-question-circle",
            header: "Überschrift Hauptfrage 1",
            text: "Hauptfrage 1",
            exitsFunnel: false,
            subQuestions: [
              {
                icon: "fas fa-info-circle",
                text: "Subfrage 1-1",
                exitsFunnel: false,
                skillsHeader: "Skills Überschrift",
                skills: ["Skill A1", "Skill B1"],
              },
            ],
          },
          {
            icon: "fas fa-question-circle",
            header: "Überschrift Hauptfrage 2",
            text: "Hauptfrage 2",
            exitsFunnel: false,
            subQuestions: [
              {
                icon: "fas fa-info-circle",
                text: "Subfrage 2-1",
                exitsFunnel: false,
                skillsHeader: "Skills Überschrift",
                skills: ["Skill A1", "Skill B1"],
              },
            ],
          },
        ],
      };
    },
    newJobFunnel() {
      ToastService.show("neuer Funnel wurde vorbereited ...");
      this.funnelData = this.initFunnel();
    },
    openCustomPromptDialog() {
      this.showCustomPromptDialog = true;
    },
    openFunnel(id: string) {
      const url = this.$router.resolve({ path: "/jobs", query: { id } }).href;
      window.open(url, "_blank");
    },
    removeMandant(mandantIndex: number) {
      this.funnelData.atsRecruit.mandants.splice(mandantIndex, 1);
    },
    removePlz(mandantIndex: number, plz: any) {
      const plzIndex =
        this.funnelData.atsRecruit.mandants[mandantIndex].plzs.indexOf(plz);
      if (plzIndex > -1) {
        this.funnelData.atsRecruit.mandants[mandantIndex].plzs.splice(
          plzIndex,
          1
        );
      }
    },
    removeMainQuestion(index: number) {
      this.funnelData.questions.splice(index, 1);
    },
    removeSkill(
      questionIndex: number,
      subQuestionIndex: number,
      skillIndex: number
    ) {
      this.funnelData.questions[questionIndex].subQuestions[
        subQuestionIndex
      ].skills.splice(skillIndex, 1);
    },
    removeSubQuestion(mainIndex: number, subIndex: number) {
      this.funnelData.questions[mainIndex].subQuestions.splice(subIndex, 1);
    },
    async saveFunnel() {
      try {
        const funnelService = new FunnelService();
        if (!this.funnelData._id) {
          const response = await funnelService.saveJobFunnel(this.funnelData);
          this.funnelData = response;
          ToastService.showSuccess("Funnel erfolgreich erstellt");
        } else {
          await this.funnelService.updateFunnel(this.funnelData);
        }
        this.$emit("loadJobFunnels");
      } catch (error) {
        ToastService.showError("Fehler bei Speichern des Funnels: " + error);
      }
    },
    showIconPicker(
      section: FunnelSection,
      mainIndex: number,
      subIndex: number | null = null
    ) {
      this.context.section = section;
      this.context.mainIndex = mainIndex;
      this.context.subIndex = subIndex;
      (this.$refs.iconPicker as InstanceType<typeof IconPicker>).openDialog();
    },
    setIcon(icon: string) {
      const { section, mainIndex, subIndex } = this.context;

      switch (section) {
        case FunnelSection.MainQuestion:
          if (
            mainIndex !== undefined &&
            mainIndex !== null &&
            this.funnelData.questions[mainIndex]
          ) {
            this.funnelData.questions[mainIndex].icon = icon;
          }
          break;
        case FunnelSection.SubQuestion:
          if (
            mainIndex !== undefined &&
            mainIndex !== null &&
            subIndex !== undefined &&
            subIndex !== null &&
            this.funnelData.questions[mainIndex] &&
            this.funnelData.questions[mainIndex].subQuestions[subIndex]
          ) {
            this.funnelData.questions[mainIndex].subQuestions[subIndex].icon =
              icon;
          }
          break;
        case FunnelSection.ThankYouPage:
          if (
            mainIndex !== undefined &&
            mainIndex !== null &&
            this.funnelData.thankYouPage.steps[mainIndex]
          ) {
            this.funnelData.thankYouPage.steps[mainIndex].icon = icon;
          }
          break;
        case FunnelSection.NoWayPage:
          if (
            mainIndex !== undefined &&
            mainIndex !== null &&
            this.funnelData.noWayPage.steps[mainIndex]
          ) {
            this.funnelData.noWayPage.steps[mainIndex].icon = icon;
          }
          break;
        default:
          console.warn(`Unknown icon section: ${section}`);
      }
    },
    showSubQuestions(index: number) {
      this.progressBar = 50;
      this.selectedMainQuestionIndex = index;
    },
    showMainQuestions() {
      this.progressBar = 10;
      this.selectedMainQuestionIndex = null;
    },
    uploadFunnelHeader(event: any) {
      const file = event.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.funnelData.design.funnelHeader = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    uploadEffectImage(event: any) {
      const file = event.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.funnelData.design.effectImage = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
});
</script>
