import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-60e6cdfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            variant: _ctx.vStyle.card.variant || undefined,
            rounded: _ctx.vStyle.card.rounded || undefined,
            border: _ctx.vStyle.card.border || undefined,
            flat: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "my-4" }, {
                default: _withCtx(() => [
                  _createTextVNode("Features")
                ]),
                _: 1
              }),
              _createVNode(_component_v_list, { density: "compact" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      class: "my-4 mx-0 features__item",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_tooltip, { bottom: "" }, {
                          activator: _withCtx(({ props }) => [
                            _createElementVNode("span", _mergeProps({ ref_for: true }, props), _toDisplayString(feature), 17)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("span", {
                              innerHTML: _ctx.descriptions[index]
                            }, null, 8, _hoisted_1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["variant", "rounded", "border"])
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptions, (subscription, index) => {
        return (_openBlock(), _createBlock(_component_v_col, {
          cols: "12",
          md: "3",
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              variant: _ctx.vStyle.card.variant || undefined,
              rounded: _ctx.vStyle.card.rounded || undefined,
              border: _ctx.vStyle.card.border || undefined,
              class: _normalizeClass({ active: _ctx.selected === subscription.name })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card_title, { class: "my-4 d-flex justify-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(subscription.name), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_v_list, {
                  density: "compact",
                  class: "no-background"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subscription.features, (feature, index) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        class: "my-4 d-flex justify-center features__item",
                        key: index
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_tooltip, {
                            bottom: "",
                            text: _ctx.features[index]
                          }, {
                            activator: _withCtx(({ props }) => [
                              _createElementVNode("span", _mergeProps({ ref_for: true }, props), [
                                _createElementVNode("div", {
                                  class: _normalizeClass(feature ? 'tick' : 'cross')
                                }, _toDisplayString(feature
                        ? typeof feature === "string"
                          ? feature
                          : "✔"
                        : "✘"), 3)
                              ], 16)
                            ]),
                            _: 2
                          }, 1032, ["text"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["variant", "rounded", "border", "class"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}