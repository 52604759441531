import { User, UserConfig } from "@/models/user.model";
import store from "@/store/store";
import { PasswordChange } from "@/models/password-change.model";
import { ApiResponse } from "@/models/api-response.model";
import { Router } from "vue-router";
import { Routes } from "@/enums/routes.enum";
import { ArrayUtils } from "../../helper/remove-duplicates.helper";
import {
  UserConfigFilterBoard,
  UserConfigFilterType,
} from "../../enums/board-filters.enum";
import { ApiPublic } from "./api-public.class";
import { Api } from "./api.class";

export class UserService {
  private api = Api.getInstance();
  private apiPublic = ApiPublic.getInstance();
  private urlPath = "/user";

  async getUser() {
    const response = await this.api.getBackendApi().get<User>(this.urlPath);
    return Promise.resolve(response.data);
  }

  async getUsers() {
    const response = await this.api
      .getBackendApi()
      .get<User[]>(`${this.urlPath}/all`);
    return Promise.resolve(response.data);
  }

  async addUser(user: User) {
    const response = await this.api
      .getBackendApi()
      .post<User>(this.urlPath, user);
    return response.data;
  }

  async editUser(user: User): Promise<ApiResponse | undefined> {
    if (user.config.mandants?.length === 0) {
      console.error(
        "Speichern von Benutzer ohne Mandantenberechtigung verhindert ..."
      );
      return undefined;
    }
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(this.urlPath, user);
    store.commit("SET_USER", user);
    return response.data;
  }

  async updateUserConfig(userConfig: UserConfig, router: Router) {
    if (userConfig.mandants?.length === 0) {
      console.error(
        "Speichern von Benutzer ohne Mandantenberechtigung verhindert ..."
      );
      router.push(Routes.dashboard); // FIXME: when user mandant deletion bug from dispatcherboard is fixed
      return null;
    }

    if (userConfig.mandants)
      userConfig.mandants = ArrayUtils.removeDuplicates(userConfig.mandants);
    if (userConfig.loggedInMandants)
      userConfig.loggedInMandants = ArrayUtils.removeDuplicates(
        userConfig.loggedInMandants
      );

    // Deduplicate properties in dispatcherBoard columns-filters
    if (userConfig.dispatcherBoard) {
      const columns: Array<keyof typeof userConfig.dispatcherBoard> = [
        UserConfigFilterBoard.columnCandidate,
        UserConfigFilterBoard.columnCustomer,
        UserConfigFilterBoard.columnDemand,
        UserConfigFilterBoard.columnEmployee,
      ];
      columns.forEach((column) => {
        const colConfig = userConfig.dispatcherBoard[column];
        if (colConfig) {
          ArrayUtils.deduplicateProperties(colConfig, [
            UserConfigFilterType.filterMandants,
            UserConfigFilterType.filterStatus,
            UserConfigFilterType.orderDraggable,
          ]);
        }
      });
    }

    const response = await this.api
      .getBackendApi()
      .put<UserConfig>(`/user/config`, userConfig);

    store.commit("SET_USER_CONFIG", userConfig);
    return response.data;
  }

  async updateUserProfile(
    userProfileData: Partial<User>
  ): Promise<ApiResponse> {
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(`${this.urlPath}/profile`, userProfileData);
    store.commit("SET_USER_PROFILE", userProfileData);
    return response.data;
  }

  async changePassword(passwordChange: PasswordChange): Promise<ApiResponse> {
    return (
      await this.api
        .getBackendApi()
        .put(`${this.urlPath}/password`, passwordChange)
    ).data as ApiResponse;
  }

  async restorePassword(
    linkId: string,
    newPassword: string
  ): Promise<ApiResponse> {
    return (
      await this.apiPublic
        .getBackendApi()
        .post(`${this.urlPath}/restore-password`, { newPassword, linkId })
    ).data as ApiResponse;
  }

  async checkRestoreLink(id: string): Promise<ApiResponse> {
    const response = await this.apiPublic
      .getBackendApi()
      .get(`${this.urlPath}/check-restore-link?id=${id}`);
    return response.data as ApiResponse;
  }

  async forgotPassword(emailAddress: string): Promise<ApiResponse> {
    const response = await this.apiPublic
      .getBackendApi()
      .post(`${this.urlPath}/password-forgotten`, { emailAddress });
    return response.data as ApiResponse;
  }

  async removeUser(usermail: string): Promise<void> {
    await this.api.getBackendApi().delete(`${this.urlPath}/${usermail}`);
  }
}
