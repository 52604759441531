<template>
  <v-alert
    v-if="!isRestoreLinkValid"
    icon="fa-solid fa-user-lock"
    title="Fehlerhafter Link"
    text="Bitte wiederholen Sie den Passwort-Rücksetzungs-Prozess"
    class="pa-5"
    max-width="500"
    style="margin: 10vh auto"
    dismissible="false"
    variant="tonal"
    color="red-darken-3"
  />
  <v-container class="fill-height" justify="center" align="center">
    <v-card
      variant="flat"
      v-if="isRestoreLinkValid"
      class="pa-5"
      max-width="500"
      style="margin: 10vh auto"
    >
      <div class="text-center">
        <img :src="appPasswordRestoreLogo" />
      </div>
      <v-card-title>Neues Passwort setzen</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid" lazy-validation>
          <v-text-field
            variant="outlined"
            v-model="password"
            :rules="passwordRules"
            label="Neues Passwort"
            type="password"
            required
            outlined
          ></v-text-field>

          <v-text-field
            variant="outlined"
            v-model="confirmPassword"
            :rules="confirmPasswordRules"
            label="Passwort bestätigen"
            type="password"
            required
            outlined
          ></v-text-field>

          <v-alert v-if="errorMessage" type="error" dense>
            {{ errorMessage }}
          </v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn :disabled="!isValid" color="primary" @click="submitPassword">
          Passwort speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { UserService } from "../../../services/api/user.service";
import { Routes } from "../../../enums/routes.enum";
import ToastService from "../../../services/toast.service";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "PasswordRestore",
  data() {
    return {
      appPasswordRestoreLogo:
        "https://assets.dispositioner.de/password-restore.svg",
      email: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
      isValid: false,
      passwordRules: [
        (v: any) => !!v || "Passwort ist erforderlich",
        (v: any) =>
          (v && v.length >= 8) ||
          "Passwort muss mindestens 8 Zeichen lang sein",
        (v: any) =>
          /[A-Z]/.test(v) ||
          "Passwort muss mindestens einen Großbuchstaben enthalten",
        (v: any) =>
          /[a-z]/.test(v) ||
          "Passwort muss mindestens einen Kleinbuchstaben enthalten",
        (v: any) =>
          /[0-9]/.test(v) || "Passwort muss mindestens eine Zahl enthalten",
        (v: any) =>
          /[!@#$%^&*]/.test(v) ||
          "Passwort muss mindestens ein Sonderzeichen enthalten (!@#$%^&*)",
      ],
      confirmPasswordRules: [
        (v: any) => !!v || "Passwort-Bestätigung ist erforderlich",
        (v: any) => v === this.password || "Passwörter stimmen nicht überein",
      ],
    };
  },
  setup() {
    const userService = new UserService();
    const route = useRoute();
    const routeId = ref<string | null>(null);
    const isRestoreLinkValid = ref(false);

    const checkRouteId = async () => {
      try {
        const { id } = route.query;
        if (!id || typeof id !== "string") {
          throw new Error("Ungültige oder fehlende ID.");
        }

        routeId.value = id;

        // API-Aufruf zur Validierung
        const result = await userService.checkRestoreLink(id);

        if (result.response) {
          isRestoreLinkValid.value = true;
        } else {
          isRestoreLinkValid.value = false;
        }
      } catch (error: any) {
        isRestoreLinkValid.value = false;
      }
    };

    onMounted(() => {
      checkRouteId();
    });

    return {
      isRestoreLinkValid,
      routeId,
      userService,
    };
  },
  methods: {
    async submitPassword() {
      const form = this.$refs.form as HTMLFormElement;
      if (form.validate()) {
        try {
          console.log("Passwort erfolgreich gesetzt:", this.password);
          this.userService
            .restorePassword(this.routeId as string, this.password)
            .then(
              () => {
                this.$router.push(Routes.logout);
              },
              () => {
                ToastService.showError("Passwort konnte nicht gesetzt werden");
              }
            );
        } catch (error: any) {
          this.errorMessage =
            error.response?.data?.message ||
            "Fehler beim Speichern des Passworts";
        }
      }
    },
  },
});
</script>

<style scoped>
.fill-height {
  height: 100vh;
}
</style>
