import axios, { AxiosInstance } from "axios";
import store from "../../store/store";
import { EventBusService, EventBusEvent } from "../event-bus.service";

export class ApiBase {
  public backendApi!: AxiosInstance;

  constructor(credentials = true) {
    this.backendApi = axios.create({
      baseURL: `${store.getters.getEnv.VUE_APP_BACKEND_URI}`,
      withCredentials: credentials,
    });
    this.setInterceptor();
  }

  setHeaderContentTypeJson() {
    this.backendApi.defaults.headers.common["Content-Type"] =
      "application/json";
  }

  setHeaderApiKey(apikey: string) {
    this.backendApi.defaults.headers.common["X-ApiKey"] = apikey;
  }

  getBackendApi() {
    return this.backendApi;
  }

  setInterceptor() {
    this.backendApi.interceptors.request.use(
      (config: any) => {
        const accessToken = localStorage.getItem("accessToken");
        if (
          config.url === "/login" ||
          config.url === "/logout" ||
          accessToken
        ) {
          return config;
        } else {
          return Promise.reject(new Error("Kein Zugriffstoken vorhanden"));
        }
      },
      async (error: any) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("accessToken");
          EventBusService.getInstance().publish(EventBusEvent.userLoggedOut);
        }
        if (error.response && error.response.status === 404) {
          console.error("Anfrage fehlgeschlagen: ", error);
        }
      }
    );
  }
}
