<!-- src/components/whatsapp/ChatMessages.vue -->
<template>
  <v-container class="mx-0 pa-0 mb-5 chat-messages" fluid>
    <v-card
      :variant="vStyle.card.variant || undefined"
      :rounded="vStyle.card.rounded || undefined"
      :border="vStyle.card.border || undefined"
      class="ma-0 pa-0 border-md rounded-md chat-card"
    >
      <v-card-subtitle> Chat Nachrichten </v-card-subtitle>
      <v-card-text v-if="messages.length === 0" class="spinner-container">
        <v-progress-circular
          indeterminate
          color="primary"
          size="300"
          width="16"
          class="slow-spinner"
        ></v-progress-circular>
      </v-card-text>
      <v-card-text v-if="messages.length > 0">
        <div v-for="msg in messages" :key="msg.id" class="message-container">
          <v-card
            :class="{
              'message-from-me': msg.fromMe,
              'message-from-them': !msg.fromMe,
            }"
          >
            <v-list-item-title>
              <strong v-if="msg.fromMe">Ich:</strong>
              <strong v-else>{{ msg.sender.pushname }}:</strong>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ msg.body }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption text-grey--text">
              <small>{{ formatTimestamp(msg.timestamp) }}</small>
            </v-list-item-subtitle>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ChatMessages",
  props: {
    messages: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  data() {
    return {
      vStyle: this.$store.state.vStyle,
    };
  },
  methods: {
    formatTimestamp(timestamp: number) {
      const date = new Date(timestamp * 1000);
      return date.toLocaleString();
    },
  },
});
</script>

<style scoped>
.chat-messages {
  max-height: 28rem;
  overflow-y: auto;
}

.chat-card {
  height: 25rem;
  overflow-y: auto;
  background-color: #faf6f4;
  color: var(--color-font-primary);
  padding: 1rem;
}

.message-container {
  display: flex;
  justify-content: flex-start;
}

.message-from-me {
  background-color: #d9fdd3;
  color: var(--color-font-primary);
  max-width: 95%;
  min-width: 30%;
  margin-left: auto;
  margin-bottom: 0.7rem;
  border-radius: 0.7rem;
  padding: 1rem;
  word-break: break-word;
}

.message-from-them {
  background-color: #fff;
  color: var(--color-font-primary);
  max-width: 95%;
  min-width: 30%;
  margin-right: auto;
  margin-bottom: 0.7rem;
  border-radius: 0.7rem;
  padding: 1rem;
  word-break: break-word;
}

.v-list-item-title {
  font-weight: bold;
}

.v-list-item-subtitle {
  margin-top: 0.5rem;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
