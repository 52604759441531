<!--src/components/disposition/CandidateColumn.vue-->
<template>
  <div class="column">
    <div v-if="!isActive" class="spacer-dispo-column"></div>
    <div v-if="isActive" class="ml-0 pl-0 header-dispo-column">
      <div>
        <FilterMenu>
          <template #filter-menu-left-column>
            <div class="date-filter-item">
              beworben nach:<br />
              <input type="date" v-model="dateAfter" />
              <button @click="dateAfter = null">X</button>
            </div>
            <v-divider class="mb-1"></v-divider>
            <v-radio-group
              v-model="selectedStatus"
              @change="selectStatusFilter"
            >
              <v-radio
                v-for="status in statusOptionsRecruit"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              ></v-radio>
            </v-radio-group>
            <div>
              <v-switch
                label="immer ATS synchronisieren"
                color="primary"
                v-model="shouldAllwaysSyncWithAts"
              >
              </v-switch>
              <v-tooltip activator="parent" location="top left"
                >Kandidaten werden bei jedem Abruf mit dem ATS-System
                synchronisiert (langsamer)</v-tooltip
              >
            </div>
          </template>
          <template #filter-menu-right-column>
            <div class="date-filter-item">
              beworben vor:<br />
              <input type="date" v-model="dateBefore" />
              <button @click="dateBefore = null">X</button>
            </div>
            <v-divider class="mb-1"></v-divider>
            <div class="mandants-options">
              <v-checkbox
                density="compact"
                v-for="mandant in mandants"
                :key="mandant.uuid"
                v-model="selectedMandants"
                :label="mandant.name"
                :value="mandant.uuid"
                class="ma-0 pa-0"
                @update:model-value="updateMandantsFilter(selectedMandants)"
              ></v-checkbox>
            </div>
            <v-divider class="mb-10"> </v-divider>
            <v-select
              :variant="vStyle.input.variant || undefined"
              :rounded="vStyle.input.rounded || undefined"
              :base-color="vStyle.input.baseColor || undefined"
              :color="vStyle.input.color || undefined"
              v-model="filterCandidatesCurrent"
              density="compact"
              :items="FilterCandidates"
              label="sortieren nach ..."
              item-title="label"
              item-value="label"
              @update:model-value="
                filterCandidates(candidatesAll, filterCandidatesCurrent)
              "
            >
            </v-select>
          </template>
        </FilterMenu>
      </div>
      <v-slider
        class="mt-4 mr-2 text-caption"
        v-model="sliderCandidateStatusValue"
        :max="4"
        step="1"
        tick-size="10"
        color="primary"
        show-ticks="always"
        @update:model-value="handleSliderChange()"
      >
        <template v-slot:tick-label="{ index }">
          <v-icon
            v-if="mdAndDown"
            :key="index"
            color="primary-darken-1"
            class="mt-3"
            >{{
              sliderCandidateStateIcons[
                index as keyof typeof sliderCandidateStateIcons
              ]
            }}</v-icon
          >
          <span v-else>{{
            sliderCandidateStatesLabels[
              index as keyof typeof sliderCandidateStatesLabels
            ]
          }}</span>
        </template>
      </v-slider>

      <v-icon>{{ isDebounceActive ? "fa-regular fa-clock" : "none" }}</v-icon>
      <v-btn icon variant="text" @click="loadCandidates(true)">
        <v-icon size="large" class="filter-icon"
          >fas fa-cloud-arrow-down</v-icon
        >
        <v-tooltip activator="parent" location="top left"
          >Kandidaten neu laden, auf neue Kandidaten im ATS prüfen und alle
          Stati vollständig mit ATS synchronisieren</v-tooltip
        >
      </v-btn>
    </div>
    <v-infinite-scroll
      v-if="candidateOrdered.length > 0"
      :onLoad="loadMoreCandidates"
      :disabled="isLoadingCandidates"
      class="infinite-scroll-hidden-scrollbar ma-0 pa-0"
      :height="columnHeight - DispatcherBoardColumnHeight.iLoaderSubtract"
      :distance="DispatcherBoardColumnHeight.iLoaderDistance"
    >
      <draggable
        class="ma-0 pa-0"
        :list="candidateOrdered"
        group="candidate"
        @start="drag = true"
        @end="drag = false"
        item-key="id"
        :move="() => false"
      >
        <template #item="{ element, index }">
          <CandidateItem
            :key="element.id"
            :ref="(el: any) => collectRef(el, index)"
            :candidate="element"
            :interComponentMessage="interComponentMessage"
            :isActive="isActive"
            :isFullyCollapsed="minimizeAllItems"
            :softwareIntegration="softwareIntegration"
            :lastUpdateTimeline="lastUpdateTimeline"
            :user="user"
            @loadCandidates="loadCandidates"
            @loadCandidatesFull="loadCandidatesFull"
            @toggleItemsMinimized="toggleItemsMinimized"
            @updatePostcode="updatePostcodeFromItem"
          />
        </template>
      </draggable>
      <template v-if="candidatesAll.length > 0" v-slot:loading>
        <v-icon class="spinner-icon">fas fa-spinner</v-icon>
        <v-card-subtitle>
          lade weitere {{ iLoader.incrementBy }} Kandidaten ...
        </v-card-subtitle>
      </template>
      <template v-slot:empty>
        <v-alert variant="tonal" closable color="secondary">
          Alle Kandidaten wurden geladen!
        </v-alert>
      </template>
    </v-infinite-scroll>
  </div>
</template>

<script lang="ts">
import { AllAtsAdapter } from "@/adapter/all-ats.adapter";
import { CandidateList } from "@/models/candidate-list.model";
import { CandidateService } from "@/services/api/candidate.service";
import { defineComponent, PropType } from "vue";
import { FilterCandidates } from "@/enums/board-filters.enum";
import { getEnumOptions } from "@/helper/enum.helper";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapMutations } from "vuex";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { SoftwareIntegration } from "@/models/company-config.model";
import { SpinnerService } from "@/services/spinner.service";
import { useDisplay } from "vuetify";
import { User } from "@/models/user.model";
import { UserHelperService } from "@/services/user-helper.service";
import { UserService } from "@/services/api/user.service";
import { ZvooveCandidateList } from "@/models/external/zvoove-candidate-list.model";
import CandidateItem from "./CandidateItem.vue";
import debounce from "lodash/debounce";
import draggable from "vuedraggable";
import FilterMenu from "./elements/FilterMenu.vue";
import { DispatcherBoardColumnHeight } from "../../enums/app-layout.enum";
import { useRouter } from "vue-router";
import {
  CandidateSearchCriteria,
  MatchCandidatesRequest,
} from "../../models/candidate.model";

export default defineComponent({
  name: "CandidateColumn",
  components: {
    FilterMenu,
    CandidateItem,
    draggable,
  },
  props: {
    columnHeight: {
      type: Number,
      default: DispatcherBoardColumnHeight.standard,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    interComponentMessage: {
      type: Object as PropType<any>,
      required: true,
    },
    lastUpdateTimeline: {
      type: String,
      required: true,
    },
    loggedInMandantUuids: {
      type: Object as PropType<string[]>,
      required: true,
    },
    mandants: {
      type: Object as PropType<Mandant[]>,
      required: true,
    },
    softwareIntegration: {
      type: Object as PropType<SoftwareIntegration>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  data() {
    const { mdAndDown } = useDisplay();
    return {
      mdAndDown,
      bewerbungenFiltered: [] as ZvooveCandidateList[],
      candidateOrdered: [] as CandidateList[],
      candidatesAll: [] as CandidateList[],
      candidateService: new CandidateService(),
      candidateRefs: [] as Array<InstanceType<typeof CandidateItem>>,
      dateAfter: null,
      dateBefore: null,
      drag: false,
      DispatcherBoardColumnHeight,
      FilterCandidates: getEnumOptions(FilterCandidates),
      filterCandidatesCurrent: "",
      iLoader: {
        candidatesCountOnInit: parseInt(
          this.$store.getters.getEnv.VUE_APP_COLUMN_ITEM_COUNT
        ),
        candidatesCount: parseInt(
          this.$store.getters.getEnv.VUE_APP_COLUMN_ITEM_COUNT
        ),
        incrementBy: parseInt(
          this.$store.getters.getEnv.VUE_APP_COLUMN_ITEM_INCREMENT
        ),
      },
      isDebounceActive: false,
      isLoadingCandidates: false,
      minimizeAllItems: false,
      mouse: {
        x: 0,
        y: 0,
      },
      selectedMandants:
        this.user.config.dispatcherBoard.columnCandidate.filterMandants,
      selectedStatus:
        this.user.config.dispatcherBoard.columnCandidate.filterStatus[0], // [0] = single Status in this case
      shouldAllwaysSyncWithAts: false,
      showPlaceholder: false,
      sliderCandidateStatesLabels: {
        0: "Anrufen",
        1: "Termin",
        2: "Disposition",
        3: "Vermittlung",
        4: "Pool",
      },
      sliderCandidateStateIcons: {
        0: "fas fa-phone",
        1: "fas fa-calendar-days",
        2: "fas fa-users-gear",
        3: "fas fa-magnifying-glass",
        4: "fas fa-clock",
      },
      sliderCandidateStatusValue: 0,
      userService: new UserService(),
      userHelperService: UserHelperService.getInstance(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      statusOptionsRecruit: "statusOptionsRecruit",
    }),
  },

  mounted() {
    this.setSelectedMandantsFromLoggedInMandants();
    this.loadCandidates(false);
  },

  watch: {
    dateAfter() {
      this.loadCandidates(false);
    },
    dateBefore() {
      this.loadCandidates(false);
    },
    interComponentMessage: {
      handler(newVal) {
        if (newVal.message === InterComponentMessage.searchCandidate) {
          this.loadCandidates(true, newVal.payload.searchterm);
        } else if (
          newVal.message === InterComponentMessage.deepSearchCandidate
        ) {
          this.loadCandidatesDetailSearch(
            newVal.payload.candidateDetailSearchObject
          );
        } else if (
          newVal.message === InterComponentMessage.matchMeFromCustomerOrDemand
        ) {
          this.loadCandidatesMatching(newVal.payload);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      addPostcode: MutationType.addPostcodeCandidates,
      clearPostcodeList: MutationType.clearPostcodesCandidates,
    }),

    updatePostcodeFromItem(plz: string) {
      this.addPostcode(plz);
    },
    setSelectedMandantsFromLoggedInMandants() {
      if (this.selectedMandants && this.loggedInMandantUuids) {
        if (
          this.user.config.dispatcherBoard.columnCandidate.filterMandants
            .length > 0
        ) {
          //Do not set if other filters are setted
          return;
        }
        this.selectedMandants = this.loggedInMandantUuids;
      }
    },
    handleSelectedMandantsChange(newValue: string) {
      this.loadCandidates(false);
      this.$store
        .dispatch("updateDispatcherBoardFilter", {
          columnName: "columnCandidate",
          property: "filterMandants",
          value: newValue,
        })
        .then(() => {
          this.userService.updateUserConfig(
            this.$store.state.company.loggedInUser.config,
            useRouter()
          );
        });
    },
    handleSliderChange() {
      this.isDebounceActive = true;
      this.updateSelectedStatus(this.sliderCandidateStatusValue);
    },
    updateSelectedStatus(sliderValue: number) {
      // Clear the current status
      this.selectedStatus = "";

      const matchingOption = this.statusOptionsRecruit.find(
        (option: any) => option.slider === sliderValue
      );
      if (matchingOption) {
        this.selectedStatus = matchingOption.value;
        this.updateStatusFilter();
      }
    },
    async loadCandidates(syncWithAts: boolean, searchtermInput?: string) {
      this.candidateOrdered = [];
      this.iLoader.candidatesCount = this.iLoader.candidatesCountOnInit;
      let mandantsFilter = undefined as undefined | string[];
      if (this.selectedMandants) mandantsFilter = this.selectedMandants;
      if (this.isLoadingCandidates) return;
      let selectedStatus = this.selectedStatus;
      if (selectedStatus && selectedStatus.length > 3) {
        this.clearPostcodeList();
        try {
          SpinnerService.showSpinner();
          this.isLoadingCandidates = true;
          let candidateList: CandidateList[] = [];
          const atsDeterminesStatus =
            this.softwareIntegration.atsDeterminesStatus;

          if (!syncWithAts && !this.shouldAllwaysSyncWithAts) {
            const backendCandidateList = await this.loadCandidatesFromBackend(
              selectedStatus,
              mandantsFilter,
              searchtermInput
            );

            candidateList = backendCandidateList || [];
          } else if (this.softwareIntegration.zvooveRecruit) {
            try {
              // Get candidates from ats
              const { error, atsCandidateList } =
                await AllAtsAdapter.getCandidateList(
                  selectedStatus,
                  mandantsFilter
                );

              if (atsCandidateList) {
                // Send ats candidate list to the backend
                const response =
                  await this.candidateService.updateCandidatesStatusAts(
                    atsCandidateList,
                    atsDeterminesStatus,
                    selectedStatus
                  );
                // Get backend candidate list with updated statuses
                const backendCandidateList =
                  await this.loadCandidatesFromBackend(
                    selectedStatus,
                    mandantsFilter,
                    searchtermInput
                  );
                candidateList = backendCandidateList || [];

                // Add new ats candidates to the candidate list
                if (response && response !== "Update successful") {
                  if (Array.isArray(response)) {
                    candidateList = [...candidateList, ...response];
                  }
                }
              } else if (error) {
                // Get backend candidate list if the is no other ats
                const backendCandidateList =
                  await this.loadCandidatesFromBackend(
                    selectedStatus,
                    mandantsFilter,
                    searchtermInput
                  );
                candidateList = backendCandidateList || [];
              }
            } catch (error) {
              console.error(
                "Error fetching and synchronizing with the ATS:",
                error
              );
            }
          }
          this.candidatesAll = this.filterCandidates(candidateList);

          // for vuetify infinite loader
          this.candidateOrdered = this.candidatesAll.slice(
            0,
            this.iLoader.candidatesCount
          );
        } catch (error) {
          console.error("Error fetching the candidate list:", error);
        } finally {
          SpinnerService.removeSpinner();
          this.isLoadingCandidates = false;
        }
      }
    },
    async loadCandidatesFull() {
      for (const candidateItem of this.candidateRefs) {
        if (
          candidateItem &&
          typeof candidateItem.checkForFullLoad === "function"
        ) {
          candidateItem.checkForFullLoad();
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    },
    collectRef(
      el: Element | InstanceType<typeof CandidateItem> | null,
      index: number
    ) {
      if (el && "$props" in el) {
        this.candidateRefs[index] = el as InstanceType<typeof CandidateItem>;
      }
    },
    async loadCandidatesDetailSearch(
      candidateDetailSearchObject: CandidateSearchCriteria
    ) {
      this.candidateOrdered = [];
      this.iLoader.candidatesCount = this.iLoader.candidatesCountOnInit;

      try {
        SpinnerService.showSpinner();
        const backendCandidateList =
          await this.candidateService.getCandidateListByDeepSearch(
            candidateDetailSearchObject
          );
        const candidateList = backendCandidateList || [];
        this.candidatesAll = this.filterCandidates(candidateList);

        // for vuetify infinite loader
        this.candidateOrdered = this.candidatesAll.slice(
          0,
          this.iLoader.candidatesCount
        );
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },

    async loadCandidatesMatching(
      matchCandidatesRequest: MatchCandidatesRequest
    ) {
      this.candidateOrdered = [];
      this.iLoader.candidatesCount = this.iLoader.candidatesCountOnInit;

      try {
        SpinnerService.showSpinner();
        const backendCandidateList =
          await this.candidateService.matchCandidates(matchCandidatesRequest);
        const candidateList = backendCandidateList || [];
        this.candidatesAll = this.filterCandidates(candidateList);

        // for vuetify infinite loader
        this.candidateOrdered = this.candidatesAll.slice(
          0,
          this.iLoader.candidatesCount
        );
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
    },
    async loadCandidatesFromBackend(
      selectedStatus: string,
      mandantsFilter: string[] | undefined,
      searchtermInput?: string
    ) {
      let backendCandidateList: CandidateList[] = [];
      try {
        SpinnerService.showSpinner();
        if (searchtermInput) {
          backendCandidateList =
            await this.candidateService.getCandidateListBySearchTerm(
              searchtermInput,
              mandantsFilter
            );
        } else {
          backendCandidateList = await this.candidateService.getCandidateList(
            selectedStatus,
            mandantsFilter
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        SpinnerService.removeSpinner();
      }
      return backendCandidateList;
    },
    async loadMoreCandidates({ done }: any) {
      // debounce for complete scroll
      setTimeout(async () => {
        if (this.iLoader.candidatesCount < this.candidatesAll.length) {
          this.iLoader.candidatesCount += this.iLoader.incrementBy;
          this.candidateOrdered = this.candidatesAll.slice(
            0,
            this.iLoader.candidatesCount
          );
        } else if (
          this.candidateOrdered.length === this.candidatesAll.length &&
          this.candidatesAll.length > 0
        ) {
          done("empty");
          return;
        }
        done("ok");
      }, 100);
    },
    filterCandidates(candidateList: CandidateList[], filterOption?: string) {
      // newest on top
      candidateList = candidateList.reverse();
      // Filter-logic based on application date
      if (this.dateAfter || this.dateBefore) {
        candidateList = candidateList.filter((candidate: CandidateList) => {
          const applicationDate = new Date(candidate.applicationDate);
          const afterDate = this.dateAfter ? new Date(this.dateAfter) : null;
          const beforeDate = this.dateBefore ? new Date(this.dateBefore) : null;
          return (
            (!afterDate || applicationDate >= afterDate) &&
            (!beforeDate || applicationDate <= beforeDate)
          );
        });
      }

      if (filterOption) {
        switch (filterOption) {
          case FilterCandidates.Lastname:
            candidateList = candidateList.sort(
              (a, b) => a.lastName?.localeCompare(b.lastName || "") || 0
            );
            break;
          case FilterCandidates.ApplicationDate:
            candidateList = candidateList.sort(
              (a, b) =>
                new Date(b.applicationDate).getTime() -
                new Date(a.applicationDate).getTime()
            );
            break;
          case FilterCandidates.ApplicationId:
            candidateList = candidateList.sort((a, b) => {
              const appIdA = a.applicationId || "";
              const appIdB = b.applicationId || "";

              const isNumericA = !isNaN(Number(appIdA));
              const isNumericB = !isNaN(Number(appIdB));

              if (!isNumericA && isNumericB) return -1;
              if (isNumericA && !isNumericB) return 1;

              if (!isNumericA && !isNumericB) {
                return appIdA.localeCompare(appIdB);
              }

              return Number(appIdB) - Number(appIdA);
            });
            break;
          case FilterCandidates.AppliedAs:
            candidateList = candidateList.sort((a, b) => {
              if (!a.appliedAs) return -1;
              if (!b.appliedAs) return 1;
              return a.appliedAs.localeCompare(b.appliedAs);
            });
            break;
          case FilterCandidates.Postcode:
            candidateList = candidateList.sort((a, b) => {
              if (!a.addressPostalCode) return -1;
              if (!b.addressPostalCode) return 1;
              const postalCodeA = parseInt(a.addressPostalCode, 10);
              const postalCodeB = parseInt(b.addressPostalCode, 10);

              if (isNaN(postalCodeA)) return -1;
              if (isNaN(postalCodeB)) return 1;

              return postalCodeA - postalCodeB;
            });
            break;
          default:
            break;
        }
      }
      this.candidateOrdered = candidateList;
      return candidateList.map((item: any, index: any) => ({
        ...item,
        id: index,
      }));
    },
    selectStatusFilter() {
      this.updateStatusFilter();
    },
    async toggleItemsMinimized() {
      if (this.minimizeAllItems) {
        await this.iLoaderInitCards();
      }
      this.minimizeAllItems = !this.minimizeAllItems;
    },
    async iLoaderInitCards() {
      if (this.candidatesAll.length > 0) {
        this.candidateOrdered = this.candidatesAll.slice(
          0,
          this.iLoader.candidatesCountOnInit
        );
      }
    },
    updateStatusFilter: debounce(function (this: any) {
      this.loadCandidates();
      this.$store
        .dispatch("updateDispatcherBoardFilter", {
          columnName: "columnCandidate",
          property: "filterStatus",
          value: [this.selectedStatus], // Has to be in Array cause its a single status
          validArray: this.statusOptionsRecruit.map((item: any) => item.value),
        })
        .then(() => {
          this.userService.updateUserConfig(
            this.$store.state.company.loggedInUser.config
          );
        });
      this.isDebounceActive = false;
    }, 1500),
    updateMandantsFilter: debounce(function (this: any, newValue: string[]) {
      this.loadCandidates();
      this.$store
        .dispatch("updateDispatcherBoardFilter", {
          columnName: "columnCandidate",
          property: "filterMandants",
          value: newValue,
          validArray: this.$store.getters.mandantUuids,
        })
        .then(() => {
          this.userService.updateUserConfig(
            this.$store.state.company.loggedInUser.config
          );
        });
    }, 1500),
  },
});
</script>

<style scoped>
.date-filter-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.7em;
}

.date-filter-item button {
  margin-left: 5px; /* Abstand zwischen Datumseingabe und X-Button */
}

/* Stil für Labels */
label {
  cursor: pointer;
  margin: 5px;
}

/* Trennen Sie die Spalten */
.filter-menu-left-column,
.filter-menu-right-column {
  padding: 10px;
}

.filter-menu-left-column {
  width: 50%;
  border-right: 1px solid var(--border-medium);
}
.filter-menu-right-column {
  width: 50%;
  border-left: 1px solid var(--border-medium);
}

/* Stil für Datumsfelder */
input[type="date"],
input[type="time"] {
  border: 2px solid;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem;
  transition: border-color 0.3s, transform 0.3s;
}

input[type="date"]:hover,
input[type="date"]:focus,
input[type="time"]:hover,
input[type="time"]:focus {
  border-color: var(--color-accent);

  transform: scale(1.1);
}

.infinite-scroll-hidden-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer & Edge */
}

.infinite-scroll-hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari & Opera */
}

.spinner-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
