//src/enums/mobilities.enum.ts
export enum Mobility {
  OwnCar = "eigener PKW",
  Car = "Auto",
  PublicTransport = "ÖPNV",
  UpTo5Km = "Mobil bis 5 km",
  UpTo10Km = "Mobil bis 10 km",
  UpTo15Km = "Mobil bis 15 km",
  UpTo25Km = "Mobil bis 25 km",
  UpTo30Km = "Mobil bis 30 km",
  UpTo40Km = "Mobil bis 40 km",
  UpTo50Km = "Mobil bis 50 km",
  UpTo70Km = "Mobil bis 70 km",
  UpTo100Km = "Mobil bis 100 km",
}

export enum MobilitySelectable {
  OwnCar = "eigener PKW",
  Car = "Auto",
  PublicTransport = "ÖPNV",
}

export enum MobilityIcon {
  Car = "fas fa-car",
  Train = "fas fa-train-tram",
}
