import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_ctx.skillsDialog || _ctx.interviewDialog)
    ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
        default: _withCtx(() => [
          (_ctx.skillsDialog)
            ? (_openBlock(), _createBlock(_component_v_dialog, {
                key: 0,
                modelValue: _ctx.skillsDialog,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.skillsDialog) = $event)),
                persistent: "",
                "max-width": _ctx.smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Daten ergänzen ... ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    variant: _ctx.vStyle.card.variant || undefined,
                                    rounded: _ctx.vStyle.card.rounded || undefined,
                                    border: _ctx.vStyle.card.border || undefined
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_subtitle, { class: "my-4" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Kandidaten bearbeiten,")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, {
                                        variant: "text",
                                        block: "",
                                        style: {"color":"var(--color-primary)"},
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openEditCandidateDialog')))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Bearbeiten")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["variant", "rounded", "border"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_col, {
                                cols: "12",
                                md: "6"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_card, {
                                    variant: _ctx.vStyle.card.variant || undefined,
                                    rounded: _ctx.vStyle.card.rounded || undefined,
                                    border: _ctx.vStyle.card.border || undefined
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_card_subtitle, { class: "my-4" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" oder in ATS eintragen!")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, {
                                        variant: "text",
                                        block: "",
                                        style: {"color":"var(--color-primary)"},
                                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openZvoove('stammdaten')))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("1. Stammdaten")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, {
                                        variant: "text",
                                        block: "",
                                        style: {"color":"var(--color-primary)"},
                                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openZvoove('perfectmatch/skills')))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("2. Skills")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_btn, {
                                        variant: "text",
                                        block: "",
                                        style: {"color":"var(--color-primary)"},
                                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openZvoove('vita')))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("3. Lebenslauf")
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_v_divider),
                                      _createVNode(_component_v_btn, {
                                        variant: "text",
                                        block: "",
                                        color: "success",
                                        onClick: _ctx.handleAtsRecruitConfirm
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("OK und aus ATS aktualisieren")
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ]),
                                    _: 1
                                  }, 8, ["variant", "rounded", "border"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "abort",
                            onClick: _ctx.closeSkillsModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Abbrechen")
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_btn, {
                            color: "success",
                            onClick: _ctx.handleEditConfirm
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Weiter")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue", "max-width"]))
            : _createCommentVNode("", true),
          (_ctx.interviewDialog)
            ? (_openBlock(), _createBlock(_component_v_dialog, {
                key: 1,
                modelValue: _ctx.interviewDialog,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.interviewDialog) = $event)),
                persistent: "",
                "max-width": "600px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Zusammenfassung des Vorstellungsgesprächs")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_textarea, {
                            variant: _ctx.vStyle.input.variant || undefined,
                            rounded: _ctx.vStyle.input.rounded || undefined,
                            "base-color": _ctx.vStyle.input.baseColor || undefined,
                            color: _ctx.vStyle.input.color || undefined,
                            modelValue: _ctx.interviewSummary,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.interviewSummary) = $event)),
                            label: "Bitte gibt eine Zusammenfassung ein."
                          }, null, 8, ["variant", "rounded", "base-color", "color", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_btn, {
                            color: "abort",
                            onClick: _ctx.closeInterviewModal
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Abbrechen")
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_v_btn, {
                            color: "success",
                            style: {"color":"var(--color-primary)"},
                            onClick: _ctx.handleInterviewConfirm
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("OK")
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}