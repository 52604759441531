<template>
  <v-sheet
    class="d-flex justify-space-between pa-1"
    color="primary-darken-1"
    v-if="isLoggedIn"
  >
    <button
      v-if="getUserRole() === 0"
      @click="gimmeStore()"
      class="gimme-store-button"
    >
      GIMME STORE DATA
    </button>
    <v-spacer></v-spacer>
    <ZorstStatus
      v-if="$store.state.company.softwareIntegration.zorst"
    ></ZorstStatus>
    &nbsp;

    <!-- New v-menu implementation -->
    <v-btn icon density="compact" color="primary-darken-1">
      <v-icon color="white" class="menu-icon"> fa-solid fa-bars </v-icon>

      <v-menu activator="parent" offset-y>
        <v-list bg-color="primary-darken-1">
          <v-list-item
            color="error"
            v-for="item in filteredItems"
            :key="item.text"
            class="d-flex"
            @click="toggleMenu(item.route)"
          >
            <template v-slot:prepend>
              <v-icon size="xs">{{ item.icon }}</v-icon>
            </template>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { UserRole } from "@/enums/user-role.enum";
import { UserHelperService } from "@/services/user-helper.service";
import { mapActions, mapGetters } from "vuex";
import ToastService from "@/services/toast.service";
import ZorstStatus from "./ZorstStatus.vue";
import { Routes } from "@/enums/routes.enum";

export default defineComponent({
  name: "TopHeader",
  components: {
    ZorstStatus,
  },
  props: {
    isLoggedIn: Boolean,
    showVersion: Boolean,
  },
  data() {
    return {
      menuItems: [
        {
          text: "Dashboard",
          route: Routes.dashboard,
          icon: "fa-solid fa-tachometer-alt",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: "Firmenkunden",
          route: Routes.configCompany,
          icon: "fa-solid fa-building",
        },
        {
          text: "Benutzer",
          route: Routes.configUser,
          icon: "fa-solid fa-user",
        },
        {
          text: "Niederlassungen",
          route: Routes.configMandant,
          icon: "fa-solid fa-map-marker-alt",
        },
        {
          text: "Software & Schnittstellen",
          route: Routes.configApi,
          icon: "fa-solid fa-plug",
        },
        {
          text: "Recurion.ai Einstellungen",
          route: Routes.configAi,
          icon: "fa-solid fa-rocket",
        },
        {
          accessRoles: [UserRole.UberAdmin],
          text: "Backend Logs",
          route: Routes.backendLogs,
          icon: "fa-solid fa-boxes-packing",
        },
        {
          accessRoles: [
            UserRole.UberAdmin,
            UserRole.Admin,
            UserRole.CompanyAdmin,
          ],
          text: "Initialisierung & -bereinigung",
          route: Routes.dataCleaning,
          icon: "fa-solid fa-broom",
        },
        {
          text: "Version",
          route: Routes.version,
          icon: "fa-solid fa-info-circle",
          condition: "showVersion",
        },
        {
          text: "Ausloggen",
          route: Routes.logout,
          icon: "fa-solid fa-sign-out-alt",
        },
      ],
      userRole: UserHelperService.getInstance().getUserRole(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["storeHasToInit"]),
    filteredItems() {
      return this.menuItems.filter((item) => {
        if (!item.accessRoles) {
          return true;
        }
        return item.accessRoles.includes(this.userRole);
      });
    },
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  created() {
    this.checkIfStoreHasToInit();
  },
  methods: {
    ...mapActions(["loadStoreInitialData"]),
    async checkIfStoreHasToInit() {
      if (this.storeHasToInit) {
        await this.loadStoreInitialData().then(() => {
          ToastService.show(
            `Sichere Verbindung zu ${this.$store.state.company.name} hergestellt ...`
          );
        });
      }
    },
    getUserRole() {
      const userService = UserHelperService.getInstance();
      const userRole = userService.getUserRole();
      return userRole;
    },
    gimmeStore() {
      console.log(this.$store.state);
    },

    toggleMenu(route: string) {
      if (route) {
        this.router.push(route);
      }
    },
  },
});
</script>

<style scoped>
.menu-icon {
  color: var(--color-accent);
  cursor: pointer;
  font-size: 1.5rem;
}
.menu-icon:hover {
  scale: var(--small-scale-up);
  color: var(--color-font-light);
}

.gimme-store-button {
  color: white;
}

@media only screen and (max-width: 1200px) {
  .gimme-store-button {
    display: none;
  }
}
</style>
