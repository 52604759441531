import AutoLogoutService from "../auto-logout.service";
import { Api } from "./api.class";

export class HeartbeatService {
  private api = Api.getInstance();
  private urlPath = "/heartbeat";
  private autoLogoutService: AutoLogoutService;

  constructor() {
    this.autoLogoutService = AutoLogoutService.getInstance();
  }

  async sendHeartbeat(): Promise<any> {
    const result = await this.api.getBackendApi().post(`${this.urlPath}`);
    const response = result.data;
    if (response.error) {
      throw response.error;
    }
    this.autoLogoutService.extendTimer();
    return response;
  }
}
