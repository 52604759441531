export class RequestLimiter {
  private static instance: RequestLimiter;
  private maxConcurrentRequests: number;
  private queue: (() => Promise<void>)[];
  private activeRequests: number;

  private constructor(maxConcurrentRequests: number) {
    this.maxConcurrentRequests = maxConcurrentRequests;
    this.queue = [];
    this.activeRequests = 0;
  }

  static getInstance(maxConcurrentRequests = 10): RequestLimiter {
    if (!RequestLimiter.instance) {
      RequestLimiter.instance = new RequestLimiter(maxConcurrentRequests);
    }
    return RequestLimiter.instance;
  }

  async makeRequest<T>(requestFn: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.queue.push(async () => {
        try {
          this.activeRequests++;
          const result = await requestFn();
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          this.activeRequests--;
          setTimeout(() => {
            this.processQueue();
          }, 500);
        }
      });

      this.processQueue();
    });
  }

  private processQueue() {
    if (
      this.activeRequests < this.maxConcurrentRequests &&
      this.queue.length > 0
    ) {
      const nextRequest = this.queue.shift();
      if (nextRequest) nextRequest();
    }
  }
}
