import { Mandant } from "@/models/mandant.model";
import { Api } from "./api.class";
import { ApiResponse } from "@/models/api-response.model";
import { base64ToBlob } from "@/helper/pdf.helper";

export class MandantService {
  private api = Api.getInstance();
  private urlPath = "/mandant";

  async addMandant(mandant: Mandant): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .post<ApiResponse>(`${this.urlPath}`, mandant);
    return response.data.response;
  }

  async editMandant(mandant: Mandant): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(`${this.urlPath}/${mandant.uuid}`, mandant);
    return response.data.response;
  }

  async deleteMandant(uuid: string): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .delete<ApiResponse>(`${this.urlPath}/${uuid}`);
    return response.data.response;
  }

  async getMandants(): Promise<Mandant[]> {
    return (
      await this.api.getBackendApi().get<ApiResponse>(`${this.urlPath}/all`)
    ).data.response;
  }

  async addPdf(
    uuid: string,
    candidateId: string,
    profileNumber: string,
    htmlDoc: string
  ): Promise<any> {
    const response = await this.api
      .getBackendApi()
      .put<ApiResponse>(`${this.urlPath}/profile/${candidateId}`, {
        uuid,
        profileNumber,
        html: JSON.stringify(htmlDoc),
      });
    if (response.data.response) {
      return base64ToBlob(response.data.response.data);
    }
    return undefined;
  }
}
