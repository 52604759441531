<!--JobFunnels.vue-->
<template>
  <v-row>
    <v-col cols="12" md="2">
      <v-row class="ml-2 mt-5">
        <v-col cols="12" v-for="funnel in jobFunnels" :key="funnel._id">
          <v-card
            :variant="
              funnel._id === currentFunnel
                ? 'flat'
                : vStyle.card.variant || undefined
            "
            :color="funnel._id === currentFunnel ? 'secondary' : undefined"
            :rounded="vStyle.card.rounded || undefined"
            :border="vStyle.card.border || undefined"
            @click="editJobFunnel(funnel)"
          >
            <v-card-title class="d-flex justify-space-between"
              >{{ funnel.companyConfig.title }}
              <v-btn
                icon
                variant="text"
                @click="confirmDeleteFunnel(funnel._id || '')"
                ><v-icon>fa-solid fa-trash-can</v-icon></v-btn
              ></v-card-title
            >
            <v-card-subtitle>{{ funnel.funnelHeader }}</v-card-subtitle>
            <v-card-text>
              <v-img
                v-if="funnel.design.funnelHeader"
                width="100%"
                aspect-ratio="1/1"
                cover
                :src="funnel.design.funnelHeader"
              />
              <v-sheet
                height="8rem"
                :color="funnel.design.bgQuestionForm"
                class="d-flex justify-center flex-wrap"
              >
                <v-sheet
                  v-for="(question, index) in funnel.questions"
                  :key="index"
                  width="4rem"
                  height="2rem"
                  class="mt-4 mx-4 mb-0"
                  :color="funnel.design.primary"
                ></v-sheet>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            :variant="getCardVariant()"
            :color="getCardColor()"
            :rounded="vStyle.card.rounded || undefined"
            border="dashed"
            class="mt-4"
            @click="newJobFunnel()"
          >
            <v-card-title>
              <v-icon>fa-solid fa-plus</v-icon>
              Funnel
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="10">
      <JobFunnelEdit
        ref="jobFunnelEditComponent"
        @loadJobFunnels="loadJobFunnels()"
      ></JobFunnelEdit>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import DialogService from "@/services/dialog.service";
import JobFunnelEdit from "@/components/recruiting/elements/JobFunnelEdit.vue";
import { defineComponent } from "vue";
import { FunnelService } from "../../services/api/funnel.service";
import { JobFunnel } from "../../models/job-funnel.model";
import ToastService from "../../services/toast.service";
import { CurrentFunnel } from "../../enums/funnel.enum";

export default defineComponent({
  name: "JobFunnels",
  components: {
    JobFunnelEdit,
  },
  data() {
    return {
      funnelService: new FunnelService(),
      jobFunnels: {} as JobFunnel[],
      CurrentFunnel: CurrentFunnel,
      currentFunnel: "",
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["company"]), //Get Funnel Data from vuex store.je
  },
  mounted() {
    this.loadJobFunnels();
  },
  methods: {
    async confirmDeleteFunnel(id: string) {
      if (!id) return;
      const confirmed = await DialogService.confirm(
        "Die ausgewählte Landingpage löschen?",
        "Abbrechen",
        "Löschen"
      );
      if (confirmed) {
        try {
          this.funnelService.deleteFunnel(id).then(() => {
            ToastService.showSuccess("Funnel wurde erfolgreich entfernt");
            this.loadJobFunnels();
          });
        } catch (error) {
          ToastService.showError("Fehler beim Löschen des Funnels");
        }
      }
    },
    editJobFunnel(jobFunnel: JobFunnel) {
      if (jobFunnel._id) this.currentFunnel = jobFunnel._id;
      if (this.$refs.jobFunnelEditComponent) {
        (
          this.$refs.jobFunnelEditComponent as InstanceType<
            typeof JobFunnelEdit
          >
        ).editJobFunnel(jobFunnel);
      }
    },
    getCardVariant() {
      return this.currentFunnel === CurrentFunnel.creatingNewFunnel
        ? "flat"
        : this.vStyle.card.variant || undefined;
    },
    getCardColor() {
      return this.currentFunnel === CurrentFunnel.creatingNewFunnel
        ? "secondary"
        : undefined;
    },
    async loadJobFunnels() {
      this.jobFunnels = await this.funnelService.getAllFunnels();
    },
    newJobFunnel() {
      if (this.currentFunnel === CurrentFunnel.creatingNewFunnel) return;
      this.currentFunnel = CurrentFunnel.creatingNewFunnel;
      if (this.$refs.jobFunnelEditComponent) {
        (
          this.$refs.jobFunnelEditComponent as InstanceType<
            typeof JobFunnelEdit
          >
        ).newJobFunnel();
      }
    },
  },
});
</script>
