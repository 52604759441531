<!-- views/dispostion-view.vue -->
<template>
  <div v-if="layout === AppLayout.dispositioner">
    <MainHeader
      @tab-selected="handleTabSelection"
      :showDispositionTabs="true"
    ></MainHeader>
    <DispatcherBoard
      v-if="currentTab === HeaderTab.DispatcherBoard"
    ></DispatcherBoard>
    <Calendar v-if="currentTab === HeaderTab.Calendar"></Calendar>
  </div>
  <v-row v-if="layout === AppLayout.pdmaster">
    <v-col cols="12" md="auto">
      <LeftHeader></LeftHeader>
    </v-col>
    <v-col cols="12" md="11">
      <PdMasterHeader
        @tab-selected="handleTabSelection"
        :showDispositionTabs="true"
      ></PdMasterHeader>
      <DispatcherBoard
        v-if="currentTab === HeaderTab.DispatcherBoard"
      ></DispatcherBoard>
      <Calendar v-if="currentTab === HeaderTab.Calendar"></Calendar>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { AppLayout } from "@/enums/app-layout.enum";
import { defineComponent } from "vue";
import { HeaderTab } from "@/enums/header-tabs.enum";
import { ZvooveOneMultiFetch } from "@/services/api/api-integration-one.service";
import Calendar from "@/components/disposition/AppointmentCalendar.vue";
import DispatcherBoard from "@/components/disposition/DispatcherBoard.vue";
import LeftHeader from "@/components/header/LeftHeader.vue";
import MainHeader from "@/components/header/Header.vue";
import PdMasterHeader from "@/components/header/PdMasterHeader.vue";

export default defineComponent({
  name: "DispositionView",
  components: {
    Calendar,
    DispatcherBoard,
    LeftHeader,
    MainHeader,
    PdMasterHeader,
  },
  data() {
    return {
      AppLayout: AppLayout,
      currentTab: HeaderTab.DispatcherBoard as string,
      HeaderTab: HeaderTab,
      layout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
    };
  },
  methods: {
    async fetchZvooveOneInitData() {
      if (
        !this.$store.getters.softwareIntegration.zvooveOne ||
        this.$store.getters.customerList.length > 0
      ) {
        return;
      }
      try {
        const zvooveOneMultiFetch = new ZvooveOneMultiFetch();
        await zvooveOneMultiFetch.fetchZvooveOneInitData(
          this.$store.getters.allZvooveOneGeschaeftsstelleIds,
          this.$store.getters.getLoggedInMandantBranchNumbers
        );
      } catch (error) {
        console.error("Fehler beim Abrufen der Initialdaten", error);
      }
    },
    handleTabSelection(selectedTab: string) {
      this.currentTab = selectedTab;
    },
  },
});
</script>

<style scoped>
/* Stile hier */
</style>
