<!--src/components/dispostion/PhoneClient.vue-->
<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        icon
        class="phone-icon"
        variant="text"
        density="compact"
      >
        <v-icon size="xsmall">fa-solid fa-phone</v-icon>
        <v-tooltip activator="parent" location="bottom"
          >Telefonat starten</v-tooltip
        >
      </v-btn>
    </template>
    <v-card
      :variant="vStyle.card.variant || undefined"
      :rounded="vStyle.card.rounded || undefined"
      :border="vStyle.card.border || undefined"
    >
      <v-card-subtitle class="my-1">Telefon</v-card-subtitle>
      <v-list dense>
        <v-list-item @click="handleOutgoingCall"
          ><v-icon class="text-medium-emphasis">fas fa-phone</v-icon
          ><v-icon class="text-medium-emphasis mr-3 mb-3" size="xsmall"
            >fas fa-right-long</v-icon
          >Ausgehend</v-list-item
        >
        <v-list-item @click="handleIncomingCall"
          ><v-icon class="text-medium-emphasis mr-1">fas fa-phone-volume</v-icon
          ><v-icon class="text-medium-emphasis mr-2 mb-3" size="xsmall"
            >fas fa-left-long</v-icon
          >Eingehend</v-list-item
        >
      </v-list>
    </v-card>
  </v-menu>

  <v-dialog
    v-model="showSelectNummerDialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
  >
    <v-card>
      <v-card-title>Wähle eine Nummer</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(phoneNumber, index) in phoneNumbers"
            :key="index"
            @click="dialNumber(phoneNumber)"
            :title="phoneNumber.label"
            :subtitle="phoneNumber.value"
          >
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="showSelectNummerDialog = false"
          >Schließen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showSelectContactDialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
  >
    <v-card>
      <v-card-title>Wähle einen Ansprechpartner</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="(phoneNumber, index) in phoneNumbers"
            :key="index"
            @click="selectContact(phoneNumber)"
            :subtitle="phoneNumber.label"
          >
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="showSelectContactDialog = false"
          >Schließen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showResultDialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xxs)'"
  >
    <v-card>
      <v-card-title class="d-flex"
        >{{ isIncoming ? "Eingehendes Telefonat" : "Teilnehmer erreicht?" }}
        <v-spacer></v-spacer>
        <externalSoftwareLinksMenu
          v-if="candidate"
          :zvooveRecruitMitarbeiterUuid="candidate.candidateUuid"
          :hideUpdateMenuPoint="true"
        ></externalSoftwareLinksMenu>

        <externalSoftwareLinksMenu
          v-else-if="employee"
          :zvooveOneEmployeeNumber="employee.employeeNumber"
          :hideUpdateMenuPoint="true"
        ></externalSoftwareLinksMenu>

        <externalSoftwareLinksMenu
          v-else-if="customer"
          :zvooveOneCustomerNumber="customer.customerNumber"
          :hideUpdateMenuPoint="true"
        ></externalSoftwareLinksMenu>
      </v-card-title>
      <v-card-subtitle v-if="candidate">{{ candidateName }}</v-card-subtitle>
      <v-card-subtitle v-if="customer">{{
        selectedPhoneNumber?.label
      }}</v-card-subtitle>
      <v-card-subtitle v-if="candidate"
        >{{ employee?.firstName }} {{ employee?.lastName }}
      </v-card-subtitle>
      <v-card-text>
        <v-btn
          variant="outlined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          block
          color="primary"
          class="mb-1"
          @click="callEstablished()"
          >{{ isIncoming ? "Notiz schreiben" : "Erreicht?" }}</v-btn
        >
        <v-btn
          variant="outlined"
          :rounded="vStyle.btn.rounded || undefined"
          block
          v-if="customer || candidate"
          color="primary"
          class="mb-1"
          @click="setAppointment()"
          >Termin / Ergebnis</v-btn
        >
        <v-btn
          variant="outlined"
          :rounded="vStyle.btn.rounded || undefined"
          v-if="!isIncoming"
          block
          color="primary"
          class="mb-1"
          @click="closeModalNotReached('nicht erreicht')"
          >Nicht erreicht</v-btn
        >
        <v-btn
          v-if="customer || candidate"
          block
          color="primary"
          variant="text"
          class="mt-5"
          @click="addFollowUp()"
          >Wiedervorlage</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="showNoteDialog"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-xs)'"
  >
    <v-card>
      <v-card-title>Notiz zum Telefonat</v-card-title>
      <v-card-text>
        <v-textarea
          :variant="vStyle.input.variant || undefined"
          :rounded="vStyle.input.rounded || undefined"
          :base-color="vStyle.input.baseColor || undefined"
          :color="vStyle.input.color || undefined"
          v-model="callNote"
          label="Notiz"
        ></v-textarea>
        <v-btn
          variant="outlined"
          :rounded="vStyle.btn.rounded || undefined"
          v-if="customer"
          block
          class="mb-2"
          density="compact"
          color="primary"
          @click="addDemand()"
          >Anfrage anlegen</v-btn
        >
        <v-btn
          variant="outlined"
          :rounded="vStyle.btn.rounded || undefined"
          v-if="customer || candidate"
          block
          density="compact"
          color="primary"
          @click="addFollowUp()"
          >Wiedervorlage</v-btn
        >
      </v-card-text>
      <v-card-actions>
        <v-btn
          :variant="vStyle.btn.variant || undefined"
          :rounded="vStyle.btn.rounded || undefined"
          :border="vStyle.btn.border || undefined"
          color="primary"
          @click="backToResultModal()"
          >zurück</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submitNote(true)"
          >Speichern + zurück</v-btn
        >
        <v-btn color="success" @click="submitNote()">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Mandant } from "@/models/mandant.model";
import { Customer } from "@/models/customer.model";
import { CandidateList } from "@/models/candidate-list.model";
import { PhoneDetails } from "@/models/phone-details.model";
import { TimelineHelperService } from "@/services/timeline-helper.service";
import ToastService from "@/services/toast.service";
import { useDisplay } from "vuetify";
import externalSoftwareLinksMenu from "./ExternalSoftwareLinksMenu.vue";
import { mapGetters, mapMutations } from "vuex";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { DemandItem } from "@/models/demand.model";
import { Employee } from "@/models/employee.model";

export default defineComponent({
  name: "PhoneClient",
  emits: [
    "collapseParentItem",
    "setAppointmentEvent",
    "addFollowUpEvent",
    "isDialingNumber",
  ],
  components: {
    externalSoftwareLinksMenu: externalSoftwareLinksMenu,
  },
  props: {
    candidate: {
      type: Object as () => CandidateList | null,
      default: null,
    },
    candidateName: {
      type: String,
      default: "",
    },
    customer: {
      type: Object as () => Customer | null,
      default: null,
    },
    employee: {
      type: Object as () => Employee | null,
      default: null,
    },
    phoneNumbers: {
      type: Array,
      required: true,
    } as any,
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      callNote: "",
      isIncoming: false,
      selectedPhoneNumber: null as PhoneDetails | null,
      showSelectContactDialog: false,
      showSelectNummerDialog: false,
      showNoteDialog: false,
      showResultDialog: false,
      timelineHelperService: new TimelineHelperService(),
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters({
      interComponentMessage: "interComponentMessage",
    }),
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.sendDemandDetailsBackToPhoneClient:
            this.addDemandDocuToCallNote(newVal.payload);
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
      clearICM: MutationType.clearICM,
    }),
    addDemand() {
      if (this.customer && this.selectedPhoneNumber) {
        const contactDetails = this.selectedPhoneNumber.label.split(" ");
        const [firstName, lastName] = contactDetails.slice(1, 3);

        const contact = this.customer.contacts.find(
          (contact) =>
            contact.firstName === firstName && contact.lastName === lastName
        );

        this.setICM({
          message: InterComponentMessage.addDemandFromPhoneClient,
          payload: {
            customer: this.customer.generalData.name ?? "",
            customerNumber: this.customer.customerNumber ?? "",
            mandant: this.customer.mandants[0] ?? "",
            postcode: this.customer.addressAndCommunication.postalCode ?? "",
            city: this.customer.addressAndCommunication.city ?? "",
            contact: contact
              ? {
                  salutation: contact.salutation,
                  firstName: contact.firstName,
                  lastName: contact.lastName,
                  phone: contact.phone,
                  email: contact.email,
                  role: contact.role,
                }
              : null,
          },
        });
      }
    },
    addDemandDocuToCallNote(demands: DemandItem[]) {
      const demandTextLines = demands.map(
        (demand) =>
          `${demand.quantity} x ${demand.position} in ${demand.location.postcode} ${demand.location.city}`
      );

      const newLines = ["Auftrag/Anfrage aufgenommen:", ...demandTextLines];
      const newText = newLines.join("\n");

      if (!this.callNote.includes(newText)) {
        this.callNote += this.callNote ? `\n\n${newText}` : newText;
      }
      this.clearICM();
    },
    addFollowUp() {
      this.$emit("addFollowUpEvent");
      this.showResultDialog = false;
    },
    backToResultModal() {
      this.showResultDialog = true;
      this.showNoteDialog = false;
    },
    callEstablished() {
      this.showResultDialog = false;
      this.showNoteDialog = true;
    },
    closeModalNotReached(noteText: string) {
      this.submitEntry(noteText);
      this.$emit("collapseParentItem");
      this.showResultDialog = false;
    },
    dialNumber(selectedNumberObj: any) {
      this.isIncoming = false;
      window.location.href = `tel:${selectedNumberObj.value}`;
      this.selectedPhoneNumber = selectedNumberObj;
      this.$emit("isDialingNumber");
      this.showResultDialog = true;
      this.showSelectNummerDialog = false;
    },
    handleOutgoingCall() {
      this.isIncoming = false;
      if (this.phoneNumbers.length === 1) {
        this.selectedPhoneNumber = this.phoneNumbers[0];
        this.dialNumber(this.phoneNumbers[0]);
      } else {
        this.showSelectNummerDialog = true;
      }
    },
    handleIncomingCall() {
      this.isIncoming = true;
      if (this.phoneNumbers.length === 1) {
        this.selectedPhoneNumber = this.phoneNumbers[0];
        this.showResultDialog = true;
      } else {
        if (this.customer) {
          this.showSelectContactDialog = true;
        } else {
          this.showResultDialog = true;
        }
      }
    },
    selectContact(selectedNumberObj: any) {
      this.selectedPhoneNumber = selectedNumberObj;
      this.showResultDialog = true;
      this.showSelectContactDialog = false;
    },
    setAppointment() {
      this.$emit("setAppointmentEvent");
      this.showResultDialog = false;
    },
    submitEntry(noteText: string) {
      const mandants = this.$store.state.company.mandants;

      let timelineData = {
        note: noteText,
        entryType: "",
        mandant: "",
        customer: undefined as
          | undefined
          | { name: string; contact: string; customerId: string | undefined },
        participant: undefined as
          | undefined
          | { name: string; uuid: string; employeeId: string },
      };

      if (this.candidate) {
        const participantName = this.candidateName;
        const mandantUuid = this.candidate?.mandants[0]
          ? this.candidate?.mandants[0]
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.phoneCallCandidate.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: this.candidate.candidateUuid,
          employeeId: "", // Placeholder for EmployeeItem.vue integration
        };
      } else if (this.customer) {
        const customerName = this.customer.generalData.name;
        const customerContact = this.selectedPhoneNumber?.label ?? "";
        const customerId = this.customer._id;
        const mandantUuid = this.customer.mandants[0] || "";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.phoneCallCustomer.description;
        timelineData.mandant = mandantUuid;
        timelineData.customer = {
          name: customerName,
          contact: customerContact,
          customerId: customerId,
        };
      } else if (this.employee) {
        const participantName = `${this.employee.firstName} ${this.employee.lastName}`;
        const matchedMandant = mandants.find(
          (m: Mandant) => m.branchNumber === this.employee?.branchOfficeId
        );
        const mandantUuid = matchedMandant
          ? matchedMandant.uuid
          : "StandardwertWennNichtGefunden";

        timelineData.entryType =
          this.$store.state.company.timelineEntryTypes.phoneCallEmployee.description;
        timelineData.mandant = mandantUuid;
        timelineData.participant = {
          name: participantName,
          uuid: "", // TODO: Add when added in Employee model (employee was a candidate ...)
          employeeId: this.employee._id ?? "",
        };
      }
      this.timelineHelperService
        .timelineAutoDocu(timelineData)
        .then(() => {
          ToastService.show("Eintrag erfolgreich hinzugefügt.");
          if (this.showNoteDialog) {
            this.showNoteDialog = false;
            this.callNote = "";
          }
        })
        .catch((error: any) => {
          console.error("Fehler beim Hinzufügen des Timeline-Eintrags:", error);
          ToastService.showError("Fehler beim Dokumentieren des Telefonats.");
        });
    },
    submitNote(backToResultModal?: boolean) {
      if (!this.callNote.trim()) {
        ToastService.showError("Bitte Telefonat dokumentieren");
        return;
      }
      this.submitEntry(this.callNote);
      this.showNoteDialog = false;
      this.callNote = "";
      if (backToResultModal) {
        this.showResultDialog = true;
      }
    },
  },
});
</script>

<style scoped>
.phone-icon {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.phone-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
