<template>
  <div>
    <v-menu
      v-model="menu"
      activator="parent"
      :close-on-content-click="false"
      max-width="300"
    >
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-text>
          <v-text-field
            variant="underlined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="searchQuery"
            label="Mitarbeiter suchen"
            @input="filterEmployees"
            dense
            clearable
          ></v-text-field>
        </v-card-text>
        <v-list>
          <v-list-item
            v-for="(employee, index) in filteredEmployees"
            :key="index"
            @click="selectEmployee(employee)"
          >
            {{ employee.name }}
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { EmployeeList } from "@/models/employee.model";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { debounce } from "lodash";

export default defineComponent({
  name: "SearchEmployee",
  emits: ["employeeSelected"],
  data() {
    return {
      searchQuery: "",
      menu: false,
      filteredEmployees: [],
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ...mapGetters(["employeeList"]),
  },
  methods: {
    toggleMenu() {
      this.menu = !this.menu;
    },
    filterEmployees: debounce(function (this: any) {
      const query = this.searchQuery.toLowerCase();
      this.filteredEmployees = this.employeeList.filter(
        (employee: EmployeeList) =>
          employee.status === 2 && employee.name.toLowerCase().includes(query)
      );
    }, 1500),
    selectEmployee(employee: { employeeNumber: string; name: string }) {
      this.menu = false;
      this.$emit("employeeSelected", employee.employeeNumber, employee.name);
    },
  },
});
</script>
