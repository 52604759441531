import { AiRequest, AiRequestObject } from "../../models/ai-specs.model";
import { Api } from "./api.class";

export class AiCluster {
  private api = Api.getInstance();
  private urlPathNeural = "/ai-core";
  private urlPath = "/ai-cluster";

  async clusterRequest(request: AiRequestObject, file?: File) {
    const formData = new FormData();
    formData.append("request", JSON.stringify(request));
    if (file) {
      formData.append("file", file);
    }
    return await this.api.getBackendApi().post(this.urlPath, formData);
  }

  async neuralRequest(request: AiRequest, file?: File) {
    const formData = new FormData();
    formData.append("request", JSON.stringify(request));
    if (file) {
      formData.append("file", file);
    }
    return await this.api.getBackendApi().post(this.urlPathNeural, formData);
  }
}
