<!-- src/components/Joblist.vue -->
<template>
  <v-row class="my-4">
    <v-col cols="12" sm="4">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-card-title>Stellen hinzufügen</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentJob.title"
                  label="Stellenbezeichnung"
                  placeholder="Stellenbezeichnung eingeben"
                  density="compact"
                  :rules="[rules.required]"
                  data-tid="new-form-job-title"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentLocation.postalCode"
                  label="PLZ"
                  type="number"
                  placeholder="PLZ"
                  density="compact"
                  data-tid="new-form-postal-code"
                  :rules="[rules.postalCode, rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentLocation.city"
                  label="Ort"
                  placeholder="Ort"
                  density="compact"
                  data-tid="new-form-city"
                  :rules="[rules.city, rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentJob.tasks"
                  label="Aufgaben"
                  placeholder="Aufgaben beschreiben"
                  rows="3"
                  auto-grow
                  density="compact"
                  data-tid="new-form-tasks"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentJob.profile"
                  label="Profil"
                  placeholder="Profil beschreiben"
                  rows="3"
                  auto-grow
                  density="compact"
                  data-tid="new-form-profile"
                ></v-textarea>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentJob.salaryFrom"
                  label="Gehalt von"
                  type="number"
                  density="compact"
                  data-tid="new-form-salary-from"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentJob.salaryTo"
                  label="Gehalt bis"
                  type="number"
                  density="compact"
                  data-tid="new-form-salary-to"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentJob.salaryPeriod"
                  label="Währung / Zeitraum"
                  placeholder="z.B. EUR/Jahr"
                  density="compact"
                  data-tid="new-form-salary-period"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="currentJob.socialMedia"
                  label="Social Media"
                  density="compact"
                  data-tid="new-form-socialmedia"
                  disabled
                ></v-checkbox>
              </v-col>
              <v-col cols="9">
                <v-autocomplete
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  chips
                  label="Bundesagentur für Arbeit Berufsbezeichnungen"
                  v-model="currentJob.categoriesBfA"
                  :items="allCategoriesBfA"
                  multiple
                  density="compact"
                  data-tid="edit-form-bfa-categories"
                  prepend-inner-icon="fa-solid fa-magnifying-glass"
                  @click:prepend-inner="
                    checkForBfAJobCategories(currentJob.title)
                  "
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-checkbox
                  v-model="currentJob.supportedBfAJobAd"
                  label="Betreuung"
                  density="compact"
                  data-tid="new-form-bfa-support"
                  :disabled="currentJob.categoriesBfA.length === 0"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-select
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="currentLocation.mandants[0]"
                  :items="mandanten"
                  item-title="name"
                  item-value="uuid"
                  label="Niederlassung wählen"
                  placeholder="Niederlassung wählen"
                  density="compact"
                  data-tid="new-form-mandant"
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-btn
                  :variant="vStyle.btn.variant || undefined"
                  :rounded="vStyle.btn.rounded || undefined"
                  :border="vStyle.btn.border || undefined"
                  type="submit"
                  color="success"
                  data-tid="new-form-submit"
                  :disabled="!valid"
                >
                  Auf Liste setzen
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-container class="d-flex my-0">
          <v-checkbox
            v-model="publishOnHomepage"
            label="Direkt auf allen eigenen Stellenbörsen veröffentlichen"
            density="compact"
            data-tid="new-form-publish-on-homepage"
          ></v-checkbox>
          <v-checkbox
            v-model="publishOnBfA"
            label="Auf Bundesagentur für Arbeit veröffentlichen"
            density="compact"
            style="font-size: 0.9em"
            data-tid="new-form-publish-on-bfa"
          ></v-checkbox>
        </v-container>
        <v-card-text class="d-flex mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            :variant="vStyle.btn.variant || undefined"
            :rounded="vStyle.btn.rounded || undefined"
            :border="vStyle.btn.border || undefined"
            @click="postJobList"
            color="warning"
            data-tid="new-form-post-joblist"
          >
            Liste ausschreiben!
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8">
      <v-card
        :variant="vStyle.card.variant || undefined"
        :rounded="vStyle.card.rounded || undefined"
        :border="vStyle.card.border || undefined"
      >
        <v-table>
          <thead>
            <tr>
              <th>Stellenbezeichnung</th>
              <th>Ort</th>
              <th>Aufgaben</th>
              <th>Profil</th>
              <th>Gehalt von bis</th>
              <th>Mandant</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="longpress"
              v-for="jobAd in jobAds"
              :key="jobAd._id"
              @contextmenu.prevent="openContextMenu($event, jobAd)"
              @longpress="openContextMenu($event, jobAd)"
            >
              <td class="px-1" style="width: 15%">
                {{ jobAd.title }}
                <span class="text-medium-emphasis font-weight-medium">{{
                  jobAd.supportedBfAJobAd
                    ? "- betreute Stelle Bundesagentur für Arbeit"
                    : ""
                }}</span>
              </td>
              <td class="px-1" style="width: 15%">
                <!--linked location object-->
                {{ getLocationById(jobAd.locationId)?.postalCode }}
                {{ getLocationById(jobAd.locationId)?.city }}
              </td>
              <td class="px-1" style="width: 20%">{{ jobAd.tasks }}</td>
              <td class="px-1" style="width: 20%">{{ jobAd.profile }}</td>
              <td class="px-1" style="width: 10%">
                {{ jobAd.salaryFrom }} - {{ jobAd.salaryTo }}
                {{ jobAd.salaryPeriod }}
              </td>
              <td class="px-1" style="width: 15%">
                {{ getMandantNameById(jobAd.locationId) }}
              </td>
              <td class="px-1" style="width: 5%">
                <v-icon
                  v-if="jobAd.status === PostingStatus.requested"
                  class="fas fa-newspaper"
                ></v-icon>
                <v-icon
                  v-else-if="jobAd.status === PostingStatus.posted"
                  class="fas fa-square-check"
                ></v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card>
    </v-col>
    <!-- Contextmenu -->
    <v-menu
      v-model="showContextMenu"
      :style="{
        top: contextMenuPosition.y + 'px',
        left: contextMenuPosition.x + 'px',
      }"
    >
      <v-card class="px-0">
        <v-card-text>
          <v-list
            v-if="selectedJob.uuid && softwareIntegration.zvooveRecruit"
            class="px-2 context-menu-item"
            @click="openJodAdInAtsRecruit(selectedJob.uuid)"
            id="context-menu-open-ats"
          >
            in ATS Recruit ansehen
          </v-list>
          <v-list
            class="px-2 context-menu-item"
            @click="editJob(selectedJob)"
            data-tid="context-menu-edit"
          >
            Eintrag bearbeiten
          </v-list>
          <v-list
            class="px-2 context-menu-item"
            @click="deleteJob((selectedJob as JobAd)._id)"
            data-tid="context-menu-delete"
          >
            Eintrag löschen
          </v-list>
          <v-list
            class="px-2 context-menu-item"
            @click="removeAllPosted"
            data-tid="context-menu-remove-posted"
          >
            Alle Veröffentlichten entfernen
          </v-list>
          <v-list
            class="px-2 context-menu-item"
            @click="removeAllEntries"
            data-tid="context-menu-remove-all"
          >
            Alle Einträge löschen
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-row>
  <!-- Modal for editing -->
  <v-dialog
    v-model="showModal"
    persistent
    :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-sm)'"
  >
    <v-card>
      <v-container class="d-flex">
        <v-card-title> Bearbeiten </v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="close-icon">
          fa-solid fa-xmark
        </v-icon>
      </v-container>
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Stellenbezeichnung"
            v-model="currentJob.title"
            placeholder="Stellenbezeichnung eingeben"
            density="compact"
            :rules="[rules.required]"
            data-tid="edit-form-job-title"
          ></v-text-field>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="PLZ"
                v-model="currentLocation.postalCode"
                type="number"
                placeholder="PLZ"
                density="compact"
                data-tid="edit-form-postal-code"
                :rules="[rules.postalCode, rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="Ort"
                v-model="currentLocation.city"
                placeholder="Ort"
                density="compact"
                data-tid="edit-form-city"
                :rules="[rules.city, rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-select
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Mandant auswählen"
            v-model="currentJob.mandants[0]"
            :items="mandanten"
            item-title="name"
            item-value="uuid"
            placeholder="Mandant auswählen"
            density="compact"
            data-tid="edit-form-mandant"
            @update:model-value="updateLocationMandant(currentJob.mandants[0])"
            :rules="[rules.required]"
          ></v-select>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Aufgaben"
            v-model="currentJob.tasks"
            placeholder="Beschreiben Sie die Aufgaben"
            rows="3"
            auto-grow
            density="compact"
            data-tid="edit-form-tasks"
          ></v-textarea>
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            label="Profil"
            v-model="currentJob.profile"
            placeholder="Profil beschreiben"
            rows="3"
            auto-grow
            density="compact"
            data-tid="edit-form-profile"
          ></v-textarea>
          <v-autocomplete
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            chips
            label="Bundesagentur für Arbeit Berufsbezeichnungen"
            v-model="currentJob.categoriesBfA"
            :items="allCategoriesBfA"
            multiple
            density="compact"
            data-tid="edit-form-bfa-categories"
            prepend-inner-icon="fa-solid fa-magnifying-glass"
            @click:prepend-inner="checkForBfAJobCategories(currentJob.title)"
          ></v-autocomplete>
          <v-checkbox
            v-model="currentJob.supportedBfAJobAd"
            label="in Betreuung bei der Bundesagentur für Arbeit geben"
            density="compact"
            data-tid="new-form-bfa-support"
            :disabled="currentJob.categoriesBfA.length === 0"
          ></v-checkbox>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="Gehalt von"
                v-model="currentJob.salaryFrom"
                type="number"
                placeholder="Gehalt von"
                density="compact"
                data-tid="edit-form-salary-from"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="Gehalt bis"
                v-model="currentJob.salaryTo"
                type="number"
                placeholder="Gehalt bis"
                density="compact"
                data-tid="edit-form-salary-to"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :variant="vStyle.input.variant || undefined"
                :rounded="vStyle.input.rounded || undefined"
                :base-color="vStyle.input.baseColor || undefined"
                :color="vStyle.input.color || undefined"
                label="Währung / Zeitraum"
                v-model="currentJob.salaryPeriod"
                placeholder="z.B. EUR/Jahr"
                density="compact"
                data-tid="edit-form-salary-period"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-checkbox
            label="Social Media"
            v-model="currentJob.socialMedia"
            data-tid="edit-form-socialmedia"
            disabled
          ></v-checkbox>
          <v-card-actions>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              color="abort"
              @click="cancelEdit"
            >
              Abbrechen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              :disabled="!valid"
              color="primary"
              @click="handleSubmit"
              data-tid="edit-form-submit"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { BfaService } from "@/services/api/bfa.service";
import { defineComponent } from "vue";
import { JobAdService } from "@/services/api/job-ad.service";
import { JobPosterService } from "@/services/job-poster.service";
import { Mandant } from "@/models/mandant.model";
import { mapGetters, mapState } from "vuex";
import { useDisplay } from "vuetify";
import DialogService from "@/services/dialog.service";
import Fuse, { FuseResult } from "fuse.js";
import {
  JobAd,
  jobAdType,
  JobLocation,
  PostingStatus,
} from "@/models/job-ad.model";
import { RulesMessage } from "../../enums/app-messages.enum";

export default defineComponent({
  name: "JobList",
  computed: {
    ...mapGetters({
      softwareIntegration: "softwareIntegration",
    }),
    ...mapState({
      mandanten: (state: any) => state.company.mandants,
    }),
    publishOnHomepage: {
      get(): boolean {
        return this.$store.getters.isJobAdPublishOnHomepage;
      },
      set(value: boolean) {
        this.$store.commit("SET_JOB_AD_PUBLISH_HOMEPAGE", value);
      },
    },
    publishOnBfA: {
      get(): boolean {
        return this.$store.getters.isJobAdPublishOnBfA;
      },
      set(value: boolean) {
        this.$store.commit("SET_JOB_AD_PUBLISH_ON_BFA", value);
      },
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      allCategoriesBfA: [] as string[],
      contextMenuPosition: { x: 0, y: 0 },
      currentLocation: {
        city: "",
        postalCode: null,
        mandants: [""],
      } as any,
      currentJob: {
        categoriesBfA: [],
        locationId: "",
        mandants: [],
        profile: "",
        socialMedia: false,
        supportedBfAJobAd: false,
        salaryFrom: null,
        salaryTo: null,
        salaryPeriod: "",
        status: PostingStatus.requested,
        tasks: "",
        title: "",
        type: jobAdType.joblist,
      } as JobAd,
      jobAds: [] as JobAd[],
      jobLocations: [] as JobLocation[],
      jobAdService: new JobAdService(),
      jobPosterService: new JobPosterService(),
      PostingStatus: PostingStatus,
      rules: {
        required: (value: string) => !!value || RulesMessage.required,
        postalCode: (value: string) =>
          /^[0-9]{4,5}$/.test(value) || RulesMessage.postcode,
        city: (value: string) =>
          /^[a-zA-ZäöüÄÖÜß\s]+$/.test(value) || RulesMessage.city,
        number: (value: number | null) =>
          (value !== null && !isNaN(value)) || RulesMessage.numberField,
      },
      showModal: false,
      showContextMenu: false,
      selectedJob: {} as JobAd,
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  async mounted() {
    const bfaService = new BfaService();
    this.allCategoriesBfA = await bfaService.getBfaJoblist();
  },
  watch: {
    "this.$store.state.jobPosterState.postedJobAds"() {
      this.loadJobList();
    },
  },
  methods: {
    cancelEdit() {
      this.showModal = false;
      this.resetForm();
    },
    checkForBfAJobCategories(jobTitle: string) {
      const fuseOptions = {
        includeScore: true,
        keys: ["name"],
      };

      const fuse = new Fuse(this.allCategoriesBfA, fuseOptions);
      const result: FuseResult<string>[] = fuse.search(jobTitle);
      const top3 = result.slice(0, 3);
      const similarJobs = top3.map((item) => item.item);
      this.currentJob.categoriesBfA = similarJobs;
    },
    closeContextMenu() {
      this.showContextMenu = false;
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    deleteJob(id: string | undefined) {
      if (id) {
        this.jobAdService.deleteJobAd(id).then(async () => {
          this.jobAds = await this.jobAdService.getJobAds(jobAdType.joblist);
          this.jobLocations = await this.jobAdService.getLocations();
        });
      }
    },
    editJob(job: JobAd) {
      this.currentJob = { ...job };
      if (job._id)
        this.currentLocation = this.getLocationById(job.locationId) || {
          _id: "",
          postalCode: null,
          city: "",
          mandant: "",
        };
      this.showModal = true;
      this.showContextMenu = false;
    },
    findMandantName(uuid: string) {
      const mandant = this.mandanten.find((m: Mandant) => m.uuid === uuid);
      return mandant ? mandant.name : "Unbekannt";
    },
    async postJobList() {
      try {
        await this.jobPosterService.jobPostingLoop(jobAdType.joblist); //Type 1 for joblist job ads
      } catch (error: any) {
        console.error("Fehler beim Ausschreiben der JobList Stelle:", error);
      }
    },
    getLocationById(id: string) {
      const location = this.jobLocations.find(
        (location: JobLocation) => location._id === id
      );
      return location || null;
    },
    getMandantNameById(id: string) {
      const location = this.getLocationById(id);

      const mandant = this.mandanten.find(
        (m: Mandant) => m.uuid === location?.mandants?.[0]
      );
      return mandant ? mandant.name : "Unbekannt";
    },
    handleSubmit() {
      // checking minimal need job data
      if (!this.currentJob.title.trim()) {
        DialogService.alert("Bitte geben Sie eine Stellenbezeichnung ein.");
        return;
      }
      if (!this.currentLocation.postalCode) {
        DialogService.alert("Bitte geben Sie eine PLZ ein.");
        return;
      }
      if (!this.currentLocation.city.trim()) {
        DialogService.alert("Bitte geben Sie einen Ort ein.");
        return;
      }
      if (!this.currentLocation.mandants[0]) {
        DialogService.alert("Bitte wählen Sie eine Niederlassung aus.");
        return;
      }
      if (this.currentJob._id) {
        this.currentJob.mandants = this.currentLocation.mandants;
        this.jobAdService
          .updateJobAdAndJobLocation(this.currentJob, this.currentLocation)
          .then(async () => {
            this.jobAds = await this.jobAdService.getJobAds(jobAdType.joblist);
            this.jobLocations = await this.jobAdService.getLocations();
          });
      } else {
        this.currentJob.mandants = this.currentLocation.mandants;
        this.jobAdService
          .addNewJobAd(this.currentJob, this.currentLocation)
          .then(async () => {
            this.jobAds = await this.jobAdService.getJobAds(jobAdType.joblist);
            this.jobLocations = await this.jobAdService.getLocations();
          });
      }
      this.resetForm();
      this.showModal = false;
    },
    async loadJobList() {
      this.jobAds = await this.jobAdService.getJobAds(jobAdType.joblist);
      this.jobLocations = await this.jobAdService.getLocations();
    },
    openContextMenu(event: any, jobAd: JobAd) {
      this.selectedJob = jobAd;
      event.preventDefault();

      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const menuWidth = 220;
      const menuHeight = 180;

      let xPos = event.clientX + window.scrollX;
      let yPos = event.clientY + window.scrollY;

      if (xPos + menuWidth > windowWidth + window.scrollX) {
        xPos = windowWidth + window.scrollX - menuWidth;
      }

      if (yPos + menuHeight > windowHeight + window.scrollY) {
        yPos = windowHeight + window.scrollY - menuHeight;
      }

      if (xPos < window.scrollX) {
        xPos = window.scrollX;
      }
      if (yPos < window.scrollY) {
        yPos = window.scrollY;
      }

      this.contextMenuPosition = { x: xPos, y: yPos };
      this.showContextMenu = true;
    },
    openJodAdInAtsRecruit(uuid: string) {
      const externalLink = `${this.softwareIntegration.zvooveRecruitLink}/recruiting/stelle/${uuid}/publish/history`;
      window.open(externalLink, "_blank", "width=950,height=900"); //Width 950 supress zvoove Recruits Side Menu and TODO: use responsive dimensions from service
    },
    async removeAllPosted() {
      const confirmed = await DialogService.confirm(
        "Alle geposteten Stellenanzeigen aus Liste entfernen?",
        "Nein",
        "Ja"
      );
      if (confirmed) {
        for (const jobAd of this.jobAds) {
          if (jobAd.status === PostingStatus.posted && jobAd._id) {
            await this.deleteJob(jobAd._id);
          }
        }
        this.jobAds = this.jobAds.filter(
          (jobAd) => jobAd.status !== PostingStatus.posted
        );
      }
    },
    async removeAllEntries() {
      const confirmed = await DialogService.confirm(
        "Alle Stellenanzeigen aus Liste entfernen?",
        "Nein",
        "Ja"
      );
      if (confirmed) {
        for (const jobAd of this.jobAds) {
          if (jobAd._id) {
            await this.deleteJob(jobAd._id);
          }
        }
        this.jobAds = [];
      }
    },
    resetForm() {
      this.currentJob._id = "";
      this.currentJob.status = PostingStatus.requested;
      // NO CHANGE IN:  mandant, title, tasks, profile, salaryFrom, salaryTo, salaryPeriod - if the User wants to post multiple Job-Ads in different locations
    },
    updateLocationMandant(mandant: string) {
      this.currentLocation.mandants[0] = mandant;
    },
  },
  beforeMount() {
    this.loadJobList();
  },
});
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--table-background);
}
th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid var(--border-light);
}
th {
  background-color: var(--table-header);
}
table tr:hover {
  background-color: var(--table-hover);
}
</style>
