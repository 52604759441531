<template>
  <AdminMenu />
  <PdMasterHeader v-if="layout === AppLayout.pdmaster"></PdMasterHeader>
  <v-container fluid>
    <v-row class="mt-5">
      <v-col cols="12" md="10">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-title>Backend Logs</v-card-title>
          <v-card-text>
            <!-- Filter für jede Spalte -->
            <v-row>
              <v-col cols="12" sm="2">
                <div class="d-flex justify-space-between">
                  Timestamp
                  <v-menu
                    v-model="menuState.date"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="compact"
                        variant="text"
                        v-bind="props"
                        :color="
                          filters.startDate || filters.endDate
                            ? 'tertiary'
                            : 'primary'
                        "
                      >
                        <v-icon size="xsmall">
                          {{
                            filters.startDate || filters.endDate
                              ? "fa-solid fa-filter-circle-xmark"
                              : "fas fa-filter"
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :variant="vStyle.card.variant || undefined"
                      :rounded="vStyle.card.rounded || undefined"
                      :border="vStyle.card.border || undefined"
                    >
                      <v-card-title class="d-flex mb-5"
                        >Zeitraum anzeigen <v-spacer></v-spacer>
                        <v-btn icon variant="text"
                          ><v-icon @click="resetFilters(BackendLogFilter.date)"
                            >fa-solid fa-filter-circle-xmark</v-icon
                          ></v-btn
                        ></v-card-title
                      >
                      <v-card-text>
                        <v-text-field
                          :variant="vStyle.input.variant || undefined"
                          :rounded="vStyle.input.rounded || undefined"
                          :base-color="vStyle.input.baseColor || undefined"
                          :color="vStyle.input.color || undefined"
                          v-model="filters.startDate"
                          label="Von"
                          type="datetime-local"
                          density="compact"
                          @change="applyFilters"
                        ></v-text-field>
                        <v-text-field
                          :variant="vStyle.input.variant || undefined"
                          :rounded="vStyle.input.rounded || undefined"
                          :base-color="vStyle.input.baseColor || undefined"
                          :color="vStyle.input.color || undefined"
                          v-model="filters.endDate"
                          label="Bis"
                          type="datetime-local"
                          density="compact"
                          @change="applyFilters"
                        ></v-text-field>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>

              <v-col cols="12" sm="2">
                <div class="d-flex justify-space-between">
                  Company
                  <v-menu
                    v-model="menuState.company"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="compact"
                        variant="text"
                        v-bind="props"
                        :color="
                          filters.company.length > 0 ? 'tertiary' : 'primary'
                        "
                      >
                        <v-icon size="xsmall">
                          {{
                            filters.company.length > 0
                              ? "fa-solid fa-filter-circle-xmark"
                              : "fas fa-filter"
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :variant="vStyle.card.variant || undefined"
                      :rounded="vStyle.card.rounded || undefined"
                      :border="vStyle.card.border || undefined"
                    >
                      <v-card-title class="mb-5 d-flex"
                        >Nach Unternehmen filtern<v-btn icon variant="text"
                          ><v-icon
                            @click="resetFilters(BackendLogFilter.company)"
                            >fa-solid fa-filter-circle-xmark</v-icon
                          ></v-btn
                        ></v-card-title
                      >
                      <v-card-text>
                        <v-checkbox
                          v-for="company in companyOptions"
                          :key="company"
                          v-model="filters.company"
                          :label="company"
                          :value="company"
                          density="compact"
                          @update:model-value="applyFilters"
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>

              <v-col cols="12" sm="1">
                <div class="d-flex justify-space-between">
                  User
                  <v-menu
                    v-model="menuState.user"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="compact"
                        variant="text"
                        v-bind="props"
                        :color="
                          filters.user.length > 0 ? 'tertiary' : 'primary'
                        "
                      >
                        <v-icon size="xsmall">
                          {{
                            filters.user.length > 0
                              ? "fa-solid fa-filter-circle-xmark"
                              : "fas fa-filter"
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :variant="vStyle.card.variant || undefined"
                      :rounded="vStyle.card.rounded || undefined"
                      :border="vStyle.card.border || undefined"
                    >
                      <v-card-title class="mb-5 d-flex"
                        >Nach Benutzer filtern
                        <v-btn icon variant="text"
                          ><v-icon @click="resetFilters(BackendLogFilter.user)"
                            >fa-solid fa-filter-circle-xmark</v-icon
                          ></v-btn
                        ></v-card-title
                      >
                      <v-card-text>
                        <v-checkbox
                          v-for="user in userOptions"
                          :key="user"
                          v-model="filters.user"
                          :label="user"
                          :value="user"
                          density="compact"
                          @update:model-value="applyFilters"
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>

              <v-col cols="12" sm="1">
                <div class="d-flex justify-space-between">
                  Level
                  <v-menu
                    v-model="menuState.level"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="compact"
                        variant="text"
                        v-bind="props"
                        :color="
                          filters.level.length > 0 ? 'tertiary' : 'primary'
                        "
                      >
                        <v-icon size="xsmall">
                          {{
                            filters.level.length > 0
                              ? "fa-solid fa-filter-circle-xmark"
                              : "fas fa-filter"
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :variant="vStyle.card.variant || undefined"
                      :rounded="vStyle.card.rounded || undefined"
                      :border="vStyle.card.border || undefined"
                    >
                      <v-card-title class="mb-5 d-flex"
                        >Nach Level filtern
                        <v-btn icon variant="text"
                          ><v-icon @click="resetFilters(BackendLogFilter.level)"
                            >fa-solid fa-filter-circle-xmark</v-icon
                          ></v-btn
                        ></v-card-title
                      >
                      <v-card-text>
                        <v-checkbox
                          v-for="level in levelOptions"
                          :key="level"
                          v-model="filters.level"
                          :label="level"
                          :value="level"
                          density="compact"
                          @update:model-value="applyFilters"
                        ></v-checkbox>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="d-flex justify-space-between">
                  Message
                  <v-menu
                    v-model="menuState.message"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        density="compact"
                        variant="text"
                        v-bind="props"
                        :color="filters.message ? 'tertiary' : 'primary'"
                      >
                        <v-icon size="xsmall">
                          {{
                            filters.message
                              ? "fa-solid fa-filter-circle-xmark"
                              : "fas fa-filter"
                          }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :variant="vStyle.card.variant || undefined"
                      :rounded="vStyle.card.rounded || undefined"
                      :border="vStyle.card.border || undefined"
                    >
                      <v-card-title class="mb-5 d-flex"
                        >Nachricht filtern
                        <v-btn icon variant="text"
                          ><v-icon
                            @click="resetFilters(BackendLogFilter.message)"
                            >fa-solid fa-filter-circle-xmark</v-icon
                          ></v-btn
                        ></v-card-title
                      >
                      <v-card-text>
                        <v-text-field
                          :variant="vStyle.input.variant || undefined"
                          :rounded="vStyle.input.rounded || undefined"
                          :base-color="vStyle.input.baseColor || undefined"
                          :color="vStyle.input.color || undefined"
                          v-model="filters.message"
                          label="Nachricht filtern"
                          density="compact"
                          @keydown.enter="applyFilters"
                        ></v-text-field>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>
            </v-row>

            <v-infinite-scroll
              :onLoad="loadMoreLogs"
              :disabled="loading || noMoreLogs"
              :distance="20"
            >
              <v-row noGutters v-for="log in filteredLogs" :key="log._id">
                <v-col class="mr-1" cols="12" sm="2">{{
                  formatDate(log.timestamp)
                }}</v-col>
                <v-col class="mr-1" cols="12" sm="2">{{
                  log.metadata.company
                }}</v-col>
                <v-col class="mr-1" cols="12" sm="1">{{
                  log.metadata.username
                }}</v-col>
                <v-col class="mr-1" cols="12" sm="1">{{ log.level }}</v-col>
                <v-col class="mr-1" cols="12" sm="5">{{ log.message }}</v-col>
              </v-row>
            </v-infinite-scroll>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card
          :variant="vStyle.card.variant || undefined"
          :rounded="vStyle.card.rounded || undefined"
          :border="vStyle.card.border || undefined"
        >
          <v-card-title class="mt-3"></v-card-title>
          <v-card-text>
            <v-card-title class="mb-10">Get custom count</v-card-title>
            <v-slider
              v-model="logCountToFetch"
              prepend-icon="fa-solid fa-circle-minus"
              append-icon="fa-solid fa-infinity"
              thumb-label="always"
              step="500"
              tick-size="4"
              max="20500"
              min="1000"
            >
              <template v-slot:thumb-label="{ modelValue }">
                <span v-if="modelValue === 20500">
                  <v-icon>fa-solid fa-infinity</v-icon>
                </span>
                <span v-else>
                  {{ modelValue }}
                </span>
              </template>
            </v-slider>
            <v-btn
              :variant="vStyle.btn.variant || undefined"
              :rounded="vStyle.btn.rounded || undefined"
              :border="vStyle.btn.border || undefined"
              color="primary"
              block
              @click="loadCustomCount()"
              >Fetch</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AdminMenu from "@/components/menus/admin-menu.component.vue";
import { BackendLoggingService } from "@/services/api/backend-logging.service";
import PdMasterHeader from "@/components/header/PdMasterHeader.vue";
import moment from "moment";
import { AppLayout } from "../../../enums/app-layout.enum";
import { BackendLogEntry } from "../../../models/backed-log.model";
import { BackendLogFilter } from "../../../enums/backend-log.enum";

export default defineComponent({
  name: "BackendLogs",
  components: {
    AdminMenu,
    PdMasterHeader,
  },
  data() {
    return {
      AppLayout: AppLayout,
      BackendLogFilter: BackendLogFilter,
      backendLoggingService: new BackendLoggingService(),
      layout: this.$store.getters.getEnv.VUE_APP_LAYOUT,
      vStyle: this.$store.state.vStyle,
      logs: [] as BackendLogEntry[],
      filteredLogs: [] as BackendLogEntry[],
      filters: {
        startDate: "" as string,
        endDate: "" as string,
        company: [] as string[],
        user: [] as string[],
        level: [] as string[],
        message: "",
      },
      menuState: {
        date: false,
        company: false,
        user: false,
        level: false,
        message: false,
      },
      companyOptions: [] as string[],
      userOptions: [] as string[],
      levelOptions: ["info", "warning", "error"],
      logCountToFetch: 5000,
      logCount: 100,
      logCountFrom: 0,
      loading: false,
      noMoreLogs: false,
    };
  },
  methods: {
    formatDate(dateString: string) {
      return moment(dateString).format("DD.MM.YYYY HH:mm:ss");
    },
    applyFilters() {
      this.filteredLogs = this.logs.filter((log) => {
        const matchesDate =
          (!this.filters.startDate ||
            moment(log.timestamp).isSameOrAfter(this.filters.startDate)) &&
          (!this.filters.endDate ||
            moment(log.timestamp).isSameOrBefore(this.filters.endDate));
        const matchesCompany =
          this.filters.company.length === 0 ||
          this.filters.company.includes(log.metadata.company);
        const matchesUser =
          this.filters.user.length === 0 ||
          this.filters.user.includes(log.metadata.username);
        const matchesLevel =
          this.filters.level.length === 0 ||
          this.filters.level.includes(log.level);
        const matchesMessage =
          !this.filters.message ||
          log.message
            .toLowerCase()
            .includes(this.filters.message.toLowerCase());

        return (
          matchesDate &&
          matchesCompany &&
          matchesUser &&
          matchesLevel &&
          matchesMessage
        );
      });
    },
    getNewFilterOptions() {
      if (this.logs) {
        this.companyOptions = Array.from(
          new Set(this.logs.map((log) => log.metadata.company))
        );
        this.userOptions = Array.from(
          new Set(this.logs.map((log) => log.metadata.username))
        );
        this.applyFilters();
      }
    },
    async loadBackendLogs(logCount?: number, logCountFrom?: number) {
      const response: BackendLogEntry[] =
        await this.backendLoggingService.getAllLogs(logCount, logCountFrom);
      return response;
    },
    async loadCustomCount() {
      if (this.logCountToFetch === 20500) {
        const maxLogCount = 9999999;
        this.logs = await this.loadBackendLogs(maxLogCount);
        this.noMoreLogs = true;
        this.logCountFrom = maxLogCount;
      } else {
        this.logs = await this.loadBackendLogs(this.logCountToFetch);
        this.logCountFrom = this.logCountToFetch;
      }
      this.getNewFilterOptions();
      this.applyFilters();
    },
    async loadMoreLogs({ done }: any) {
      if (this.loading || this.noMoreLogs) return;

      this.loading = true;
      try {
        const newLogs: BackendLogEntry[] = await this.loadBackendLogs(
          this.logCount,
          this.logCountFrom
        );

        if (newLogs && newLogs.length > 0) {
          this.logs.push(...newLogs);
          this.logCountFrom += this.logCount;
        } else {
          this.noMoreLogs = true;
          done("empty");
          return;
        }
        this.getNewFilterOptions();
        this.applyFilters();
        done("ok");
      } catch (error) {
        console.error("Error loading logs:", error);
        done("error");
      } finally {
        this.loading = false;
      }
    },
    resetFilters(filter?: string) {
      if (filter) {
        switch (filter) {
          case BackendLogFilter.date:
            this.filters.startDate = "";
            this.filters.endDate = "";
            this.menuState.date = false; // Menü schließen
            break;
          case BackendLogFilter.company:
            this.filters.company = [];
            this.menuState.company = false; // Menü schließen
            break;
          case BackendLogFilter.user:
            this.filters.user = [];
            this.menuState.user = false; // Menü schließen
            break;
          case BackendLogFilter.level:
            this.filters.level = [];
            this.menuState.level = false; // Menü schließen
            break;
          case BackendLogFilter.message:
            this.filters.message = "";
            this.menuState.message = false; // Menü schließen
            break;
          default:
            console.warn(`Unknown filter: ${filter}`);
        }
      } else {
        this.filters = {
          startDate: "",
          endDate: "",
          company: [],
          user: [],
          level: [],
          message: "",
        };
      }
      this.applyFilters();
    },
  },
});
</script>
