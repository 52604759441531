<template>
  <div>
    <v-btn icon variant="text" @click="showModal = true">
      <v-icon size="large" class="filter-icon">fa-solid fa-circle-plus</v-icon>
      <v-tooltip activator="parent" location="top left"
        >neue Anfrage / neuen Auftrag anlegen</v-tooltip
      >
    </v-btn>

    <v-dialog
      v-model="showModal"
      persistent
      :max-width="smAndDown ? 'var(--d-w-sd)' : 'var(--d-w-l)'"
    >
      <v-card>
        <v-card-title class="d-flex pa-5">
          <v-icon class="mx-2">fa-solid fa-file-signature</v-icon>
          {{
            modalMode === ModalMode.add
              ? "Anfrage/Auftrag anlegen"
              : "Anfrage/Auftrag ändern"
          }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="close-icon">
            <i class="fa-solid fa-xmark"></i>
          </v-icon>
        </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-row class="d-flex ma-0 pa-0">
              <v-col cols="6" class="py-0">
                <v-select
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  :items="mandants"
                  item-value="uuid"
                  item-title="name"
                  label="Niederlassung"
                  v-model="demand.mandants"
                  id="select-mandant"
                  density="compact"
                  multiple
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-select
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  :items="formattedDemandStates"
                  item-value="value"
                  item-title="title"
                  label="Status wählen"
                  v-model="demand.demandState"
                  id="select-demand"
                  density="compact"
                ></v-select>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="demand.customer"
                  label="Unternehmen"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="demand.demandContactPerson.firstName"
                  label="Ansprechperson Vorname"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="demand.demandContactPerson.lastName"
                  label="Ansprechperson Nachname"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="
                    demand.demandContactPerson.contactDetails.phoneNumber
                  "
                  label="Telefon"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pt-0 pb-6">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="demand.demandContactPerson.contactDetails.email"
                  label="Email"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  :variant="vStyle.input.variant || undefined"
                  :rounded="vStyle.input.rounded || undefined"
                  :base-color="vStyle.input.baseColor || undefined"
                  :color="vStyle.input.color || undefined"
                  v-model="demand.demandContactPerson.position"
                  label="Ansprechperson Position"
                  density="compact"
                ></v-text-field>
              </v-col>
              <v-col v-if="modalMode === ModalMode.edit" cols="6" class="py-0">
                <v-btn
                  variant="outlined"
                  :rounded="vStyle.btn.rounded || undefined"
                  :border="vStyle.btn.border || undefined"
                  @click="postJobList()"
                  >Stellen auf JobList setzen
                  <v-tooltip activator="parent" location="bottom"
                    >AI extrahiert die Aufgaben und Anforderungen aus den
                    Anfragen bzw. Aufträgen und speichert es in Recruting -
                    JobList</v-tooltip
                  ></v-btn
                >
              </v-col>
            </v-row>
            <v-container fluid>
              <v-card
                :variant="vStyle.card.variant || undefined"
                :rounded="vStyle.card.rounded || undefined"
                :border="vStyle.card.border || undefined"
                v-for="(item, index) in demand.demands"
                :key="index"
                class="mt-2 demand-card"
              >
                <v-card-title class="d-flex mb-5">
                  Anfrage / Auftrag
                  <div class="ml-1" v-if="item.position">
                    {{ item.quantity }}x "{{ item.position }}"
                  </div>
                  <v-spacer></v-spacer>
                  <v-icon @click="deleteDemand(index)" class="delete-icon">
                    <i class="fa-solid fa-trash-can"></i>
                  </v-icon>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="item.quantity"
                        label="Menge"
                        :rules="[rules.number, rules.required]"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="item.position"
                        label="Position"
                        :rules="[rules.required]"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="item.budget.amount"
                        label="Budget"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="item.budget.currency"
                        label="Währung"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-textarea
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="item.description"
                    label="Beschreibung"
                    density="compact"
                    rows="3"
                  ></v-textarea>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        density="compact"
                        v-model="newRequirement"
                        @keyup.enter="addRequirement(index)"
                        label="Skill / Voraussetzung hinzufügen"
                      />
                      <div class="tags-container">
                        <v-chip
                          v-for="(requirement, reqIndex) in item.requirements"
                          :key="reqIndex"
                          class="customer-tag"
                          color="primary"
                          text-color="var(--color-font-light)"
                          @click:close="removeRequirement(index, reqIndex)"
                        >
                          {{ requirement }}
                          <v-icon
                            small
                            @click.stop="removeRequirement(index, reqIndex)"
                            class="fa fa-times"
                          ></v-icon>
                        </v-chip>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="item.location.postcode"
                        label="PLZ"
                        :rules="[rules.postalCode, rules.required]"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        v-model="item.location.city"
                        label="Ort"
                        density="compact"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex flex-column" cols="12" sm="4">
                      <v-text-field
                        :variant="vStyle.input.variant || undefined"
                        :rounded="vStyle.input.rounded || undefined"
                        :base-color="vStyle.input.baseColor || undefined"
                        :color="vStyle.input.color || undefined"
                        label="Gültig bis"
                        type="date"
                        :rules="[rules.required]"
                        density="compact"
                        v-model="item.applicationDeadline"
                      />
                    </v-col>
                  </v-row>
                  <v-textarea
                    :variant="vStyle.input.variant || undefined"
                    :rounded="vStyle.input.rounded || undefined"
                    :base-color="vStyle.input.baseColor || undefined"
                    :color="vStyle.input.color || undefined"
                    v-model="item.additionalInformation"
                    label="weitere Information"
                    density="compact"
                    rows="2"
                  ></v-textarea>
                </v-card-text>
              </v-card>
              <v-btn class="add-demand-btn" @click="addDemand" icon>
                <v-icon> fa-solid fa-plus </v-icon>
              </v-btn>
            </v-container>
          </v-card-text>
          <v-card-actions class="action-buttons">
            <v-btn color="abort" @click="closeModal">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="saveDemand" :disabled="!valid">
              {{ modalMode === ModalMode.add ? "Anlegen" : "Speichern" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { useDisplay } from "vuetify";
import { Demand } from "@/models/demand.model";
import { ModalMode } from "@/enums/dialog-action.enum";
import { mapGetters, mapMutations } from "vuex";
import { DemandService } from "@/services/api/demand.service";
import moment from "moment";
import { InterComponentMessage } from "@/enums/inter-component-messagin.enum";
import { MutationType } from "@/enums/vuex-mutationtype.enum";
import { AiService } from "@/services/ai.service";
import ToastService from "@/services/toast.service";
import { JobAdService } from "@/services/api/job-ad.service";
import {
  JobAd,
  jobAdType,
  JobLocation,
  PostingStatus,
} from "@/models/job-ad.model";
import { BfaService } from "@/services/api/bfa.service";
import Fuse from "fuse.js";
import { SpinnerService } from "@/services/spinner.service";
import { removeIdFields } from "@/helper/remove-id.helper";
import { Customer } from "../../../models/customer.model";
import { RulesMessage } from "../../../enums/app-messages.enum";
import { DemandState } from "../../../enums/demand-states.enum";

export default defineComponent({
  name: "DialogAddEditDemand",
  emits: ["loadDemands", "insertUpdatedDemand"],
  props: {
    modalMode: {
      type: String as PropType<ModalMode>,
      required: true,
    },
  },
  data() {
    const { smAndDown } = useDisplay();
    return {
      smAndDown,
      allCategoriesBfA: [] as string[],
      city: "",
      demand: this.initializeDemand(),
      demandService: new DemandService(),
      isDemandFromPhoneClient: false,
      newRequirement: "",
      postcode: "",
      rules: {
        required: (value: string) => !!value || RulesMessage.required,
        postalCode: (value: string) =>
          /^[0-9]{4,5}$/.test(value) || RulesMessage.postcode,
        number: (value: number | null) =>
          (value !== null && !isNaN(value)) || RulesMessage.numberField,
      },
      showModal: false,
      valid: false,
      vStyle: this.$store.state.vStyle,
    };
  },
  computed: {
    ModalMode() {
      return ModalMode;
    },
    ...mapGetters({
      demandStates: "demandStates",
      interComponentMessage: "interComponentMessage",
      loggedInMandantUuids: "getLoggedInMandantUuids",
      mandants: "reducedMandants",
    }),
    formattedDemandStates() {
      return Object.keys(this.demandStates).map((key) => ({
        value: key,
        title: this.demandStates[key],
      }));
    },
  },
  watch: {
    interComponentMessage: {
      handler(newVal) {
        switch (newVal.message) {
          case InterComponentMessage.addDemandFromPhoneClient:
            this.addDemandFromPhoneClient(newVal.payload);
            this.isDemandFromPhoneClient = true;
            break;
          default:
            break;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setICM: MutationType.setICM,
      clearICM: MutationType.clearICM,
    }),
    addDemand() {
      if (this.demand.demands.length > 0) {
        this.postcode = this.demand.demands[0].location.postcode;
        this.city = this.demand.demands[0].location.city;
      }
      this.demand.demands.push({
        position: "",
        quantity: 1,
        description: "",
        requirements: [],
        location: {
          postcode: this.postcode,
          city: this.city,
        },
        applicationDeadline: moment()
          .add(this.$store.state.demandExpiryDefault, "days")
          .format("YYYY-MM-DD"),
        budget: {
          amount: 0,
          currency: "€",
        },
        additionalInformation: "",
      });
    },
    addDemandFromAI(payload: any) {
      this.demand = this.initializeDemand();
      this.demand.customer = payload.customer;
      (this.demand.mandants = this.$store.getters.getLoggedInMandantUuids),
        (this.demand.demandState = DemandState.Active);
      this.demand.demands = (
        payload.demands || [
          {
            position: "Keine Position erkannt",
            quantity: 1,
            description: "",
            requirements: [],
            location: {
              postcode: this.postcode,
              city: this.city,
            },
            applicationDeadline: moment()
              .add(this.$store.state.demandExpiryDefault, "days")
              .format("YYYY-MM-DD"),
            budget: {
              amount: 0,
              currency: "€",
            },
            additionalInformation: "",
          },
        ]
      ).map((demand: any) => {
        // Setze ein Standarddatum, wenn `applicationDeadline` nicht vorhanden ist
        if (!demand.applicationDeadline) {
          demand.applicationDeadline = moment()
            .add(this.$store.state.demandExpiryDefault, "days")
            .format("YYYY-MM-DD");
        }
        return demand;
      });

      this.demand.demandContactPerson = {
        firstName: payload.demandContactPerson?.firstName || "",
        lastName: payload.demandContactPerson?.lastName || "",
        position: payload.demandContactPerson?.position || "",
        contactDetails: {
          phoneNumber:
            payload.demandContactPerson?.contactDetails.phoneNumber || "",
          email: payload.demandContactPerson?.contactDetails.email || "",
        },
      };

      this.showModal = true;
    },
    addDemandFromCustomer(customer: Customer) {
      this.demand = this.initializeDemand();
      this.demand.customer = customer.generalData.name;
      this.demand.mandants = customer.mandants;
      this.demand.demandState = DemandState.Active;
      this.demand.demands[0].location.postcode =
        customer.addressAndCommunication.postalCode;
      this.demand.demands[0].location.city =
        customer.addressAndCommunication.city;

      this.showModal = true;
    },
    addDemandFromPhoneClient(payload: any) {
      this.demand = this.initializeDemand();
      this.demand.customer = payload.customer;
      this.demand.mandants = [payload.mandant];
      this.demand.demandState = DemandState.Active;
      this.demand.demands[0].location.postcode = payload.postcode;
      this.demand.demands[0].location.city = payload.city;

      this.demand.demandContactPerson = {
        firstName: payload.contact?.firstName || "",
        lastName: payload.contact?.lastName || "",
        position: payload.contact?.role || "",
        contactDetails: {
          phoneNumber: payload.contact?.phone || payload.phone || "",
          email: payload.contact?.email || payload.email || "",
        },
      };

      this.showModal = true;
      this.clearICM();
    },
    addRequirement(index: number) {
      if (this.newRequirement.trim()) {
        this.demand.demands[index].requirements.push(
          this.newRequirement.trim()
        );
        this.newRequirement = "";
      }
    },
    closeModal() {
      this.showModal = false;
      this.demand = this.initializeDemand();
    },
    deleteDemand(index: number) {
      this.demand.demands.splice(index, 1);
    },
    async editDemand(demand: Demand) {
      this.showModal = true;
      this.demand = demand;
    },
    initializeDemand(): Demand {
      return {
        tags: [],
        customer: "",
        mandants: this.$store.getters.getLoggedInMandantUuids,
        demandState: DemandState.Passive,
        demandContactPerson: {
          firstName: "",
          lastName: "",
          position: "",
          contactDetails: {
            phoneNumber: "",
            email: "",
          },
        },
        demands: [
          {
            position: "",
            quantity: 1,
            description: "",
            requirements: [],
            location: {
              postcode: this.postcode,
              city: this.city,
            },
            applicationDeadline: moment()
              .add(this.$store.state.demandExpiryDefault, "days")
              .format("YYYY-MM-DD"),
            budget: {
              amount: 0,
              currency: "€",
            },
            additionalInformation: "",
          },
        ],
      };
    },
    async matchCategories(jobAdDetails: any) {
      const bfaService = new BfaService();

      if (!this.allCategoriesBfA.length) {
        this.allCategoriesBfA = await bfaService.getBfaJoblist();
      }

      const fuse = new Fuse(this.allCategoriesBfA, {
        includeScore: true,
        threshold: 0.3,
      });

      const matchedCategories = jobAdDetails.bfaCategories
        .map((category: string) => {
          const result = fuse.search(category);
          return result.length ? result[0].item : null;
        })
        .filter((category: string): category is string => category !== null);

      while (matchedCategories.length < 3) {
        matchedCategories.push(this.allCategoriesBfA[matchedCategories.length]);
      }

      return matchedCategories.slice(0, 3);
    },
    async postJobList() {
      const jobs = this.demand.demands;
      SpinnerService.showSpinner();

      for (const [index, job] of jobs.entries()) {
        const cleanedJob = removeIdFields(job);
        const jobText = JSON.stringify(cleanedJob, null, 2);

        const jobAd: JobAd = {
          categoriesBfA: [] as string[],
          locationId: "",
          mandants: this.demand.mandants,
          profile: "",
          socialMedia: false,
          supportedBfAJobAd: true,
          salaryFrom: null,
          salaryTo: null,
          salaryPeriod: "",
          status: PostingStatus.requested,
          tasks: "",
          title: cleanedJob.position,
          type: jobAdType.joblist,
        };

        const location: JobLocation = {
          city: cleanedJob.location.city,
          postalCode: parseInt(cleanedJob.location.postcode),
          mandants: this.demand.mandants,
        };

        try {
          if (jobText) {
            const aiService = new AiService();
            const jobAdDetails = await aiService.extractJobAdData(jobText);
            jobAd.tasks = jobAdDetails.tasks;
            jobAd.profile = jobAdDetails.profile;

            jobAd.categoriesBfA = await this.matchCategories(jobAdDetails);
          } else {
            ToastService.showError(
              "Kein Inhalt der Anfrage gefunden, Standardeintrag wird hinzugefügt."
            );
          }

          const jobAdService = new JobAdService();
          await jobAdService.addNewJobAd(jobAd, location);
          ToastService.show(
            `Job ${index + 1} '${
              cleanedJob.position
            }' erfolgreich zur Liste hinzugefügt`
          );
        } catch (error) {
          ToastService.showError(
            "Fehler beim Hinzufügen des Jobs zur Liste: " + error
          );
        } finally {
          SpinnerService.removeSpinner();
        }
      }
    },
    removeRequirement(index: number, reqIndex: number) {
      this.demand.demands[index].requirements.splice(reqIndex, 1);
    },
    saveDemand() {
      if (this.modalMode === ModalMode.add) {
        if (this.isDemandFromPhoneClient) {
          this.setICM({
            message: InterComponentMessage.sendDemandDetailsBackToPhoneClient,
            payload: this.demand.demands,
          });
          this.isDemandFromPhoneClient = false;
        }
        this.demandService
          .addDemand(this.demand)
          .then(() => {
            this.$emit("loadDemands");
            this.closeModal();
          })
          .catch((error) => {
            console.error("Error saving demand:", error);
          });
      } else {
        this.demandService.updateDemand(this.demand).then(() => {
          this.$emit("insertUpdatedDemand", this.demand);
          this.closeModal();
        });
      }
    },
  },
});
</script>

<style scoped>
.delete-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.delete-icon:hover {
  color: var(--color-tertiary);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.add-demand-btn {
  position: relative;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-primary);
  color: var(--color-font-light);
  margin-bottom: -1rem;
}
.demand-card {
  position: relative;
}
</style>
