// src/enums/demand-states.enum.ts
export enum DemandState {
  Passive = "0",
  Active = "1",
  Urgent = "2",
  Priority = "3",
  ExclusiveConfidential = "4",
}

export enum DemandStateLabels {
  Passive = "Passive Anfrage",
  Active = "Aktive Anfrage",
  Urgent = "Dringende Anfrage",
  Priority = "Priorisierte Anfrage",
  ExclusiveConfidential = "Exklusiver vertraulicher Auftrag",
}
const demandStateLabels: { [key in DemandState]: string } = {
  [DemandState.Passive]: DemandStateLabels.Passive,
  [DemandState.Active]: DemandStateLabels.Active,
  [DemandState.Urgent]: DemandStateLabels.Urgent,
  [DemandState.Priority]: DemandStateLabels.Priority,
  [DemandState.ExclusiveConfidential]: DemandStateLabels.ExclusiveConfidential,
};

export const getDemandStates = (): { [key: string]: string } => {
  return Object.entries(DemandState)
    .filter((entry) => isNaN(Number(entry[0])))
    .reduce<{ [key: string]: string }>((acc, entry) => {
      acc[entry[1] as DemandState] = demandStateLabels[entry[1] as DemandState];
      return acc;
    }, {});
};
