<!--src/components/config/ai/AiConfigJobAdTemplate.vue-->
<template>
  <v-container>
    <h2 class="mb-5">
      <v-icon size="small" class="mr-2">fa-solid fa-rectangle-ad</v-icon>Vorlage
      Stellenanzeigen
    </h2>
    <v-form @submit.prevent="handleSubmit">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.Bezeichnung"
            label="Bezeichnung"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.StellenzielHeader"
            label="Stellenziel Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.Stellenziel"
            label="Stellenziel"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.AufgabenHeader"
            label="Aufgaben Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.Aufgaben"
            label="Aufgaben"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.PerspektivenHeader"
            label="Perspektiven Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.Perspektiven"
            label="Perspektiven"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.UnternehmensbedeutungHeader"
            label="Unternehmensbedeutung Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.Unternehmensbedeutung"
            label="Unternehmensbedeutung"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.FachlicheAnforderungenHeader"
            label="Fachliche Anforderungen Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.FachlicheAnforderungen"
            label="Fachliche Anforderungen"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.PersoenlicheAnforderungenHeader"
            label="Persönliche Anforderungen Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.PersoenlicheAnforderungen"
            label="Persönliche Anforderungen"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.ArbeitgeberleistungHeader"
            label="Arbeitgeberleistung Header"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            :variant="vStyle.input.variant || undefined"
            :rounded="vStyle.input.rounded || undefined"
            :base-color="vStyle.input.baseColor || undefined"
            :color="vStyle.input.color || undefined"
            v-model="localAiData.template.jobAd.Arbeitgeberleistung"
            label="Arbeitgeberleistung"
            rows="3"
            class="mb-4"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-btn
        :variant="vStyle.btn.variant || undefined"
        :rounded="vStyle.btn.rounded || undefined"
        :border="vStyle.btn.border || undefined"
        class="mt-10"
        type="submit"
        color="success"
        >Speichern</v-btn
      >
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { AiData } from "@/models/company-config.model";

export default defineComponent({
  name: "AiConfigJobAdTemplate",
  props: {
    modelValue: {
      type: Object as PropType<AiData>,
      required: true,
    },
  },
  data() {
    return {
      localAiData: { ...this.modelValue },
      vStyle: this.$store.state.vStyle,
    };
  },
  watch: {
    modelValue(newValue: AiData) {
      this.localAiData = { ...newValue };
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("update:modelValue", this.localAiData);
      this.$emit("save");
    },
  },
});
</script>
